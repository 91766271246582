import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { ReactionsType } from 'types/reactions'
import reactionSlice from 'redux/slices/reactions'
import ReactionCount from 'components/common/reactions/reactionCount'
import ReactionDetailModal from 'components/common/reactions/reactionDetailModal'
import EmojiPicker from 'components/common/emojiPicker'
import TapAndHold from 'components/common/tapAndHold'
import CdnSvg from 'components/common/cdnSvg'

const addReactionIconPath = '/images/addReactionIcon.svg'

interface ReactionsWidgetProps {
  reactableId: string
  reactableType: string
  reactions: ReactionsType[]
  currentUserReactions: { emojiId: string, isViaSlack: boolean }[]
  disablePicker?: boolean
  className?: string
  onlyLike?: boolean
}

const EmptyLikes = props => (
  <ReactionCount
    emojiId='heart'
    display='❤️'
    custom={false}
    totalCount={0}
    {...props}
  />
)

const ReactionsWidget = ({
  reactableId,
  reactableType,
  reactions = [],
  currentUserReactions = [],
  disablePicker,
  className = 'my-3',
  onlyLike = false,
}: ReactionsWidgetProps) => {
  const dispatch = useDispatch()
  const [selectedEmojiId, setSelectedEmojiId] = useState('')
  const isDetailModalOpen = !!selectedEmojiId
  const filteredReactions = useMemo(
    () => reactions.filter(reaction => !onlyLike || (onlyLike && reaction.emojiId === 'heart')),
    [reactions, onlyLike]
  )

  const onEmojiSelect = (emoji) => {
    const isCustomReaction = emoji.src && !emoji.native
    const nativeParams = {
      emojiId: emoji.id,
      nativeEmoji: emoji.native,
      unified: emoji.unified,
      shortcode: emoji.shortcodes,
      skin: emoji.skin,
    }

    if (getCurrentUserReactionDetails(emoji.id).hasReacted) {
      if (isCustomReaction) return

      updateSkinTone(nativeParams)
    } else {
      const params = isCustomReaction ? { isCustomReaction, customReactionName: emoji.name } : nativeParams

      dispatch(reactionSlice.asyncActions.create(reactableId, reactableType, params))
    }
  }

  const updateSkinTone = (params) => {
    dispatch(reactionSlice.asyncActions.update(reactableId, reactableType, params))
  }

  const currentUserReactionsIndexed = useMemo(
    () => _.keyBy(currentUserReactions, 'emojiId'),
    [currentUserReactions]
  )

  const getCurrentUserReactionDetails = (emojiId: string) => {
    const reaction = currentUserReactionsIndexed[emojiId]
    const hasReacted = !!reaction
    const hasReactedViaSlack = reaction?.isViaSlack || false

    return { hasReacted, hasReactedViaSlack }
  }

  return (
    <div className={classNames('ReactionsWidget', className)}>
      <div className='d-flex flex-row gap-2 flex-wrap'>
        {filteredReactions.map(({
          emojiId, display, totalCount, custom,
        }) => (
          <TapAndHold key={emojiId} onTapAndHold={() => setSelectedEmojiId(emojiId)}>
            <ReactionCount
              emojiId={emojiId}
              display={display}
              custom={custom}
              totalCount={totalCount}
              currentUserReactionDetails={getCurrentUserReactionDetails(emojiId)}
              reactableId={reactableId}
              reactableType={reactableType}
              onPopoverClick={setSelectedEmojiId}
            />
          </TapAndHold>
        ))}

        {!onlyLike ? (
          <EmojiPicker
            onEmojiSelect={onEmojiSelect}
            trigger={<CdnSvg src={addReactionIconPath} />}
            triggerClassName='AddReaction d-flex align-items-center h-100'
            disablePicker={disablePicker}
          />
        ) : (
          filteredReactions.length === 0 && (
            <EmptyLikes
              currentUserReactionDetails={getCurrentUserReactionDetails('heart')}
              reactableId={reactableId}
              reactableType={reactableType}
              onPopoverClick={() => {}}
            />
          )
        )}
      </div>

      {isDetailModalOpen && (
        <ReactionDetailModal
          visible
          toggle={() => setSelectedEmojiId('')}
          reactions={filteredReactions}
          reactableId={reactableId}
          reactableType={reactableType}
          selectedEmoji={selectedEmojiId}
          setSelectedEmoji={setSelectedEmojiId}
        />
      )}
    </div>
  )
}

export default ReactionsWidget
