import React from 'react'
import { BubbleMenu } from '@tiptap/react'
import LinkBubbleMenuOptions from 'components/common/tiptap/menus/linkBubbleMenuOptions'
import ImageBubbleMenuOptions from 'components/common/tiptap/menus/imageBubbleMenuOptions'
import EmbedBubbleMenuOptions from 'components/common/tiptap/menus/embedBubbleMenuOptions'
import VideoBubbleMenuOptions from 'components/common/tiptap/menus/videoBubbleMenuOptions'
import SocialShareBubbleMenuOptions from 'components/common/tiptap/menus/socialShareBubbleMenuOptions'

const ClearyBubbleMenu = ({ editor }) => {
  const isLinkActive = editor.isActive('link')
  const isEmbedActive = editor.isActive('inlineEmbed') || editor.isActive('blockEmbed')
  const isImageActive = editor.isActive('inlineImage') || editor.isActive('blockImage')
  const isSocialShareActive = editor.isActive('socialShare')
  const isVideoActive = editor.isActive('blockVideo') || editor.isActive('inlineVideo')

  const showBubbleMenu = ({ editor }) => editor.isActive('link')
    || editor.isActive('inlineImage')
    || editor.isActive('blockImage')
    || editor.isActive('inlineEmbed')
    || editor.isActive('blockEmbed')
    || editor.isActive('blockVideo')
    || editor.isActive('inlineVideo')
    || editor.isActive('socialShare')

  const Component = () => {
    if (isImageActive) {
      return <ImageBubbleMenuOptions editor={editor} />
    } else if (isLinkActive) {
      return <LinkBubbleMenuOptions editor={editor} />
    } else if (isEmbedActive) {
      return <EmbedBubbleMenuOptions editor={editor} />
    } else if (isVideoActive) {
      return <VideoBubbleMenuOptions editor={editor} />
    } else if (isSocialShareActive) {
      return <SocialShareBubbleMenuOptions editor={editor} />
    }

    return null
  }

  return (
    <BubbleMenu
      className='ClearyBubbleMenu'
      editor={editor}
      tippyOptions={{
        duration: 0,
        interactive: true,
        placement: 'top',
      }}
      shouldShow={showBubbleMenu}
    >
      {Component()}
    </BubbleMenu>
  )
}

export default ClearyBubbleMenu
