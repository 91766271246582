import React from 'react'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import classNames from 'classnames'

interface IconButtonWithPopoverProps {
  id?: string | number
  text: string
  icon: React.ReactNode
  onClick?: () => void
  className?: string
  popoverClassName?: string
  textClassName?: string
  popoverPlacement?: string // Supports bottom and right
}

const IconButtonWithPopover = ({
  id,
  text,
  icon,
  onClick = () => {},
  className = 'mr-2',
  popoverPlacement = 'bottom',
  popoverClassName = 'IconButtonPopover p-2',
  textClassName = 'text-white',
} : IconButtonWithPopoverProps) => (
  <div className={className}>
    <HoverAndClickPopover
      target={(
        <div className='IconButtonWithPopover pointer' onClick={onClick}>
          {icon}
        </div>
      )}
      id={id?.toString()}
      placement={popoverPlacement}
      popoverClassName={classNames(popoverClassName, `${popoverPlacement}Popover`)}
    >
      <span className={textClassName}>{text}</span>
    </HoverAndClickPopover>
  </div>
)

export default IconButtonWithPopover
