import Axios from 'axios'

export default {
  summary(params) {
    return Axios.post('/api/admin/analytics/search/summary', params)
  },
  usage(params) {
    return Axios.post('/api/admin/analytics/search/usage', params)
  },
  topQueries(params) {
    return Axios.post('/api/admin/analytics/search/top_queries', params)
  },
  queriesWithZeroResults(params) {
    return Axios.post('/api/admin/analytics/search/queries_with_zero_results', params)
  },
  selectedResultTypes(params) {
    return Axios.post('/api/admin/analytics/search/selected_result_types', params)
  },
  topSelectedResults(params) {
    return Axios.post('/api/admin/analytics/search/selected_results', params)
  },
}
