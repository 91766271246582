import Axios from 'axios'
import snakeCaseKeysObjectsOnly from 'utils/snakeCaseKeysObjectsOnly'

export default {
  fetchAll(userIds) {
    const request = {
      params: { user_ids: userIds },
    }
    return Axios.get('/api/users', request)
  },

  simpleFetchAll(userIds, params = {}, usernames = undefined) {
    const request = {
      params: snakeCaseKeysObjectsOnly(params) || {},
    }

    return Axios.post('/api/users/simple', { user_ids: userIds, usernames }, request)
  },

  fetchActiveUsersCount() {
    return Axios.get('/api/users/active_count')
  },

  fetchCelebrations() {
    return Axios.get('/api/users/celebrations')
  },

  fetchFromCSV(data) {
    return Axios.post('/api/users/from_csv', data)
  },

  grantSoftLaunchAccess(id) {
    return Axios.post(`/api/users/${id}/grant_soft_launch_access`)
  },

  recentCelebration(id, celebrationType) {
    return Axios.get(`/api/users/${id}/recent_celebrations/${celebrationType}`)
  },
}
