import API from 'services/api'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import MentionList from 'components/common/tiptap/suggestions/mentions/mentionList'
import baseSuggestion from 'components/common/tiptap/suggestions/baseSuggestion'

const mentionSuggestion = {
  allowSpaces: true,

  items: async ({ query }) => {
    const response = await API.globalSearch(query, { type: 'user' })
    const results = denormalizedJsonApiResponse(response, 'searchResult')

    return results.map(({ user }) => user)
  },

  debounce: 300,

  ...baseSuggestion(MentionList, '@'),
}

export default mentionSuggestion
