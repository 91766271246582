import React, { useState, useRef } from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.tiptap.toolbar')

const AUTOPLAY_ATTRS = ['autoplay', 'mute', 'muted']

const AutoplayButton = ({ editor }) => {
  const getActiveNodeType = () => {
    if (editor.isActive('inlineVideo')) {
      return 'inlineVideo'
    } else if (editor.isActive('blockVideo')) {
      return 'blockVideo'
    } else if (editor.isActive('inlineEmbed')) {
      return 'inlineEmbed'
    } else if (editor.isActive('blockEmbed')) {
      return 'blockEmbed'
    } else {
      return null
    }
  }

  const nodeType = getActiveNodeType()
  const isVideo = ['inlineVideo', 'blockVideo'].includes(nodeType || '')

  const src = editor.getAttributes(nodeType).src || ''
  const isYoutubeVideo = src.includes('youtube')
  const isVimeoVideo = src.includes('vimeo')
  const isLoomVideo = src.includes('loom')
  const isVideoUrl = isYoutubeVideo || isVimeoVideo || isLoomVideo
  const videoAutoplay = editor.getAttributes(nodeType).autoplay
  const isAutoplayEnabled = isVideo ? videoAutoplay : src.includes('autoplay=1')


  const toggleAutoplay = () => {
    if (isVideo) {
      editor.chain().focus().updateAttributes(nodeType, { autoplay: !isAutoplayEnabled }).run()
    } else {
      const url = new URL(src)
      const searchParams = url.searchParams

      AUTOPLAY_ATTRS.forEach((attr) => {
        if (isAutoplayEnabled) {
          searchParams.delete(attr)
        } else {
          searchParams.set(attr, '1')
        }
      })

      url.search = searchParams.toString()
      editor.chain().focus().updateAttributes(nodeType, { src: url.toString() }).run()
    }
  }

  if (!isVideo && !isVideoUrl) return null

  return (
    <ToolbarButton
      onClick={toggleAutoplay}
      tooltipText={I18N('autoplay')}
      isActive={isAutoplayEnabled}
    >
      <CdnSvg src='/images/tiptap/replay.svg' />
    </ToolbarButton>
  )
}

export default AutoplayButton
