import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'

import videoUploadSlice from 'redux/slices/videoUploads'
import API from 'services/api'
import appSignal from 'services/appSignal'

const uploadVideoFromFile = async (file, clearyRichTextId, dispatch, callback) => {
  try {
    const response = await API.videos.create({ name: file?.name, clearyRichTextId, encodedState: 'uploading' })
    const videoId = response?.data?.data?.id

    uploadVideoWithUppy(file, videoId, dispatch)

    return callback(videoId)
  } catch (e) {
    return null
  }
}

const uploadVideoWithUppy = (file, videoId, dispatch) => {
  const uppy = new Uppy({
    restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['video/*'] },
    autoProceed: true,
  })
  .use(AwsS3Multipart, { companionUrl: '/' })
  .on('upload-progress', _.debounce((file, progress) => {
    const percentageProgress = Math.round((progress.bytesUploaded / progress.bytesTotal) * 100)

    dispatch(videoUploadSlice.actions.setUploadProgress({
      videoId,
      progress: percentageProgress,
      status: 'uploading',
    }))
  }, 200))
  .on('complete', async (result) => {
    const file = result.successful[0] as any

    const video = {
      id: file.s3Multipart.key.split('cache/')[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
      originalUrl: file.uploadURL,
    }

    const videoParams = {
      id: videoId,
      name: video.metadata.filename,
      encodedState: 'new',
      video,
    }

    await API.videos.update(videoParams)
  })
  .on('upload-error', async (_file, error, _response) => {
    await API.videos.update({
      id: videoId,
      encodedState: 'failed',
    })

    appSignal.sendErrorUnlessClearyBackendError(error)
  })

  uppy.addFile({
    name: file.name,
    type: file.type,
    data: file,
  })
}

export default uploadVideoFromFile
