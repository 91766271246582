import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const BlockquoteButton = ({ editor, isDisabled = false }) => (
  <ToolbarButton
    className='BlockquoteButton'
    onClick={() => editor.chain().focus().toggleBlockquote().run()}
    isActive={editor.isActive('blockquote')}
    tooltipText={I18N('blockquote_tooltip')}
    isDisabled={isDisabled}
  >
    <CdnSvg src='/images/tiptap/quotation-mark.svg' />
  </ToolbarButton>
)

export default BlockquoteButton
