import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

const initialState = {
  softLaunchUserIds: [],
  userIdsApprovedForExternalAlerts: [],
  softLaunchUsersCount: { activeCount: 0, totalCount: 0 },
  meta: {
    isLoadingSoftLaunchUsers: false,
    queryParamsForSoftLaunchUsers: defaultPaginationParams,
    isLoadingUsersApprovedForExternalAlerts: false,
    queryParamsForUsersApprovedForExternalAlerts: defaultPaginationParams,
  },
}

const userAccessManagementSlice = createSlice({
  name: 'userAccessManagement',
  initialState,
  reducers: {
    setIsLoadingUsersApprovedForExternalAlerts(state, action) {
      state.meta.isLoadingUsersApprovedForExternalAlerts = action.payload
    },
    setQueryParamsForUsersApprovedForExternalAlerts(state, action) {
      state.meta.queryParamsForUsersApprovedForExternalAlerts = action.payload
    },
    setUserIdsApprovedForExternalAlerts(state, action) {
      state.userIdsApprovedForExternalAlerts = action.payload
    },
    removeUserIdApprovedForExternalAlerts(state, action) {
      const userId = action.payload
      state.userIdsApprovedForExternalAlerts = _.reject(state.userIdsApprovedForExternalAlerts, id => id === userId)
    },
    setIsloadingSoftLaunchUsers(state, action) {
      state.meta.isLoadingSoftLaunchUsers = action.payload
    },
    setQueryParamsForSoftLaunchUsers(state, action) {
      state.meta.queryParamsForSoftLaunchUsers = action.payload
    },
    setSoftLaunchUserIds(state, action) {
      state.softLaunchUserIds = action.payload
    },
    addSoftLaunchUserIds(state, action) {
      const currentUserIdsSet = new Set(state.softLaunchUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.softLaunchUserIds = Array.from(currentUserIdsSet)
    },
    setSoftLaunchUsersCount(state, action) {
      state.softLaunchUsersCount = action.payload
    },
  },
})

_.assign(userAccessManagementSlice, {
  asyncActions: {
    fetchSoftLaunchUsersCount: () => async (dispatch) => {
      const response = await API.admin.users.fetchSoftLaunchUsersCount()

      const countData = response.data
      dispatch(userAccessManagementSlice.actions.setSoftLaunchUsersCount(countData))
    },

    fetchSoftLaunchUsers: queryParams => async (dispatch) => {
      dispatch(userAccessManagementSlice.actions.setIsloadingSoftLaunchUsers(true))
      try {
        const response = await API.admin.users.fetchSoftLaunchUsers({ ...queryParams })
        const newQueryParams = queryParamsFromHeaders(response)
        const userIds = response.data.data.map(user => user.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(userAccessManagementSlice.actions.setQueryParamsForSoftLaunchUsers(newQueryParams))
        dispatch(userAccessManagementSlice.actions.setSoftLaunchUserIds(userIds))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(userAccessManagementSlice.actions.setIsloadingSoftLaunchUsers(false))
      }
    },

    fetchUsersApprovedForExternalAlerts: queryParams => async (dispatch, getState) => {
      dispatch(userAccessManagementSlice.actions.setIsLoadingUsersApprovedForExternalAlerts(true))

      try {
        const response = await API.admin.users.fetchUsersApprovedForExternalAlerts(queryParams)
        const newQueryParams = queryParamsFromHeaders(response)
        const userIds = response.data.data.map(user => user.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(userAccessManagementSlice.actions.setQueryParamsForUsersApprovedForExternalAlerts(newQueryParams))
        dispatch(userAccessManagementSlice.actions.setUserIdsApprovedForExternalAlerts(userIds))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(userAccessManagementSlice.actions.setIsLoadingUsersApprovedForExternalAlerts(false))
      }
    },
  },
})

_.assign(userAccessManagementSlice, {
  selectors: {
    getUsersApprovedForExternalAlerts: () => state => state.userAccessManagement.userIdsApprovedForExternalAlerts.map(id => build(state.entities, 'user', id)),

    getUserExternalAlertsPageData: () => (state) => {
      const {
        isLoadingUsersApprovedForExternalAlerts,
        queryParamsForUsersApprovedForExternalAlerts,
      } = state.userAccessManagement.meta
      const isLoading = isLoadingUsersApprovedForExternalAlerts
      const queryParams = queryParamsForUsersApprovedForExternalAlerts
      return { isLoading, queryParams }
    },

    getSoftLaunchUsers: () => state => state.userAccessManagement.softLaunchUserIds.map(id => build(state.entities, 'user', id)),

    getSoftLaunchAccessPageData: () => (state) => {
      const { isLoadingSoftLaunchUsers, queryParamsForSoftLaunchUsers } = state.userAccessManagement.meta
      const isLoading = isLoadingSoftLaunchUsers
      const queryParams = queryParamsForSoftLaunchUsers
      return { isLoading, queryParams }
    },

    getSoftLaunchUsersCount: () => state => state.userAccessManagement.softLaunchUsersCount,
  },
})

export default userAccessManagementSlice
