import Axios from 'axios'
import searchURI from 'services/searchURI'
import snakeCaseKeys from 'utils/snakeCaseKeys'

const generateCrudRoutes = (
  baseRoute,
  modelName,
  additionalOptions = {},
  buildPayload = model => model
) => ({
  fetchAll(opts) {
    const options = { page: 1, ...opts, ...additionalOptions }

    return Axios.get(searchURI(`${baseRoute}`, options))
  },

  fetch(id, queryParams = {}) {
    return Axios.get(searchURI(`${baseRoute}/${id}`, queryParams), additionalOptions)
  },

  create(model) {
    if (Object.values(model).some(value => value instanceof File)) {
      return Axios.post(`${baseRoute}`, generateFormData(modelName, buildPayload(model)), additionalOptions)
    }

    return Axios.post(`${baseRoute}`, { [modelName]: buildPayload(model) }, additionalOptions)
  },

  update(model) {
    if (Object.values(model).some(value => value instanceof File)) {
      return Axios.patch(`${baseRoute}/${model.id}`, generateFormData(modelName, buildPayload(model)), additionalOptions)
    }

    return Axios.patch(`${baseRoute}/${model.id}`, { [modelName]: buildPayload(model) }, additionalOptions)
  },

  destroy(model) {
    return Axios.delete(`${baseRoute}/${model.id}`, additionalOptions)
  },
})

// the FormDataParser middleware will conver the attributes object to Rails params
const generateFormData = (modelName, model) => {
  const formData = new FormData()
  const attributes = {}
  Object.entries(model).forEach(([key, value]) => {
    if (value instanceof File) {
      formData.append(`${_.snakeCase(modelName)}[${_.snakeCase(key)}]`, value, value.fileName)
    } else {
      attributes[key] = value
    }
  })

  formData.append(`${_.snakeCase(modelName)}[attributes]`, JSON.stringify(snakeCaseKeys(attributes)))

  return formData
}

export default generateCrudRoutes
