import generateCrudRoutes from 'services/generateCrudRoutes'

const buildUserSkillPayload = userSkill => _.pick(userSkill, [
  'id',
  'name',
  'userSkillTypeId',
])

const userSkills = generateCrudRoutes(
  '/api/admin/user_skills',
  'user_skill',
  {},
  buildUserSkillPayload
)

export default userSkills
