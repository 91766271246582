import React, { useState, useEffect, useRef } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import Comment from 'components/comments/comment'
import CommentEditor from 'components/comments/commentEditor'
import CdnSvg from 'components/common/cdnSvg'
import commentSlice from 'redux/slices/comments'
import commentsCountText from 'components/comments/commentsCount'
import { useDispatch, useSelector } from 'react-redux'
import { useSlideShow } from 'pages/celebrations/slide_show_page/useSlideShow'

const I18N = i18nPath('views.comments')

interface CommentsWidgetProps {
  style?: any
  className?: string
  commentable: any
  type: string
  setCommentsCount: (count: number) => void
  commentsCount: number
  disableComments?: boolean
  isReadOnly?: boolean
  hideDeleteOption?: boolean
  showCommentsOnInit?: boolean
  commentsListWrapperClass?: string
  disableNewComments?: boolean
  onToggleComments?: () => void
}

const CommentsWidget = ({
  style,
  className,
  commentable,
  type,
  setCommentsCount,
  commentsCount,
  disableComments,
  isReadOnly,
  hideDeleteOption = false,
  showCommentsOnInit,
  commentsListWrapperClass,
  disableNewComments = false,
  onToggleComments,
}: CommentsWidgetProps) => {
  const dispatch = useDispatch()
  const slideShow = useSlideShow()

  const reduxComments = useSelector(commentSlice.selectors.getComments(type, commentable.id))
  const comments = _.sortBy(reduxComments, 'createdAt')
  const { queryParams, isLoading } = useSelector(commentSlice.selectors.getMetaData(type, commentable.id))
  const showSeeMoreLink = (queryParams.total || commentsCount) > comments?.length

  const [isCommentsWidgetVisible, setCommentsWidgetVisible] = useState(false)
  const [commentEditorFocus, setCommentEditorFocus] = useState(false)

  const commentsWidgetRef = useRef(null)

  const toggleCommentsWidget = () => {
    if (onToggleComments) {
      onToggleComments()

      return
    }

    if (isReadOnly) return
    if (commentsCount === 0 && disableNewComments) return
    setCommentsWidgetVisible(!isCommentsWidgetVisible)
  }

  const onDeleteComment = (comment) => {
    if (confirm(I18N('delete_confirm'))) {
      dispatch(commentSlice.asyncActions.delete(comment, type, () => setCommentsCount(commentsCount - 1)))
    }
  }

  const fetchComments = ({ page = 1 }) => {
    const qp = { ...queryParams, page, perPage: 3 }
    dispatch(commentSlice.asyncActions.fetchAll(commentable, type, qp))
  }

  const onCommentSent = () => {
    setCommentsCount(commentsCount + 1)
  }

  const onEditorFocus = () => {
    slideShow?.pause()
  }

  const onEditorBlur = () => {
    slideShow?.resume()
  }

  useEffect(() => {
    if (commentsCount === 0) {
      setCommentEditorFocus(true)
    }
  }, [])

  // Should be visible for articles
  useEffect(() => {
    if (showCommentsOnInit && commentsCount) {
      setCommentsWidgetVisible(true)
      fetchComments({ page: 1 })
    }

    return (() => {
      dispatch(commentSlice.actions.reset({ type, commentableId: commentable.id }))
    })
  }, [])

  // For when it's used in the article editor
  if (disableComments) return null

  return (
    <span className={classNames('CommentsWidget--Wrapper', className)} ref={commentsWidgetRef} style={style}>
      <span className='CommentsWidget--Toggle pill-button' onClick={toggleCommentsWidget}>
        <span className='text-small d-flex align-items-center'>
          <CdnSvg src='/images/commentIcon.svg' className='mr-1' />
          {commentsCountText(commentsCount, disableNewComments)}
        </span>
      </span>

      {!isReadOnly && isCommentsWidgetVisible && (
        <div className={classNames('CommentsWidget--Comments position-relative mt-3 ml-2', commentsListWrapperClass)}>
          <div className='pl-3'>
            {showSeeMoreLink && (
              <div className='mb-2'>
                {isLoading ? (
                  <LoadingIndicator className='ml-2' />
                ) : (
                  <span onClick={() => fetchComments({ page: queryParams.page + 1 })} className='see-more-comments link'>
                    {I18N('see_more_comments')}
                  </span>
                )}
              </div>
            )}

            <div>
              {comments.map(comment => (
                <Comment
                  comment={comment}
                  key={comment.id}
                  onDeleteComment={onDeleteComment}
                  hideDeleteOption={hideDeleteOption}
                  commentable={commentable}
                  type={type}
                />
              ))}
              {!disableNewComments && (
                <CommentEditor
                  commentable={commentable}
                  type={type}
                  onSendComment={onCommentSent}
                  focusOnInitialize={commentEditorFocus}
                  handleDOMEvents={{
                    focus: onEditorFocus,
                    blur: onEditorBlur,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </span>
  )
}

export default CommentsWidget
