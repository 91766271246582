import build from 'redux-object'
import { createSlice } from '@reduxjs/toolkit'
import appSignal from 'services/appSignal'

import API from 'services/api'

import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import entitySlice from 'redux/slices/entities'

export const initialState = {
  meta: {
    ...defaultMeta,
    isLoadingUserSetting: false,
    pushNotificationsEnabled: false,
  },
}

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    ...defaultActions,

    isLoadingUserSetting(state, action) {
      state.meta.isLoadingUserSetting = action.payload
    },

    setPushNotificationsEnabled(state, action) {
      state.meta.pushNotificationsEnabled = action.payload
    },
  },
})

const asyncActions = {
  fetchAll: () => async (dispatch) => {
    dispatch(settingSlice.actions.isLoading(true))

    try {
      const response = await API.settings.fetchAll()
      // using names as ids because many settings have a null id
      const settings = response.data.data.map(setting => ({
        ...setting,
        id: setting.attributes?.name,
      }))

      dispatch(settingSlice.actions.setPushNotificationsEnabled(response.data.meta.pushNotificationsEnabled))
      dispatch(entitySlice.actions.add({ data: { data: settings } }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(settingSlice.actions.isLoading(false))
    }
  },

  fetch: (name, onSuccess = (value) => {}) => async (dispatch) => {
    dispatch(settingSlice.actions.isLoadingUserSetting(true))

    try {
      const response = await API.settings.fetch(name)

      // using name as id because default setting has a null id
      const setting = {
        ...response.data.data,
        id: response.data.data.attributes?.name,
      }

      dispatch(entitySlice.actions.add({ data: { data: setting } }))
      onSuccess(setting.attributes.value)
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(settingSlice.actions.isLoadingUserSetting(false))
    }
  },

  update: (name, value) => async (dispatch) => {
    try {
      const response = await API.settings.update({ name, value })
      const setting = {
        ...response.data.data,
        id: name,
      }

      dispatch(entitySlice.actions.update({ data: { data: setting } }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    }
  },
}


const selectors = {
  getMetaData: () => state => state.settings.meta,

  getSetting: name => state => build(state.entities, 'setting', name),
}

export default {
  ...settingSlice,
  asyncActions,
  selectors,
}
