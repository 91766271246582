import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'

const EMPTY_EDITOR_CLASS = 'is-editor-empty'

const ClearyPlaceholder = Extension.create({
  name: 'placeholder',

  addOptions() {
    return {
      placeholder: 'Write something …',
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('placeholder'),
        props: {
          decorations: ({ doc, selection }) => {
            const { from, anchor } = selection
            const decorations: Decoration[] = []
            const firstChild = doc.firstChild
            const isEditorEmpty = doc.childCount === 1 && firstChild?.isTextblock && firstChild?.content.size === 0

            if (from !== 1 || !isEditorEmpty) {
              return null
            }

            doc.descendants((node, pos) => {
              const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize

              if (hasAnchor) {
                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                  'class': EMPTY_EDITOR_CLASS,
                  'data-placeholder': this.options.placeholder,
                })

                decorations.push(decoration)
              }

              return false
            })

            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})

export default ClearyPlaceholder
