import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarColorPicker from 'components/common/tiptap/toolbar/items/toolbarColorPicker'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { findListType } from './utils'

const I18N = i18nPath('views.tiptap.toolbar')

const TextColorPicker = ({ editor, isDisabled = false }) => {
  const { customStyles } = useCurrentCompany()
  const defaultColor = customStyles?.variables?.colors?.textColorPrimary || '#0e003c'

  const handleChange = (newColor) => {
    editor.chain().focus().setColor(newColor).run()

    const listType = findListType(editor)

    if (listType) {
      editor.chain().focus().setMarkerColor(newColor, listType).run()
    }
  }

  return (
    <ToolbarColorPicker
      className='TextColorPicker'
      onChange={handleChange}
      onRemove={() => editor.chain().focus().unsetColor().run()}
      color={editor.getAttributes('textStyle')?.color || defaultColor}
      tooltipText={I18N('text_color_tooltip')}
      isDisabled={isDisabled}
    >
      <CdnSvg src='/images/tiptap/text-color.svg' />
    </ToolbarColorPicker>
  )
}

export default TextColorPicker
