import React, { useRef, useState } from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import classNames from 'classnames'
import BlockColorPicker from 'components/common/blockColorPicker'

interface Props {
  color: any
  onChange: (newColor: string) => void
  onRemove: () => void
  tooltipText: string
  children: any
  isDisabled?: boolean
  className?: string
}

const ToolbarColorPicker = ({
  color, onChange, tooltipText, onRemove, children, isDisabled = false, className = '',
}: Props) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  return (
    <div className='ToolbarColorPicker position-relative' ref={buttonRef}>
      <ToolbarButton
        className={classNames('ToolbarColorPickerButton', className)}
        onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
        tooltipText={tooltipText}
        hideTooltip={isColorPickerOpen}
        isDisabled={isDisabled}
      >
        {children}
        <BlockColorPicker
          isOpen={isColorPickerOpen}
          onClose={() => setIsColorPickerOpen(false)}
          color={color}
          onChange={onChange}
          onRemove={onRemove}
          buttonRef={buttonRef}
        />
      </ToolbarButton>
    </div>
  )
}

export default ToolbarColorPicker
