import aiAnswers from './analytics/aiAnswers'
import culture from './analytics/culture'
import engagement from './analytics/engagement'
import modules from './analytics/modules'
import news from './analytics/news'
import pages from './analytics/pages'
import search from './analytics/search'

export default {
  aiAnswers,
  culture,
  engagement,
  modules,
  news,
  pages,
  search,
}
