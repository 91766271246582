import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import { eventCaptureKey } from 'components/common/tiptap/extensions/captureEvent'

const baseSuggestion = (Component, targetChar) => ({
  render: () => {
    let component
    let popup

    return {
      onStart: (props) => {
        component = new ReactRenderer(Component, {
          props,
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        if (props.query === ' ') {
          popup[0].hide()
        } else {
          component.updateProps(props)

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        }
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component?.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
      },
    }
  },

  allow: ({ state, isActive = false }) => {
    const eventCaptureState = eventCaptureKey.getState(state)

    if (eventCaptureState === 'Escape' || eventCaptureState === 'mousedown') {
      return false
    }

    if (!isActive && eventCaptureState !== targetChar) {
      return false
    }

    return true
  },
})

export default baseSuggestion
