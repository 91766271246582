import React, { useState, useEffect } from 'react'
import ReactSelect from 'components/common/react_select'
import { useDispatch, useSelector } from 'react-redux'

import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

import API from 'services/api'
import userSlice from 'redux/slices/users'

import { I18NCommon } from 'utils/i18nHelpers'
import { components } from 'react-select'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { useDebounce } from 'usehooks-ts'
import CdnSvg from 'components/common/cdnSvg'

export const Option = (props) => {
  const { data } = props

  return (
    <components.Option {...props}>
      <EmployeeMiniCard key={data.username} employee={data} />
    </components.Option>
  )
}

const fetchResults = async (
  input, setOptions, setIsLoading, includeInactiveUsers, includeHiddenUsers,
  excludeUsersHiddenInOrgChart, excludedUserIds, requiredUserFlags
) => {
  const params: Record<string, any> = { type: 'user' }
  if (includeInactiveUsers) {
    params.includeInactiveUsers = true
  }

  if (includeHiddenUsers) {
    params.includeHiddenUsers = true
  }

  if (excludeUsersHiddenInOrgChart) {
    params.excludeUsersHiddenInOrgChart = true
  }

  if (requiredUserFlags) {
    params.requiredUserFlagFilters = requiredUserFlags
  }

  const response = await API.globalSearch(input, params)

  const results = denormalizedJsonApiResponse(response, 'searchResult')

  const users = results
    .filter(result => result.user && !excludedUserIds.includes(result.user.id))
    .map(result => ({
      ...result.user,
      value: result.user.username,
      label: result.user.preferredFullName,
      type: 'user',
    }))

  setOptions(users)
  setIsLoading(false)
}

interface EmployeeSearchProps {
  onChange?: any
  className?: string
  selectedEmployee?: any
  selectedEmployees?: any[]
  initialSelectedEmployeeId?: any
  isDisabled?: boolean
  isClearable?: boolean
  isMulti?: boolean
  includeInactiveUsers?: boolean
  includeHiddenUsers?: boolean
  excludeUsersHiddenInOrgChart?: boolean
  placeholder?: string
  autoFocus?: boolean,
  styles?: { [key: string]: any }
  customFetchResults?: any
  defaultOptions?: any[]
  excludedUserIds?: string[]
  showAlertIconForInactiveUsers?: boolean
  requiredUserFlags?: string[]
}

const EmployeeSearch = ({
  onChange,
  className,
  selectedEmployee,
  selectedEmployees,
  initialSelectedEmployeeId,
  isDisabled = false,
  isClearable = true,
  isMulti = false,
  includeInactiveUsers = false,
  includeHiddenUsers = false,
  excludeUsersHiddenInOrgChart = false,
  placeholder = I18n.t('views.employee_search.placeholder'),
  autoFocus = false,
  styles = {},
  customFetchResults,
  defaultOptions,
  excludedUserIds = [],
  showAlertIconForInactiveUsers = false,
  requiredUserFlags,
}: EmployeeSearchProps) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState(defaultOptions || [])
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState('')
  const reduxUser = useSelector(state => (
    initialSelectedEmployeeId && userSlice.selectors.getUser(initialSelectedEmployeeId)(state))
  )

  const debouncedValue = useDebounce(value, 500)

  useEffect(() => {
    if (debouncedValue?.length > 2) {
      setIsLoading(true)
      if (customFetchResults) {
        customFetchResults(debouncedValue, setOptions, setIsLoading)
      } else {
        fetchResults(
          debouncedValue, setOptions, setIsLoading, includeInactiveUsers,
          includeHiddenUsers, excludeUsersHiddenInOrgChart, excludedUserIds,
          requiredUserFlags
        )
      }
    } else {
      setOptions(defaultOptions || [])
    }
  }, [debouncedValue])

  const noOptionHandler = ({ inputValue }) => {
    if (inputValue.length < 3) {
      return I18NCommon('type_to_search')
    }
    if (options.length === 0) {
      return I18NCommon('no_results_found')
    }
    return null
  }

  useEffect(() => {
    if (defaultOptions) {
      setOptions(defaultOptions)
    }
  }, [JSON.stringify(defaultOptions)])

  useEffect(() => {
    // if there's no initial value don't do anything
    if (!initialSelectedEmployeeId) {
      return
    }

    // if a user has been set, don't do anything
    if (selectedEmployee?.id) {
      return
    }

    // if redux user is present, we should set it as the selectedEmployee
    if (reduxUser?.id) {
      onChange(reduxUser)
      return
    }

    // dispatch request to obtain user, this will make the effect run again and set the redux user as the selected employee
    dispatch(userSlice.asyncActions.fetchUser(initialSelectedEmployeeId))
  }, [initialSelectedEmployeeId, reduxUser?.id, selectedEmployee?.id])

  return (
    <div className={`EmployeeSearch ${className || ''}`}>
      <ReactSelect
        name='form-field-name'
        isLoading={isLoading}
        getOptionValue={user => user.username}
        getOptionLabel={user => (
          <span className='d-flex align-items-center'>
            {!user.active && showAlertIconForInactiveUsers ? (
              <CdnSvg src='/images/alertOutlineIcon.svg' className='AlertOutlineIcon mr-2' />
            ) : (
              <EmployeeThumbnailPhoto employee={user} size='22px' className='mr-1' />
            )}
            <span className='d-flex align-items-center h-100 text-normal'>{user.preferredFullName}</span>
          </span>
        )}
        onChange={onChange}
        onInputChange={setValue}
        options={options}
        isClearable={isClearable}
        placeholder={placeholder}
        value={isMulti ? selectedEmployees : selectedEmployee}
        noOptionsMessage={noOptionHandler}
        filterOption={() => true}
        components={{
          Option,
        }}
        isDisabled={isDisabled}
        isMulti={isMulti}
        autoFocus={autoFocus}
        styles={styles}
      />
    </div>
  )
}

export default EmployeeSearch
