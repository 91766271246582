import React, { useEffect, useMemo } from 'react'
import useApi from 'components/common/hooks/useApi'
import Lottie, { LottieComponentProps } from 'lottie-react'
import API from 'services/api'
import classNames from 'classnames'

interface Props {
  effect: string
  onComplete?: () => void
  loop?: LottieComponentProps['loop']
  fixed?: boolean
  className?: string
}

const effectLoops = {
  stars: 3,
}

const EffectPlayer = ({
  effect, onComplete, loop, fixed = true, className = '',
}: Props) => {
  const [fetchEffect, {
    data,
    isLoading,
  }] = useApi(API.celebrations.loadEffect, {
    globalStateBucket: `effect-${effect}`,
  })

  useEffect(() => {
    if (data || isLoading) return

    fetchEffect(effect)
  }, [effect])

  // the Lottie animationData should be mutable
  // so let's just clone the data
  // since redux data is not mutable
  const extensibleData = useMemo(() => {
    if (!data) return null

    return _.cloneDeep(data)
  }, [data])

  if (!extensibleData) return null

  const fixedClassesName = fixed ? 'position-fixed top-0 left-0 right-0 bottom-0' : ''

  return (
    <Lottie
      className={classNames('EffectPlayer', fixedClassesName, className)}
      animationData={extensibleData}
      loop={loop || effectLoops[effect] || false}
      onComplete={onComplete}
    />
  )
}

export default EffectPlayer
