import React, { useState } from 'react'
import classNames from 'classnames'
import KebabIcon from 'components/icons/kebabIcon'

const KebabMenu = ({
  lightColor = false,
  className = '',
  iconWidth = '13px',
  iconHeight = '3px',
}) => (
  <span
    data-testid='cy_kebab_menu'
    className={classNames('KebabMenu text-smallest d-flex align-items-center justify-content-center', className, { lightColor })}
  >
    <span>
      <KebabIcon
        fill={lightColor ? 'var(--white-pure)' : 'var(--kebab-menu-light-color)'}
        width={iconWidth}
        height={iconHeight}
      />
    </span>
  </span>
)

export default KebabMenu
