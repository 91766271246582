import React, { useRef, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ColorPicker, { ColorPickerPreview } from 'components/common/tiptap/menus/context_menu_items/colorPicker'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const DEFAULT_CELL_OUTLINE_COLOR = '#E4E4E4'

const OutlineColorItem = ({ editor, onClose }) => {
  const [isColorPickerOpened, setColorPickerOpened] = useState(false)
  const headerOutlineColor = editor.getAttributes('tableHeader')?.outlineColor
  const cellOutlineColor = editor.getAttributes('tableCell')?.outlineColor
  const outlineColor = headerOutlineColor || cellOutlineColor || DEFAULT_CELL_OUTLINE_COLOR
  const ref = useRef<HTMLDivElement>(null)

  return (
    <BaseContextMenuItem
      label={I18N('cell_style.submenu.border_color')}
      onClick={() => setColorPickerOpened(!isColorPickerOpened)}
      ref={ref}
      component={(
        <>
          <ColorPickerPreview color={outlineColor} />

          <ColorPicker
            isOpen={isColorPickerOpened}
            onClose={() => setColorPickerOpened(false)}
            color={outlineColor}
            onChange={(color) => {
              onClose()
              editor.chain().focus().setCellAttribute('outlineColor', color).run()
            }}
            onRemove={() => {
              onClose()
              editor.chain().focus().setCellAttribute('outlineColor', null).run()
            }}
            buttonRef={ref}
          />
        </>
      )}
    />
  )
}

export default OutlineColorItem
