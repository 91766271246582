import React from 'react'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'
import { i18nPath } from 'utils/i18nHelpers'
import isAppleOS from 'utils/isAppleOS'

const I18N = i18nPath('views.tiptap.context_menu')

const CopyMenuItem = ({ editor, onClose }) => (
  <BaseContextMenuItem
    iconPath='/images/duplicateIcon.svg'
    label={I18N('copy')}
    onClick={() => {
      onClose()
      editor.commands.focus()

      // The timeout here is needed to make sure the tiptap editor is enable to copy the content
      // I couldn't figure out why exactly it is needed, but it works.
      setTimeout(() => {
        document.execCommand('copy')
      }, 100)
    }}
    disabled={editor.state.selection.empty}
    shortcut={isAppleOS() ? '⌘ + C' : 'Ctrl + C'}
  />
)

export default CopyMenuItem
