import generateCrudRoutes from 'services/generateCrudRoutes'

const buildUserSkillTypePayload = userSkillType => _.pick(userSkillType, [
  'id',
  'name',
  'slug',
])

const userSkillTypes = generateCrudRoutes(
  '/api/admin/user_skill_types',
  'user_skill_type',
  {},
  buildUserSkillTypePayload
)

export default userSkillTypes
