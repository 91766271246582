import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'

const adminLayouts = {
  layoutCollections: generateCrudRoutes('/api/admin/layout/layout_collections', 'layoutCollection'),

  layoutCollectionTemplates: {
    fetchAll(params) {
      return Axios.get(searchURI('/api/admin/layout/layout_collection_templates', params))
    },
  },

  layouts: {
    fetch(layoutCollectionId, size) {
      return Axios.get(`/api/admin/layout/layout_collections/${layoutCollectionId}/layouts/${size}`)
    },

    update(layoutCollectionId, layout) {
      return Axios.patch(`/api/admin/layout/layout_collections/${layoutCollectionId}/layouts/${layout.id}`, { layout })
    },
  },

  availableWidgets: {
    fetchAll(layoutCollectionId) {
      return Axios.get(`/api/admin/layout/layout_collections/${layoutCollectionId}/available_widgets`)
    },
  },
}

export default adminLayouts
