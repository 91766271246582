import { Extension } from '@tiptap/core'
import { Suggestion } from 'components/common/tiptap/extensions/suggestion'
import slashCommandSuggestion from 'components/common/tiptap/suggestions/slash_commands/slashCommandSuggestion'
import { trackAmplitudeEvent } from 'services/tracker'
import { PluginKey } from '@tiptap/pm/state'

const trackCommand = itemName => trackAmplitudeEvent('tiptap_insert_menu_item_selected', { item: itemName })

const onSlashCommand = ({ editor, range, props }) => {
  // Delete the slash text that was typed, but remain in the same position
  const rangeStart = range.from - 1
  const isStartOfLine = rangeStart === 0 || _.isEmpty(editor.state.doc.textBetween(rangeStart - 1, rangeStart))
  if (isStartOfLine) {
    editor.chain().focus().deleteRange({ from: rangeStart, to: range.to }).insertContentAt(rangeStart, '<p></p>')
    .run()
  } else {
    editor.chain().focus().deleteRange({ from: rangeStart, to: range.to }).run()
  }

  // Track the command
  trackCommand(props.id)

  // Run the selected command
  if (props.onClick) props.onClick(editor)

  return true
}

const slashCommandPluginKey = new PluginKey('slash-command')

const SlashCommand = Extension.create({
  name: 'slash-command',

  addOptions() {
    return {
      suggestion: {
        char: '/',
        pluginKey: slashCommandPluginKey,
        allowSpaces: true,

        command: onSlashCommand,
      },
      aiEnabled: false,
    }
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        ...slashCommandSuggestion({
          aiEnabled: this.options.aiEnabled,
          socialShareEnabled: this.options.socialShareEnabled,
        }),
      }),
    ]
  },
})


export default SlashCommand
