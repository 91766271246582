import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SimpleUserType } from 'types/user'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'
import { EditorState } from '@tiptap/pm/state'

export type SimpleUserWithColor = SimpleUserType & {
  color: string
  name: string
  lastContributedAt: string
}

// these were generated by ChatGPT, they should be all different and look ok on a white background
export const COLLABORATIVE_USER_COLORS = [
  '#FF4136',
  '#FF851B',
  '#FFDC00',
  '#2ECC40',
  '#0074D9',
  '#B10DC9',
  '#FF69B4',
  '#FF38CA',
  '#F012BE',
  '#01FF70',
  '#3D9970',
  '#7FDBFF',
  '#AAAAAA',
  '#85144B',
  '#39CCCC',
  '#FF6D3F',
  '#9E7A2B',
  '#FF587D',
  '#006E51',
  '#E83E8C',
]

const addUserToEditor = (editor, currentUser, users: SimpleUserWithColor[]) => {
  let selectedColor = users.find(user => user.id === currentUser.id)?.color

  if (!selectedColor) {
    const usedColors = users.map(user => user.color)
    const unusedColors = usedColors
      ? COLLABORATIVE_USER_COLORS.filter((color: string) => !usedColors?.includes(color))
      : COLLABORATIVE_USER_COLORS
    selectedColor = _.isEmpty(unusedColors) ? _.sample(COLLABORATIVE_USER_COLORS) : _.sample(unusedColors)
  }

  editor.chain().updateUser({
    ..._.pick(currentUser, 'id', 'preferredFullName', 'title', 'city', 'username', 'primaryPhotoThumbnailUrl'),
    name: currentUser.preferredFullName,
    color: selectedColor,
    active: true,
  }).run()
}


const resetEditorState = (editor) => {
  setTimeout(() => {
    const newEditorState = EditorState.create({
      doc: editor.state.doc,
      plugins: editor.state.plugins,
      schema: editor.state.schema,
    })
    editor.view.updateState(newEditorState)
  })
}

const useCollaborativeEditing = (isCollaborative: boolean, editor: any) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const usersCurrentlyEditing: SimpleUserWithColor[] = editor?.storage?.collaborationCursor?.users || []

  useEffect(() => {
    if (!editor || !isCollaborative) return undefined

    addUserToEditor(editor, currentUser, usersCurrentlyEditing)

    // we need to reset the editor state otherwise a user can undo and delete all the initial content of the editor
    resetEditorState(editor)

    dispatch(collaborativeEditorSlice.actions.setEditor(editor))

    return () => {
      dispatch(collaborativeEditorSlice.actions.setEditor(null))
    }
  }, [editor])

  useEffect(() => {
    if (editor && isCollaborative) {
      dispatch(collaborativeEditorSlice.actions.setUsersCurrentlyEditing(_.uniqBy(usersCurrentlyEditing, 'id')))
    }

    return () => {
      if (editor && isCollaborative) {
        dispatch(collaborativeEditorSlice.actions.setUsersCurrentlyEditing([]))
      }
    }
  }, [JSON.stringify(usersCurrentlyEditing.map(user => user.id))])
}

export default useCollaborativeEditing
