import React from 'react'

interface Value {
  isPublicApp: boolean
}

const PublicAppContext = React.createContext<Value>({
  isPublicApp: false,
})

export const useIsPublicApp = () => {
  const { isPublicApp } = React.useContext(PublicAppContext)

  return isPublicApp
}

export default PublicAppContext
