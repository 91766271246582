import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'

const I18NCommon = i18nPath('views.common')

const initialState = {
  meta: {
    ...defaultMeta,
  },
}

const invitationSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  batchCreate: (invitable, type, params, onSuccess = () => {}) => async (dispatch) => {
    dispatch(invitationSlice.actions.isSaving(true))

    try {
      await API.invitations.batchCreate(invitable, type, params)
      dispatch(showToastMessage({ message: I18NCommon('invitations_created'), type: 'success' }))
      onSuccess()
    } catch (e) {
      dispatch(showToastMessage({ message: I18NCommon('failed_to_create_invitations'), type: 'error' }))
    } finally {
      dispatch(invitationSlice.actions.isSaving(false))
    }
  },
  admin: {
    resendInvitation: (invitationId, onSuccess = () => null, onFinally = () => null) => async (dispatch) => {
      try {
        await API.admin.invitations.resendInvitation(invitationId)
        dispatch(showToastMessage({ message: I18NCommon('invitation_resent'), type: 'success' }))
        onSuccess()
      } catch (e) {
        const errorMessage = I18NCommon('failed_to_resend_invitation')
        dispatch(showToastMessage({ message: errorMessage, type: 'error' }))
      } finally {
        onFinally()
      }
    },
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.invitations.meta,
}

export { initialState }
export default {
  ...invitationSlice,
  asyncActions,
  selectors,
}
