import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import CancelButton from 'components/common/cancelButton'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'

const I18N = i18nPath('views.common.delete_modal')

const DeleteModal = ({
  className = undefined,
  deleteText = I18N('delete_text'),
  deleteSecondaryText = I18N('delete_secondary_text'),
  cancelText = I18N('cancel_text'),
  deleteConfirmText = I18N('delete_confirm_text'),
  deleteConfirm,
  showDeleteModal,
  closeDeleteModal,
  isDeleting = false,
}) => (
  <Modal size='md' className={classNames('DeleteModal', className)} visible={showDeleteModal} toggle={closeDeleteModal}>
    <div className='p-2 text-center'>
      <h4 className='font-weight-600'>{deleteText}</h4>
      <div className='text-secondary mb-5 font-weight-400'>{deleteSecondaryText}</div>
      <div className='d-flex justify-content-center align-items-center'>
        <CancelButton className='mr-3 text-secondary font-weight-600' onClick={closeDeleteModal} disabled={isDeleting}>
          {cancelText}
        </CancelButton>
        <Button onClick={deleteConfirm} disabled={isDeleting} showLoadingSpinner={isDeleting} variant='danger'>
          {deleteConfirmText}
        </Button>
      </div>
    </div>
  </Modal>
)

export default DeleteModal
