import React, { useCallback, useState } from 'react'

import { Button } from 'components/common/buttons'
import { I18NCommon } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import ShareWidget from 'components/common/social_share/shareWidget'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { trackEvent } from 'services/tracker'

const SocialShareButton = ({
  articleId,
  url,
  suggestedText,
  variant = 'primary',
}) => {
  const [popoverTarget, setPopoverTarget] = useState(null)

  const { settings: { news: { socialSharing } } } = useCurrentCompany()

  const availableSocialNetworks = Object
    .keys(socialSharing || {})
    .filter(network => socialSharing[network])

  const onClick = useCallback(async (network) => {
    if (articleId) {
      await trackEvent('article:social_shared', {
        trackableId: articleId,
        trackableType: 'Article',
        link: url,
        network,
      })
    }
  }, [articleId])

  if (_.isEmpty(availableSocialNetworks)) return null

  return (
    <div className='SocialShareButton'>
      <Button ref={el => setPopoverTarget(el)} onClick={e => e.preventDefault()} variant={variant}>
        <span>
          {I18NCommon('share_this_link')} <CdnSvg src='/images/shareIcon.svg' className='shareIcon ml-1' />
        </span>
      </Button>

      {popoverTarget && (
        <ShareWidget
          availableNetworks={availableSocialNetworks}
          onClick={onClick}
          url={url}
          suggestedText={suggestedText}
          target={popoverTarget}
        />
      )}
    </div>
  )
}

export default SocialShareButton
