import { useRef } from 'react'
import API from 'services/api'
import appSignal from 'services/appSignal'

const useTransactionLatencyMonitoring = (isCollaborative) => {
  const transactionsCount = useRef(0)
  const averageTransactionLatency = useRef(0)
  const lastValueSavedAt = useRef(0)

  const saveTransactionLatency = (transaction) => {
    const now = Date.now()
    // wait 1 minute before sending the next value to avoid sending too many requests to the server
    if (!isCollaborative || lastValueSavedAt.current + 60000 > now) return

    const latency = now - transaction.time
    averageTransactionLatency.current = (
      averageTransactionLatency.current * transactionsCount.current + latency
    ) / (transactionsCount.current + 1)
    transactionsCount.current += 1

    if (transactionsCount.current === 50) {
      lastValueSavedAt.current = now
      try {
        API.tiptapEditor.trackLatency(averageTransactionLatency.current)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      }

      transactionsCount.current = 0
      averageTransactionLatency.current = 0
    }
  }

  return saveTransactionLatency
}

export default useTransactionLatencyMonitoring
