import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import FormEditableField from 'components/form_fields/formEditableField'
import FormCheck from 'components/form_fields/formCheck'
import checkIfValidUrl from 'utils/checkIfValidUrl'

const I18N = i18nPath('views.tiptap.toolbar')
const SCHEME_REGEX = /^[a-z][a-z0-9+\-.]*:\/\//i


const LinkDropdownContent = ({
  editor,
  onUpdateSuccess = () => {},
  nodeName = 'link',
}) => {
  const getLinkData = () => {
    let linkData = {
      text: '',
      start: null,
      end: null,
      marks: [],
    }

    const { from, to } = editor.state.selection

    // Since the selected text might be part of a link, but the selection might not be the entire link,
    // we need to find the start and end of the link if it exists
    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === 'text' && node.marks.some(mark => mark.type.name === 'link')) {
        if (pos <= from && pos + node.text.length >= to) {
          linkData = {
            text: node.text,
            start: pos,
            end: pos + node.text.length,
            marks: node.marks,
          }
        }
      }
    })

    // If there was no link, we just use the selected text
    if (!linkData.text) {
      linkData.text = editor.state.doc.textBetween(from, to)
    }

    return linkData
  }

  const linkData = getLinkData()
  const initialText = linkData.text
  const isInitialTextEmpty = _.isEmpty(initialText)
  const [text, setText] = useState(initialText || '')
  const [url, setUrl] = useState(editor.getAttributes(nodeName)?.href || '')
  const completedUrl = SCHEME_REGEX.test(url) ? url : 'https://' + url
  const [openInNewTab, setOpenInNewTab] = useState(true)
  const target = openInNewTab ? '_blank' : '_self'
  const isImageSelected = nodeName !== 'link'
  const disableUpdateButton = _.isEmpty(url) || (!isImageSelected && _.isEmpty(text))

  const updateLink = () => {
    const href = url.startsWith('{') && url.endsWith('}') ? url : completedUrl

    if (isImageSelected) {
      editor.commands.updateAttributes(nodeName, { href, target })
    } else if (isInitialTextEmpty) {
      // When there's no selected text, we insert a new link
      const content = `<a href="${href}" target="${target}">${text}</a>`

      editor.chain().focus().insertContent(content).run()
    } else if (linkData.start !== null && linkData.end !== null) {
      // This gets called when there's a link already selected and we want to update it
      let transaction = editor.state.tr
      // We use a transaction to delete the old link and insert the new one
      // making sure we keep all the old attributes and marks
      transaction = transaction.delete(linkData.start, linkData.end)
      const linkMark: any = linkData.marks.find((mark: any) => mark.type.name === 'link')
      const linkMarkAttrs = linkMark ? linkMark.attrs : {}
      const otherMarks = linkData.marks.filter((mark: any) => mark.type.name !== 'link')

      transaction = transaction.insert(
        linkData.start,
        editor.schema.text(
          text,
          [...otherMarks, editor.schema.marks.link.create({ ...linkMarkAttrs, href, target })])
      )
      editor.view.dispatch(transaction)
    } else {
      // this gets called when there's a selected text without a link and we want to add a link to it
      editor.chain().focus().setLink({ href, target }).run()
    }

    onUpdateSuccess()
  }

  return (
    <form className='LinkDropdownContent p-3' onSubmit={updateLink}>
      {!isImageSelected && (
        <FormEditableField
          currentValue={text}
          fieldName='text'
          label={I18N('link_dropdown_content.text')}
          onChange={({ target }) => setText(target.value)}
          isRequired
          autoFocus={isInitialTextEmpty}
        />
      )}
      <FormEditableField
        currentValue={url}
        fieldName='url'
        placeholder='https://example.com'
        label={I18N('link_dropdown_content.url')}
        onChange={({ target }) => setUrl(target.value)}
        isRequired
        autoFocus={!isInitialTextEmpty || isImageSelected}
      />
      <FormCheck
        name='open_in_new_tab'
        label={I18N('link_dropdown_content.open_in_new_tab')}
        checked={openInNewTab}
        onChange={({ target }) => setOpenInNewTab(target.checked)}
        id='open_in_new_tab'
        className='my-2'
      />
      <div className='d-flex justify-content-end'>
        <ButtonSmallNarrow
          type='submit'
          className='mt-2'
          disabled={disableUpdateButton}
        >
          {I18N(isInitialTextEmpty ? 'link_dropdown_content.insert_link' : 'link_dropdown_content.update_link')}
        </ButtonSmallNarrow>
      </div>
    </form>
  )
}

export default LinkDropdownContent
