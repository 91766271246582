import URI from 'urijs'
import snakeCaseKeysObjectsOnly from 'utils/snakeCaseKeysObjectsOnly'

const searchURI = (route, opts) => {
  if (opts && opts.constructor === Object) {
    const { arrays, nonArrays } = splitObjectIntoArraysAndNonArrays(opts)

    const uri = new URI(route).search(snakeCaseKeysObjectsOnly(nonArrays))

    Object.entries(arrays).forEach(([key, value]) => {
      value.forEach(v => uri.addSearch(`${_.snakeCase(key)}[]`, v))
    })

    return uri
  } else {
    return new URI(route).search(snakeCaseKeysObjectsOnly(opts))
  }
}

const splitObjectIntoArraysAndNonArrays = (obj) => {
  const arrays = {}
  const nonArrays = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      arrays[key] = value
    } else {
      nonArrays[key] = value
    }
  })

  return { arrays, nonArrays }
}

export default searchURI
