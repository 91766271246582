import API from 'services/api'
import { trackAmplitudeEvent } from 'services/tracker'
import { isDevelopmentEnv } from 'utils/env'
import { i18nPath } from 'utils/i18nHelpers'
import pageVariables from 'utils/pageVariables'

const CELEBRATIONS_CDN_DOMAIN = 'celebrations.gocleary.com'

const I18N = i18nPath('views.celebrations.greeting_card')

const useSocialShare = (celebration) => {
  const availableNetworks = ['linkedin', 'twitter', 'facebook', 'copy']

  const handleShareClick = source => (network) => {
    API.celebrations.socialShare(celebration.id, network, source)
  }

  const resolveUrl = (network) => {
    const host = isDevelopmentEnv ? window.location.host : `https://${cdnDomain}`

    return `${host}/public/c/${celebration.socialShareToken}?utm_source=${network}`
  }

  const onSocialSharePreview = async () => {
    await API.celebrations.socialShare(celebration.id, 'preview', 'social_share_preview')

    trackAmplitudeEvent('celebration_preview_clicked', {
      celebration_type: celebration.type,
      milestone: 'say_thanks',
    })

    window.open(resolveUrl('preview'), '_blank')
  }

  const suggestedText = I18N(`social_share_suggested_text.${celebration.type}`)
  const title = I18N('social_share_title')

  let { cdnDomain } = pageVariables
  const { environment } = pageVariables

  if (environment === 'production' || environment === 'blue') {
    cdnDomain = CELEBRATIONS_CDN_DOMAIN
  }

  return {
    availableNetworks,
    handleShareClick,
    onSocialSharePreview,
    resolveUrl,
    suggestedText,
    title,
  }
}

export default useSocialShare
