import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

const I18N = i18nPath('views.tiptap.toolbar.image_bubble_menu')

const ImageStyleDropdownContent = ({ editor }) => {
  const nodeName = editor.isActive('blockImage') ? 'blockImage' : 'inlineImage'
  const attributes = editor.getAttributes(nodeName)
  const isRounded = !!attributes.borderRadius
  const isBordered = !!attributes.border
  const hasShadow = !!attributes.boxShadow

  return (
    <div>
      <div
        className={classNames('pointer w-100 p-2', { isActive: isRounded })}
        onClick={() => {
          const newAttributes = { borderRadius: isRounded ? undefined : '10px' }
          editor.commands.updateAttributes(nodeName, newAttributes)
        }}
      >
        {I18N('rounded')}
      </div>
      <div
        className={classNames('pointer w-100 p-2', { isActive: isBordered })}
        onClick={() => {
          const newAttributes = { border: isBordered ? undefined : '5px solid #CCC' }
          editor.commands.updateAttributes(nodeName, newAttributes)
        }}
      >
        {I18N('bordered')}
      </div>
      <div
        className={classNames('pointer w-100 p-2', { isActive: hasShadow })}
        onClick={() => {
          const newAttributes = { boxShadow: hasShadow ? undefined : '10px 10px 5px 0px #CCC' }
          editor.commands.updateAttributes(nodeName, newAttributes)
        }}
      >
        {I18N('shadow')}
      </div>
    </div>
  )
}

const ImageStyleButton = ({ editor }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <ToolbarDropdownButton
      className={classNames('TiptapImageStyleButton')}
      dropdownContent={<ImageStyleDropdownContent editor={editor} />}
      tooltipText={I18N('style')}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
    >
      <CdnSvg src='/images/tiptap/star-in-box.svg' />
    </ToolbarDropdownButton>
  )
}

export default ImageStyleButton
