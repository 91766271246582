import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useIsPublicApp } from 'publicApps/publicAppContext'

const useIsCelebrationForCurrentUser = (celebration) => {
  const currentUser = useCurrentUser()
  const isPublicApp = useIsPublicApp()

  return !isPublicApp && celebration?.user?.id === currentUser.id
}

export default useIsCelebrationForCurrentUser
