import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

const I18N = i18nPath('views.tiptap.toolbar.image_bubble_menu')

const VideoDisplaySelect = ({ editor, isDisabled = false }) => {
  const isBlockVideo = editor.isActive('blockVideo')
  const selectedNode = isBlockVideo ? 'blockVideo' : 'inlineVideo'
  const currentAttributes = editor.getAttributes(selectedNode)

  return (
    <ToolbarSelect
      className={classNames('VideoDisplaySelect', { disabled: isDisabled })}
      options={[{
        value: 'inline',
        label: I18N('inline'),
        onClick: () => {
          if (!isBlockVideo) return

          editor.chain().focus().setInlineVideo({
            ...currentAttributes,
            float: null,
            display: 'inline',
          }).run()
        },
        isActive: !isBlockVideo,
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }, {
        value: 'block',
        label: I18N('break_text'),
        onClick: () => {
          editor.chain().focus().setBlockVideo({
            ...currentAttributes,
            float: null,
            display: 'block',
          }).run()
        },
        isActive: isBlockVideo && !currentAttributes.float,
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }, {
        value: 'wrap_left',
        label: I18N('wrap_text_left'),
        onClick: () => {
          editor.chain().focus().setBlockVideo({
            ...currentAttributes,
            float: 'left',
            display: 'block',
          }).run()
        },
        isActive: isBlockVideo && currentAttributes.float === 'left',
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }, {
        value: 'wrap_right',
        label: I18N('wrap_text_right'),
        onClick: () => {
          editor.chain().focus().setBlockVideo({
            ...currentAttributes,
            float: 'right',
            display: 'block',
          }).run()
        },
        isActive: isBlockVideo && currentAttributes.float === 'right',
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }]}
      tooltipText={I18N('display')}
      isDisabled={isDisabled}
    />
  )
}

export default VideoDisplaySelect
