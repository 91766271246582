import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import PaperPlaneSendIcon from 'components/icons/paperPlaneSendIcon'

import { i18nPath } from 'utils/i18nHelpers'
import commentSlice from 'redux/slices/comments'

const I18N = i18nPath('views.tiptap')

interface CommentEditorProps {
  comment?: any
  commentable: any
  focusOnInitialize?: boolean
  isEditing?: boolean
  onSendComment?: () => void
  type: string
  handleDOMEvents?: any
}

const CommentEditor = ({
  comment,
  commentable,
  focusOnInitialize,
  isEditing = false,
  onSendComment,
  handleDOMEvents = {},
  type,
}: CommentEditorProps) => {
  const [commentContent, setCommentContent] = useState(comment?.content || '')
  const dispatch = useDispatch()
  const tiptapEditorRef = useRef<any>()

  const onChange = (commentContent) => {
    setCommentContent(commentContent)
  }

  const sendComment = (content) => {
    if (isEditing) {
      dispatch(commentSlice.asyncActions.update(comment, type, { content }, onSendComment))
    } else {
      dispatch(commentSlice.asyncActions.create(commentable, type, { content }, onSendComment))
    }

    tiptapEditorRef.current?.commands?.clearContent(true)
    tiptapEditorRef.current?.commands?.blur()
    setCommentContent('')
  }

  const disableSendButton = _.isEmpty(commentContent)

  const onKeyDown = (_eventView, e) => {
    // detect if the document has a div with the class 'MentionList' or 'EmojiList'
    // if it does, it means that the dropdown list is active
    // and we should not submit the comment on enter
    const isMentionOrEmojiListActive = !!document.querySelector('.MentionList, .EmojiList')

    const areCorrectKeysPressed = e.key === 'Enter' && !e.shiftKey && !e.ctrlKey

    if (areCorrectKeysPressed && tiptapEditorRef.current && !isMentionOrEmojiListActive) {
      const editor = tiptapEditorRef.current
      const content = editor.getHTML()
      sendComment(content)
      return true
    }

    return false
  }

  return (
    // This ID is necessary to inject the Tribute's results DropDown below to comment input
    <div id={`comment-input-box-${commentable.id}`} className='position-relative mt-1'>
      <TiptapEditor
        configuration={{
          ...SIMPLE_EDITOR_CONFIGURATION,
          placeholder: I18N('comments_placeholder_text'),
          imagesEnabled: true,
          focusOnInitialize,
          userMentionsEnabled: true,
        }}
        toolbarItems={[]}
        onChange={onChange}
        html={commentContent}
        className='CommentsWidget--TiptapEditor'
        handleKeyDown={onKeyDown}
        editorRef={tiptapEditorRef}
        handleDOMEvents={handleDOMEvents}
      />
      <div className='position-absolute right-0 bottom-0 zindex-base'>
        <button
          data-testid='cy_send_comment'
          className='btn btn-icon mb-2'
          onClick={() => sendComment(commentContent)}
          disabled={disableSendButton}
          style={{ height: '100%' }}
        >
          <PaperPlaneSendIcon />
        </button>
      </div>
    </div>
  )
}

export default CommentEditor
