import { useMemo, useState } from 'react'


const useMemoryPaginationAndSearch = (data, { pageSize = 10, searchField = '' } = {}) => {
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')

  const start = (page - 1) * pageSize
  const end = start + pageSize

  const filteredData = useMemo(() => {
    if (!query || !searchField) return data

    return (
      data.filter(d => (
        d[searchField].toLowerCase().includes(query.toLowerCase())
      ))
    )
  }, [data, query, searchField])

  const paginatedData = useMemo(() => filteredData.slice(start, end), [filteredData, start, end])

  const paginationContainerProps = {
    page,
    numPages: Math.ceil(filteredData.length / pageSize),
    perPage: pageSize,
    totalCount: filteredData.length,
    handleClick: setPage,
  }

  const searchInputProps = {
    value: query,
    onChange: (e) => {
      setPage(1)
      setQuery(e.target.value)
    },
  }

  return {
    paginatedData,
    paginationContainerProps,
    searchInputProps,
  }
}

export default useMemoryPaginationAndSearch
