import { Extension } from '@tiptap/core'

const TARGETED_NODE_BEFORE_TYPES = ['blockquote', 'bulletList', 'orderedList']

const ClearyBackspace = Extension.create({
  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const state = editor.state
        const { $from, to } = state.selection

        // Only when the selection is empty.
        if ($from.pos !== to) {
          return false
        }

        // Check if cursor is at the start of a paragraph that follows a targeted node.
        if ($from.parent && $from.parent.type.name === 'paragraph' && !$from.parent.textContent) {
          const nodeBefore = state.doc.resolve($from.pos - 1).nodeBefore

          // If the node before is a targeted node, move the cursor to its end.
          if (nodeBefore && TARGETED_NODE_BEFORE_TYPES.includes(nodeBefore.type.name)) {
            const targetPos = $from.pos - 2 // -1 for the position before, another -1 to get inside the targeted node
            return editor.chain().deleteRange({ from: $from.pos - 1, to: $from.pos }).focus(targetPos).run()
          }
        }

        return false
      },
    }
  },
})

export default ClearyBackspace
