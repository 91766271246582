import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import commentSlice from 'redux/slices/comments'
import celebrationSlice from 'redux/slices/celebrations'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import StatelessCardComment from 'components/celebrations/greetingCardComment/statelessCardComment'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import TrashIcon from 'components/icons/trashIcon'
import DeleteModal from 'components/common/modals/deleteModal'
import SignGreetingCardModal from 'components/celebrations/signGreetingCardModal'
import { useIsPublicApp } from 'publicApps/publicAppContext'

const I18N = i18nPath('views.celebrations.greeting_card.comments')
const pencilIconPath = '/images/pencilIcon.svg'

const Actions = ({
  canDeleteComment,
  canEditComment,
  handleDelete,
  isDeleting,
  comment,
}) => {
  const [currentOpenModal, setCurrentOpenModal] = useState()
  const isDeleteModalOpen = currentOpenModal === 'delete'
  const isEditModalOpen = currentOpenModal === 'edit'

  return (
    <>
      {(canDeleteComment || canEditComment) && (
        <div className='position-absolute top-0 right-0 zindex-dropdown p-1'>
          <DropdownMenuContainer>
            {canEditComment && (
              <CardDropdownMenuItem
                width='150px'
                onClick={() => setCurrentOpenModal?.('edit')}
                primaryText={(
                  <div className='d-flex align-items-center'>
                    <CdnSvg src={pencilIconPath} className='pencilIcon mr-2' />
                    <span>{I18NCommon('edit')}</span>
                  </div>
                )}
              />
            )}
            {canDeleteComment && (
              <CardDropdownMenuItem
                width='150px'
                onClick={() => setCurrentOpenModal?.('delete')}
                primaryText={(
                  <div className='d-flex align-items-center'>
                    <TrashIcon fill='var(--danger-color)' className='mr-2' />
                    <span className='text-danger'>{I18NCommon('delete')}</span>
                  </div>
                )}
              />
            )}
          </DropdownMenuContainer>
          {isDeleteModalOpen && (
            <DeleteModal
              deleteConfirm={handleDelete}
              showDeleteModal={isDeleteModalOpen}
              closeDeleteModal={() => setCurrentOpenModal?.(null)}
              isDeleting={isDeleting}
              deleteSecondaryText={I18N('delete_confirm')}
            />
          )}
          {isEditModalOpen && (
            <SignGreetingCardModal
              comment={comment}
              celebration={comment.commentable}
              visible={isEditModalOpen}
              onClose={() => setCurrentOpenModal?.(null)}
            />
          )}
        </div>
      )}
    </>
  )
}

const GreetingCardComment = ({
  comment,
  onToggleComments = null,
  showCommentsOnInit = true,
  hideEditedTag = false,
  className = '',
  nameOnTop = false,
}) => {
  const { settings } = useCurrentCompany()
  const useReactions = settings.reactions?.type === 'full_emoji_library'

  const dispatch = useDispatch()
  const { permittedActions, commentable: celebration } = comment

  const { isDeleting } = useSelector(commentSlice.selectors.getMetaData('celebration', celebration?.id))

  const onDeleteSuccess = () => {
    const commentsCount = celebration.commentsCount - 1
    dispatch(celebrationSlice.asyncActions.updateAttributes(celebration.id, {
      hasCommented: false,
      commentsCount,
    }))
  }

  const handleDelete = () => {
    dispatch(commentSlice.asyncActions.delete(comment, 'celebration', onDeleteSuccess))
  }

  const canDeleteComment = permittedActions?.delete
  const canEditComment = permittedActions?.edit

  return (
    <StatelessCardComment
      className={className}
      comment={comment}
      hideEditedTag={hideEditedTag}
      onToggleComments={onToggleComments}
      showCommentsOnInit={showCommentsOnInit}
      useReactions={useReactions}
      nameOnTop={nameOnTop}
      actionsComponent={(
        <Actions
          canDeleteComment={canDeleteComment}
          canEditComment={canEditComment}
          handleDelete={handleDelete}
          isDeleting={isDeleting}
          comment={comment}
        />
      )}
    />
  )
}

const PublicAppGreetingCardComment = ({
  comment,
  className = '',
}) => (
  <StatelessCardComment
    className={className}
    comment={comment}
    showCommentsOnInit
    useReactions
    showUser={false}
    showReactionsAndComments={false}
  />
)

const GreetingCardCommentRouter = (props) => {
  const isPublicApp = useIsPublicApp()

  const Component = isPublicApp ? PublicAppGreetingCardComment : GreetingCardComment

  return <Component {...props} />
}


export default GreetingCardCommentRouter
