const VIDEO_PATTERNS = [
  {
    pattern: /^(https?:\/\/)?(www\.)?youtube\.com.*v=([a-zA-Z0-9_-]+).*$/,
    embedUrl: id => `https://www.youtube.com/embed/${id}`,
  },
  {
    pattern: /^(https?:\/\/)?(www\.)?youtu\.be\/([a-zA-Z0-9_-]+).*$/,
    embedUrl: id => `https://www.youtube.com/embed/${id}`,
  },
  {
    pattern: /^(https?:\/\/)?(www\.)?vimeo\.com\/(\w+).*$/,
    embedUrl: id => `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0`,
  },
  {
    pattern: /^(https?:\/\/)?(www\.)?loom\.com\/share\/(\w+).*$/,
    embedUrl: id => `https://www.loom.com/embed/${id}`,
  },
]

const getEmbedUrl = (url = '') => {
  try {
    if (!url) return url

    for (let i = 0; i < VIDEO_PATTERNS.length; i += 1) {
      const match = url.match(VIDEO_PATTERNS[i].pattern)
      if (match) return VIDEO_PATTERNS[i].embedUrl(match[3])
    }

    return url
  } catch (err) {
    return url
  }
}

export default getEmbedUrl
