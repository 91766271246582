import build from 'redux-object'

const buildByIdOrSlugFromEntitiesStore = (idOrSlug, type, state) => {
  // we need to filter through all the events to get the event id from slug
  if (state.entities[type]) {
    const obj = _.find(Object.values(state.entities[type]), o => o.attributes.slug === idOrSlug)
    const idFromSlug = obj ? obj.id : idOrSlug

    return build(state.entities, type, idFromSlug)
  }

  return null
}

export default buildByIdOrSlugFromEntitiesStore
