import classNames from 'classnames'
import CelebrationImage from 'components/celebrations/celebrationImage'
import EffectPlayer from 'components/celebrations/effectPlayer'
import CelebrationSlider from 'pages/celebrations/slide_show_page/celebrationSlider'
import CelebrationSliderActions from 'pages/celebrations/slide_show_page/celebrationSliderActions'
import useCreateSlideShow, { SlideControlType, SlideShowContext } from 'pages/celebrations/slide_show_page/useSlideShow'
import React, { useEffect, useState } from 'react'

interface Props {
  celebration: any
  comments: any[]
  slideControlType?: SlideControlType,
  onExitSlideShow?: () => void
}

const UncontrolledSlideShowPage = ({
  celebration, comments, slideControlType, onExitSlideShow,
}: Props) => {
  const [showCelebrationEffect, setShowCelebrationEffect] = useState(false)
  const [isOpenCardBGTransparent, setIsOpenCardBGTransparent] = useState(false)
  const slideShow = useCreateSlideShow({
    celebration,
    comments,
    slideControlType,
    onExitSlideShow,
  })

  const withCustomImage = !!celebration?.coverImageUrl

  const backgroundStyles = {
    backgroundImage: withCustomImage ? `url(${celebration?.coverImageUrl})` : undefined,
  }

  /**
   * We'll start the effect with the whole page background using the effect secondary color so we
   * don't show the celebration card before the effect starts.
   * After one second, we'll make the card background transparent so the celebration card can be
   * seen from inside the effect.
   */
  useEffect(() => {
    setTimeout(() => {
      setIsOpenCardBGTransparent(true)
    }, 1000)
  }, [])

  useEffect(() => {
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight
    let css

    if (viewportWidth < viewportHeight) {
      css = `
        .open-card-effect svg {
          height: ${viewportHeight}px !important;
          width: ${viewportHeight * 1.77}px !important;
          margin-left: -${(viewportHeight * 1.77 - viewportWidth) / 2}px;
        }
      `
    } else {
      css = `
        .open-card-effect svg {
          height: ${viewportWidth}px !important;
          width: ${viewportWidth * 1.77}px !important;
          margin-top: -${(viewportWidth - viewportHeight) / 2}px;
          margin-left: -${(viewportWidth * 1.77 - viewportWidth) / 2}px;
        }
      `
    }

    if (document.getElementById('open-card-lottie-effect')) {
      document.getElementById('open-card-lottie-effect')?.remove()
    }

    const styleTag = document.createElement('style')
    styleTag.id = 'open-card-lottie-effect'
    styleTag.innerHTML = '\n' + css
    document.body.appendChild(styleTag)

    return () => {
      styleTag.remove()
    }
  }, [])

  return (
    <SlideShowContext.Provider value={slideShow}>
      <div
        className={
          classNames(
            'SlideShowPage',
            {
              'SlideShowPage--comments': slideShow.currentSlide > 1,
            }
          )
        }
      >
        {showCelebrationEffect && celebration?.effect && (
          <EffectPlayer effect={celebration.effect} onComplete={() => setShowCelebrationEffect(false)} />
        )}

        <EffectPlayer
          effect='open_card'
          className={classNames('open-card-effect', { transparent: isOpenCardBGTransparent })}
          onComplete={() => {
            slideShow.resume()
            setShowCelebrationEffect(true)
          }}
        />

        <div
          className={classNames('CelebrationPage__background', celebration.type)}
          style={backgroundStyles}
        >
          {!withCustomImage && (
            <CelebrationImage
              type={celebration.type}
              isCustomCelebration={celebration.customCelebration}
            />
          )}
        </div>

        <CelebrationSliderActions setShowEffect={setShowCelebrationEffect} />

        <CelebrationSlider {...slideShow.tapAndHold} />
      </div>
    </SlideShowContext.Provider>
  )
}

export default UncontrolledSlideShowPage
