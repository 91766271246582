import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import { useSlideShow } from 'pages/celebrations/slide_show_page/useSlideShow'
import React from 'react'
import { useHistory } from 'react-router-dom'

const iconMap = {
  left: '/images/chevronLeftOutline.svg',
  right: '/images/chevronRightOutline.svg',
}

const CelebrationSliderControls = ({ direction, cover = false }) => {
  const {
    currentSlide, numberOfSlides, next, prev,
  } = useSlideShow()

  const targetSlide = direction === 'right' ? next : prev
  const shouldShowControl = direction === 'right' ? currentSlide < numberOfSlides : currentSlide > 1

  return (
    <>
      {shouldShowControl && (
        <div
          className={
            classNames(
              `SlideShowPage__Slider__Controls SlideShowPage__Slider__Controls--${direction}`,
              { 'SlideShowPage__Slider__Controls SlideShowPage__Slider__Controls--right-cover': cover }
            )
          }
          onClick={() => {
            targetSlide()
          }}
        >
          <CdnSvg src={iconMap[direction]} />
        </div>
      )}
    </>
  )
}

export default CelebrationSliderControls
