import Axios from 'axios'
import searchURI from 'services/searchURI'


const messagesApi = {
  fetchAll(conversationId, opts) {
    const options = { page: 1, ...opts }

    return Axios.get(searchURI(`/api/admin/ai/chat/conversations/${conversationId}/messages`, options))
  },
}

export default messagesApi
