import React, { useEffect, useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { i18nPath } from 'utils/i18nHelpers'
import LinkDropdownContent from 'components/common/tiptap/toolbar/items/linkDropdownContent'
import { OPEN_LINK_DROPDOWN_EVENT } from 'components/common/tiptap/extensions/clearyLink'

const I18N = i18nPath('views.tiptap.toolbar')

const InsertLinkDropdownButton = ({
  editor,
  isDisabled = false,
  tooltipText = I18N('insert_link_tooltip'),
  iconPath = '/images/tiptap/link.svg',
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)


  useEffect(() => {
    const openDropdown = (event) => {
      if (event.detail?.editorUniqueId === editor?.options?.editorProps?.attributes?.editorUniqueId) {
        setIsDropdownOpen(true)
      }
    }

    document.addEventListener(OPEN_LINK_DROPDOWN_EVENT, openDropdown)

    return () => {
      document.removeEventListener(OPEN_LINK_DROPDOWN_EVENT, openDropdown)
    }
  }, [])

  const getActiveNode = () => {
    if (editor.isActive('inlineImage')) {
      return 'inlineImage'
    }
    if (editor.isActive('blockImage')) {
      return 'blockImage'
    }

    return 'link'
  }

  return (
    <ToolbarDropdownButton
      tooltipText={tooltipText}
      className='TiptapInsertLinkDropdownButton'
      dropdownContent={(
        <LinkDropdownContent
          editor={editor}
          onUpdateSuccess={() => setIsDropdownOpen(false)}
          nodeName={getActiveNode()}
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src={iconPath} />
    </ToolbarDropdownButton>
  )
}

export default InsertLinkDropdownButton
