import React from 'react'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.tiptap.toolbar')

export const FONT_FAMILIES = [
  { name: 'Arial', value: 'Arial, sans-serif' },
  { name: 'DM Sans', value: 'DM Sans, sans-serif' },
  { name: 'Georgia', value: 'Georgia, serif' },
  { name: 'Helvetica', value: 'Helvetica Neue, Helvetica, sans-serif' },
  { name: 'Impact', value: 'Impact, sans-serif' },
  { name: 'Inter', value: 'Inter, sans-serif' },
  { name: 'Roboto', value: 'Roboto, sans-serif' },
  { name: 'Saira', value: 'Saira, sans-serif' },
  { name: 'Tahoma', value: 'Tahoma, sans-serif' },
  { name: 'Times New Roman', value: 'Times New Roman, serif' },
  { name: 'Verdana', value: 'Verdana, sans-serif' },
]

const FontFamilySelect = ({ editor, isDisabled = false }) => {
  const { customStyles, settings: { editor: { allowedFonts } } } = useCurrentCompany()

  const baseFontFamily = customStyles.variables?.fontFamily?.baseFontFamily
  const defaultValue = baseFontFamily ? baseFontFamily.split(',')[0].replaceAll('"', '') : 'Inter'

  const filteredFontFamilies = _.isEmpty(allowedFonts)
    ? FONT_FAMILIES
    : FONT_FAMILIES.filter(font => allowedFonts.includes(font.name))

  return (
    <ToolbarSelect
      className='FontFamilySelect'
      isDisabled={isDisabled}
      options={filteredFontFamilies.map(font => ({
        value: font.name,
        label: font.name,
        customContent: <span style={{ fontFamily: font.value }}>{font.name}</span>,
        onClick: () => editor.chain().focus().setFontFamily(font.value).run(),
        isActive: editor.isActive('textStyle', { fontFamily: font.value }),
      }))}
      defaultValue={defaultValue}
      tooltipText={I18N('font_family_tooltip')}
    />
  )
}

export default FontFamilySelect
