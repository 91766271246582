import generateCrudRoutes from 'services/generateCrudRoutes'

export const buildConversationPayload = conversation => _.pick(conversation, [
  'id',
  'title',
  'userId',
])

const conversations = generateCrudRoutes(
  '/api/admin/ai/chat/conversations',
  'conversation',
  {},
  buildConversationPayload
)

export default conversations
