import React, { forwardRef } from 'react'
import BaseItemList from 'components/common/tiptap/suggestions/baseItemList'
import { SimpleUserType } from 'types/user'

interface Props {
  items: SimpleUserType[]
  command: (any) => void
  query: string
}

const EmojiRenderer = ({ item }) => (
  <div className='EmojiRenderer'>
    {`${item.skins[0].native} :${item.id}`}
  </div>
)

const EmojiList = forwardRef(({ items, command, query }: Props, ref) => {
  const onItemSelect = (item) => {
    command({ emoji: item?.skins[0]?.native, query })
  }

  return (
    <BaseItemList
      ref={ref}
      onItemSelect={onItemSelect}
      items={items}
      itemRenderer={item => <EmojiRenderer item={item} />}
      className='EmojiList'
    />
  )
})

export default EmojiList
