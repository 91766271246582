import React, {
  createContext, useContext, useMemo, useState
} from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { ButtonNarrow } from 'components/common/buttons'
import invitationSlice from 'redux/slices/invitations'
import useTrackGreetingCardEvent from 'components/celebrations/hooks/useTrackGreetingCardEvent'
import invitationHelperText from 'components/celebrations/utils/invitationHelperText'
import RoundedAvatars from 'components/common/roundedAvatars'
import InvitedPeopleModal from 'pages/people/celebration_editor_page/invitedPeopleModal'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCelebrationInvitedData from 'components/celebrations/hooks/useCelebrationInvitedData'

const I18N = i18nPath('views.celebrations.greeting_card_invitations')

export const GreetingCardInvitationsContext = createContext(
  { onPeopleInvited: (users) => {} }
)

const GreetingCardInvitations = ({ celebration, className = '', onClose }) => {
  const currentUser = useCurrentUser()

  const dispatch = useDispatch()
  const [showInvitedPeopleModal, setShowInvitedPeopleModal] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState([])

  const { onPeopleInvited } = useContext(GreetingCardInvitationsContext)
  const { isSaving } = useSelector(invitationSlice.selectors.getMetaData())

  const trackGreetingCardEvent = useTrackGreetingCardEvent()

  const sendInvitations = () => {
    const inviteeIds = selectedEmployees.map(employee => employee.id)
    const onSuccess = () => {
      onPeopleInvited(selectedEmployees)
      trackGreetingCardEvent('greeting_card_invitations_sent', celebration, { count: selectedEmployees.length })
      setSelectedEmployees([])
      onClose()
    }

    dispatch(invitationSlice.asyncActions.batchCreate(celebration, 'celebration', { inviteeIds }, onSuccess))
  }

  const helperText = invitationHelperText(celebration)

  const {
    invitedPeople,
    followersById,
  } = useCelebrationInvitedData(celebration)

  const excludedUserIds = useMemo(() => [celebration.user.id, currentUser.id], [celebration])

  return (
    <div className={classNames('GreetingCardInvitations mb-5 w-100 mt-3', className)}>
      <h4>{I18N('invite_others_to_sign')}</h4>
      <div className='font-weight-500 mb-2'>{I18N('select_colleagues')}</div>
      <EmployeeSearch
        className='mb-3'
        isMulti
        selectedEmployees={selectedEmployees}
        onChange={setSelectedEmployees}
        excludedUserIds={excludedUserIds}
      />

      <div className='EstimatedAudienceAvatars p-3 d-flex justify-content-start align-items-center mt-2"'>
        <RoundedAvatars
          avatarSize='30px'
          users={invitedPeople}
          popOverPlacement='auto'
          onMoreinvitedPeopleClick={() => setShowInvitedPeopleModal(true)}
          className='flex-shrink-0'
        />

        <div
          onClick={() => setShowInvitedPeopleModal(true)}
          className={classNames('text-left text-normal text-secondary ml-3 mb-0 pointer flex-grow-1')}
        >
          {I18N('were_invited_to_sign', { count: invitedPeople.length })}
        </div>
      </div>

      <p className='text-muted text-small mt-2'>{helperText}</p>

      {showInvitedPeopleModal && invitedPeople.length > 0 && (
        <InvitedPeopleModal
          users={invitedPeople}
          followersById={followersById}
          onClose={() => setShowInvitedPeopleModal(false)}
          helperText={helperText}
        />
      )}

      <div className='d-flex justify-content-end'>
        <ButtonNarrow
          onClick={onClose}
          variant='link'
          className='text-secondary'
        >
          {I18NCommon('cancel')}
        </ButtonNarrow>
        <ButtonNarrow
          disabled={isSaving || selectedEmployees.length === 0}
          showLoadingSpinner={isSaving}
          onClick={sendInvitations}
        >
          {I18N('send_invites')}
        </ButtonNarrow>
      </div>
    </div>
  )
}

export default GreetingCardInvitations
