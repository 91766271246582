import { useEffect, useRef, useState } from 'react'
import ClipboardJS from 'clipboard'

const useCopyToClipboard = ({
  copyElement,
  successDuration = 3000,
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const timeout = useRef<any>(null)

  const handleCopyClick = () => {
    setIsCopied(true)

    timeout.current = setTimeout(() => {
      setIsCopied(false)
    }, successDuration)
  }

  useEffect(() => {
    if (copyElement) {
      // eslint-disable-next-line no-new
      new ClipboardJS(copyElement as Element)
    }
  }, [copyElement])

  useEffect(() => () => {
    clearTimeout(timeout.current)
  }, [])

  return { isCopied, handleCopyClick }
}

export default useCopyToClipboard
