
import React, { forwardRef } from 'react'
import classNames from 'classnames'
import useHandleSuggestionListEvents from 'components/common/tiptap/hooks/useHandleSuggestionListEvents'

interface Props {
  items: any[]
  onItemSelect: (item: any) => void
  itemRenderer: (item: any) => any
  className?: string
}

const BaseItemList = forwardRef(({
  items,
  onItemSelect,
  itemRenderer,
  className,
}: Props, ref) => {
  const {
    selectedIndex,
    handleItemSelect,
    listItemsRef,
  } = useHandleSuggestionListEvents({
    items,
    ref,
    onItemSelect,
  })

  if (!items.length) return null

  return (
    <div className={classNames(className, 'BaseItemList d-flex flex-column')}>
      {items.map((item, index) => (
        <div
          className={classNames('item', index === selectedIndex && 'is-selected')}
          key={index}
          onClick={() => handleItemSelect(index)}
          ref={node => listItemsRef.set(index, node)}
        >
          {itemRenderer(item)}
        </div>
      ))}
    </div>
  )
})

export default BaseItemList
