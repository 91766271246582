import React, { useState } from 'react'

import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import RichTextView from 'components/common/richTextView'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import CommentEditor from 'components/comments/commentEditor'
import TrashIcon from 'components/icons/trashIcon'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ReactionsWidget from 'components/common/reactions/reactionsWidget'
import ViaSlackTag from 'components/common/viaSlackTag'
import CdnSvg from 'components/common/cdnSvg'


const I18N = i18nPath('views.comments')
const pencilIconPath = '/images/pencilIcon.svg'

const TimestampAndEditedTag = ({
  createdAt,
  editedAt,
}) => (
  <div className='d-flex flex-row align-items-center'>
    <div className='text-small text-secondary ml-2'>{i18nMoment(createdAt).fromNow()}</div>
    {editedAt && (
      <>
        <span className='mx-1'>{' • '}</span>
        <span className='text-small text-secondary cursor-default'>{I18NCommon('edited')}</span>
      </>
    )}
  </div>
)

interface CommentProps {
  comment: any
  onDeleteComment: (commentId: string) => void
  hideDeleteOption?: boolean
  commentable: any
  type: string
}

const Comment = ({
  comment,
  onDeleteComment,
  hideDeleteOption = false,
  commentable,
  type,
}: CommentProps) => {
  const { settings } = useCurrentCompany()
  const useReactions = settings.reactions?.type === 'full_emoji_library'

  const [isEditing, setIsEditing] = useState(false)

  if (typeof comment.content === 'undefined') {
    return null
  }

  const showDeleteOption = !hideDeleteOption && comment.permittedActions.delete
  const showEditOption = comment.permittedActions.edit
  const showDropdown = showDeleteOption || showEditOption
  const isUserPresent = !_.isEmpty(comment.user)

  return (
    <div data-testid='cy_comment' className='mb-4' id={`comment-${comment.id}`}>
      <span className='d-flex align-items-start'>
        <EmployeeThumbnailPhoto
          employee={comment.user}
          linkToProfile
          linkClassName='d-flex align-items-start'
          className='mr-2'
          size='30px'
        />

        <div className='flex-grow-1'>
          <div className='text-small text-primary d-flex align-items-start justify-content-between mt-1'>
            <div className='d-flex flex-row align-items-center'>
              {isUserPresent ? (
                <EmployeeLinkWithPopover
                  user={comment.user}
                  hideImage
                  userLinkClassName='text-small text-primary font-weight-600'
                />
              ) : (
                I18n.t('views.common.unknown_user')
              )}

              <TimestampAndEditedTag createdAt={comment.createdAt} editedAt={comment.editedAt} />

              {comment.source === 'slack' && (<ViaSlackTag className='comment-slack-source' />)}
            </div>

            {showDropdown && (
              <DropdownMenuContainer dropdownOffsetPx={-2} closeMenuOnItemClick className='CommentDropdown'>
                {showEditOption && (
                  <CardDropdownMenuItem
                    className='p-2'
                    width='200px'
                    primaryText={(
                      <div className='d-flex align-items-center'>
                        <CdnSvg src={pencilIconPath} className='PencilIcon mr-2' />
                        <span>{I18N('edit')}</span>
                      </div>
                    )}
                    onClick={() => setIsEditing(true)}
                  />
                )}
                {showDeleteOption && (
                  <CardDropdownMenuItem
                    className='p-2'
                    width='200px'
                    primaryText={(
                      <div className='d-flex align-items-center'>
                        <TrashIcon fill='var(--danger-color)' className='TrashIcon mr-2' />
                        <span className='text-danger'>{I18N('delete')}</span>
                      </div>
                    )}
                    onClick={() => onDeleteComment(comment)}
                  />
                )}
              </DropdownMenuContainer>
            )}
          </div>
          {isEditing ? (
            <CommentEditor
              commentable={commentable}
              type={type}
              onSendComment={() => setIsEditing(false)}
              comment={comment}
              isEditing
            />
          ) : (
            <RichTextView className='break-word' record={comment} recordType='comment' richTextKey='content' />
          )}

          <>
            <ReactionsWidget
              reactableId={comment.id}
              reactableType='comment'
              reactions={comment.reactions}
              currentUserReactions={comment.currentUserReactions}
              className='mt-3'
              onlyLike={!useReactions}
            />
          </>
        </div>
      </span>
    </div>
  )
}

export default Comment
