import TextStyle from '@tiptap/extension-text-style'
import styleAttribute from 'components/common/tiptap/extensions/utils/styleAttribute'

// Font Size extension based on the answer by Gianni Azizi here: https://stackoverflow.com/questions/70564092/tiptap-font-size-react

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (size: string) => ReturnType;
      unsetFontSize: () => ReturnType;
    };
  }
}

const ClearyTextStyle = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      fontWeight: styleAttribute('fontWeight'),

      fontSize: {
        default: null,
        parseHTML: element => element.style.fontSize.replace('px', ''),
        renderHTML: (attributes) => {
          if (!attributes.fontSize) {
            return {}
          }

          if (isNaN(attributes.fontSize)) {
            return { style: `font-size: ${attributes.fontSize}` }
          }

          return { style: `font-size: ${attributes.fontSize}px` }
        },
      },
    }
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setFontSize: fontSize => ({ commands }) => commands.setMark(this.name, { fontSize }),
      unsetFontSize: () => ({ chain }) => chain().setMark(this.name, { fontSize: null }).removeEmptyTextStyle().run(),
    }
  },
})

export default ClearyTextStyle
