import React, { useEffect } from 'react'

import Modal from 'components/common/modal'
import EmojiCounterDisplay from 'components/common/reactions/emojiCounterDisplay'
import useApi from 'components/common/hooks/useApi'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import classNames from 'classnames'
import { ReactionsType } from 'types/reactions'
import ViaSlackTag from 'components/common/viaSlackTag'
import API from 'services/api'

const UserReaction = ({
  user,
  isViaSlack,
}) => (
  <div className='d-flex flex-row align-items-center py-2 bordered-bottom'>
    <EmployeeThumbnailPhoto employee={user} size='25px' />
    <p className='m-0 ml-2'>{user.preferredFullName}</p>
    {isViaSlack && <ViaSlackTag />}
  </div>
)

interface ReactionDetailModalProps {
  visible: boolean
  toggle: () => void
  reactions: ReactionsType[]
  reactableId: string
  reactableType: string
  selectedEmoji: string
  setSelectedEmoji: (emojiId: string) => void
}

const ReactionDetailModal = ({
  visible,
  toggle,
  reactions,
  reactableId,
  reactableType,
  selectedEmoji,
  setSelectedEmoji,
}: ReactionDetailModalProps) => {
  const [fetchReactions, { isLoading, data = [] }] = useApi(API.reactions.fetchAll)

  useEffect(() => {
    if (visible) {
      fetchReactions(reactableId, reactableType, { emojiId: selectedEmoji })
    }
  }, [selectedEmoji, visible])

  return (
    <Modal size='md' visible={visible} toggle={toggle} className='ReactionDetailModal' closeButton={false}>
      <div className='d-flex flex-row h-100'>
        <div className='LeftEmojiNav d-flex flex-column align-items-start gap-2 h-100 py-4 px-3 flex-shrink-0'>
          {reactions.map(({
            emojiId, display, totalCount, custom,
          }) => (
            <EmojiCounterDisplay
              key={emojiId}
              emojiId={emojiId}
              display={display}
              custom={custom}
              totalCount={totalCount}
              onClick={() => setSelectedEmoji(emojiId)}
              className={classNames({ isSelected: emojiId === selectedEmoji })}
            />
          ))}
        </div>

        <div className='UserList px-4 py-3 h-100 w-100'>
          {isLoading ? (
            <CirclesLoadingIndicator />
          ) : (
            <>
              {data.map(({ user, source }) => (
                <UserReaction key={user.id} user={user} isViaSlack={source === 'slack'} />
              ))}
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ReactionDetailModal
