import React from 'react'
import { useHistory } from 'react-router-dom'
// we cant use a Link component since it's rendered outside of the BrowserRouter
// instead, approximate it with an <a> tag and an onclick handler to push the url to avoid a page refresh
const LinkAnchor = ({
  history = undefined,
  children,
  link,
  className = '',
  targetBlank = false,
}) => {
  const routerHistory = useHistory()
  const onLinkClick = (e) => {
    if (targetBlank) return

    e.preventDefault()
    if (!history) {
      routerHistory.push(link)
    } else {
      history.push(link)
    }
  }

  let targetBlankProps = {}
  if (targetBlank) {
    targetBlankProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  if (!link) {
    return <span className={className}>{children}</span>
  }

  return (
    <a className={className} onClick={onLinkClick} href={link} {...targetBlankProps}>
      {children}
    </a>
  )
}

export default LinkAnchor
