import generateCrudRoutes from 'services/generateCrudRoutes'

const buildBadgePayload = badge => _.pick(badge, [
  'id',
  'locations',
  'displayName',
  'description',
  'image',
  'numYears',
  'sendNotifications',
  'type',
  'ownerId',
])

const badges = generateCrudRoutes('/api/badges', 'badge', {}, buildBadgePayload)

export default badges
