import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import useHandleSuggestionListEvents from 'components/common/tiptap/hooks/useHandleSuggestionListEvents'

const I18N = i18nPath('views.tiptap.ai.mentioned_records')

const getTitle = (item) => {
  switch (item.type) {
  case 'article':
    return item.article.title
  case 'page':
    return item.page.title
  case 'page/workspace':
    return item.pageWorkspace.title
  case 'qna/event':
    return item.qnaEvent.title
  case 'group':
    return item.group.name
  case 'user':
    return item.user.preferredFullName
  default:
    return ''
  }
}

const AiMentionList = forwardRef(({ items, command, editor }: any, ref) => {
  const recordsByType = _.groupBy(items, 'type')

  const onItemSelect = (item) => {
    command({
      editor,
      attrs: {
        type: item.rawType,
        id: item.rawId,
        name: getTitle(item),
      },
    })
  }
  const {
    selectedIndex,
    handleItemSelect,
    listItemsRef,
  } = useHandleSuggestionListEvents({
    items,
    ref,
    onItemSelect,
  })

  if (!items.length) return null

  return (
    <div className={classNames('AiMentionList BaseItemList d-flex flex-column py-2 px-3')}>
      {Object.keys(recordsByType).map(type => (
        <div className='mb-2' key={type}>
          <span className='text-secondary text-small py-2'>
            {I18N(`record_types.${type.replace('/', '_')}`)}
          </span>
          {recordsByType[type].map((item) => {
            const index = items.indexOf(item)

            return (
              <div
                className={classNames('item my-2 p-2 border-radius bordered', index === selectedIndex && 'is-selected')}
                key={index}
                onClick={() => handleItemSelect(index)}
                ref={node => listItemsRef.set(index, node)}
              >
                <span className='font-weight-500 truncate-text-at-1-lines'>{getTitle(item)}</span>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
})

export default AiMentionList
