import generateCrudRoutes from 'services/generateCrudRoutes'

export const buildCompanyValuePayload = companyValue => _.pick(companyValue, [
  'id',
  'name',
  'description',
  'image',
  'orderPosition',
  'active',
])

const companyValues = generateCrudRoutes(
  '/api/admin/company_values',
  'companyValue',
  {},
  buildCompanyValuePayload
)

export default companyValues
