import React, { useRef, useState } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import hasHover from 'utils/hasHover'

const HoverAndClickPopover = ({
  children,
  target,
  id = '',
  popoverClassName = '',
  placement = 'bottom-start',
  onClick = () => {},
  onOverlayEnter = () => {},
}) => {
  const mouseLeftTextTimer = useRef()

  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseLeftText = () => {
    if (!hasHover) return

    setShowTooltip(false)
  }

  const handleTextMouseEnter = () => {
    if (!hasHover) return

    clearTimeout(mouseLeftTextTimer.current)
    setShowTooltip(true)
  }

  const handleTextMouseLeave = () => {
    mouseLeftTextTimer.current = setTimeout(handleMouseLeftText, 75)
  }

  const popover = (
    <Popover placement='bottom' className={popoverClassName}>
      {children}
    </Popover>
  )

  return (
    <span onMouseEnter={handleTextMouseEnter} onMouseLeave={handleTextMouseLeave} onClick={onClick}>
      <OverlayTrigger show={showTooltip} placement={placement} overlay={popover} onEnter={onOverlayEnter}>
        <span id={id} style={{ cursor: 'pointer' }}>
          {target}
        </span>
      </OverlayTrigger>
    </span>
  )
}

export default HoverAndClickPopover
