import { createSlice } from '@reduxjs/toolkit'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

import { defaultActions } from 'redux/slices/utils/commonReducers'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import denormalizedCurrentUser from 'utils/denormalizedCurrentUser'

export const initialState = denormalizedCurrentUser
  ? { ...denormalizedCurrentUser }
  : {}

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    ...defaultActions,

    updateCurrentUser(state, action) {
      Object.assign(state, action.payload)
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  update: (user, change) => async (dispatch) => {
    const changedKeys = Object.keys(change)
    try {
      const response = await API.profile.update(user, change)
      const updatedUser = denormalizedJsonApiResponse(response, 'user')

      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(currentUserSlice.actions.updateCurrentUser(_.pick(updatedUser, changedKeys)))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    }
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------


export default {
  ...currentUserSlice,
  asyncActions,
}
