import Axios from 'axios'
import searchURI from 'services/searchURI'

const acknowledgeablePath = (acknowledgeableId: string, type: string) => {
  switch (type) {
  case 'article':
    return `/api/admin/articles/${acknowledgeableId}/acknowledgements`
  default:
    return ''
  }
}

const acknowledgements = {
  fetchAll(acknowledgeableId: string, type: string, queryParams: any = {}) {
    const options = { page: 1, ...queryParams }

    return Axios.get(searchURI(acknowledgeablePath(acknowledgeableId, type), options))
  },

  sendReminders(acknowledgeableId: string, type: string, params: any = {}) {
    return Axios.post(`${acknowledgeablePath(acknowledgeableId, type)}/send_reminders`, params)
  },

  export(acknowledgeableId: string, type: string) {
    return Axios.post(`${acknowledgeablePath(acknowledgeableId, type)}/export`)
  },
}

export default acknowledgements
