import React from 'react'
import SignWithUploadedFile from 'components/celebrations/signGreetingCardModal/signWithUploadedFile'

interface Props {
  comment: any
  media: any
  setMedia: (media: any) => void
  disabled?: boolean
}

const Preview = ({ source }: { source: string }) => (
  <img
    src={source}
    alt='preview'
    className='preview'
  />
)

const SignWithImage = ({
  comment, media, setMedia, disabled,
}: Props) => (
  <SignWithUploadedFile
    comment={comment}
    media={media}
    setMedia={setMedia}
    disabled={disabled}
    type='image'
    Preview={Preview}
  />
)

export default SignWithImage
