import React, {
  useEffect, useImperativeHandle, useRef, useState
} from 'react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

type Props = {
  label: string
  iconPath?: string
  onClick?: () => void
  disabled?: boolean
  shortcut?: string
  isActive?: boolean
  children?: React.ReactNode
  component?: React.ReactNode
}

const BaseContextMenuItem = React.forwardRef<any, Props>(({
  iconPath,
  label,
  onClick,
  disabled,
  shortcut,
  isActive,
  children,
  component = null,
}, ref) => {
  const [submenuPosition, setSubmenuPosition] = useState<'left' | 'right' | null>(null)
  const menuItemRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => menuItemRef.current)

  const handleClick = () => {
    if (disabled || !onClick) return

    onClick()
  }

  // The mouse leave timeout is used to prevent the submenu from flickering when the mouse
  // moves from the menu item to the submenu. When the mouse leaves the menu item, a timeout
  // is set to hide the submenu. If the mouse enters the submenu before the timeout is reached,
  // the timeout is cleared and the submenu is not hidden. If the mouse leaves the menu item
  // and the timeout is reached, the submenu is hidden.
  let mouseLeaveTimeout
  const handleMouseEnter = () => {
    if (disabled || !children || !menuItemRef.current) return

    if (mouseLeaveTimeout) {
      clearTimeout(mouseLeaveTimeout)
      mouseLeaveTimeout = null
    }

    const item = menuItemRef.current
    const { right, width } = item.getBoundingClientRect()
    const { innerWidth } = window

    if (right + width > innerWidth) {
      setSubmenuPosition('left')
    } else {
      setSubmenuPosition('right')
    }
  }

  const handleMouseLeave = () => {
    mouseLeaveTimeout = setTimeout(() => {
      setSubmenuPosition(null)
    }, 100)
  }

  useEffect(() => () => {
    if (mouseLeaveTimeout) clearTimeout(mouseLeaveTimeout)
  }, [])

  return (
    <div
      className={
        classNames('ContextMenuItem d-flex align-items-center w-100 p-2', { disabled, isActive })
      }
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={menuItemRef}
    >
      {iconPath && <CdnSvg className={classNames('ContextMenuItemIcon', { disabled })} src={iconPath} />}
      <span className='ml-1 text-small'>{label}</span>
      {shortcut && <span className='ml-auto text-secondary text-smallest'>{shortcut}</span>}

      {component}

      {children && (
        <>
          <CdnSvg className='ArrowRight ml-auto' src='/images/arrow-right.svg' />
          <div
            className={
              classNames(
                'ContextMenuSubmenu align-items-end justify-content-center',
                submenuPosition && `ContextMenuSubmenu--${submenuPosition}`
              )
            }
          >
            {children}
          </div>
        </>
      )}
    </div>
  )
})

export default BaseContextMenuItem
