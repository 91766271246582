import Axios from 'axios'
import searchURI from 'services/searchURI'

const buildReactionPayload = reaction => _.pick(reaction, [
  'emojiId',
  'nativeEmoji',
  'unified',
  'shortcode',
  'skin',
  'isCustomReaction',
  'customReactionName',
])

const reactablePath = (reactableId: string, type: string) => {
  switch (type) {
  case 'answer':
    return `/api/qna/answers/${reactableId}/reactions`
  case 'article':
    return `/api/articles/${reactableId}/reactions`
  case 'awarded_badge':
    return `/api/awarded_badges/${reactableId}/reactions`
  case 'comment':
    return `/api/comments/${reactableId}/reactions`
  case 'celebration':
    return `/api/celebrations/${reactableId}/reactions`
  case 'shoutout':
    return `/api/shoutouts/${reactableId}/reactions`
  default:
    return ''
  }
}

const reactions = {
  fetchAll(reactableId: string, type: string, queryParams) {
    return Axios.get(searchURI(`${reactablePath(reactableId, type)}`, queryParams))
  },

  create(reactableId: string, type: string, reaction: any) {
    return Axios.post(`${reactablePath(reactableId, type)}`, { reaction: buildReactionPayload(reaction) })
  },

  like(reactableId: string, type: string) {
    return Axios.post(`${reactablePath(reactableId, type)}/like`)
  },

  update(reactableId: string, type: string, reaction) {
    return Axios.patch(`${reactablePath(reactableId, type)}/${reaction.emojiId}`, { reaction: buildReactionPayload(reaction) })
  },

  destroy(reactableId: string, type: string, emojiId: string) {
    return Axios.delete(`${reactablePath(reactableId, type)}/${emojiId}`)
  },
}

export default reactions
