import React, {
  useMemo, useRef, useState
} from 'react'
import ParagraphFormatSelect from 'components/common/tiptap/toolbar/items/paragraphFormatSelect'
import FontFamilySelect from 'components/common/tiptap/toolbar/items/fontFamilySelect'
import FontSizeSelect from 'components/common/tiptap/toolbar/items/fontSizeSelect'
import BoldButton from 'components/common/tiptap/toolbar/items/boldButton'
import ItalicButton from 'components/common/tiptap/toolbar/items/italicButton'
import UnderlineButton from 'components/common/tiptap/toolbar/items/underlineButton'
import ClearFormatButton from 'components/common/tiptap/toolbar/items/clearFormatButton'
import StrikethroughButton from 'components/common/tiptap/toolbar/items/strikethroughButton'
import SubscriptButton from 'components/common/tiptap/toolbar/items/subscriptButton'
import SuperscriptButton from 'components/common/tiptap/toolbar/items/superscriptButton'
import TextAlignSelect from 'components/common/tiptap/toolbar/items/textAlignSelect'
import ToolbarSeparator from 'components/common/tiptap/toolbar/items/toolbarSeparator'
import TextColorPicker from 'components/common/tiptap/toolbar/items/textColorPicker'
import TextBackgroundColorPicker from 'components/common/tiptap/toolbar/items/textBackgroundColorPicker'
import HorizontalRuleButton from 'components/common/tiptap/toolbar/items/horizontalRuleButton'
import InsertImageDropdownButton from 'components/common/tiptap/toolbar/items/insertImageDropdownButton'
import InsertLinkDropdownButton from 'components/common/tiptap/toolbar/items/insertLinkDropdownButton'
import OrderedListButton from 'components/common/tiptap/toolbar/items/orderedListButton'
import BulletListButton from 'components/common/tiptap/toolbar/items/bulletListButton'
import BlockquoteButton from 'components/common/tiptap/toolbar/items/blockquoteButton'
import UndoButton from 'components/common/tiptap/toolbar/items/undoButton'
import RedoButton from 'components/common/tiptap/toolbar/items/redoButton'
import LineHeightSelect from 'components/common/tiptap/toolbar/items/lineHeightSelect'
import EmbedDropdownButton, { VideoEmbedDropdownButton } from 'components/common/tiptap/toolbar/items/embedDropdownButton'
import MoreDropdownButton from 'components/common/tiptap/toolbar/items/moreDropdownButton'
import InsertTableDropdownButton from 'components/common/tiptap/toolbar/items/insertTableDropdownButton'
import ColumnLayoutSelect from 'components/common/tiptap/toolbar/items/columnLayoutSelect'
import DetailsButton from 'components/common/tiptap/toolbar/items/detailsButton'

import useResponsiveToolbar from 'components/common/tiptap/hooks/useResponsiveToolbar'
import SourceCodeViewToggle from 'components/common/tiptap/toolbar/items/sourceCodeViewToggle'
import classNames from 'classnames'
import InsertEmojiDropdownButton from 'components/common/tiptap/toolbar/items/insertEmojiDropdownButton'
import InsertFileDropdownButton from 'components/common/tiptap/toolbar/items/insertFileDropdownButton'
import IndentButton from 'components/common/tiptap/toolbar/items/indentButton'
import OutdentButton from 'components/common/tiptap/toolbar/items/outdentButton'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import CdnSvg from 'components/common/cdnSvg'
import AiAssistantButton from 'components/common/tiptap/toolbar/items/ai/aiAssistantButton'
import SocialShareButton from 'components/common/tiptap/toolbar/items/socialShareButton'

export const TOOLBAR_BUTTONS_MAP = {
  'paragraphFormat': ParagraphFormatSelect,
  'fontFamily': FontFamilySelect,
  'fontSize': FontSizeSelect,
  'bold': BoldButton,
  'italic': ItalicButton,
  'underline': UnderlineButton,
  'clearFormat': ClearFormatButton,
  'strikethrough': StrikethroughButton,
  'subscript': SubscriptButton,
  'superscript': SuperscriptButton,
  'textAlign': TextAlignSelect,
  'textColor': TextColorPicker,
  'textBackgroundColor': TextBackgroundColorPicker,
  'horizontalRule': HorizontalRuleButton,
  'insertLink': InsertLinkDropdownButton,
  'insertImage': InsertImageDropdownButton,
  'insertFile': InsertFileDropdownButton,
  'insertTable': InsertTableDropdownButton,
  'embed': EmbedDropdownButton,
  'orderedList': OrderedListButton,
  'bulletList': BulletListButton,
  '|': ToolbarSeparator,
  'blockquote': BlockquoteButton,
  'undo': UndoButton,
  'redo': RedoButton,
  'lineHeight': LineHeightSelect,
  'columnLayout': ColumnLayoutSelect,
  'codeView': SourceCodeViewToggle,
  'videoEmbed': VideoEmbedDropdownButton,
  'emoji': InsertEmojiDropdownButton,
  'indent': IndentButton,
  'outdent': OutdentButton,
  'aiAssistant': AiAssistantButton,
  'details': DetailsButton,
  'socialShare': SocialShareButton,
}

type ToolbarWithChildren = {
  name: string
  children: ToolbarItem[]
  tooltipText: string
  iconPath: string
  className?: string
}

type CustomToolbarComponent = {
  name: string
  Component: any
}

export type ToolbarItem = keyof typeof TOOLBAR_BUTTONS_MAP | ToolbarWithChildren | CustomToolbarComponent

const isToolbarWithChildren = (item: ToolbarItem): item is ToolbarWithChildren => (
  (item as ToolbarWithChildren).children !== undefined
)

interface Props {
  editor: any
  toolbarItems: ToolbarItem[]
  isSourceCodeViewEnabled: boolean
  setCurrentView: (value: string) => void
  stickyToolbar?: boolean
}

interface ToolbarContentProps extends Props {
  currentEditorStatus: string
}

const ToolbarContent = React.memo(({
  editor,
  isSourceCodeViewEnabled = false,
  setCurrentView,
  stickyToolbar = false,
  toolbarItems,
} : ToolbarContentProps) => {
  const toolbarRef = useRef<HTMLElement | null>(null)
  const [priorityButtons, moreButtons] = useResponsiveToolbar(toolbarRef, toolbarItems)

  const [secondaryToolbarButtons, setSecondaryToolbarButtons] = useState<ToolbarItem[]>([])
  const showSecondaryToolbar = secondaryToolbarButtons.length > 0

  const toggleSecondaryToolbar = (items) => {
    if (_.isEqual(secondaryToolbarButtons, items)) {
      setSecondaryToolbarButtons([])
    } else {
      setSecondaryToolbarButtons(items)
    }
  }

  const renderButton = renderItemsWithChildren => (item, index) => {
    if (typeof item === 'string') {
      return React.createElement<any>(TOOLBAR_BUTTONS_MAP[item], {
        key: `${item}-${index}`,
        editor,
        isSourceCodeViewEnabled,
        isDisabled: isSourceCodeViewEnabled,
        setCurrentView,
      })
    } else if (isToolbarWithChildren(item)) {
      if (!renderItemsWithChildren) return null

      return (
        <ToolbarButton
          className={item.className}
          key={`${item.name}-${index}`}
          onClick={() => toggleSecondaryToolbar(item.children)}
          isDisabled={isSourceCodeViewEnabled}
          tooltipText={item.tooltipText}
        >
          <CdnSvg src={item.iconPath} />
        </ToolbarButton>
      )
    } else {
      return item.Component
    }
  }

  return (
    <div className={classNames('TiptapToolbarWrapper mb-2 d-print-none', { isSticky: stickyToolbar })}>
      <section ref={toolbarRef} className='TiptapToolbar p-1'>
        {priorityButtons.map(renderButton(true))}

        {moreButtons.length > 0 && (
          <MoreDropdownButton
            onClick={() => toggleSecondaryToolbar(moreButtons)}
          />
        )}
      </section>
      <section
        className={classNames('TiptapToolbar TiptapSecondaryToolbar p-1', { showSecondaryToolbar })}
      >
        {secondaryToolbarButtons.map(renderButton(false))}
      </section>
    </div>
  )
})

const Toolbar = ({
  editor,
  toolbarItems,
  isSourceCodeViewEnabled = false,
  setCurrentView,
  stickyToolbar = false,
}: Props) => {
  const schemaNodesAndMarks = useMemo(() => {
    const nodes = editor.schema.nodes
    const marks = editor.schema.marks

    return [
      ...Object.values(nodes).map((node: any) => node.name),
      ...Object.values(marks).map((mark: any) => mark.name),
    ]
  }, [editor])

  const currentEditorStatus = schemaNodesAndMarks.map(name => `${name}${editor.isActive(name)}${JSON.stringify(editor.getAttributes(name))}`).join('')

  return (
    <ToolbarContent
      editor={editor}
      toolbarItems={toolbarItems}
      isSourceCodeViewEnabled={isSourceCodeViewEnabled}
      setCurrentView={setCurrentView}
      stickyToolbar={stickyToolbar}
      currentEditorStatus={currentEditorStatus} // this is used to force rerenders other wise the toolbar active icons don't update
    />
  )
}

export default Toolbar
