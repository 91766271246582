import React, { useState, useRef } from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import useClickOutside from 'components/common/hooks/useClickOutside'
import OpenLinkButton from 'components/common/tiptap/menus/links/openLinkButton'
import InsertLinkDropdownButton from 'components/common/tiptap/toolbar/items/insertLinkDropdownButton'

const I18N = i18nPath('views.tiptap.toolbar')

const LinkBubbleMenuOptions = ({ editor }) => {
  const linkMenuOptionsRef = useRef<any>()
  const [showLinkDropdown, setShowLinkDropdown] = useState(false)

  useClickOutside({
    ref: linkMenuOptionsRef,
    onClickOutside: () => setShowLinkDropdown(false),
    enableClickOutside: showLinkDropdown,
  })

  const onRemoveLink = () => {
    // Remove the currently active link
    editor.chain().focus().unsetLink().run()
    // Hide the bubble menu
    editor.commands.blur()
  }

  return (
    <div
      ref={linkMenuOptionsRef}
      className='BubbleMenuOptions d-flex p-2 align-items-center justify-content-center'
    >
      <OpenLinkButton editor={editor} />
      <InsertLinkDropdownButton
        editor={editor}
        tooltipText={I18N('link_bubble_menu.edit_link')}
        iconPath='/images/tiptap/pencil.svg'
      />
      <ToolbarButton
        onClick={onRemoveLink}
        tooltipText={I18N('link_bubble_menu.remove_link')}
      >
        <CdnSvg src='/images/tiptap/unlink.svg' />
      </ToolbarButton>
    </div>
  )
}

export default LinkBubbleMenuOptions
