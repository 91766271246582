import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const HORIZONTAL_ALIGNMENT = ['left', 'center', 'right', 'justify']

const HorizontalAlignmentItem = ({ editor, onClose }) => (
  <BaseContextMenuItem
    iconPath='/images/tiptap/align-left.svg'
    label={I18N('horizontal_alignment.label')}
  >
    {HORIZONTAL_ALIGNMENT.map(alignment => (
      <BaseContextMenuItem
        key={alignment}
        label={I18N(`horizontal_alignment.submenu.${alignment}`)}
        isActive={editor.isActive({ horizontalAlign: alignment })}
        onClick={() => {
          onClose()

          editor.chain().focus().setCellAttribute('horizontalAlign', alignment).run()
        }}
      />
    ))}
  </BaseContextMenuItem>
)

export default HorizontalAlignmentItem
