import React from 'react'

const SlackIcon = ({ width = '20px', height = '20px', fillColor = '#808284' }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='slack' transform='translate(-1.000000, -1.000000)' fill={fillColor} fillRule='nonzero'>
        <path d='M5.1985,13.64 C5.1985,14.797 4.2533,15.7422 3.0962,15.7422 C1.9392,15.7422 0.994,14.797 0.994,13.64 C0.994,12.483 1.9392,11.5378 3.0962,11.5378 L5.1985,11.5378 L5.1985,13.64 Z' id='Shape' />
        <path d='M6.2579,13.64 C6.2579,12.483 7.2031,11.5378 8.3601,11.5378 C9.5171,11.5378 10.4623,12.483 10.4623,13.64 L10.4623,18.9037 C10.4623,20.0607 9.5171,21.0059 8.3601,21.0059 C7.2031,21.0059 6.2579,20.0607 6.2579,18.9037 L6.2579,13.64 Z' id='Shape' />
        <path d='M8.3601,5.1985 C7.2031,5.1985 6.2579,4.2533 6.2579,3.0963 C6.2579,1.9393 7.2031,0.9941 8.3601,0.9941 C9.5171,0.9941 10.4623,1.9393 10.4623,3.0963 L10.4623,5.1985 L8.3601,5.1985 Z' id='Shape' />
        <path d='M8.3599,6.2578 C9.517,6.2578 10.4622,7.2029 10.4622,8.36 C10.4622,9.517 9.517,10.4622 8.3599,10.4622 L3.0962,10.4622 C1.9392,10.4622 0.994,9.517 0.994,8.36 C0.994,7.2029 1.9392,6.2578 3.0962,6.2578 L8.3599,6.2578 Z' id='Shape' />
        <path d='M16.8015,8.36 C16.8015,7.2029 17.7467,6.2578 18.9037,6.2578 C20.0608,6.2578 21.0059,7.2029 21.0059,8.36 C21.0059,9.517 20.0608,10.4622 18.9037,10.4622 L16.8015,10.4622 L16.8015,8.36 Z' id='Shape' />
        <path d='M15.7421,8.36 C15.7421,9.517 14.7969,10.4622 13.6399,10.4622 C12.4828,10.4622 11.5377,9.517 11.5377,8.36 L11.5377,3.0963 C11.5377,1.9393 12.4828,0.9941 13.6399,0.9941 C14.7969,0.9941 15.7421,1.9393 15.7421,3.0963 L15.7421,8.36 Z' id='Shape' />
        <path d='M13.6399,16.8015 C14.7969,16.8015 15.7421,17.7466 15.7421,18.9037 C15.7421,20.0607 14.7969,21.0059 13.6399,21.0059 C12.4828,21.0059 11.5377,20.0607 11.5377,18.9037 L11.5377,16.8015 L13.6399,16.8015 Z' id='Shape' />
        <path d='M13.6399,15.7422 C12.4828,15.7422 11.5377,14.797 11.5377,13.64 C11.5377,12.483 12.4828,11.5378 13.6399,11.5378 L18.9036,11.5378 C20.0606,11.5378 21.0058,12.483 21.0058,13.64 C21.0058,14.797 20.0606,15.7422 18.9036,15.7422 L13.6399,15.7422 Z' id='Shape' />
      </g>
    </g>
  </svg>
)

export default SlackIcon
