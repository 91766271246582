import React from 'react'

const CloseIcon = ({ className = 'CloseIcon' }) => (
  <svg data-testid='close-icon-svg' className={className} width='16px' height='16px' viewBox='0 0 16 16'>
    <g strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1258.000000, -168.000000)' fillRule='nonzero' strokeWidth='2'>
        <g transform='translate(907.000000, 164.000000)'>
          <g transform='translate(347.000000, 0.000000)'>
            <path
              d='M11.4893617,11.4893617 L11.4893617,4.05633232 C11.4893617,3.77431458 11.7179823,3.54569403 12,3.54569403 C12.2820177,3.54569403 12.5106383,3.77431458 12.5106383,4.05633232 L12.5106383,11.4893617 L19.9436677,11.4893617 C20.2256854,11.4893617 20.454306,11.7179823 20.454306,12 C20.454306,12.2820177 20.2256854,12.5106383 19.9436677,12.5106383 L12.5106383,12.5106383 L12.5106383,19.9436677 C12.5106383,20.2256854 12.2820177,20.454306 12,20.454306 C11.7179823,20.454306 11.4893617,20.2256854 11.4893617,19.9436677 L11.4893617,12.5106383 L4.05633232,12.5106383 C3.77431458,12.5106383 3.54569403,12.2820177 3.54569403,12 C3.54569403,11.7179823 3.77431458,11.4893617 4.05633232,11.4893617 L11.4893617,11.4893617 L11.4893617,11.4893617 Z'
              id='Shape'
              transform='translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) '
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CloseIcon
