import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const DetailsButton = ({ editor, isDisabled = false }) => {
  const canSetDetails = editor.can().setDetails()
  const canUnsetDetails = editor.can().unsetDetails()

  const handleClick = () => {
    if (canSetDetails) {
      editor.chain().focus().setDetails().run()
    } else if (canUnsetDetails) {
      editor.chain().focus().unsetDetails().run()
    }
  }

  return (
    <ToolbarButton
      className='DetailsButton'
      onClick={handleClick}
      tooltipText={I18N('details_tooltip')}
      isActive={canUnsetDetails && !canSetDetails}
      isDisabled={isDisabled || (!canSetDetails && !canUnsetDetails)}
    >
      <CdnSvg src='/images/tiptap/details.svg' />
    </ToolbarButton>
  )
}

export default DetailsButton
