import React from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { I18NCommon } from 'utils/i18nHelpers'
import TrashIcon from 'components/icons/trashIcon'

const BubbleMenuDeleteButton = ({
  editor,
  tooltipText = I18NCommon('remove'),
}) => {
  const onDelete = () => {
    editor.chain().focus().deleteSelection().run()
    editor.commands.blur()
  }

  return (
    <ToolbarButton
      className='BubbleMenuDeleteButton'
      onClick={onDelete}
      tooltipText={tooltipText}
    >
      <TrashIcon fill='var(--text-color-primary)' />
    </ToolbarButton>
  )
}

export default BubbleMenuDeleteButton
