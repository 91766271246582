import React from 'react'
import { useDispatch } from 'react-redux'
import emojiMartData from '@emoji-mart/data'
import classNames from 'classnames'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import listToSentence from 'utils/listToSentence'
import reactionSlice from 'redux/slices/reactions'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useApi from 'components/common/hooks/useApi'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import EmojiCounterDisplay, { EmojiDisplay } from 'components/common/reactions/emojiCounterDisplay'


const I18N = i18nPath('views.reactions')

interface ReactionCountProps {
  emojiId: string
  currentUserReactionDetails: { hasReacted: boolean, hasReactedViaSlack: boolean }
  reactableId: string
  reactableType: string
  display: string
  totalCount: number
  custom: boolean
  onPopoverClick: (emojiId: string) => void
}

const ReactionCount = ({
  emojiId,
  currentUserReactionDetails,
  reactableId,
  reactableType,
  display,
  totalCount,
  custom,
  onPopoverClick,
}: ReactionCountProps) => {
  const dispatch = useDispatch()
  const { id: currentUserId, preferredSkinTone = 1 } = useCurrentUser()
  const emojiName = custom ? emojiId.split(':').slice(1).join(':') : emojiId
  const { hasReacted, hasReactedViaSlack } = currentUserReactionDetails
  const customEmojiParams = { isCustomReaction: true, customReactionName: emojiName }

  const [fetchReactions, { isLoading, data = [] }] = useApi(API.reactions.fetchAll)
  const names: string[] = data
      ?.filter(reaction => reaction.user.id !== currentUserId)
      .map(reaction => reaction.user.preferredFullName) || []

  if (hasReacted) {
    names.unshift(hasReactedViaSlack ? I18NCommon('you_via_slack') : I18NCommon('you'))
  }

  if (names.length === 0) {
    names.unshift(I18NCommon('no_one'))
  }

  const handleMouseEnter = () => {
    if (_.isEmpty(data)) {
      const queryParams = { page: 1, perPage: 5, emojiId }
      fetchReactions(reactableId, reactableType, queryParams)
    }
  }

  const handleClick = () => {
    if (hasReacted) {
      dispatch(reactionSlice.asyncActions.destroy(reactableId, reactableType, emojiId))
    } else {
      const params = custom ? customEmojiParams : findEmojiById(emojiId)

      if (params) {
        dispatch(reactionSlice.asyncActions.create(reactableId, reactableType, params))
      }
    }
  }

  const findEmojiById = (emojiId: string) => {
    const typedEmojiMartData = emojiMartData as any
    const emojiData = typedEmojiMartData.emojis[emojiId]

    if (!emojiData) return null

    // We need to subtract 1 because the skin tones are 1-6 but the array is 0-5
    const emoji = emojiData.skins.length === 1 ? emojiData.skins[0] : emojiData.skins[preferredSkinTone - 1]

    return {
      emojiId,
      nativeEmoji: emoji.native,
      unified: emoji.unified,
    }
  }

  return (
    <HoverAndClickPopover
      target={(
        <EmojiCounterDisplay
          emojiId={emojiId}
          display={display}
          totalCount={totalCount}
          custom={custom}
          onClick={handleClick}
          className={classNames({ hasReacted })}
        />
      )}
      popoverClassName='ReactionCountPopover zindex-base py-2 px-3'
      placement='top'
      onOverlayEnter={handleMouseEnter}
    >
      {isLoading ? (
        <CirclesLoadingIndicator />
      ) : (
        <div className='d-flex flex-row align-items-center pointer' data-testid='reaction-tooltip' onClick={() => onPopoverClick(emojiId)}>
          <p className='text-smallest text-white text-center m-0'>{`${listToSentence(names, totalCount)} ${I18N('reacted_with')} :${emojiName}:`}</p>
          <EmojiDisplay display={display.replace(/ /g, '')} custom={custom} customImgClassName='ml-2' emojisClassName='ml-2 text-large' />
        </div>
      )}
    </HoverAndClickPopover>
  )
}

export default ReactionCount
