import baseSuggestion from 'components/common/tiptap/suggestions/baseSuggestion'
import EmojiList from 'components/common/tiptap/suggestions/emojis/emojiList'
import data from '@emoji-mart/data'

const MAX_RESULTS = 10

interface Emoji {
  id: string
  name: string
  keywords: string[]
  skins: {
    native: string
  }[]
}

interface EmojiData {
  emojis: Emoji[]
}

const emojiSuggestion = {
  items: ({ query }) => {
    const emojis: Emoji[] = Object.values((data as EmojiData).emojis)

    const result: Emoji[] = []
    for (let i = 0; i < emojis.length; i += 1) {
      const currentEmoji = emojis[i]

      if (currentEmoji.id.includes(query)) {
        result.push(currentEmoji)
      } else if (currentEmoji.name.toLowerCase().includes(query)) {
        result.push(currentEmoji)
      } else {
        for (let j = 0; j < currentEmoji.keywords.length; j += 1) {
          if (currentEmoji.keywords[j].includes(query)) {
            result.push(currentEmoji)
            break
          }
        }
      }

      if (result.length === MAX_RESULTS) {
        break
      }
    }
    return result
  },

  ...baseSuggestion(EmojiList, ':'),
}

export default emojiSuggestion
