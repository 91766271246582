import { I18NCommon } from 'utils/i18nHelpers'

const listToSentence = (list: string[], totalCount: number) => {
  if (list.length === 0) return ''

  const remainingCount = totalCount - list.length

  if (remainingCount <= 0) {
    // If there are no remaining, just join the list with commas and "and"
    return list.join(', ').replace(/,(?!.*,)/gmi, ` ${I18NCommon('and')}`)
  } else {
    // If there are remaining, join the list and append the remaining count
    const listString = list.join(', ')
    return `${listString} ${I18NCommon('and')} ${remainingCount} ${I18NCommon('others', { count: remainingCount })}`
  }
}

export default listToSentence
