import classNames from 'classnames'
import CelebrationImage from 'components/celebrations/celebrationImage'
import GreetingCardHeadlineDisplay from 'components/celebrations/greetingCardHeadlineDisplay'
import React from 'react'

const CelebrationCoverSlide = ({ celebration }) => {
  const withCustomImage = !!celebration?.coverImageUrl

  const greetingCardHeaderStyle = {
    backgroundImage: withCustomImage ? `url(${celebration?.coverImageUrl})` : undefined,
  }

  return (
    <>
      <div
        className={
          classNames(
            'SlideShowPage__Slider__CoverSlide',
            celebration.type,
            {
              'confetti-background': !withCustomImage,
            }
          )
        }
        style={greetingCardHeaderStyle}
      >
        <div
          className={
            classNames(
              'CelebrationHeadlineWrapper w-100',
              'd-flex flex-column justify-content-center h-100'
            )
          }
        >
          <GreetingCardHeadlineDisplay celebration={celebration} />
        </div>
        {!withCustomImage && (
          <CelebrationImage
            type={celebration.type}
            isCustomCelebration={celebration.customCelebration}
          />
        )}
      </div>
    </>
  )
}

export default CelebrationCoverSlide
