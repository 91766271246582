import React from 'react'

import { I18NCommon } from 'utils/i18nHelpers'

import classNames from 'classnames'

interface CancelButtonProps {
  onClick: () => void
  className?: string
  children?: any
}

const CancelButton = ({ onClick, className = 'mr-3', children }: CancelButtonProps) => (
  <span
    className={classNames('CancelButton text-secondary pointer', className)}
    onClick={onClick}
  >
    {children || I18NCommon('cancel')}
  </span>
)

export default CancelButton
