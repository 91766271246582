import React from 'react'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const InsertRowAboveItem = ({ editor, onClose }) => (
  <BaseContextMenuItem
    iconPath='/images/plusIcon.svg'
    label={I18N('insert_row_above')}
    onClick={() => {
      onClose()
      editor.chain().focus().addRowBefore().run()
    }}
  />
)

export default InsertRowAboveItem
