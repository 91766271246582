const isElementVisible = (element, percentage = 0.1) => {
  if (!element) return null

  const boundingRect = element.getBoundingClientRect()

  // top and bottom are both relative to 0 which is the top of the viewport
  const top = boundingRect.top
  const bottom = boundingRect.bottom
  const elementLength = bottom - top
  if (elementLength === 0) return false

  // Check how much of the element is actually contained in the actual viewport
  const elementWindowStart = Math.max(0, top)
  const elementWindowEnd = Math.min(window.innerHeight, bottom)

  const actualElementLenghtInsideWindow = Math.max(elementWindowEnd - elementWindowStart, 0)

  return actualElementLenghtInsideWindow / elementLength >= percentage
}

export default isElementVisible
