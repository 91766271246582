// eslint-disable-next-line import/prefer-default-export
export const findListType = (editor) => {
  const { selection, schema } = editor.state
  let listType: null | string = null

  selection.ranges.forEach((range) => {
    editor.state.doc.nodesBetween(range.$from.pos, range.$to.pos, (node) => {
      if (node.type === schema.nodes.bulletList) {
        listType = 'bulletList'
      } else if (node.type === schema.nodes.orderedList) {
        listType = 'orderedList'
      }
    })
  })

  return listType
}
