import Axios from 'axios'

export default {
  fetchAll() {
    return Axios.get('/api/auth_tokens')
  },

  create() {
    return Axios.post('/api/auth_tokens')
  },

  delete(id) {
    return Axios.delete(`/api/auth_tokens/${id}`)
  },
}
