import useQueryParams from 'components/common/hooks/useQueryParams'
import { trackAmplitudeEvent } from 'services/tracker'

const useTrackGreetingCardEvent = () => {
  const { source, invitation } = useQueryParams()

  const trackGreetingCardEvent = (eventName, celebration, extraParams = {}) => {
    trackAmplitudeEvent(eventName, {
      celebration_id: celebration.id,
      type: celebration.type,
      card_sent: !celebration.upcoming,
      source,
      invitation: invitation === 'true',
      ...extraParams,
    })
  }

  return trackGreetingCardEvent
}

export default useTrackGreetingCardEvent
