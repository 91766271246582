import React from 'react'

import FormCheck from 'react-bootstrap/FormCheck'

import classNames from 'classnames'

interface ClearyFormCheckProps {
  className?: string,
  name?: string,
  label?: React.ReactNode,
  checked?: boolean,
  onChange?: (value: any) => void,
  id?: any,
  disabled?: boolean,
}


const ClearyFormCheck = ({
  className,
  name,
  label,
  checked,
  onChange,
  id,
  disabled,
}: ClearyFormCheckProps) => (
  <FormCheck
    id={id}
    className={classNames('FormCheck', className)}
    name={name}
    label={label}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
  />
)

export default ClearyFormCheck
