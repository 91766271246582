import React from 'react'

const PaperPlaneSendIcon = ({
  height = '17px', width = '19px', className = '', fill = 'var(--btn-primary-color)',
}) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={width}
    height={height}
    viewBox='0 0 19 17'
    version='1.1'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-530.000000, -14.000000)' fill={fill}>
        <g transform='translate(530.000000, 14.500000)'>
          <path
            d='M17.8684597,6.34616467 L2.53878222,0.138185919 C1.89296704,-0.123374215 1.1633588,-0.00831238355 0.634735155,0.438317299 C0.106111512,0.885019416 -0.114986393,1.57328981 0.0577950517,2.234506 L1.4222712,7.45672683 L8.10293107,7.45672683 C8.41034548,7.45672683 8.65960944,7.69996109 8.65960944,8.00002004 C8.65960944,8.30004277 8.41038259,8.54331325 8.10293107,8.54331325 L1.4222712,8.54331325 L0.0577950517,13.7654979 C-0.114986393,14.4267503 0.106074403,15.1150207 0.634735155,15.5616866 C1.16443497,16.0092217 1.89411743,16.122871 2.53881933,15.8618179 L17.8684968,9.65387541 C18.5664507,9.37123691 19,8.73750952 19,8.00002004 C19,7.26253056 18.5664507,6.62876695 17.8684597,6.34616467 Z'
            id='Path'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default PaperPlaneSendIcon
