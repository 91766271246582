import React from 'react'
import ResizeOverlay from 'components/common/tiptap/extensions/nodeViews/resizeOverlay'
import { STYLE_ATTRIBUTES } from 'components/common/tiptap/extensions/video'
import ClearyVideoPlayer from 'components/common/video/clearyVideoPlayer'

const VideoResizer = (props: any) => (
  <ResizeOverlay {...props}>
    <ClearyVideoPlayer
      key={props.node.attrs['data-video-id']}
      src={props.node.attrs.src}
      videoId={props.node.attrs['data-video-id']}
      style={_.pick(props.node.attrs, STYLE_ATTRIBUTES)}
      autoPlay={props.node.attrs.autoplay}
      controls={true}
      isEditing
    />
  </ResizeOverlay>
)

export default VideoResizer
