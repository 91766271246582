import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const SuperscriptButton = ({ editor, isDisabled = false }) => (
  <ToolbarButton
    className='SuperscriptButton'
    onClick={() => editor.chain().focus().toggleSuperscript().run()}
    isActive={editor.isActive('superscript')}
    tooltipText={I18N('superscript_tooltip')}
    isDisabled={isDisabled}
  >
    <CdnSvg src='/images/tiptap/superscript.svg' />
  </ToolbarButton>
)

export default SuperscriptButton
