import classNames from 'classnames'
import useAnimationState from 'components/common/hooks/useAnimationState'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useSwipeable } from 'react-swipeable'

const Offcanvas = ({ isOpen, toggle, children }) => {
  const animationState = useAnimationState(isOpen)

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [isOpen])

  const handlers = useSwipeable({
    onSwipedDown: () => toggle(),
    trackMouse: true,
  })

  if (animationState === 'hidden') return null

  return (
    <>
      {createPortal(
        <>
          <div className='OffcanvasBackdrop' onClick={toggle} data-testid='offcanvas-backdrop' />

          <div
            {...handlers}
            className={classNames('Offcanvas', animationState)}
            tabIndex={-1}
            data-testid='offcanvas'
          >
            <div className='OffcanvasHandler mb-3' />
            <div className='OffcanvasBody'>
              <div className='d-flex justify-content-center'>
                {children}
              </div>
            </div>
          </div>
        </>,
        document.body
      )}
    </>
  )
}

export default Offcanvas
