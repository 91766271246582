import generateCrudRoutes from 'services/generateCrudRoutes'

const buildConversationPayload = record => _.pick(record, [
  'title',
])

const conversationApi = {
  ...generateCrudRoutes('/api/ai/chat/conversations', 'conversation', {}, buildConversationPayload),
}

export default conversationApi
