import React from 'react'

import classNames from 'classnames'

const ProgressBar = ({
  className = '',
  completedCount = 0,
  totalCount = 0,
  showProgressInfo = true,
}) => {
  const percentage = totalCount === 0 ? 0 : Math.min(Math.round((completedCount / totalCount) * 100), 100)

  return (
    <div className={classNames('d-flex flex-column justify-content-center ProgressBar', className)}>
      {showProgressInfo && (
        <div className='d-flex justify-content-between'>
          <p className='text-secondary text-normal mb-0'>{completedCount}/{totalCount}</p>
          <p className='text-normal mb-0'>{percentage}%</p>
        </div>
      )}
      <div className='bar-container'>
        <div className='bar-completed' style={{ width: `${percentage}%` }} />
      </div>
    </div>
  )
}

export default ProgressBar
