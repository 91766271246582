import React from 'react'

const TooltipIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1rem'
    height='1rem'
    viewBox='0 0 11 11'
    version='1.1'
  >
    <title>Icon/Info</title>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-346.000000, -870.000000)'>
        <g id='Group-10' transform='translate(166.000000, 621.000000)'>
          <g id='Group-5' transform='translate(20.000000, 243.000000)'>
            <g id='Group-7' transform='translate(34.000000, 0.000000)'>
              <g id='info-outline' transform='translate(126.000000, 6.000000)'>
                <rect
                  id='Rectangle'
                  fill='#000000'
                  fillRule='nonzero'
                  opacity='0'
                  transform='translate(5.500000, 5.500000) rotate(180.000000) translate(-5.500000, -5.500000) '
                  x='-8.14163551e-16'
                  y='8.14163551e-16'
                  width='11'
                  height='11'
                />
                <path
                  d='M5.5,0.916666667 C6.71557558,0.916666667 7.88136435,1.39955219 8.74090608,2.25909392 C9.60044781,3.11863565 10.0833333,4.28442442 10.0833333,5.5 C10.0833333,8.0313051 8.0313051,10.0833333 5.5,10.0833333 C2.9686949,10.0833333 0.916666667,8.0313051 0.916666667,5.5 C0.916666667,2.9686949 2.9686949,0.916666667 5.5,0.916666667 Z M5.5,1.83333333 C3.47495592,1.83333333 1.83333333,3.47495592 1.83333333,5.5 C1.83333333,7.52504408 3.47495592,9.16666667 5.5,9.16666667 C6.47246046,9.16666667 7.40509148,8.78035825 8.09272486,8.09272486 C8.78035825,7.40509148 9.16666667,6.47246046 9.16666667,5.5 C9.16666667,3.47495592 7.52504408,1.83333333 5.5,1.83333333 Z M5.5,4.58333333 C5.75313051,4.58333333 5.95833333,4.78853616 5.95833333,5.04166667 L5.95833333,5.04166667 L5.95833333,7.33333333 C5.95833333,7.58646384 5.75313051,7.79166667 5.5,7.79166667 C5.24686949,7.79166667 5.04166667,7.58646384 5.04166667,7.33333333 L5.04166667,7.33333333 L5.04166667,5.04166667 C5.04166667,4.78853616 5.24686949,4.58333333 5.5,4.58333333 Z M5.5,3.20833333 C5.75313051,3.20833333 5.95833333,3.41353616 5.95833333,3.66666667 C5.95833333,3.91979718 5.75313051,4.125 5.5,4.125 C5.24686949,4.125 5.04166667,3.91979718 5.04166667,3.66666667 C5.04166667,3.41353616 5.24686949,3.20833333 5.5,3.20833333 Z'
                  id='Combined-Shape'
                  fill='#1A1C1F'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default TooltipIcon
