import React, { useState } from 'react'

import Modal from 'components/common/modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dropdown, DropdownButton, Tab, Tabs
} from 'react-bootstrap'
import classNames from 'classnames'
import commentSlice from 'redux/slices/comments'
import useTrackGreetingCardEvent from 'components/celebrations/hooks/useTrackGreetingCardEvent'
import celebrationSlice from 'redux/slices/celebrations'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { ButtonNarrow } from 'components/common/buttons'
import SignWithGif from 'components/celebrations/signGreetingCardModal/signWithGif'
import CdnSvg from 'components/common/cdnSvg'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import SignWithImage from 'components/celebrations/signGreetingCardModal/signWithImage'
import SignWithVideoFromFile from 'components/celebrations/signGreetingCardModal/signWithVideoFromFile'
import SignWithVideoFromCamera from 'components/celebrations/signGreetingCardModal/signWithVideoFromCamera'
import SignWithAudio from 'components/celebrations/signGreetingCardModal/signWithAudio'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import { REMOVE_MEDIA } from 'services/api/comments'

const I18N = i18nPath('views.celebrations.sign_greeting_card_page')

const currentTabMap = {
  text: 'gif',
  image: 'image',
  video: 'videoRecording',
  audio: 'audio',
}

const SignGreetingCardModal = ({
  comment = undefined,
  celebration,
  visible,
  onClose,
  onCommentCreated = () => {},
}) => {
  const dispatch = useDispatch()
  const [content, setContent] = useState(comment?.content || '')
  const [gifUrl, setGifUrl] = useState(comment?.gifUrl)
  const [media, setMedia] = useState({ type: null, file: null })
  const [activeKey, setActiveKey] = useState(currentTabMap[comment?.media?.type] || 'gif')
  const [showVideoDropdown, setShowVideoDropdown] = useState(false)

  const isCreating = !comment
  const { isSaving } = useSelector(commentSlice.selectors.getMetaData('celebration', celebration.id))
  const trackGreetingCardEvent = useTrackGreetingCardEvent()

  const handleCommentCreated = () => {
    const commentsCount = celebration.commentsCount + 1

    trackGreetingCardEvent('greeting_card_submit_message_clicked', celebration, {
      media_type: media?.type || (gifUrl ? 'gif' : 'none'),
    })

    dispatch(celebrationSlice.asyncActions.updateAttributes(celebration.id, {
      hasCommented: true,
      commentsCount,
    }))

    onCommentCreated()
  }

  const saveComment = () => {
    const params = {
      content,
      gifUrl,
    }

    if (isCreating) {
      dispatch(commentSlice.asyncActions.create(
        celebration,
        'celebration',
        params,
        handleCommentCreated,
        media.file
      ))
    } else {
      dispatch(commentSlice.asyncActions.update(
        comment,
        'celebration',
        params,
        onClose,
        media.file
      ))
    }
  }

  return (
    <Modal
      className='SignGreetingCardModal'
      size='lg'
      visible={visible}
      toggle={onClose}
      modalContentClassName='px-3'
    >
      <div className='SignGreetingCardForm'>
        <h3 className='mb-3'>
          {I18N(`title.${celebration.type}`, { name: celebration.user?.preferredFullName })}
        </h3>

        <Tabs
          activeKey={activeKey}
          className='mb-3'
          onSelect={(key) => {
            if (key === 'video') {
              setShowVideoDropdown(oldVal => !oldVal)

              return
            }

            setShowVideoDropdown(false)
            setActiveKey(key)
          }}
        >
          <Tab
            eventKey='gif'
            title={(
              <>
                <CdnSvg src='/images/illustrations/gif.svg' className='mr-2' />

                GIF
              </>
            )}
          >
            <SignWithGif
              setGifUrl={setGifUrl}
              gifUrl={gifUrl}
              initialTerm={celebration.type}
              key={activeKey}
            />
          </Tab>
          <Tab
            eventKey='image'
            title={(
              <>
                <CdnSvg src='/images/illustrations/image.svg' className='mr-2' />

                {I18N('sign_modal.tabs.image')}
              </>
            )}
          >
            <SignWithImage
              comment={comment}
              media={media}
              setMedia={setMedia}
              disabled={isSaving}
            />
          </Tab>

          <Tab
            eventKey='video'
            tabClassName={
              classNames({ active: ['videoUpload', 'videoRecording'].includes(activeKey) })
            }
            title={(
              <DropdownButton
                show={showVideoDropdown}
                bsPrefix='custom'
                title={(
                  <>
                    <CdnSvg src='/images/illustrations/video.svg' className='mr-2' />

                    {I18N('sign_modal.tabs.video')}
                  </>
                )}
              >
                <Dropdown.Item onClick={() => setActiveKey('videoRecording')}>
                  <CdnSvg src='/images/tiptap/video.svg' className='mr-2 icon' />

                  {I18N('sign_modal.tabs.record_video')}
                </Dropdown.Item>

                <Dropdown.Item onClick={() => setActiveKey('videoUpload')}>
                  <CdnSvg src='/images/uploadIcon.svg' className='mr-2 icon' />

                  {I18N('sign_modal.tabs.upload_video')}
                </Dropdown.Item>
              </DropdownButton>
            )}
          />

          <Tab
            eventKey='videoUpload'
          >
            <SignWithVideoFromFile
              comment={comment}
              media={media}
              setMedia={setMedia}
              disabled={isSaving}
            />
          </Tab>

          <Tab
            eventKey='videoRecording'
          >
            <SignWithVideoFromCamera
              comment={comment}
              media={media}
              setMedia={setMedia}
            />
          </Tab>

          <Tab
            eventKey='audio'
            title={(
              <>
                <CdnSvg src='/images/illustrations/audio.svg' className='mr-2' />

                {I18N('sign_modal.tabs.audio')}
              </>
            )}
          >
            <SignWithAudio
              comment={comment}
              media={media}
              setMedia={setMedia}
            />
          </Tab>
        </Tabs>

        <div className='mt-4'>
          <TiptapEditor
            configuration={{
              ...SIMPLE_EDITOR_CONFIGURATION,
              placeholder: I18NCommon('default_placeholder_text'),
            }}
            toolbarItems={SMALL_TOOLBAR}
            onChange={setContent}
            html={content}
            className='RoundedTiptapEditor w-100 mb-3'
            editorContentClassName='p-3'
          />
        </div>

        <div className='d-flex justify-content-end'>
          <ButtonNarrow
            onClick={saveComment}
            disabled={
              isSaving || (isHtmlStringEmpty(content) && !gifUrl && (!media.file || media.file === REMOVE_MEDIA))
            }
            showLoadingSpinner={isSaving}
          >
            {I18N('sign_the_card')}
          </ButtonNarrow>
        </div>
      </div>
    </Modal>
  )
}

export default SignGreetingCardModal
