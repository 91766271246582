import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const MAX_TABLE_SIZE = 10

interface TableSize {
  row: number,
  col: number,
}

export const TableDropdownContent = ({
  editor,
  onInsertSuccess,
}) => {
  const [selectedTableSize, setSelectedTableSize] = useState<TableSize>({ row: 1, col: 1 })

  const addTable = (rows, cols) => {
    editor.chain().focus().insertTable({ rows, cols, withHeaderRow: false }).run()

    onInsertSuccess()
  }

  const generateTable = () => {
    const rows: any[] = []

    for (let i = 0; i < MAX_TABLE_SIZE; i += 1) {
      const cells: any[] = []

      for (let j = 0; j < MAX_TABLE_SIZE; j += 1) {
        const isSelected = i < selectedTableSize.row && j < selectedTableSize.col
        cells.push(
          <td
            className={isSelected ? 'selected' : ''}
            onMouseOver={() => setSelectedTableSize({ row: i + 1, col: j + 1 })}
            onClick={() => addTable(i + 1, j + 1)}
            key={j}
          />
        )
      }

      rows.push(<tr key={i}>{cells}</tr>)
    }
    return rows
  }

  return (
    <div className='TableDropdownContent'>
      <div className='d-flex align-items-center justify-content-center'>
        {selectedTableSize.row} x {selectedTableSize.col}
      </div>
      <table className='insertTable'>
        <tbody>
          {generateTable()}
        </tbody>
      </table>
    </div>
  )
}


const InsertTableDropdownButton = ({ editor, isDisabled = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <ToolbarDropdownButton
      tooltipText={I18N('insert_table_tooltip')}
      className='InsertTableDropdownButton'
      dropdownContent={(
        <TableDropdownContent
          editor={editor}
          onInsertSuccess={() => setIsDropdownOpen(false)}
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src='/images/tiptap/table.svg' />
    </ToolbarDropdownButton>
  )
}

export default InsertTableDropdownButton
