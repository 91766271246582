import React from 'react'
import GreetingCardComment from 'components/celebrations/greetingCardComment'

const CelebrationCommentSlide = ({ comments }) => (
  <div className='SlideShowPage__Slider__CommentSlide'>
    {comments.map(comment => (
      <div key={comment.id} id={`comment-${comment.id}`}>
        <GreetingCardComment comment={comment} nameOnTop={true} />
      </div>
    ))}
  </div>
)

export default CelebrationCommentSlide
