import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import SayThanksModal from 'components/celebrations/sayThanksModal'
import CelebrationModalContext, { SOCIAL_SHARE_MODAL } from 'components/celebrations/greeting_card_actions/celebrationModalContext'

export const SAY_THANKS_MODAL = 'sayThanksModal'
const I18N = i18nPath('views.celebrations.greeting_card')

const SayThanksButton = ({
  celebration, modalClassName = '',
}) => {
  const {
    setCurrentOpenModal, currentOpenModal, closeModal, onThanksSent,
  } = React.useContext(CelebrationModalContext)

  return (
    <>
      <Button onClick={() => setCurrentOpenModal(SAY_THANKS_MODAL)}>
        {!celebration.thanksMessage ? I18N('say_thanks') : I18N('resend_thanks')}
      </Button>

      {(currentOpenModal === SAY_THANKS_MODAL || currentOpenModal === SOCIAL_SHARE_MODAL) && (
        <SayThanksModal
          className={modalClassName}
          celebration={celebration}
          visible
          onClose={closeModal}
          onThanksSent={onThanksSent}
          missingSayThanksRecipientIds={celebration.missingSayThanksRecipientIds}
        />
      )}
    </>
  )
}

export default SayThanksButton
