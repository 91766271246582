import Axios from 'axios'
import searchURI from 'services/searchURI'

const layouts = {
  layouts: {
    fetch(size, params = {}) {
      return Axios.get(searchURI(`/api/layout/layouts/${size}`, params))
    },
  },

  links: {
    fetchAll(widgetId) {
      return Axios.get(`/api/layout/widgets/${widgetId}/links`)
    },

    create(widgetId, link) {
      return Axios.post(`/api/layout/widgets/${widgetId}/links`, { link })
    },

    update(widgetId, link) {
      return Axios.patch(`/api/layout/widgets/${widgetId}/links/${link.id}`, { link })
    },

    delete(widgetId, linkId) {
      return Axios.delete(`/api/layout/widgets/${widgetId}/links/${linkId}`)
    },
  },

  usedWidgets: {
    fetchAll(opts = {}) {
      return Axios.get(searchURI('/api/layout/used_widgets', opts))
    },
  },
}

export default layouts
