import React from 'react'
import classNames from 'classnames'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

const I18N = i18nPath('views.fe_error')

const DefaultErrorMessage = ({
  className, history, onErrorRender, showTitle = true,
}) => (
  <div key='ErrorBoundary' className={classNames('App_ErrorBoundary container my-3', className)}>
    <h2>{showTitle && I18N('title')}</h2>
    {onErrorRender && <p className='ErrorBoundary-links'>{onErrorRender}</p>}
    {!onErrorRender && (
      <p className='ErrorBoundary-links'>
        {i18nFormat(I18N('description'), <a onClick={() => (window.location = '/')}>{I18N('click_here')}</a>)}
        {' '}
        {(typeof history !== 'undefined') && (
          <>
                  or
            {' '}
            {i18nFormat(
              I18N('go_back'),
              <a onClick={() => history.goBack()}>{I18N('back')}</a>,
              I18N('previous_page')
            )}
          </>
        )}
      </p>
    )}
  </div>
)

export default DefaultErrorMessage
