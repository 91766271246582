import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const VERTICAL_ALIGNMENT = ['top', 'middle', 'bottom']

const VerticalAlignmentItem = ({ editor, onClose }) => {
  const tableHeaderAlignment = editor.getAttributes('tableHeader')?.verticalAlign
  const tableCellAlignment = editor.getAttributes('tableCell')?.verticalAlign

  const currentAlignment = tableHeaderAlignment || tableCellAlignment || 'middle'

  return (
    <BaseContextMenuItem
      iconPath='/images/tiptap/vertical-align.svg'
      label={I18N('vertical_alignment.label')}
    >
      {VERTICAL_ALIGNMENT.map(alignment => (
        <BaseContextMenuItem
          key={alignment}
          label={I18N(`vertical_alignment.submenu.${alignment}`)}
          isActive={alignment === currentAlignment}
          onClick={() => {
            onClose()

            editor.chain().focus().setCellAttribute('verticalAlign', alignment).run()
          }}
        />
      ))}
    </BaseContextMenuItem>
  )
}

export default VerticalAlignmentItem
