import BubbleMenuDeleteButton from 'components/common/tiptap/menus/buttons/bubbleMenuDeleteButton'
import EditDropdownButton from 'components/common/tiptap/toolbar/items/editDropdownButton'
import React from 'react'


const SocialShareBubbleMenuOptions = ({ editor }) => {
  const isEditMode = editor.getAttributes('socialShare').editMode

  if (isEditMode) { return null }

  return (
    <div
      className='SocialShareBubbleMenuOptions BubbleMenuOptions d-flex p-2 align-items-center justify-content-center'
    >
      <BubbleMenuDeleteButton editor={editor} />
      <EditDropdownButton editor={editor} nodeType='socialShare' />
    </div>
  )
}

export default SocialShareBubbleMenuOptions
