import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const cellActions = [
  {
    label: 'merge_cells',
    onClick: editor => editor.chain().focus().mergeCells().run(),
    isDisabled: editor => editor.state.selection.ranges.length === 1,
  },
  {
    label: 'split_cell',
    onClick: editor => editor.chain().focus().splitCell().run(),
    isDisabled: editor => editor.state.selection.ranges.length > 1,
  },
]

const MergeSplitCellsItem = ({ editor, onClose }) => (
  <BaseContextMenuItem
    iconPath='/images/tiptap/table-with-star.svg'
    label={I18N('merge_split_cells.label')}
  >
    {cellActions.map(({ label, onClick, isDisabled }) => (
      <BaseContextMenuItem
        key={label}
        label={I18N(`merge_split_cells.submenu.${label}`)}
        onClick={() => {
          onClose()
          onClick(editor)
        }}
        disabled={isDisabled(editor)}
      />
    ))}
  </BaseContextMenuItem>
)

export default MergeSplitCellsItem
