import TargetingRulesType, { OperatorValues, TypeEnum, TypeValues } from 'types/audience/targetingRules'

const TARGETABLE_USER_ATTRIBUTES = [
  'city',
  'country',
  'department',
  'job_family',
  'manager',
  'office_location',
  'region',
  'worker_type',
  'title',
]

const isValidRule = (rule) => {
  if (_.isEmpty(rule.values)) return false
  if (!TypeValues.includes(rule.type)) return false
  if (!OperatorValues.includes(rule.operator)) return false
  if (rule.type === 'attribute' && !TARGETABLE_USER_ATTRIBUTES.includes(rule.key)) return false

  return true
}

const normalizeRules = rules => rules.map((rule) => {
  if (_.isEmpty(rule.rules)) {
    let normalizedRule = _.omit(rule, 'reactKey')

    if (normalizedRule.type === TypeEnum.Reports) {
      normalizedRule = {
        ...normalizedRule,
        // For reports type rules, normalize values to just IDs if they are objects with IDs,
        // otherwise keep the raw value which should already be the id.
        // This way if we go through the method more than once, we don't end up with undefined values.
        values: normalizedRule.values.map(value => (value.id ? value.id : value)),
      }
    }

    return isValidRule(normalizedRule) ? normalizedRule : null
  } else {
    const nestedRules = normalizeRules(rule.rules)

    if (_.isEmpty(nestedRules)) return null

    return {
      ..._.omit(rule, 'reactKey'),
      rules: nestedRules,
    }
  }
}).filter(Boolean)

const normalizeTargetingRules = (targetingRules) => {
  const { targetEntireCompany, rules = [], users = [] } = targetingRules || {}

  if (targetEntireCompany) {
    return {
      targetEntireCompany,
      rules: [],
      users: [],
      userIds: [],
    }
  }

  return {
    targetEntireCompany,
    rules: normalizeRules(rules),
    users: users.map(user => _.pick(user, ['id', 'preferredFullName', 'title', 'primaryPhotoThumbnailUrl', 'username', 'city'])),
    userIds: users.map(user => user.id),
  }
}

export const normalizeTargetingRulesPayload = (targetingRules) => {
  const result = normalizeTargetingRules(targetingRules)

  // delete users from result as it's not needed in the payload for the backend
  // and it's an issue due to increasing a lot the payload size
  delete result.users

  return result
}

export const areNormalizedTargetingRulesEqual = (targetingRules1, targetingRules2) => _.isEqual(
  normalizeTargetingRules(targetingRules1),
  normalizeTargetingRules(targetingRules2)
)

export const TARGET_ENTIRE_COMPANY: TargetingRulesType = {
  targetEntireCompany: true,
  rules: [],
  users: [],
}

export const TARGET_NO_ONE: TargetingRulesType = {
  targetEntireCompany: false,
  rules: [],
  users: [],
}

export default normalizeTargetingRules
