import Axios from 'axios'

export default {
  mostPopular(params) {
    return Axios.post('/api/admin/analytics/modules/most_popular', params)
  },
  usage(params) {
    return Axios.post('/api/admin/analytics/modules/usage', params)
  },
}
