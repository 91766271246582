import React, {
  useEffect, useRef, useState
} from 'react'
import GifPicker, { ContentFilter, GifPickerProps } from 'gif-picker-react'
import { createPortal } from 'react-dom'
import CdnSvg from 'components/common/cdnSvg'
import pageVariables from 'utils/pageVariables'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

interface Props extends Omit<GifPickerProps, 'tenorApiKey'> {
  initialTerm?: string
}

const GifSearcher = ({
  initialTerm, ...props
}: Props) => {
  const { locale } = useCurrentUser()

  const [poweredByTenorContainer, setPoweredByTenorContainer] = useState<HTMLDivElement | null>(null)
  const [containerChangedCount, setContainerChangedCount] = useState(0)

  const wrapperRef = useRef<HTMLDivElement>(null)

  // The 'gif-picker-react' does not have great support in terms of customization.
  // So we need to manually add the 'Powered by Tenor' logo to the gif picker.
  useEffect(() => {
    // trending gifs has a gpr-title-container, search has a gpr-search-container
    // so we need to add the PoweredByTenorContainer to the parent of the wrapperDiv
    // and we also need to add it back when user is switching between trending and search
    const containerDiv = wrapperRef.current!.querySelector('.gpr-title-container, .gpr-search-container') as HTMLDivElement

    // add an observer to check if containerDiv is removed
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.removedNodes.forEach((node) => {
          if (node === containerDiv) {
            observer.disconnect()
            // this will trigger this effect again
            setContainerChangedCount(prevCount => prevCount + 1)
          }
        })
      })
    })

    observer.observe(containerDiv.parentNode!, { childList: true })

    const poweredByTenorContainer = document.createElement('div')
    containerDiv.insertAdjacentElement('afterend', poweredByTenorContainer)
    setPoweredByTenorContainer(poweredByTenorContainer)

    return () => {
      poweredByTenorContainer.remove()
      observer.disconnect()
    }
  }, [containerChangedCount])

  return (
    <div ref={wrapperRef}>
      {poweredByTenorContainer && createPortal(
        <CdnSvg src='/images/logos/poweredByTenor.svg' className='d-block mb-2 ml-auto PoweredByTenorSVG' />,
        poweredByTenorContainer
      )}
      <GifPicker
        {...props}
        tenorApiKey={pageVariables.tenorApiKey}
        initialSearchTerm={initialTerm}
        locale={locale}
        contentFilter={ContentFilter.HIGH}
      />
    </div>
  )
}

export default GifSearcher
