import classNames from 'classnames'
import { Button, ButtonSmallNarrow } from 'components/common/buttons'
import CelebrationModalContext from 'components/celebrations/greeting_card_actions/celebrationModalContext'
import SendInvitationsModal from 'components/celebrations/sendInvitationsModal'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.greeting_card')
export const INVITE_OTHERS_MODAL = 'invite_others'

const InviteOthersButton = ({
  celebration, short = false, small = false, buttonClassName = '', buttonText = '',
  variant = 'primary',
}) => {
  const Btn = small ? ButtonSmallNarrow : Button

  const {
    setCurrentOpenModal, currentOpenModal, closeModal, justSignedCard,
  } = React.useContext(CelebrationModalContext)

  return (
    <>
      <Btn
        onClick={() => setCurrentOpenModal(INVITE_OTHERS_MODAL)}
        className={classNames('GreetingCardActionButton', buttonClassName)}
        variant={variant}
      >
        {buttonText || I18N(short ? 'invite_others' : 'invite_others_to_sign')}
      </Btn>

      {currentOpenModal === INVITE_OTHERS_MODAL && (
        <SendInvitationsModal
          celebration={celebration}
          visible
          onClose={closeModal}
          showNicelyDone={justSignedCard}
        />
      )}
    </>
  )
}

export default InviteOthersButton
