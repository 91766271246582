import build from 'redux-object'
import { createSlice } from '@reduxjs/toolkit'
import appSignal from 'services/appSignal'

import API from 'services/api'

import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import entitySlice from 'redux/slices/entities'

export const initialState = {
  richTextInlineCommentIds: [],
  meta: {
    ...defaultMeta,
  },
}

const richTextInlineCommentSlice = createSlice({
  name: 'richTextInlineComments',
  initialState,
  reducers: {
    ...defaultActions,

    addInlineCommentIds(state, action) {
      state.richTextInlineCommentIds = _.uniq([...state.richTextInlineCommentIds, ...action.payload])
    },

    addInlineCommentId(state, action) {
      state.richTextInlineCommentIds = [...state.richTextInlineCommentIds, action.payload]
    },
  },
})

const asyncActions = {
  fetchAll: clearyRichTextId => async (dispatch) => {
    dispatch(richTextInlineCommentSlice.actions.isLoading(true))

    try {
      const response = await API.richTextInlineComments.fetchAll(clearyRichTextId)
      const richTextInlineCommentIds = response.data.data.map(richTextInlineComment => richTextInlineComment.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(richTextInlineCommentSlice.actions.addInlineCommentIds(richTextInlineCommentIds))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(richTextInlineCommentSlice.actions.isLoading(false))
    }
  },

  create: (clearyRichTextId, body, onSuccess = (response) => {}) => async (dispatch) => {
    dispatch(richTextInlineCommentSlice.actions.isSaving(true))

    try {
      const response = await API.richTextInlineComments.create(clearyRichTextId, body)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(richTextInlineCommentSlice.actions.addInlineCommentId(response.data.data.id))

      onSuccess(response)
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(richTextInlineCommentSlice.actions.isSaving(false))
    }
  },
}

const selectors = {
  getInlineComments: () => state => state.richTextInlineComments.richTextInlineCommentIds?.map(id => build(state.entities, 'richTextInlineComment', id)).filter(Boolean),

  getMetaData: () => state => state.richTextInlineComments.meta,
}


export default {
  ...richTextInlineCommentSlice,
  asyncActions,
  selectors,
}
