import { useState, useEffect } from 'react'
import useActionCable from 'components/common/hooks/useActionCable'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const useAiTextGenerationEvents = (textGenerationId) => {
  const [result, setResult] = useState('')
  const [functionCalls, setFunctionCalls] = useState<any>([])
  const [errorReason, setErrorReason] = useState<any>(null)
  const [status, setStatus] = useState<any>(null)
  const isGenerating = ['new', 'generating'].includes(status)
  const [getGeneration, { isLoading }] = useApi(API.ai.textGenerations.fetch)

  const onDataReceived = (data) => {
    const type = data.type

    if (type !== 'finish') {
      setStatus('generating')
    }

    if (type === 'context_retrieval_finished') {
      setFunctionCalls(data.called_functions || [])
    } else if (type === 'delta') {
      setResult(prev => prev + data.text)
    } else if (type === 'finish') {
      getGenerationStatus()
    } else if (type === 'error') {
      setStatus('generation_error')
      setErrorReason(data.error_reason)
    }
  }

  const getGenerationStatus = () => {
    getGeneration(textGenerationId).then((data) => {
      setResult(data?.generatedText || '')
      setErrorReason(data?.errorReason)
      setStatus(data?.status)
    })
  }

  useEffect(() => {
    setFunctionCalls([])

    if (textGenerationId) {
      getGenerationStatus()
    }
  }, [textGenerationId])

  useActionCable({
    channel: 'Ai::TextGenerationChannel',
    recordId: textGenerationId,
    recordType: '::Ai::TextGeneration',
    isActive: !!textGenerationId && status !== 'finished',
    onDataReceived,
  })

  return {
    result,
    setResult,
    isLoading,
    isGenerating,
    functionCalls,
    status,
    errorReason,
  }
}

export default useAiTextGenerationEvents
