import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import styleAttribute from 'components/common/tiptap/extensions/utils/styleAttribute'

const clearyListOverrides = {
  addAttributes() {
    return {
      ...this.parent?.(),
      listStyleType: styleAttribute('listStyleType'),
      fontSize: styleAttribute('fontSize'),
      color: styleAttribute('color'),
    }
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setMarkerSize: (fontSize: number, listType: string) => ({ commands }) => {
        const fontSizeStyle = `${fontSize}px`
        commands.updateAttributes(listType, { fontSize: fontSizeStyle })
      },
      setListItemStyleType: listStyleType => ({ state, dispatch }) => {
        const { doc, selection } = state
        const { $from, $to } = selection

        let transaction = state.tr

        // This function finds the closest list ancestor for a given position.
        const getFirstListAncestor = (pos) => {
          const resolvedPos = state.doc.resolve(pos)

          // We walk up the tree from the given position to find the closest bulletList or orderedList node
          for (let depth = resolvedPos.depth; depth > 0; depth -= 1) {
            const node = resolvedPos.node(depth)
            const nodePos = resolvedPos.before(depth)

            if (['bulletList', 'orderedList'].includes(node.type.name)) {
              return { node, pos: nodePos }
            }
          }

          return null
        }

        doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
          const nodeType = node.type.name

          // We need to find all nodes that are not listItems, but are inside a list
          // then, get the list closest parent list and update the listStyleType
          // We need to do it this way, because some listItems might not be actually selected, but contain a selected list inside them
          if (!['listItem', 'bulletList', 'orderedList'].includes(nodeType)) {
            const listParent = getFirstListAncestor(pos)

            if (listParent) {
              const parentPos = listParent.pos
              const parentNode = listParent.node

              transaction = transaction.setNodeMarkup(parentPos, undefined, { ...parentNode.attrs, listStyleType })
            }
          }
        })

        // If the document was modified, we dispatch the transaction and return true
        if (transaction.docChanged) {
          dispatch && dispatch(transaction)
          return true
        }

        return false
      },
      setMarkerColor: (color: string, listType: string) => ({ commands }) => {
        commands.updateAttributes(listType, { color })
      },
    }
  },
}


export const ClearyBulletList = BulletList.extend({
  ...clearyListOverrides,
})

export const ClearyOrderedList = OrderedList.extend({
  ...clearyListOverrides,
})
