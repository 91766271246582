import React, { useMemo } from 'react'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { REMOVE_MEDIA } from 'services/api/comments'

const I18N = i18nPath('views.celebrations.greeting_card.sign_card')

interface Props {
  comment: any
  media: any
  setMedia: (media: any) => void
  type: 'video' | 'image'
  maxSizeInMB?: number
  Preview: any
  disabled?: boolean
}

const SignWithUploadedFile = ({
  comment, media, setMedia, disabled, type, maxSizeInMB = 10, Preview,
}: Props) => {
  const previewUrl = useMemo(() => (
    media.type === type ? URL.createObjectURL(media.file) : comment?.media?.url
  ), [media.type, comment?.media?.url])

  return (
    <div className='SignWithUploadedFile'>
      {media.type === type || (media.type !== REMOVE_MEDIA && comment?.media?.type === type) ? (
        <div className='text-center'>
          <button
            className='remove'
            onClick={() => {
              setMedia({ type: REMOVE_MEDIA, file: REMOVE_MEDIA })
            }}
          >
            <CdnSvg src='/images/trashIcon.svg' />
          </button>

          <Preview source={previewUrl} />
        </div>
      ) : (
        <FileSelectDropzone
          disabled={disabled}
          maxSizeInMB={maxSizeInMB}
          onDrop={files => setMedia({ type, file: files[0] })}
          className='FileSelectDropzone'
          accept={[`${type}/*`]}
        >
          <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
            <div className='helper-text'>
              <div className='text-center'>
                <CdnSvg src='/images/tiptap/upload.svg' className='mb-2' />
              </div>

              {I18N(`upload_${type}`)}
            </div>
          </div>
        </FileSelectDropzone>
      )}
    </div>
  )
}

export default SignWithUploadedFile
