import React from 'react'
import OverlayTrigger, { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import TooltipIcon from 'components/icons/tooltipIcon'


interface ClearyTooltipProps {
  placement?: OverlayTriggerProps['placement']
  key?: string
  show?: number
  hide?: number
  children?: any
  className?: string
  tooltipClassName?: string
  Icon?: any
  content?: any
  hideTooltip?: boolean
}

const ClearyTooltip = ({
  placement = 'right',
  show = 100,
  hide = 500,
  children,
  className,
  tooltipClassName,
  Icon = TooltipIcon,
  content = null,
  hideTooltip = false,
}: ClearyTooltipProps) => (
  <OverlayTrigger
    placement={placement}
    delay={{ show, hide }}
    overlay={<Tooltip className={tooltipClassName} id={`tooltip-${placement}`}>{children}</Tooltip>}

    // show={undefined} is the default behavior, it will open on hover
    // on certain cases (such as for ReactSelects) we want the tooltip closed on hover (when the menu is open)
    // this prop accomplishes that
    show={hideTooltip ? false : undefined}
  >
    <span className={className}>
      {content}
      {!content && <Icon />}
    </span>
  </OverlayTrigger>
)

export default ClearyTooltip
