import Axios from 'axios'

const buildRequestedBadgePayload = requestedBadge => _.pick(requestedBadge, [
  'id',
  'status',
  'justification',
  'badgeId',
])

const requestedBadges = {
  fetchAll(badgeId) {
    return Axios.get(`/api/badges/${badgeId}/requested_badges`)
  },

  create(requestedbadge) {
    return Axios.post(`/api/badges/${requestedbadge.badgeId}/requested_badges`, { requestedBadge: buildRequestedBadgePayload(requestedbadge) })
  },

  update(requestedbadge) {
    return Axios.patch(`/api/badges/${requestedbadge.badgeId}/requested_badges/${requestedbadge.id}`, { requestedBadge: buildRequestedBadgePayload(requestedbadge) })
  },
}

export default requestedBadges
