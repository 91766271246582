import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const OutdentButton = ({
  editor,
  isDisabled = false,
}) => (
  <ToolbarButton
    className='OutdentButton'
    onClick={() => editor.chain().focus().decreaseIndent().run()}
    isDisabled={isDisabled || !editor.can().decreaseIndent()}
    tooltipText={I18N('outdent_tooltip')}
  >
    <CdnSvg src='/images/tiptap/outdent.svg' />
  </ToolbarButton>
)

export default OutdentButton
