
import React, { forwardRef } from 'react'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import BaseItemList from 'components/common/tiptap/suggestions/baseItemList'
import { SimpleUserType } from 'types/user'

interface Props {
  items: SimpleUserType[]
  command: (any) => void
}

const MentionList = forwardRef(({ items, command }: Props, ref) => {
  const onItemSelect = (item: SimpleUserType) => {
    command({ ...item, label: item.preferredFullName })
  }

  return (
    <BaseItemList
      ref={ref}
      onItemSelect={onItemSelect}
      items={items}
      itemRenderer={item => <EmployeeMiniCard employee={item} />}
      className='MentionList'
    />
  )
})

export default MentionList
