import React, { useEffect, useRef, useState } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import { Button } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import CloseIcon from 'components/icons/closeIcon'
import useClickOutside from 'components/common/hooks/useClickOutside'
import classNames from 'classnames'
import SocialShare from 'components/common/social_share/socialShare'
import InlineImageCrop from 'components/common/image_crop/inlineImageCrop'

const I18N = i18nPath('views.tiptap.social_share')

const startsWithHttpOrHttps = (url?: string) => url?.startsWith('http://') || url?.startsWith('https://')

export const MAX_SUGGESTION_LENGTH = 236 // This number came from the design

const defaultCroppedArea = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
}

const assertNoNaNAttributes = (croppedArea: any) => {
  if (isNaN(croppedArea.x) || isNaN(croppedArea.y) || isNaN(croppedArea.width) || isNaN(croppedArea.height)) {
    return defaultCroppedArea
  }

  return croppedArea
}

const Editor = ({
  updateAttributes, node, deleteNode,
}) => {
  const [extractOpengraphAttributes, { isLoading }] = useApi(API.admin.socialShares.extractOpengraphAttributes, {
    onSuccess: ({ title, imageUrl }) => {
      updateAttributes({
        title,
        imageUrl,
        croppedArea: defaultCroppedArea,
      })
    },
    toastErrorMessage: I18N('error_occurred_in_fetching_url'),
  })

  const connect = (fieldName: string) => ({
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updateAttributes({ [fieldName]: e.target.value })
    },
    value: node.attrs[fieldName],
  })

  const onBlurUrl = () => {
    extractOpengraphAttributes(node.attrs.url)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    updateAttributes({
      editMode: false,
    })
  }

  const isSuggestedTextExceedingMaxLength = (node.attrs.suggestedText?.length || 0) > MAX_SUGGESTION_LENGTH

  const isDisabled = isLoading || !startsWithHttpOrHttps(node.attrs.url) || isSuggestedTextExceedingMaxLength

  return (
    <div
      className='SocialShareEditor px-3 py-3 position-relative'
      contentEditable={false}
    >
      <div className='close-icon-wrapper position-absolute' onClick={deleteNode}>
        <CloseIcon />
      </div>

      <h4 className='mb-0'>
        {I18N('encourage_sharing')}
      </h4>

      <p className='text-muted mb-3'>{I18N('encourage_sharing_subtitle')}</p>

      <form onSubmit={onSubmit}>
        <div className='row'>
          <div className='form-group col'>
            <label className='required-form-label'>{I18N('url_label')}</label>
            <input name='url' {...connect('url')} onBlur={onBlurUrl} />
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <label>{I18N('title_label')}</label>
            <input name='title' {...connect('title')} disabled={isLoading} />
            <small className='form-text text-muted'>{I18N('title_helper_text')}</small>
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <label>{I18N('image_url_label')}</label>
            <input name='image_url' {...connect('imageUrl')} disabled={isLoading} />
            {node.attrs.imageUrl && (
              <div className='mt-2'>
                <InlineImageCrop
                  key={node.attrs.imageUrl} // let's make sure to render a new instance of the component when the image changes
                  imageSrc={node.attrs.imageUrl}
                  aspect={5}
                  onCropComplete={croppedAreaPercentages => updateAttributes({ croppedArea: croppedAreaPercentages })}
                  showActions={false}
                  initialCroppedAreaPercentages={assertNoNaNAttributes(node.attrs.croppedArea)}
                />
              </div>
            )}
            <small className='form-text text-muted'>{I18N('image_url_helper_text')}</small>
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <label>{I18N('suggestion_label')}</label>
            <div className='position-relative'>
              <textarea
                placeholder={I18N('suggestion_placeholder')}
                {...connect('suggestedText')}
              />

              <div className={classNames('suggestion-counter font-weight-300', { 'text-muted': !isSuggestedTextExceedingMaxLength, 'text-danger': isSuggestedTextExceedingMaxLength })}>
                {node.attrs.suggestedText?.length || 0}/{MAX_SUGGESTION_LENGTH}
              </div>
            </div>
          </div>
        </div>

        <Button
          type='submit'
          disabled={isDisabled}
          showLoadingSpinner={isLoading}
        >
          {I18NCommon('insert')}
        </Button>
      </form>
    </div>
  )
}

const Viewer = ({ node }) => (
  <SocialShare
    url={node.attrs.url}
    title={node.attrs.title}
    imageUrl={node.attrs.imageUrl}
    suggestedText={node.attrs.suggestedText}
    croppedArea={node.attrs.croppedArea}
    editMode={true}
  />
)

const SocialShareComponent = ({
  // editor,
  node,
  updateAttributes,
  deleteNode = () => { },
}) => {
  // const editorAttributes = editor?.options?.editorProps?.attributes
  // const isEditingTemplate = editorAttributes?.isEditingTemplate
  const editMode = node.attrs.editMode

  const wrapperRef = useRef<any>(null)
  const [isSelected, setIsSelected] = useState(false)

  useClickOutside({
    ref: wrapperRef,
    onClickOutside: () => setIsSelected(false),
    enableClickOutside: isSelected,
  })

  // when we go to edit mode, we need to set isSelected to true
  // this handles the case we click in the bubble edit menu (outside of the node)
  useEffect(() => {
    if (editMode) {
      // must be done in a timeout since this must execute after the useClickOutside hook
      setTimeout(() => {
        setIsSelected(true)
      })
    }
  }, [editMode])

  return (
    <NodeViewWrapper
      className={classNames('SocialShareComponent', { 'is-selected': isSelected })}
      ref={wrapperRef}
      onClick={() => setIsSelected(true)}
    >
      {editMode ? (
        <Editor
          updateAttributes={updateAttributes}
          node={node}
          deleteNode={deleteNode}
        />
      ) : (
        <Viewer node={node} />
      )}
    </NodeViewWrapper>
  )
}

export default SocialShareComponent
