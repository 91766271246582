// ensure any new configuration fields are represented here with a default
export const DEFAULT_CONFIGURATION = {
  aiEnabled: false,
  commentsEnabled: false,
  embedsEnabled: true,
  filesEnabled: true,
  focusOnInitialize: false,
  imagesEnabled: true,
  placeholder: undefined,
  slashCommandEnabled: true,
  stickyToolbar: false,
  userMentionsEnabled: true,
  videosEnabled: false,
  isZoomEnabled: false,
  isEditingTemplate: false,
  socialShareEnabled: false,
  characterLimit: null,
}

// common configuration overrides for the simple editor on profiles, groups, etc.
export const SIMPLE_EDITOR_CONFIGURATION = {
  embedsEnabled: false,
  filesEnabled: false,
  imagesEnabled: false,
  slashCommandEnabled: false,
  userMentionsEnabled: false,
  videosEnabled: false,
  isEditingTemplate: false,
}

export type ConfigSetting = keyof typeof DEFAULT_CONFIGURATION
