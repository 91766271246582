import React from 'react'
import LoadingSpinner from 'components/common/circlesLoadingIndicator'

interface Props {
  className?: string
  width?: number
  height?: number
  children?: any
  showLoadingSpinner?: boolean
}

const LoadingSpinnerText = ({
  className,
  width,
  height,
  children,
  showLoadingSpinner,
}: Props) => (
  <>
    {showLoadingSpinner ? <LoadingSpinner className={className} width={width} height={height} /> : children}
  </>
)

export default LoadingSpinnerText
