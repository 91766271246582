import React, { RefObject } from 'react'
import BlockColorPicker from 'components/common/blockColorPicker'

interface Props {
  color: any
  onChange: (newColor: string) => void
  onRemove: () => void
  isOpen: boolean
  onClose: () => void,
  buttonRef?: RefObject<HTMLDivElement>
}

const ColorPicker = ({
  color, onChange, isOpen, onRemove, onClose, buttonRef,
}: Props) => (
  <div className='ContextMenuColorPicker position-relative'>
    <BlockColorPicker
      isOpen={isOpen}
      onClose={onClose}
      color={color}
      onChange={onChange}
      onRemove={onRemove}
      buttonRef={buttonRef}
    />
  </div>
)

export const ColorPickerPreview = ({ color }) => (
  <div className='p-2 ml-auto ContextMenuColorPickerPreview' style={{ backgroundColor: color }} />
)

export default ColorPicker
