import useWindowResize from 'components/common/hooks/useWindowResize'
import Offcanvas from 'components/common/offcanvas'
import React from 'react'
import { Popover, PopoverProps } from 'reactstrap'

interface Props {
  isOpen: boolean
  toggle: () => void
  children: React.ReactNode
  target?: PopoverProps['target']
  placement?: PopoverProps['placement']
}

const ResponsivePopover: React.FC<Props> = ({
  isOpen,
  toggle,
  children,
  target,
  placement = 'bottom',
}) => {
  const { isUpToMediumScreen } = useWindowResize()

  if (!target) { return null }

  return (
    isUpToMediumScreen ? (
      <Offcanvas isOpen={isOpen} toggle={toggle}>
        {children}
      </Offcanvas>
    ) : (
      <Popover
        target={target}
        isOpen={isOpen}
        toggle={toggle}
        placement={placement}
        hideArrow
      >
        {children}
      </Popover>
    )
  )
}

export default ResponsivePopover
