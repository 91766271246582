import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import userAccessManagementSlice from 'redux/slices/userAccessManagement'
import entitySlice from 'redux/slices/entities'
import { i18nPath, I18NCommon, i18nMoment } from 'utils/i18nHelpers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { showToastMessage } from 'redux/slices/toasts'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import appSignal from 'services/appSignal'


const I18N = i18nPath('views.profile')

export const initialState = {
  celebrations: {
    workAnniversaryUserIds: [],
    birthdayUserIds: [],
    newEmployeeUserIds: [],
  },
  workAnniversaries: {
    thisWeekUserIds: [],
    lastWeekUserIds: [],
    nextWeekUserIds: [],
  },
  birthdays: {
    thisWeekUserIds: [],
    lastWeekUserIds: [],
    nextWeekUserIds: [],
  },
  newEmployees: {
    thisWeekUserIds: [],
    lastWeekUserIds: [],
  },
  activeUsersCount: 0,
  userIds: [],
  fetchingUserIds: [],
  selectableOptionValues: {},
  meta: {
    hasLoaded: false,
    isLoadingWorkAnniversaries: false,
    isLoadingBirthdays: false,
    isLoadingNewEmployees: false,
    isLoadingActiveUsersCount: false,
    isLoadingSelectableOptionValues: false,
    isLoadingUserPermissions: false,
    workAnniversaryQueryParams: defaultPaginationParams,
    newEmployeeQueryParams: defaultPaginationParams,
    birthdayQueryParams: defaultPaginationParams,
    workAnniversaryError: null,
    birthdayError: null,
    newEmployeeError: null,
    activeUsersCountError: null,
    isLoading: false,
    isSaving: false,
    isPhotoUploading: false,
    isNotFound: false,
    usersQueryParams: defaultPaginationParams,
    isLoadingUsers: false,
    usersNotFound: false,
    error: null,
    isDeleting: false,
    isMergingUsers: false,
    selectedTab: '',
  },
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clear(state, action) {
      state.meta = initialState.meta
    },
    setUserIds(state, action) {
      state.userIds = action.payload
    },
    setFetchingUserIds(state, action) {
      const currentUserIdsSet = new Set(state.fetchingUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.fetchingUserIds = Array.from(currentUserIdsSet)
    },
    setUsersNotFound(state, action) {
      state.meta.usersNotFound = action.payload
    },
    setIsLoadingUsers(state, action) {
      state.meta.isLoadingUsers = action.payload
    },
    setUsersQueryParams(state, action) {
      state.meta.usersQueryParams = action.payload
    },
    setWorkAnniversaryError(state, action) {
      state.meta.workAnniversaryError = action.payload
    },
    setIsLoadingWorkAnniversaries(state, action) {
      state.meta.isLoadingWorkAnniversaries = action.payload
    },
    setWorkAnniversaryQueryParams(state, action) {
      state.meta.workAnniversaryQueryParams = action.payload
    },
    setWorkAnniversariesThisWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.workAnniversaries.thisWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.workAnniversaries.thisWeekUserIds = Array.from(currentUserIdsSet)
    },
    setWorkAnniversariesLastWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.workAnniversaries.lastWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.workAnniversaries.lastWeekUserIds = Array.from(currentUserIdsSet)
    },
    setWorkAnniversariesNextWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.workAnniversaries.nextWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.workAnniversaries.nextWeekUserIds = Array.from(currentUserIdsSet)
    },
    setBirthdayError(state, action) {
      state.meta.birthdayError = action.payload
    },
    setIsLoadingBirthdays(state, action) {
      state.meta.isLoadingBirthdays = action.payload
    },
    setBirthdayQueryParams(state, action) {
      state.meta.birthdayQueryParams = action.payload
    },
    setBirthdaysThisWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.birthdays.thisWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.birthdays.thisWeekUserIds = Array.from(currentUserIdsSet)
    },
    setBirthdaysLastWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.birthdays.lastWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.birthdays.lastWeekUserIds = Array.from(currentUserIdsSet)
    },
    setBirthdaysNextWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.birthdays.nextWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.birthdays.nextWeekUserIds = Array.from(currentUserIdsSet)
    },
    setNewEmployeeError(state, action) {
      state.meta.newEmployeeError = action.payload
    },
    setIsLoadingNewEmployees(state, action) {
      state.meta.isLoadingNewEmployees = action.payload
    },
    setNewEmployeeQueryParams(state, action) {
      state.meta.newEmployeeQueryParams = action.payload
    },
    setNewEmployeesThisWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.newEmployees.thisWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.newEmployees.thisWeekUserIds = Array.from(currentUserIdsSet)
    },
    setNewEmployeesLastWeekUserIds(state, action) {
      const currentUserIdsSet = new Set(state.newEmployees.lastWeekUserIds || [])
      const incomingUserIds = action.payload

      incomingUserIds.forEach(id => currentUserIdsSet.add(id))

      state.newEmployees.lastWeekUserIds = Array.from(currentUserIdsSet)
    },
    hasLoaded(state, action) {
      state.meta.hasLoaded = action.payload
    },
    setActiveUsersCount(state, action) {
      state.activeUsersCount = action.payload
    },
    setIsLoadingActiveUsersCount(state, action) {
      state.meta.isLoadingActiveUsersCount = action.payload
    },
    setActiveUsersCountError(state, action) {
      state.meta.activeUsersCountError = action.payload
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    setIsSaving(state, action) {
      state.meta.isSaving = action.payload
    },
    setIsPhotoUploading(state, action) {
      state.meta.isPhotoUploading = action.payload
    },
    setIsNotFound(state, action) {
      state.meta.isNotFound = action.payload
    },
    setError(state, action) {
      state.meta.error = action.payload
    },
    setSelectableOptionValues(state, action) {
      state.selectableOptionValues = action.payload
    },
    setIsLoadingSelectableOptionValues(state, action) {
      state.meta.isLoadingSelectableOptionValues = action.payload
    },
    setIsLoadingUserPermissions(state, action) {
      state.meta.isLoadingUserPermissions = action.payload
    },
    setWorkAnniversaryUserIds(state, action) {
      state.celebrations.workAnniversaryUserIds = action.payload
    },
    setBirthdayUserIds(state, action) {
      state.celebrations.birthdayUserIds = action.payload
    },
    setNewEmployeeUserIds(state, action) {
      state.celebrations.newEmployeeUserIds = action.payload
    },
    setIsDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },
    isMergingUsers(state, action) {
      state.meta.isMergingUsers = action.payload
    },
    setSelectedTab(state, action) {
      state.meta.selectedTab = action.payload
    },
  },
})

// remove associated entities which can create large payloads for the server to handle
// for example - if the user is in many groups and there are many users in each of those groups,
// it can create large payloads as it serializes the data including all the users into each group
const buildUserPayload = (user) => {
  const keys = [
    'active',
    'approvedForExternalAlerts',
    'bio',
    'birthday',
    'city',
    'country',
    'currentLatitude',
    'currentLocation',
    'currentLongitude',
    'currentTimezone',
    'customField1',
    'customField2',
    'customField3',
    'department',
    'displayPronoun',
    'emails',
    'employeeId',
    'endDate',
    'exempt',
    'extraUserFieldsAttributes',
    'firstName',
    'gender',
    'github',
    'hidden',
    'id',
    'jobLevel',
    'lastName',
    'linkedin',
    'manager',
    'managerEmployeeId',
    'officeLocation',
    'personalEmail',
    'phoneMobile',
    'preboarding',
    'preferredLastName',
    'preferredName',
    'primaryEmail',
    'region',
    'softLaunchAccessGrantedAt',
    'startDate',
    'suppressExternalAlerts',
    'title',
    'twitter',
    'username',
    'websiteLink',
    'workerType',
    'visibleInOrgChart',
  ]

  return {
    ..._.pick(user, keys),
    assistedUserIds: user.assistedUsers?.map(u => u.id) || [],
    groupIds: user.groups?.map(g => g.id) || [],
    managerEmployeeId: user.parent?.employeeId || null,
  }
}

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------
const asyncActions = {
  admin: {
    deleteUser: (user, onSuccess = () => {}, onFailure = () => {}) => async (dispatch) => {
      dispatch(userSlice.actions.setIsDeleting(true))

      try {
        await API.admin.users.destroy(user)
        onSuccess()
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))
        dispatch(userSlice.actions.setError(error))
        appSignal.sendErrorUnlessClearyBackendError(e)
        onFailure()
      } finally {
        dispatch(userSlice.actions.setIsDeleting(false))
      }
    },
    createUser: (user, history) => async (dispatch) => {
      dispatch(userSlice.actions.setIsSaving(true))

      user = buildUserPayload(user)

      try {
        const response = await API.admin.users.create(user)
        dispatch(entitySlice.actions.add({ data: response.data }))
        const userId = response.data.data.id

        history.push(`/admin/users/${userId}`)
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(userSlice.actions.setError(error))
      } finally {
        dispatch(userSlice.actions.setIsSaving(false))
      }
    },
    updateUser: (user, options = {}, onSuccess = () => {}) => async (dispatch) => {
      dispatch(userSlice.actions.setIsSaving(true))
      dispatch(userSlice.actions.setError(null))

      user = buildUserPayload(user)

      try {
        const response = await API.admin.users.update(user)

        dispatch(entitySlice.actions.update({ data: response.data }))
        onSuccess()
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(userSlice.actions.setError(error))

        if (!options.hideErrorToastMessage) {
          dispatch(showToastMessage({ message: error.message, type: 'error' }))
        }
      } finally {
        dispatch(userSlice.actions.setIsSaving(false))
      }
    },
    createPhoto: (image, user, options = {}) => async (dispatch) => {
      dispatch(userSlice.actions.setIsPhotoUploading(true))

      const croppedAreaPixels = options.croppedAreaPixels

      try {
        const response = await API.profile.photos.create(image, user, croppedAreaPixels)

        if (options.reloadUser === false) {
          dispatch(entitySlice.actions.add({
            data: response.data,
            reverseRelationships: [{ entityId: user.id, entityType: 'user', relationshipName: 'photos' }],
          }))
        } else {
          dispatch(asyncActions.admin.fetchUser(user.id))
        }
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(userSlice.actions.setError(error))
      } finally {
        dispatch(userSlice.actions.setIsPhotoUploading(false))
      }
    },
    promotePhoto: (photo, user) => async (dispatch) => {
      await API.profile.photos.promote(photo).then(() => {
        dispatch(userSlice.asyncActions.admin.fetchUser(user.id))
      }).catch((e) => {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(userSlice.actions.setError(error))
      })
    },
    destroyPhoto: (photo, user) => async (dispatch) => {
      try {
        const response = await API.profile.photos.destroy(photo)

        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(entitySlice.actions.remove({ id: photo.id, type: 'photo' }))

        dispatch(asyncActions.admin.fetchUser(user.id))
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(userSlice.actions.setError(error))
      }
    },
    grantSoftLaunchAccess: (user, onSuccess = () => {}) => async (dispatch) => {
      const name = user.preferredFullName
      try {
        const response = await API.admin.users.grantSoftLaunchAccess(user.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('grant_soft_launch_access_success', { name }), type: 'success' }))
        onSuccess()
      } catch (e) {
        dispatch(showToastMessage({ message: I18N('grant_soft_launch_access_error', { name }), type: 'error' }))
      }
    },
    revokeSoftLaunchAccess: (user, onSuccess = () => {}) => async (dispatch) => {
      const name = user.preferredFullName

      try {
        const response = await API.admin.users.revokeSoftLaunchAccess(user.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('revoke_soft_launch_access_success', { name }), type: 'success' }))
        onSuccess()
      } catch (error) {
        if (error.response.data.error.message === 'ClearyError::Admin::InvalidUserChange') {
          dispatch(showToastMessage({ message: I18N('cannot_revoke_self_soft_launch_access'), type: 'error' }))
        } else {
          dispatch(showToastMessage({ message: I18N('revoke_soft_launch_access_error', { name }), type: 'error' }))
        }
      }
    },
    unapproveExternalAlerts: user => async (dispatch) => {
      const name = user.preferredFullName
      try {
        const response = await API.admin.users.unapproveExternalAlerts(user.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(userAccessManagementSlice.actions.removeUserIdApprovedForExternalAlerts(user.id))
        dispatch(showToastMessage({ message: I18N('unapprove_external_alerts_success', { name }), type: 'success' }))
      } catch (e) {
        dispatch(showToastMessage({ message: I18N('unapprove_external_alerts_error', { name }), type: 'error' }))
      }
    },
    approveExternalAlerts: user => async (dispatch) => {
      const name = user.preferredFullName
      try {
        const response = await API.admin.users.approveExternalAlerts(user.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(userAccessManagementSlice.actions.setUserIdsApprovedForExternalAlerts([user.id]))
        dispatch(showToastMessage({ message: I18N('approve_external_alerts_success', { name }), type: 'success' }))
      } catch (e) {
        dispatch(showToastMessage({ message: I18N('approve_external_alerts_error', { name }), type: 'error' }))
      }
    },
    fetchUser: userId => async (dispatch) => {
      dispatch(userSlice.actions.setIsLoading(true))

      try {
        const response = await API.admin.users.fetch(userId)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(userSlice.actions.setIsNotFound(false))
      } catch (e) {
        dispatch(userSlice.actions.setIsNotFound(true))
      } finally {
        dispatch(userSlice.actions.setIsLoading(false))
      }
    },

    fetchUsers: (queryParams, selectedTab) => async (dispatch, getState) => {
      dispatch(userSlice.actions.setIsLoadingUsers(true))
      dispatch(userSlice.actions.setSelectedTab(selectedTab))

      try {
        const response = await API.admin.users.fetchAll(queryParams)
        const newQueryParams = queryParamsFromHeaders(response)
        const userIds = response.data.data.map(user => user.id)

        // if the admin changes tabs quickly when the response is completed they may be looking at another tab so we don't update the state
        const selectedTabInState = getState().users.meta.selectedTab
        if (!selectedTabInState || selectedTabInState === selectedTab) {
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(userSlice.actions.setUserIds(userIds))
          dispatch(userSlice.actions.setUsersQueryParams(newQueryParams))
          dispatch(userSlice.actions.setIsLoadingUsers(false))
        }
      } catch (e) {
        dispatch(userSlice.actions.setUsersNotFound(true))
        dispatch(userSlice.actions.setIsLoadingUsers(false))
      }
    },

    fetchSelectableOptionValues: () => async (dispatch) => {
      dispatch(userSlice.actions.setIsLoadingSelectableOptionValues(true))

      try {
        const response = await API.admin.users.fetchSelectableOptionValues()
        dispatch(userSlice.actions.setSelectableOptionValues(response.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userSlice.actions.setIsLoadingSelectableOptionValues(false))
      }
    },
    fetchUserPermissions: userId => async (dispatch) => {
      dispatch(userSlice.actions.setIsLoadingUserPermissions(true))

      try {
        const response = await API.admin.users.fetchUserPermissions(userId)

        // We only select the permissions data to avoid overriding entities stored in the entity slice
        dispatch(entitySlice.actions.update({ data: _.pick(response.data, 'data.id', 'data.type', 'data.attributes.virtualFields') }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userSlice.actions.setIsLoadingUserPermissions(false))
      }
    },
    addUserPermission: (userId, permission) => async (dispatch) => {
      dispatch(userSlice.actions.setIsLoadingUserPermissions(true))

      try {
        const response = await API.admin.users.addPermission(userId, permission)
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('permissions_update_success'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: I18N('permissions_update_failed'), type: 'error' }))
      } finally {
        dispatch(userSlice.actions.setIsLoadingUserPermissions(false))
      }
    },
    removeUserPermission: (userId, permission) => async (dispatch) => {
      dispatch(userSlice.actions.setIsLoadingUserPermissions(true))

      try {
        const response = await API.admin.users.removePermission(userId, permission)
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('permissions_update_success'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: I18N('permissions_update_failed'), type: 'error' }))
      } finally {
        dispatch(userSlice.actions.setIsLoadingUserPermissions(false))
      }
    },
    mergePreboardingUsers: ({ sourceUserId, destinationUserId }, onSuccess = () => {}) => async (dispatch) => {
      dispatch(userSlice.actions.isMergingUsers(true))

      try {
        const response = await API.admin.users.mergePreboardingUsers({ sourceUserId, destinationUserId })
        dispatch(entitySlice.actions.update({ data: response.data }))
        onSuccess()
      } catch (e) {
        const { error } = checkForError(e.response)
        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      } finally {
        dispatch(userSlice.actions.isMergingUsers(false))
      }
    },
  },
  fetchUser: userId => async (dispatch) => {
    try {
      const response = await API.profile.fetch(userId)

      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      console.error(e)
    }
  },
  fetchUsersByIds: (userIds, onSuccess = () => {}) => async (dispatch) => {
    if (userIds.length === 0) {
      return
    }

    try {
      dispatch(userSlice.actions.hasLoaded(false))

      const response = await API.users.fetchAll(userIds)

      dispatch(entitySlice.actions.add({ data: response.data }))
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(userSlice.actions.hasLoaded(true))
    }
  },
  fetchWorkAnniversaries: (filter = 'this_week', queryParams) => async (dispatch) => {
    dispatch(userSlice.actions.setIsLoadingWorkAnniversaries(true))

    try {
      const response = await API.employees.fetchWorkAnniversaries({ week: filter, ...queryParams })
      const newQueryParams = queryParamsFromHeaders(response)
      const userIds = response.data.data.map(user => user.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(userSlice.actions.setWorkAnniversaryQueryParams(newQueryParams))

      if (filter === 'this_week') dispatch(userSlice.actions.setWorkAnniversariesThisWeekUserIds(userIds))
      if (filter === 'next_week') dispatch(userSlice.actions.setWorkAnniversariesNextWeekUserIds(userIds))
      if (filter === 'last_week') dispatch(userSlice.actions.setWorkAnniversariesLastWeekUserIds(userIds))
    } catch (e) {
      dispatch(userSlice.actions.setWorkAnniversaryError({ message: I18N('work_anniversary_fetch_error') }))
    } finally {
      dispatch(userSlice.actions.setIsLoadingWorkAnniversaries(false))
    }
  },
  fetchBirthdays: (filter = 'this_week', queryParams) => async (dispatch) => {
    dispatch(userSlice.actions.setIsLoadingBirthdays(true))

    try {
      const response = await API.employees.fetchBirthdays({ week: filter, ...queryParams })
      const newQueryParams = queryParamsFromHeaders(response)
      const userIds = response.data.data.map(user => user.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(userSlice.actions.setBirthdayQueryParams(newQueryParams))

      if (filter === 'this_week') dispatch(userSlice.actions.setBirthdaysThisWeekUserIds(userIds))
      if (filter === 'next_week') dispatch(userSlice.actions.setBirthdaysNextWeekUserIds(userIds))
      if (filter === 'last_week') dispatch(userSlice.actions.setBirthdaysLastWeekUserIds(userIds))
    } catch (e) {
      dispatch(userSlice.actions.setBirthdayError({ message: I18N('birthday_fetch_error') }))
    } finally {
      dispatch(userSlice.actions.setIsLoadingBirthdays(false))
    }
  },
  fetchNewEmployees: (filter = 'this_week', queryParams) => async (dispatch) => {
    dispatch(userSlice.actions.setIsLoadingNewEmployees(true))

    try {
      const response = await API.employees.fetchNewEmployees({ week: filter, ...queryParams })
      const newQueryParams = queryParamsFromHeaders(response)
      const userIds = response.data.data.map(user => user.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(userSlice.actions.setNewEmployeeQueryParams(newQueryParams))

      if (filter === 'this_week') dispatch(userSlice.actions.setNewEmployeesThisWeekUserIds(userIds))
      if (filter === 'last_week') dispatch(userSlice.actions.setNewEmployeesLastWeekUserIds(userIds))
    } catch (e) {
      dispatch(userSlice.actions.setNewEmployeeError({ message: I18N('new_employee_fetch_error') }))
    } finally {
      dispatch(userSlice.actions.setIsLoadingNewEmployees(false))
    }
  },
  fetchActiveUsersCount: () => async (dispatch) => {
    dispatch(userSlice.actions.setIsLoadingActiveUsersCount(true))

    try {
      const response = await API.users.fetchActiveUsersCount()

      dispatch(userSlice.actions.setActiveUsersCount(response.data.activeCount))
    } catch (e) {
      dispatch(userSlice.actions.setActiveUsersCountError({ message: I18NCommon('error_fetching_data') }))
    } finally {
      dispatch(userSlice.actions.setIsLoadingActiveUsersCount(false))
    }
  },
  fetchCelebrations: () => async (dispatch) => {
    dispatch(userSlice.actions.setIsLoading(true))

    try {
      const response = await API.users.fetchCelebrations()

      dispatch(entitySlice.actions.add({ data: response.data.users }))
      dispatch(userSlice.actions.setNewEmployeeUserIds(response.data.newEmployeeUserIds))
      dispatch(userSlice.actions.setWorkAnniversaryUserIds(response.data.workAnniversaryUserIds))
      dispatch(userSlice.actions.setBirthdayUserIds(response.data.birthdayUserIds))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(userSlice.actions.setIsLoading(false))
    }
  },
  simpleFetchAll: (userIds, onFinally = () => {}, params = {}, usernames) => async (dispatch) => {
    dispatch(userSlice.actions.setIsLoading(true))

    try {
      if (userIds?.length > 0 || usernames?.length > 0) {
        dispatch(userSlice.actions.setFetchingUserIds(userIds))
        const userResponse = await API.users.simpleFetchAll(userIds, params, usernames)

        dispatch(entitySlice.actions.add({ data: userResponse.data }))
      }
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(userSlice.actions.setIsLoading(false))
      onFinally()
    }
  },

  grantSoftLaunchAccess: user => async (dispatch, getState) => {
    dispatch(userSlice.actions.setIsSaving(true))
    const { currentCompany: { appName } } = getState()

    try {
      const response = await API.users.grantSoftLaunchAccess(user.id)

      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('invited_to_cleary_success', { name: user.preferredName, appName }), type: 'success' }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18N('invited_to_cleary_error', { name: user.preferredName, appName }), type: 'error' }))
    } finally {
      dispatch(userSlice.actions.setIsSaving(false))
    }
  },
}
//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.users.meta,

  getFetchingUserIds: () => state => state.users.fetchingUserIds || [],

  getAdminUserMetaData: () => (state) => {
    const {
      isLoading, isSaving, isNotFound, isPhotoUploading, error, isDeleting,
    } = state.users.meta
    return {
      isLoading, isSaving, isNotFound, isPhotoUploading, error, isDeleting,
    }
  },

  getAdminUserListMetaData: () => (state) => {
    const { isLoadingUsers, usersQueryParams, usersNotFound } = state.users.meta
    const isLoading = isLoadingUsers
    const queryParams = usersQueryParams
    const isNotFound = usersNotFound
    return { isLoading, queryParams, isNotFound }
  },

  getUser: userId => state => build(state.entities, 'user', userId) || {},

  getUsers: () => state => state.users.userIds.map(id => build(state.entities, 'user', id)),

  getSimpleUsersByIds: userIds => state => (userIds?.map(id => build(state.entities, 'simpleUser', id)).filter(e => e !== null)) || [],

  getUsersByIds: userIds => (state) => {
    const users = userIds.map(id => build(state.entities, 'user', id)).filter(i => i)
    return { users }
  },

  getMeta: () => (state) => {
    const { meta } = state.users
    return { meta }
  },

  getWorkAnniversariesMetaData: () => (state) => {
    const { isLoadingWorkAnniversaries, workAnniversaryQueryParams } = state.users.meta
    const isLoading = isLoadingWorkAnniversaries
    const queryParams = workAnniversaryQueryParams
    return { isLoading, queryParams }
  },

  getBirthdaysMetaData: () => (state) => {
    const { isLoadingBirthdays, birthdayQueryParams } = state.users.meta
    const isLoading = isLoadingBirthdays
    const queryParams = birthdayQueryParams
    return { isLoading, queryParams }
  },

  getNewEmployeesMetaData: () => (state) => {
    const { isLoadingNewEmployees, newEmployeeQueryParams } = state.users.meta
    const isLoading = isLoadingNewEmployees
    const queryParams = newEmployeeQueryParams
    return { isLoading, queryParams }
  },

  getPermissionsMetadata: () => state => ({ isLoading: state.users.meta.isLoadingUserPermissions }),

  getNextWeeksWorkAnniversaries: () => state => state.users.workAnniversaries.nextWeekUserIds.map(id => build(state.entities, 'user', id)),

  getLastWeeksWorkAnniversaries: () => state => state.users.workAnniversaries.lastWeekUserIds.map(id => build(state.entities, 'user', id)),

  getThisWeeksWorkAnniversaries: () => state => state.users.workAnniversaries.thisWeekUserIds.map(id => build(state.entities, 'user', id)),

  getNextWeeksBirthdays: () => state => state.users.birthdays.nextWeekUserIds.map(id => build(state.entities, 'user', id)),

  getLastWeeksBirthdays: () => state => state.users.birthdays.lastWeekUserIds.map(id => build(state.entities, 'user', id)),

  getThisWeeksBirthdays: () => state => state.users.birthdays.thisWeekUserIds.map(id => build(state.entities, 'user', id)),

  getLastWeeksNewEmployees: () => state => state.users.newEmployees.lastWeekUserIds.map(id => build(state.entities, 'user', id)),

  getThisWeeksNewEmployees: () => state => state.users.newEmployees.thisWeekUserIds.map(id => build(state.entities, 'user', id)),

  getActiveUsersCount: () => state => state.users.activeUsersCount,

  getSelectableOptionValues: () => state => state.users.selectableOptionValues,

  getCelebrations: () => (state) => {
    const result = []

    const { birthdayUserIds, newEmployeeUserIds, workAnniversaryUserIds } = state.users.celebrations

    const currentYear = new Date().getFullYear()

    const getCelebration = (user, dateProperty, type) => {
      const date = i18nMoment(user[dateProperty])
      const sortByDate = i18nMoment(date)
      sortByDate.set('year', currentYear)

      return {
        sortByDate,
        date,
        user,
        type,
        celebrationPath: user[`${type}CelebrationPath`],
        signedByCurrentUser: user[`${type}CelebrationSignedByCurrentUser`],
      }
    }

    birthdayUserIds.forEach((birthdayUserId) => {
      const user = build(state.entities, 'user', birthdayUserId)
      if (user) result.push(getCelebration(user, 'birthday', 'birthday'))
    })

    workAnniversaryUserIds.forEach((workAnniversaryUserId) => {
      const user = build(state.entities, 'user', workAnniversaryUserId)
      if (user) result.push(getCelebration(user, 'startDate', 'workAnniversary'))
    })

    newEmployeeUserIds.forEach((newEmployeeUserId) => {
      const user = build(state.entities, 'user', newEmployeeUserId)
      if (user) result.push(getCelebration(user, 'startDate', 'newEmployee'))
    })

    result.sort((userA, userB) => userA.sortByDate - userB.sortByDate)

    return result
  },

  getSimpleUserByUsername: username => (state) => {
    const simpleUsers = build(state.entities, 'simpleUser') || []
    return simpleUsers.find(user => user.username === username)
  },
}

export default {
  ...userSlice,
  selectors,
  asyncActions,
}
