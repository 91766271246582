import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import API from 'services/api'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import Mention from '@tiptap/extension-mention'
import { Suggestion } from 'components/common/tiptap/extensions/suggestion'
import baseSuggestion from 'components/common/tiptap/suggestions/baseSuggestion'
import AiMentionList from 'components/common/tiptap/suggestions/ai/aiMentionList'
import AiMentionedRecord from 'components/common/tiptap/extensions/nodeViews/aiMentionedRecord'

const INCLUDED_RECORD_TYPES = [
  'article',
  'page',
  'user',
  'group',
  'page',
  'page/workspace',
  'qna/event',
]

const onInsertAiBlock = ({ editor, range, props }) => {
  const attrs = props.attrs
  const rangeStart = range.from - 1

  editor
    .chain()
    .focus()
    .deleteRange({ from: rangeStart, to: range.to })
    .insertContent({
      type: 'AiPromptRecordMention',
      attrs,
    })
    .insertContent(' ')
    .focus()
    .run()

  return true
}

const aiPromptSuggestion = {
  allowSpaces: true,

  items: async ({ query }) => {
    if (!query) return []
    if (query.length < 2) return []

    const response = await API.globalSearch(query)
    const results = denormalizedJsonApiResponse(response, 'searchResult')

    return results.filter(result => INCLUDED_RECORD_TYPES.includes(result.type))
  },

  ...baseSuggestion(AiMentionList, '@'),
  allow: () => true,
}


const AiPromptRecordMentionExtension = Mention.extend({
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: '@',
        command: onInsertAiBlock,
        ...aiPromptSuggestion,
        allow: () => true, // TODO: check last keypress
        debounce: 300,
      }),
    ]
  },
})

export const AiPromptRecordMentionNode = Node.create({
  name: 'AiPromptRecordMention',
  group: 'inline',
  draggable: true,
  inline: true,
  contentEditable: false,

  addAttributes() {
    return {
      ...this.parent?.(),

      id: {
        default: null,
      },
      name: {
        default: null,
      },
      type: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'span.ai-prompt-record-mention',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { ...HTMLAttributes, class: 'ai-prompt-record-mention' }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AiMentionedRecord, {
      as: 'span',
    })
  },
})


export default AiPromptRecordMentionExtension
