import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import InsertImageDropdownButton from 'components/common/tiptap/toolbar/items/insertImageDropdownButton'
import ImageDisplaySelect from 'components/common/tiptap/menus/images/imageDisplaySelect'
import BubbleMenuDeleteButton from 'components/common/tiptap/menus/buttons/bubbleMenuDeleteButton'
import TextAlignSelect from 'components/common/tiptap/toolbar/items/textAlignSelect'
import AlternativeTextButton from 'components/common/tiptap/menus/images/alternativeTextButton'
import ChangeSizeButton from 'components/common/tiptap/menus/buttons/changeSizeButton'
import ImageStyleButton from 'components/common/tiptap/menus/images/imageStyleButton'
import InsertLinkDropdownButton from 'components/common/tiptap/toolbar/items/insertLinkDropdownButton'
import OpenLinkButton from 'components/common/tiptap/menus/links/openLinkButton'
import RemoveImageLinkButton from 'components/common/tiptap/menus/images/removeImageLinkButton'

const I18N = i18nPath('views.tiptap.toolbar.image_bubble_menu')
const I18NLinks = i18nPath('views.tiptap.toolbar.link_bubble_menu')

const ImageBubbleMenuOptions = ({ editor }) => {
  const nodeName = editor.isActive('inlineImage') ? 'inlineImage' : 'blockImage'
  const hasLink = !!editor.getAttributes(nodeName).href

  return (
    <div className='BubbleMenuOptions p-2'>
      <div className='d-flex align-items-center justify-content-center'>
        <InsertImageDropdownButton
          editor={editor}
          tooltipText={I18N('replace')}
          iconPath='/images/tiptap/arrows-pointing-left-and-right.svg'
        />
        <TextAlignSelect editor={editor} />
        <BubbleMenuDeleteButton editor={editor} tooltipText={I18N('delete_image')} />
        {hasLink ? (
          <>
            <OpenLinkButton editor={editor} nodeName={nodeName} />
            <InsertLinkDropdownButton
              editor={editor}
              tooltipText={I18NLinks('edit_link')}
              iconPath='/images/tiptap/pencil.svg'
            />
            <RemoveImageLinkButton editor={editor} nodeName={nodeName} />
          </>
        ) : (
          <InsertLinkDropdownButton editor={editor} />
        )}
      </div>
      <div className='d-flex align-items-center justify-content-center'>
        <ImageDisplaySelect editor={editor} />
        <AlternativeTextButton editor={editor} />
        <ChangeSizeButton editor={editor} nodeName={nodeName} />
        <ImageStyleButton editor={editor} />
      </div>
    </div>
  )
}

export default ImageBubbleMenuOptions
