import React from 'react'
import classNames from 'classnames'

import { Link } from 'react-router-dom'

interface EmployeeThumbnailPhotoProps {
  employee?: any
  linkToProfile?: boolean
  className?: string
  linkClassName?: string
  size?: string
  photoUrl?: string
}

const EmployeeThumbnailPhoto = ({
  employee,
  linkToProfile,
  className,
  linkClassName,
  size = '4.28rem',
  photoUrl = 'primaryPhotoThumbnailUrl',
}: EmployeeThumbnailPhotoProps) => {
  const showIncludeLinkToProfile = !_.isEmpty(employee) && linkToProfile
  const imageSize = { width: `${size}`, height: `${size}` }
  const imageSource = employee ? employee[photoUrl] : '/images/profile_small-missing.png'

  const image = (
    <img
      className={classNames('EmployeeThumbnailPhoto bordered-thumbnail', className)}
      src={imageSource}
      style={{ ...imageSize }}
      loading='lazy'
    />
  )

  return (
    <>
      {showIncludeLinkToProfile && (
        <Link className={linkClassName} to={`/profile/${employee.username}`}>
          {image}
        </Link>
      )}
      {!showIncludeLinkToProfile && image}
    </>
  )
}

export default EmployeeThumbnailPhoto
