// eslint-disable-next-line import/no-cycle
import API from 'services/api'

type NotifySlackErrorProps = {
  exception: any
  action: string
  modelType: string
  modelId?: string
}

// eslint-disable-next-line import/prefer-default-export
export const notifySlackError = ({
  exception, action, modelType, modelId,
}: NotifySlackErrorProps) => {
  let errorMessage = exception.message
  let requestId, apiUrl
  const href = location.href

  if (exception.isAxiosError) {
    errorMessage = exception.stack
    requestId = exception.response.headers['x-request-id']
    apiUrl = exception.request.responseURL
  }

  return API.slackErrors.notifyError({
    slack: {
      errorMessage,
      modelType,
      modelId,
      action,
      requestId,
      apiUrl,
      href,
    },
  })
}
