import React from 'react'

const PartyHatIcon = ({ className }) => (
  <svg
    data-testid='cy_party_hat_icon'
    className={className}
    width='100px'
    height='100px'
    viewBox='0 0 100 100'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-528.000000, -520.000000)'>
        <g transform='translate(164.000000, 456.000000)'>
          <g transform='translate(222.000000, 64.000000)'>
            <g transform='translate(142.000000, 0.000000)'>
              <circle fill='#FFFFFF' cx='50' cy='50' r='50' />
              <g
                transform='translate(48.000000, 53.000000) rotate(48.000000) translate(-48.000000, -53.000000) translate(17.000000, 20.000000)'
                fillRule='nonzero'
              >
                <polygon fill='#53A6F9' points='31.2000012 65.7000026 17.4000007 29.5998012 45.0000018 29.5998012' />
                <polygon fill='#2486FC' points='36.8814015 33.5544013 31.2000012 65.7000026 45.0000018 29.5998012' />
                <ellipse fill='#1461DD' cx='31.2000012' cy='29.5998012' rx='13.8000005' ry='4.33920017' />
                <circle fill='#EEF4FF' cx='34.5984014' cy='50.050202' r='2.4000001' />
                <path
                  d='M23.8002009,38.050199 C22.7862961,38.0496552 21.798565,38.3720338 20.9802008,38.9706015 L24.288601,47.6256019 C26.8329704,47.3670223 28.7277634,45.1589213 28.5970081,42.6047909 C28.4662528,40.0506605 26.3558748,38.047599 23.7984009,38.050199 L23.8002009,38.050199 Z'
                  fill='#EEF4FF'
                />
                <polygon
                  fill='#53A6F9'
                  points='31.2000012 0.300000012 32.7108013 3.36060013 36.0882014 3.85140015 33.6444013 6.23400025 34.2210014 9.59820038 31.2000012 8.01000032 28.1790011 9.59820038 28.7556011 6.23400025 26.311801 3.85140015 29.6892012 3.36060013'
                />
                <path
                  d='M31.2000012,30.6498012 C30.6201022,30.6498012 30.1500012,30.1797002 30.1500012,29.5998012 L30.1500012,12.8904005 C30.1500012,12.3105015 30.6201022,11.8404005 31.2000012,11.8404005 C31.7799003,11.8404005 32.2500013,12.3105015 32.2500013,12.8904005 L32.2500013,29.5998012 C32.2500013,30.1797002 31.7799003,30.6498012 31.2000012,30.6498012 L31.2000012,30.6498012 Z'
                  fill='#53A6F9'
                />
                <polygon
                  fill='#53A6F9'
                  points='1.41000006 2.4480001 4.64220018 3.54420014 7.37820029 1.50300006 7.33440029 4.9158002 10.1214004 6.88680027 6.86160027 7.89960031 5.84820023 11.1594004 3.87780015 8.37240033 0.464400018 8.41620033 2.5062001 5.68080023'
                />
                <path
                  d='M24.798601,30.6492012 C24.4852207,30.6492012 24.1882353,30.5091539 23.9887122,30.2674977 C23.7891891,30.0258414 23.7079611,29.7077313 23.7672009,29.4000012 C23.7774009,29.3484012 24.748801,24.235201 22.7118009,21.4572009 L22.6758009,21.4086009 C22.4287191,22.4122509 21.8461419,23.3013585 21.0246008,23.928601 C19.3446008,25.240801 17.4846007,25.381201 16.0494006,24.307201 C14.6917304,23.2813381 14.153986,21.4948425 14.7198006,19.8900008 C15.0402006,19.0824008 16.1796006,17.2464007 19.8150008,17.6574007 C19.9152008,17.6688007 20.0142008,17.6820007 20.1120008,17.6958007 C18.5442007,14.2884006 14.3958006,10.8792004 10.7778004,11.1474004 C10.1995583,11.1918041 9.69480411,10.7590425 9.65040041,10.1808004 C9.60599672,9.60255826 10.0387583,9.09780407 10.6170004,9.05340036 C15.4410006,8.68680035 20.6286008,13.1688005 22.2762009,17.4246007 C22.4227243,17.8006224 22.5430125,18.1863465 22.6362009,18.5790007 C23.3305827,19.0026961 23.9335326,19.5605675 24.409801,20.2200008 C26.9898011,23.7468009 25.876201,29.5602012 25.827601,29.8062012 C25.7292517,30.2964715 25.2986385,30.6492012 24.798601,30.6492012 L24.798601,30.6492012 Z M18.8196007,19.6986006 C17.6874007,19.6986006 16.9206007,20.0364008 16.6716007,20.6634008 C16.4508805,21.3861924 16.7051628,22.1698899 17.3082007,22.6254009 C17.9562007,23.1102009 18.8172007,22.9854009 19.7328008,22.2714009 C20.4275596,21.7277011 20.8015667,20.8705073 20.7276008,19.9914008 C20.3551108,19.8662245 19.9700037,19.7823218 19.5792008,19.7412008 C19.3270009,19.712757 19.0733996,19.6985345 18.8196007,19.6986006 Z'
                  fill='#53A6F9'
                />
                <polygon
                  fill='#B5DAFF'
                  points='60.9900024 2.4480001 57.7578023 3.54420014 55.0218022 1.50300006 55.0656022 4.9158002 52.2786021 6.88680027 55.5384022 7.89960031 56.5512022 11.1594004 58.5222023 8.37240033 61.9356025 8.41620033 59.8938024 5.68080023'
                />
                <path
                  d='M37.6008015,30.6492012 C37.1002495,30.6487335 36.669707,30.2948044 36.5724015,29.8038012 C36.5238015,29.5584012 35.4108014,23.7438009 37.9902015,20.2176008 C38.4664762,19.5588765 39.0692057,19.0016322 39.7632016,18.5784007 C39.8563863,18.1857455 39.9766745,17.8000211 40.1232016,17.4240007 C41.7708017,13.1682005 46.9632019,8.70000035 51.7800021,9.05280036 C52.3581044,9.09747972 52.790712,9.60205436 52.7466021,10.1802004 C52.6962876,10.7554253 52.1953648,11.184902 51.6192021,11.1468004 C48.0066019,10.8768004 43.8534017,14.2878006 42.2850017,17.6952007 C42.3828017,17.6814007 42.4818017,17.6676007 42.5820017,17.6568007 C46.2192018,17.2452007 47.3562019,19.0818008 47.6772019,19.8894008 C48.2428156,21.4942472 47.7051072,23.2806233 46.3476018,24.306601 C44.9142018,25.381801 43.0542017,25.239601 41.3724016,23.928001 C40.5509321,23.300692 39.968373,22.4116118 39.7212016,21.4080009 L39.6852016,21.4566009 C37.6512015,24.232201 38.6226015,29.3454012 38.6328015,29.4000012 C38.6930584,29.7081826 38.6123026,30.0271609 38.4126593,30.2695434 C38.2130161,30.5119259 37.9154185,30.6522013 37.6014015,30.6522013 L37.6008015,30.6492012 Z M41.6712017,19.9938008 C41.5972358,20.8729073 41.9712429,21.7301011 42.6660017,22.2738009 C43.5822017,22.9878009 44.4438018,23.1138009 45.0906018,22.6278009 C45.6943622,22.1722624 45.9489503,21.3878817 45.7278018,20.6646008 C45.4278018,19.9056008 44.3664018,19.5696008 42.8196017,19.7454008 C42.4290795,19.7860428 42.0441842,19.8693396 41.6718017,19.9938008 L41.6712017,19.9938008 Z'
                  fill='#53A6F9'
                />
                <path
                  d='M34.5984014,52.4502021 C35.9238848,52.4502021 36.9984015,51.3756854 36.9984015,50.050202 C36.9984015,48.7247185 35.9238848,47.6502019 34.5984014,47.6502019 C34.5258014,47.6502019 34.4574014,47.6652019 34.3860014,47.6718019 L33.5826013,52.2174021 C33.8998279,52.3691416 34.246755,52.44865 34.5984014,52.4502021 Z'
                  fill='#D1DBED'
                />
                <polygon
                  fill='#B5DAFF'
                  points='32.0940013 6.03420024 34.5384014 3.65160015 31.1604012 3.16080013 30.4746012 1.77000007 29.6892012 3.36060013 26.311801 3.85140015 28.7556011 6.23400025 28.3698011 8.48280034 28.1790011 9.59820038 30.6150012 8.31720033 31.2000012 8.01000032 32.5554013 8.72280035'
                />
                <polygon
                  fill='#B5DAFF'
                  points='6.08460024 4.04100016 6.10440024 2.4528001 4.64220018 3.54420014 1.41000006 2.4480001 2.5062001 5.68080023 1.13580005 7.5168003 0.464400018 8.41620033 3.25200013 8.38080033 3.87780015 8.37240033 4.79100019 9.66420038 5.61180022 7.02480028 8.87100035 6.01200024'
                />
                <polygon
                  fill='#53A6F9'
                  points='56.3154022 4.04100016 56.2956022 2.4528001 57.7578023 3.54420014 60.9900024 2.4480001 59.8938024 5.68080023 61.2642024 7.5168003 61.9356025 8.41620033 59.1480024 8.38080033 58.5222023 8.37240033 57.6090023 9.66420038 56.7882023 7.02480028 53.5290021 6.01200024'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default PartyHatIcon
