import React from 'react'
import { NodeViewWrapper } from '@tiptap/react'

const AiMentionedRecord = (props: any) => (
  <NodeViewWrapper
    className='AiMentionedRecord w-100 px-1 mx-1 border-radius bordered'
    as='span'
  >
    <span>
      {props.node.attrs.name}
      <span
        className='x-button pointer text-smallest ml-2'
        onClick={() => props.deleteNode()}
      >
        x
      </span>
    </span>
  </NodeViewWrapper>
)

export default AiMentionedRecord
