import React from 'react'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const TableHeaderItem = ({ editor, onClose }) => (
  <BaseContextMenuItem
    iconPath='/images/tiptap/table-header.svg'
    label={I18N('table_header')}
    onClick={() => {
      onClose()
      editor.chain().focus().toggleHeaderRow().run()
    }}
  />
)

export default TableHeaderItem
