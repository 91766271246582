import React from 'react'
import GreetingCardBannerImage from 'components/celebrations/greetingCardBannerImage'
import GreetingCardHeadlineDisplay from 'components/celebrations/greetingCardHeadlineDisplay'
import { Link } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.greeting_card')

const GreetingCardBanner = ({
  showBanner, celebration, showCustomizeButton = false,
}) => (
  <GreetingCardBannerImage
    celebration={celebration}
    showBanner={showBanner}
    headlineDisplay={<GreetingCardHeadlineDisplay celebration={celebration} />}
  >
    {showCustomizeButton && (
      <div className='GreetingCardBannerImage__customize-button'>
        <Link to={`/people/celebrations/${celebration.id}/edit`} className='mt-3 btn btn-secondary'>
          {I18N('customize_card')}
        </Link>
      </div>
    )}
  </GreetingCardBannerImage>
)

export default GreetingCardBanner
