import React, { useEffect, useRef } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSecondarySmall, ButtonSmallNarrow } from 'components/common/buttons'
import useMediaRecorder from 'components/common/hooks/useMediaRecorder'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile.video_introduction_modal')
const I18NCommon = i18nPath('views.common')
const camcorderPlusCircleIconPath = '/images/camcorderPlusCircleIcon.svg'

const VideoPreviewWhenRecording = ({ isRecording, stream }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream
    }
  }, [stream])

  if (!isRecording || !stream) {
    return null
  }

  return <video ref={videoRef} autoPlay />
}

const getSupportedCodec = () => {
  const codecForChrome = 'video/webm;codecs=vp9,opus'
  const codecForFirefox = 'video/webm;codecs:vp9,opus'

  const usesFirefox = MediaRecorder
    && !MediaRecorder.isTypeSupported(codecForChrome)
    && MediaRecorder.isTypeSupported(codecForFirefox)

  if (usesFirefox) {
    return codecForFirefox
  }

  return codecForChrome
}

const VideoRecorder = ({
  setVideoFile,
  recordingUrl,
  willDeleteRecording,
  setWillDeleteRecording,
  setIsSaveButtonDisabled = () => {},
}) => {
  const codec = getSupportedCodec()
  const {
    startRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    previewStream,
  } = useMediaRecorder({
    video: true,
    screen: false,
    mediaRecorderOptions: { mimeType: codec },
    onStop: (_blobUrl, blob) => {
      setIsSaveButtonDisabled(false)
      const videoFile = new File([blob], `recording-${new Date().getTime()}.webm`, { type: 'video/webm' })
      setVideoFile(videoFile)
      setWillDeleteRecording(false)
    },
  })

  const isRecording = status === 'recording'
  const currentRecordingUrl = mediaBlobUrl || recordingUrl
  const isRecordingAvailable = !isRecording && currentRecordingUrl && !willDeleteRecording

  // this weird syntax is just the cleanup/return value of the useEffect hook.
  useEffect(() => () => {
    if (isRecording) {
      stopRecording()
    }
  })

  const onClickRecord = () => {
    setIsSaveButtonDisabled(true)
    startRecording()
  }

  return (
    <div className='VideoRecorder mb-2'>
      <VideoPreviewWhenRecording isRecording={isRecording} stream={previewStream} />

      {!isRecording && !isRecordingAvailable && (
        <div className='video-placeholder' onClick={onClickRecord}>
          <CdnSvg src={camcorderPlusCircleIconPath} />
        </div>
      )}

      {isRecordingAvailable && (
        <video controls src={currentRecordingUrl} />
      )}

      <div className='recording-controls'>
        {isRecording ? (
          <ButtonSecondarySmall onClick={stopRecording}>{I18N('stop_recording')}</ButtonSecondarySmall>
        ) : (
          <div className='d-flex mt-2'>
            <ButtonSecondarySmall className='mr-2' onClick={onClickRecord}>
              {I18N(isRecordingAvailable ? 're_record' : 'record_now')}
            </ButtonSecondarySmall>
            {isRecordingAvailable && (
              <ButtonSmallNarrow variant='secondary-danger' onClick={() => setWillDeleteRecording(true)}>
                {I18NCommon('delete')}
              </ButtonSmallNarrow>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoRecorder
