import API from 'services/api'
import { toast } from 'react-toastify'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap')

const saveImageFromFile = (
  file: File,
  richTextId: null | undefined | string,
  callback: (imageUrl: string, imageId: string) => void,
  errorCallback: () => void = () => {}
) => {
  const reader = new FileReader()
  reader.onload = async () => {
    const imageData = reader.result

    try {
      const response = await API.temporaryImageUpload.create(imageData, richTextId)
      const { imageUrl, imageId } = response.data

      callback(imageUrl, imageId)
    } catch (e) {
      toast.error(I18N('error_uploading_image'))
      errorCallback()
    }
  }
  reader.readAsDataURL(file)
}

export default saveImageFromFile
