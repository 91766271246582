import React, { useEffect, useRef, useState } from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import classNames from 'classnames'
import useWindowResize from 'components/common/hooks/useWindowResize'
import useClickOutside from 'components/common/hooks/useClickOutside'

interface Props {
  tooltipText: string
  children: any
  dropdownContent: any
  isDropdownOpen: boolean
  setIsDropdownOpen: (isDropdownOpen: boolean) => void
  className?: string
  isDisabled?: boolean
  dynamicDirection?: boolean
}

const DROPDOWN_BUFFER_WIDTH = 20

const ToolbarDropdownButton = ({
  tooltipText,
  children,
  dropdownContent,
  isDropdownOpen,
  setIsDropdownOpen,
  className = '',
  isDisabled = false,
  dynamicDirection = true,
}: Props) => {
  const [left, setLeft] = useState(0)
  const [direction, setDirection] = useState('bottom')
  const buttonRef = useRef<any>(null)
  const dropdownContentRef = useRef<any>(null)
  const { width, height } = useWindowResize()

  useEffect(() => {
    if (isDropdownOpen && buttonRef.current && dropdownContentRef.current) {
      const { x, y, height: buttonHeight } = buttonRef.current.getBoundingClientRect()
      const { width: dropdownWidth, height: dropdownHeight } = dropdownContentRef.current.getBoundingClientRect()

      if (x + dropdownWidth + DROPDOWN_BUFFER_WIDTH > width) {
        setLeft(width - dropdownWidth - x - DROPDOWN_BUFFER_WIDTH)
      }

      if (!dynamicDirection) {
        return
      }

      if (y + buttonHeight + dropdownHeight > height) {
        setDirection('top')
      } else {
        setDirection('bottom')
      }
    }
  }, [isDropdownOpen])

  useClickOutside({
    ref: buttonRef,
    onClickOutside: () => setIsDropdownOpen(false),
    enableClickOutside: isDropdownOpen,
    eventType: 'mousedown',
  })

  return (
    <div
      className={classNames(className, 'TiptapToolbarDropdownButton position-relative')}
      ref={buttonRef}
    >
      <ToolbarButton
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        tooltipText={tooltipText}
        isDisabled={isDisabled}
      >
        {children}
      </ToolbarButton>
      {isDropdownOpen && (
        <div
          className={classNames('DropdownMenu-content p-2', `direction-${direction}`)}
          style={{ left }}
          ref={dropdownContentRef}
        >
          {dropdownContent}
        </div>
      )}
    </div>
  )
}

export default ToolbarDropdownButton
