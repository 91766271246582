import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { INVITE_OTHERS_MODAL } from 'components/celebrations/greeting_card_actions/inviteOthersButton'
import React from 'react'

export const SOCIAL_SHARE_MODAL = 'socialShareModal'

interface Value {
  currentOpenModal: any
  justSignedCard: boolean
  setCurrentOpenModal: (currentOpenModal: any) => void
  closeModal: () => void
  onCardSigned: () => void
  onThanksSent: (isResend?: boolean) => void
}

export const useCelebrationModalValue = () => {
  const { openModal } = useQueryParams()
  const [currentOpenModal, setCurrentOpenModal] = React.useState<string | null>(openModal)
  const [justSignedCard, setJustSignedCard] = React.useState(false)
  const { settings: { celebrations: { socialShare } } } = useCurrentCompany()

  const onCardSigned = () => {
    setJustSignedCard(true)
    setCurrentOpenModal(INVITE_OTHERS_MODAL)
  }

  const closeModal = () => {
    setJustSignedCard(false)
    setCurrentOpenModal(null)
  }

  const onThanksSent = (isResend) => {
    setCurrentOpenModal(isResend || !socialShare ? null : SOCIAL_SHARE_MODAL)
  }

  return {
    currentOpenModal,
    setCurrentOpenModal,
    onCardSigned,
    justSignedCard,
    closeModal,
    onThanksSent,
  }
}

const CelebrationModalContext = React.createContext<Value>({
  currentOpenModal: null,
  setCurrentOpenModal: () => {},
  onCardSigned: () => {},
  closeModal: () => {},
  onThanksSent: () => {},
  justSignedCard: false,
})

export default CelebrationModalContext
