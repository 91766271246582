import React from 'react'
import classNames from 'classnames'
import Tooltip from 'components/common/clearyTooltip'

interface Props {
  onClick: (any) => void
  isActive?: boolean
  isDisabled?: boolean
  tooltipText: string
  hideTooltip?: boolean
  children: any
  className?: string
}

const ToolbarButton = ({
  onClick,
  isActive = false,
  isDisabled = false,
  tooltipText,
  hideTooltip,
  children,
  className = '',
}: Props) => (
  <Tooltip
    placement='bottom'
    className={classNames(className, 'TiptapToolbarButton', { isActive })}
    show={0}
    hide={0}
    hideTooltip={hideTooltip || isDisabled}
    content={(
      <button
        onClick={onClick}
        disabled={isDisabled}
      >
        {children}
      </button>
    )}
  >
    <span className='text-smallest'>{tooltipText}</span>
  </Tooltip>
)

export default ToolbarButton
