import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'

const I18N = i18nPath('views.tiptap.toolbar')

const MoreDropdownButton = ({
  onClick,
}) => (
  <div className='TiptapMoreDropdownButton'>
    <ToolbarButton
      tooltipText={I18N('more_tooltip')}
      onClick={onClick}
    >
      <CdnSvg src='/images/threeDotsIcon.svg' className='ThreeDotsIcon' />
    </ToolbarButton>
  </div>
)

export default MoreDropdownButton
