import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import DocIcon from 'components/icons/docIcon'
import cleanUrl from 'utils/cleanUrl'
import { i18nPath } from 'utils/i18nHelpers'
import messageMobileApp from 'utils/messageMobileApp'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.tiptap')

const isAttachment = src => src.includes(`${window.location.host}/api/attachments`)

export function replaceEmbeddedFilesWithLinks(htmlString) {
  // Create a temporary DOM element to manipulate the HTML string
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlString

  const iframes = tempDiv.getElementsByTagName('iframe')

  Array.from(iframes).forEach((iframe) => {
    if (isAttachment(iframe.src) || iframe.src.includes('.pdf')) {
      const replacementSpan = document.createElement('span')
      replacementSpan.setAttribute('data-iframe-replaced', 'true')
      replacementSpan.setAttribute('src', iframe.src)

      // eslint-disable-next-line no-unused-expressions
      iframe.parentNode?.replaceChild(replacementSpan, iframe)
    } else {
      const additionalLink = document.createElement('a')
      additionalLink.setAttribute('href', iframe.src)
      additionalLink.setAttribute('target', '_blank')
      additionalLink.setAttribute('rel', 'noopener noreferrer')
      additionalLink.setAttribute('class', 'mb-2')
      const host = cleanUrl(new URL(iframe.src).host)
      additionalLink.innerHTML = I18N('open_in_app_or_browser', { host })

      // eslint-disable-next-line no-unused-expressions
      iframe.parentNode?.appendChild(additionalLink)
    }
  })

  return tempDiv.innerHTML
}

const FileLink = ({ src, fileName }) => {
  const onClick = () => {
    messageMobileApp({
      action: 'openFile',
      src,
      fileName,
    })
  }

  return (
    <a onClick={onClick} target='_blank' rel='noopener noreferrer'>
      <DocIcon className='mr-1' height='1rem' width='1rem' />
      {fileName}
    </a>
  )
}

const AttachedFileLink = ({ src }) => {
  const { data: attachment } = useFetch(() => API.attachments.show(src.split('/').pop(), { format: 'json' }))

  if (!attachment) return null

  return <FileLink src={attachment?.url} fileName={attachment?.filename} />
}

const LinkComponent = ({ src }) => {
  if (isAttachment(src)) {
    return <AttachedFileLink src={src} />
  }

  return <FileLink src={src} fileName={src.split('/').pop()} />
}

const useMobileLinkPortals = (containerId) => {
  const { inMobileApp } = useCurrentUser()
  const [linkPortals, setLinkPortals] = useState<React.ReactPortal[]>([])
  const nodes = document.querySelectorAll(`#${containerId} [data-iframe-replaced='true']`)

  useEffect(() => {
    if (!inMobileApp) return undefined

    const newPortals: React.ReactPortal[] = []
    const mentionNodes = document.querySelectorAll(`#${containerId} [data-iframe-replaced='true']`)

    mentionNodes.forEach((el: HTMLIFrameElement) => {
      const portal = ReactDOM.createPortal(
        <LinkComponent src={el.getAttribute('src')} />,
        el.parentElement || el
      )

      newPortals.push(portal)
    })

    setLinkPortals(newPortals)

    return undefined
  }, [containerId, nodes.length])

  return linkPortals
}

export default useMobileLinkPortals
