import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import LoadingSpinnerText from 'components/common/loadingSpinnerText'

import classNames from 'classnames'
import { ConfirmProps, extractConfirmationProps, useConfirmationModal } from 'components/common/buttons/button'
import ConfirmationModal from 'components/common/modals/confirmationModal'

interface ButtonSmallProps {
  id?: any
  className?: string
  style?: Record<string, any>
  disabled?: boolean
  type?: string
  variant?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick?: (e: any) => void
  children?: any
  showLoadingSpinner?: boolean
  confirm?: boolean | ConfirmProps
}

const ButtonSmall = React.forwardRef<any, ButtonSmallProps>(({
  id,
  className,
  style,
  disabled,
  type,
  onMouseEnter,
  onMouseLeave,
  onClick,
  children,
  variant = 'primary',
  showLoadingSpinner = false,
  confirm = false,
}: ButtonSmallProps, ref) => {
  const {
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    handleClick,
  } = useConfirmationModal(confirm, onClick)

  return (
    <>
      <BootstrapButton
        className={className}
        size='sm'
        style={{ height: '2.143rem', ...style }}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
        variant={variant}
        type={type}
        id={id}
        ref={ref}
      >
        <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>
          {children}
        </LoadingSpinnerText>
      </BootstrapButton>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          visible
          toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
          onConfirmClick={(e) => {
            onClick?.(e)
            setIsConfirmationModalOpen(false)
          }}
          {...extractConfirmationProps(confirm)}
        />
      )}
    </>
  )
})

interface ButtonSmallNarrowProps {
  className?: string
  id?: string
  style?: Record<string, any>
  disabled?: boolean
  onClick?: () => void
  variant?: string
  children?: any
  showLoadingSpinner?: boolean
  type?: string
  confirm?: boolean | ConfirmProps
}

const ButtonSmallNarrow = React.forwardRef<any, ButtonSmallNarrowProps>(({
  className,
  id,
  style = {},
  disabled,
  onClick = () => {},
  variant = 'primary',
  children,
  showLoadingSpinner = false,
  type,
  confirm = false,
}: ButtonSmallNarrowProps, ref) => (
  <ButtonSmall
    id={id}
    style={{ minWidth: '100px', ...style }}
    className={className}
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    type={type}
    ref={ref}
    confirm={confirm}
  >
    <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>
      {children}
    </LoadingSpinnerText>
  </ButtonSmall>
))


interface ButtonSecondarySmallProps {
  className?: string
  id?: string
  disabled?: boolean
  type?: string
  style?: Record<string, any>
  value?: any
  onClick?: () => void
  children?: any
  showLoadingSpinner?: boolean
}

const ButtonSecondarySmall = ({
  className,
  id,
  disabled,
  type,
  style,
  value,
  onClick,
  children,
  showLoadingSpinner = false,
}: ButtonSecondarySmallProps) => (
  <BootstrapButton
    id={id}
    className={classNames('btn-secondary-sm', className)}
    onClick={onClick}
    style={style}
    disabled={disabled}
    value={value}
    variant='secondary'
    type={type} // Submit
    size='sm'
  >
    <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>
      {children}
    </LoadingSpinnerText>
  </BootstrapButton>
)

export { ButtonSmall, ButtonSmallNarrow, ButtonSecondarySmall }
