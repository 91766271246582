import { I18NCommon } from 'utils/i18nHelpers'

const commentsCount = (commentsCount, commentsDisabled = false) => {
  if (!commentsCount) {
    return commentsDisabled ? I18NCommon('no_comments') : I18NCommon('add_comment')
  }

  if (commentsCount === 1) return I18NCommon('comments_count_singular')

  return I18NCommon('comments_count', { commentsCount })
}

export default commentsCount
