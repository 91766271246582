import React from 'react'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import BetaTag from 'components/common/tags/betaTag'
import HoverTopMenu from 'components/common/hoverTopMenu'

const I18N = i18nPath('views.tiptap.ai')

interface AiNodeSideMenuProps {
  deleteNode: () => void
  onEditPromptClick?: () => void
  onRegenerateClick?: () => void
}

const AiNodeSideMenu = ({
  deleteNode,
  onEditPromptClick = undefined,
  onRegenerateClick = undefined,
} : AiNodeSideMenuProps) => (
  <HoverTopMenu className='AiNodeSideMenu'>
    <BetaTag className='mr-2' />
    <span className='text-white'>{I18N('ai')}</span>
    <span className='text-white mx-2'>|</span>
    {onEditPromptClick && (
      <IconButtonWithPopover
        id='edit_prompt'
        className='mr-0'
        text={I18N('edit_prompt')}
        icon={<CdnSvg src='/images/pencilIcon.svg' />}
        onClick={onEditPromptClick}
        popoverPlacement='top'
      />
    )}
    {onRegenerateClick && (
      <IconButtonWithPopover
        id='regenerate'
        text={I18N('regenerate')}
        icon={<CdnSvg src='/images/syncIcon.svg' />}
        onClick={onRegenerateClick}
        className='mx-2'
        popoverPlacement='top'
      />
    )}
    <IconButtonWithPopover
      id='delete'
      text={I18NCommon('delete')}
      icon={<CdnSvg src='/images/trashIcon.svg' />}
      onClick={() => deleteNode()}
      className='mr-0'
      popoverPlacement='top'
    />
  </HoverTopMenu>
)

export default AiNodeSideMenu
