import snakeCaseKeys from 'utils/snakeCaseKeys'
import replaceCircularReferences from './replaceCircularReferences'

// Only snake case keys if the object is a Object. Don't snakeCase  objects that are created
// using a different constructor e.g. a FormData object. SnakeCasing FormData or potentially
// other non basic objects can have unexpected behaviors (ie not work).
// Replace circular references with a string to avoid infinite loop when transforming to JSON
const snakeCaseKeysObjectsOnly = (data) => {
  if (data && data.constructor === Object) {
    return snakeCaseKeys(replaceCircularReferences(data))
  }

  return data
}

export default snakeCaseKeysObjectsOnly
