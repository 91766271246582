import { useEffect, useRef } from 'react'

/**
 * This replaces componentDidUpdate prevProps, prevState logic.
 * See https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
function usePrevious(value) {
  // ref.current property is mutable,
  // it can hold any value, similar to instance properties
  const ref = useRef()

  // most recent value stored in ref
  useEffect(() => {
    ref.current = value
  }, [value])

  // return previous value
  return ref.current
}

export default usePrevious
