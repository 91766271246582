import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import AiTextGenerationComponent from 'components/common/tiptap/extensions/nodeViews/ai/aiTextGenerationComponent'
import AiPromptComponent from 'components/common/tiptap/extensions/nodeViews/ai/aiPromptComponent'
import AiBlockComponent from 'components/common/tiptap/extensions/nodeViews/ai/aiBlockComponent'

export const AiPrompt = Node.create({
  name: 'aiPrompt',
  group: 'block',
  draggable: false,
  contentEditable: false,

  addAttributes() {
    return {
      ...this.parent?.(),

      prompt: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'div.ai-prompt',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { class: 'ai-prompt', ...HTMLAttributes }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AiPromptComponent)
  },
})


export const AiTextGeneration = Node.create({
  name: 'aiTextGeneration',
  group: 'block',
  draggable: false,
  contentEditable: false,

  addAttributes() {
    return {
      ...this.parent?.(),

      'data-text-generation-id': {
        default: null,
      },
      'prompt': {
        default: '',
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'div.ai-text-generation',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { class: 'ai-text-generation', ...HTMLAttributes }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AiTextGenerationComponent)
  },
})

export const AiBlock = Node.create({
  name: 'aiBlock',
  group: 'block',
  draggable: false,
  contentEditable: true,
  content: 'block+',
  selectable: true,
  isolating: true,

  addAttributes() {
    return {
      ...this.parent?.(),

      prompt: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'div.ai-block',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { class: 'ai-block', ...HTMLAttributes }, 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AiBlockComponent)
  },
})
