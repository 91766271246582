import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

const I18N = i18nPath('views.tiptap.toolbar.image_bubble_menu')

const EmbedDisplaySelect = ({ editor, isDisabled = false }) => {
  const isBlockEmbed = editor.isActive('blockEmbed')
  const selectedNode = isBlockEmbed ? 'blockEmbed' : 'inlineEmbed'
  const currentAttributes = editor.getAttributes(selectedNode)

  return (
    <ToolbarSelect
      className={classNames('EmbedDisplaySelect', { disabled: isDisabled })}
      options={[{
        value: 'inline',
        label: I18N('inline'),
        onClick: () => {
          if (!isBlockEmbed) return

          editor.chain().focus().setInlineEmbed({
            ...currentAttributes,
            float: null,
            display: 'inline',
          }).run()
        },
        isActive: !isBlockEmbed,
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }, {
        value: 'block',
        label: I18N('break_text'),
        onClick: () => {
          editor.chain().focus().setBlockEmbed({
            ...currentAttributes,
            float: null,
            display: 'block',
          }).run()
        },
        isActive: isBlockEmbed && !currentAttributes.float,
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }, {
        value: 'wrap_left',
        label: I18N('wrap_text_left'),
        onClick: () => {
          editor.chain().focus().setBlockEmbed({
            ...currentAttributes,
            float: 'left',
            display: 'block',
          }).run()
        },
        isActive: isBlockEmbed && currentAttributes.float === 'left',
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }, {
        value: 'wrap_right',
        label: I18N('wrap_text_right'),
        onClick: () => {
          editor.chain().focus().setBlockEmbed({
            ...currentAttributes,
            float: 'right',
            display: 'block',
          }).run()
        },
        isActive: isBlockEmbed && currentAttributes.float === 'right',
        singleValueContent: <CdnSvg src='/images/tiptap/image-display.svg' />,
      }]}
      tooltipText={I18N('display')}
      isDisabled={isDisabled}
    />
  )
}

export default EmbedDisplaySelect
