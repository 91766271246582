import React from 'react'
import Dropzone from 'react-dropzone'
import { showToastMessage } from 'redux/slices/toasts'
import { useDispatch } from 'react-redux'

interface FileSelectDropzoneProps {
  onDrop: (files: any) => void
  maxSizeInMB?: number
  disabled?: boolean
  className?: string
  style?: any
  children?: any
  accept?: string[]
}

const FileSelectDropzone = ({
  onDrop,
  maxSizeInMB = 4,
  disabled = false,
  className,
  style,
  children,
  ...props
}: FileSelectDropzoneProps) => {
  const dispatch = useDispatch()

  const handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      const toastMessage = { message: I18n.t('views.errors.file_too_large', { maxSizeInMB }), type: 'error' }
      dispatch(showToastMessage(toastMessage))
    } else {
      onDrop(files)
    }
  }

  return (
    <Dropzone
      data-testid='cy_dropzone_file_selector'
      onDrop={handleOnDrop}
      disabled={disabled}
      style={style}
      className={className}
      minSize={0}
      maxSize={maxSizeInMB * 1024 * 1024}
      {...props}
    >
      {children}
    </Dropzone>
  )
}

export default FileSelectDropzone
