import Bold from '@tiptap/extension-bold'

const ClearyBold = Bold.extend({
  addCommands() {
    return {
      setBold: () => ({ chain }) => chain()
        .setMark('textStyle', { fontWeight: null })
        .removeEmptyTextStyle()
        .setMark(this.name)
        .run(),
      toggleBold: () => ({ chain }) => chain()
        .setMark('textStyle', { fontWeight: null })
        .removeEmptyTextStyle()
        .toggleMark(this.name)
        .run(),
      unsetBold: () => ({ chain }) => chain()
        .setMark('textStyle', { fontWeight: null })
        .removeEmptyTextStyle()
        .unsetMark(this.name)
        .run(),
    }
  },
})

export default ClearyBold
