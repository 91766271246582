import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'

export default {
  ...generateCrudRoutes('/api/videos', 'video'),

  copy(id, video) {
    return Axios.post(`/api/videos/${id}/copy`, { video })
  },

  createFromZoomRecording(params) {
    return Axios.post('/api/videos/create_from_zoom_recording', params)
  },

  trackWatchingSessions(resourceType, resourceId, videoId, sessionId, sessions): Promise<any> {
    const endpoint = `/api/videos/${videoId}/${resourceType}/${resourceId}/watching_sessions`

    return Axios.post(endpoint, {
      watchingSessions: sessions.map(session => ({
        id: sessionId,
        ...session,
      })),
    })
  },

  changeThumbnail(id, video) {
    if (video.addedImage) {
      const formData = new FormData()
      formData.append('video[thumbnail]', video.addedImage, video.addedImage.fileName)

      return Axios.patch(`/api/videos/${id}/change_thumbnail`, formData)
    } else {
      return Axios.patch(`/api/videos/${id}/change_thumbnail`, { video })
    }
  },
}
