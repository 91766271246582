import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import React from 'react'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'

const GreetingCardHeadlineDisplay = ({ celebration }) => {
  const html = isHtmlStringEmpty(celebration.customTitle) ? celebration.defaultTitle : celebration.customTitle

  return <TipTapRichTextView html={html} />
}

export default GreetingCardHeadlineDisplay
