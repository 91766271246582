import useApi, { ApiFunction, ApiHookReturn as UseApiHookReturn, Options } from 'components/common/hooks/useApi'
import { useEffect, useMemo, useState } from 'react'

interface ApiHookReturn<T = any> extends UseApiHookReturn<T> {
  callApi: ApiFunction
  removeItem: (id: string) => void
  removeItems: (ids: string[]) => void
}

export type UseFetchOptions = Options & {
  load?: boolean
}

function useFetch<T = any>(
  apiFunction: ApiFunction,
  dependencies: any[] = [],
  options: UseFetchOptions = {}
): ApiHookReturn<T> {
  const { load = true } = options

  const [callApi, { data: rawData, ...resultOptions }] = useApi(apiFunction, options as Options)
  const [removedIds, setRemovedItemIds] = useState<(string)[]>([])

  const removeItems = (ids: string[]) => setRemovedItemIds([...removedIds, ...ids])
  const removeItem = (id: string) => removeItems([id])

  const finalDependencies = useMemo(() => [...dependencies, load], [dependencies, load])

  const data = useMemo(() => {
    // check if raw data is an array
    // or if there are no removed ids
    if (!Array.isArray(rawData) || removedIds.length === 0) return rawData

    return rawData.filter(item => !removedIds.includes(item.id))
  }, [rawData, removedIds])

  useEffect(() => {
    setRemovedItemIds([])
    if (load) callApi(...dependencies)
  }, finalDependencies)

  return {
    ...resultOptions,
    data,
    callApi,
    removeItem,
    removeItems,
  }
}

export default useFetch
