import Paragraph from '@tiptap/extension-paragraph'

const ClearyParagraph = Paragraph.extend({
  addCommands() {
    return {
      setParagraph: () => ({ chain, editor }) => {
        const currentNodeName = editor.state.selection.$head.parent.type.name
        const currentAttributes = editor.getAttributes(currentNodeName)

        return chain()
          .selectParentNode()
          .setMark('textStyle', { fontSize: null })
          .removeEmptyTextStyle()
          .setNode(this.name, currentAttributes)
          .run()
      },
    }
  },
})

export default ClearyParagraph
