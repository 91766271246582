import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

const I18N = i18nPath('views.celebrations')

export const initialState = {
  celebrationIds: [],
  currentCelebrationId: null,
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams,
    },
  },
}

const celebrationSlice = createSlice({
  name: 'celebrations',
  initialState,
  reducers: {
    ...defaultActions,

    clear() {
      return initialState
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    addCelebrationIds(state, action) {
      state.celebrationIds = _.uniq([...state.celebrationIds, ...action.payload])
    },

    setCurrentCelebrationId(state, action) {
      state.currentCelebrationId = action.payload
    },

    clearCurrentCelebration(state) {
      state.currentCelebrationId = null
    },
  },
})

const asyncActions = {
  fetchCelebrations: params => async (dispatch) => {
    dispatch(celebrationSlice.actions.isLoading(true))

    try {
      const response = await API.celebrations.fetchCelebrations(params)

      dispatch(entitySlice.actions.add({ data: response.data }))

      const celebrationIds = response.data.data.map(celebration => celebration.id)
      dispatch(celebrationSlice.actions.addCelebrationIds(celebrationIds))

      const newQueryParams = queryParamsFromHeaders(response)
      dispatch(celebrationSlice.actions.setQueryParams(newQueryParams))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18N('greeting_card.error_fetching_celebration'), type: 'error' }))
    } finally {
      dispatch(celebrationSlice.actions.isLoading(false))
    }
  },

  fetchCurrentCelebration: userId => async (dispatch) => {
    try {
      const response = await API.celebrations.fetchCurrentCelebration(userId)

      dispatch(entitySlice.actions.add({ data: response.data }))

      const id = response.data?.data?.id
      if (id) {
        dispatch(celebrationSlice.actions.setCurrentCelebrationId(response.data?.data?.id))
      } else {
        dispatch(celebrationSlice.actions.clearCurrentCelebration())
      }
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    }
  },

  fetchCelebration: celebrationId => async (dispatch) => {
    dispatch(celebrationSlice.actions.isLoading(true))
    dispatch(celebrationSlice.actions.isNotFound(false))

    try {
      const response = await API.celebrations.fetch(celebrationId)
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      dispatch(celebrationSlice.actions.isNotFound(true))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(celebrationSlice.actions.isLoading(false))
    }
  },

  updateAttributes: (celebrationId, attributes) => async (dispatch) => {
    dispatch(entitySlice.actions.update({
      data: {
        data: {
          id: celebrationId,
          type: 'celebration',
          attributes,
        },
      },
    }))
  },
}

const selectors = {
  getMetaData: () => state => state.celebrations.meta,

  getCelebration: celebrationId => state => build(state.entities, 'celebration', celebrationId),

  getCelebrations: () => state => state.celebrations.celebrationIds.map(id => build(state.entities, 'celebration', id)).filter(Boolean),

  getCurrentCelebration: () => (state) => {
    const id = state.celebrations.currentCelebrationId

    if (id) {
      return build(state.entities, 'celebration', state.celebrations.currentCelebrationId)
    } else {
      return null
    }
  },
}

export default {
  ...celebrationSlice,
  asyncActions,
  selectors,
}
