import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import { i18nPath } from 'utils/i18nHelpers'
import { DEFAULT_LINE_HEIGHT, LINE_HEIGHTS } from 'components/common/tiptap/extensions/lineHeight'
import classNames from 'classnames'

const I18N = i18nPath('views.tiptap.toolbar')

const LineHeightSelect = ({ editor, isDisabled = false }) => {
  const paragraphLineHeight = editor.getAttributes('paragraph')?.lineHeight
  const headingLineHeight = editor.getAttributes('heading')?.lineHeight
  const activeLineHeight = paragraphLineHeight || headingLineHeight || DEFAULT_LINE_HEIGHT

  return (
    <ToolbarSelect
      className={classNames('LineHeightSelect', { disabled: isDisabled })}
      options={LINE_HEIGHTS.map(lineHeight => ({
        value: lineHeight,
        label: I18N(`line_height_options.line_height_${lineHeight.replace('.', '_')}`),
        onClick: () => editor.chain().focus().setLineHeight(lineHeight).run(),
        isActive: activeLineHeight === lineHeight,
        singleValueContent: <CdnSvg src='/images/tiptap/line-height.svg' />,
      }))}
      defaultValue={DEFAULT_LINE_HEIGHT}
      tooltipText={I18N('line_height_tooltip')}
      isDisabled={isDisabled}
    />
  )
}

export default LineHeightSelect

