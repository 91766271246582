import React from 'react'

import LinkAnchor from 'components/common/linkAnchor'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import appSignal from 'services/appSignal'

const EmployeeMiniCardLink = ({
  employee,
  additionalContent = undefined,
  padding = undefined,
  history = undefined,
  photoSize = undefined,
  targetBlank = false,
  showEmployeeTitle = true,
  link = `/profile/${employee?.username}`,
}) => {
  // If only id and type are present, it could be that the user was deleted
  // Or that we are still fetching the user information and we only have the relationship from other entity
  const isEmployeePresent = !_.isEmpty(_.omit(employee, ['id', 'type']))

  if (!isEmployeePresent) return null

  return (
    <LinkAnchor
      link={employee?.active ? link : null}
      history={history}
      targetBlank={targetBlank}
    >
      <EmployeeMiniCard
        employee={employee}
        showEmployeeTitle={showEmployeeTitle}
        additionalContent={additionalContent}
        padding={padding}
        photoSize={photoSize}
      />
    </LinkAnchor>
  )
}

export default EmployeeMiniCardLink
