import React from 'react'
import ResizeOverlay from 'components/common/tiptap/extensions/nodeViews/resizeOverlay'
import { randomReactKey } from 'components/common/utils'
import { STYLE_ATTRIBUTES } from 'components/common/tiptap/extensions/embed'

const IframeResizer = (props: any) => (
  <ResizeOverlay {...props} keepAspectRatioOnCornerResize={false}>
    <iframe
      key={props.node.attrs['data-file-id'] || props.node.attrs.src}
      {..._.omit(props.node.attrs, STYLE_ATTRIBUTES)}
      title={props.node.attrs.title || randomReactKey()}
      className='postimage'
      style={_.pick(props.node.attrs, STYLE_ATTRIBUTES)}
    />
  </ResizeOverlay>
)

export default IframeResizer
