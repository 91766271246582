import { useRef, useEffect } from 'react'

/*
 * This hook adds an event listener that fires before the unload event.
 * Unload happens when: user navigates away, closes browser, clicks back
 * button or refreshes the page, or typing a new url in the address bar.
 *
 * The following example prevents the page from
 * reloading, unless users confirms the dialaog.
 *
 * useBeforeUnload((e) => {
 *   if (shouldBlockNavigation) {
 *     e.preventDefault()
 *    e.returnValue = ''
 *   }
 * })
*/
const useBeforeUnload = (fn) => {
  const callback = useRef()

  // Creates a new reference whenever fn changes
  useEffect(() => {
    callback.current = fn
  }, [fn])

  useEffect(() => {
    // The first 'e' comes from the addEventListener
    // callback method and then passes it down to 'fn'
    const onBeforeUnload = e => callback.current?.(e)

    window.addEventListener('beforeunload', onBeforeUnload)

    // Cleanup
    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  }, [])
}

export default useBeforeUnload
