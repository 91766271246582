const CROP_KEYS = ['width', 'height', 'x', 'y'].sort()


export const sanitizedCroppedAreaPixels = (croppedAreaPixels) => {
  const values = _.mapValues(croppedAreaPixels, (value) => {
    const newValue = parseInt(value, 10)
    return isNaN(newValue) ? null : newValue
  })

  // 4 keys are x, y, width and height
  const areSameKeys = _.isEqual(_.keys(values).sort(), CROP_KEYS)
  const areValuesPresent = _.every(values, value => value !== null)
  return areSameKeys && areValuesPresent ? values : null
}

// eslint-disable-next-line import/prefer-default-export
export const appendCroppedFormData = (formData, model, field, croppedAreaPixels) => {
  formData.delete(`${model}[${field}]`)

  const sanitizedValue = sanitizedCroppedAreaPixels(croppedAreaPixels)

  if (sanitizedValue) {
    CROP_KEYS.forEach((key) => {
      formData.append(`${model}[${field}[${key}]]`, sanitizedValue[key])
    })
  }
}
