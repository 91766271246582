import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.tiptap.toolbar.image_bubble_menu')

const AlternativeTextForm = ({ editor, closeDropdown }) => {
  const nodeName = editor.isActive('inlineImage') ? 'inlineImage' : 'blockImage'
  const initialValue = editor.getAttributes(nodeName).alt
  const [value, setValue] = useState(initialValue || '')

  const saveValueAndClose = (e) => {
    e.preventDefault()
    editor.commands.updateAttributes(nodeName, { alt: value })
    closeDropdown()
  }

  return (
    <form className='d-flex flex-column align-items-end' onSubmit={saveValueAndClose}>
      <input
        className='mb-2 w-100'
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={I18N('alt_text')}
        autoFocus
      />
      <ButtonSmallNarrow type='submit'>
        {I18NCommon('save')}
      </ButtonSmallNarrow>
    </form>
  )
}

const AlternativeTextButton = ({ editor, isDisabled = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <ToolbarDropdownButton
      tooltipText={I18N('alt_text')}
      className='TiptapAlternativeTextButton'
      dropdownContent={(
        <AlternativeTextForm editor={editor} closeDropdown={() => setIsDropdownOpen(false)} />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src='/images/tiptap/t-icon.svg' />
    </ToolbarDropdownButton>
  )
}

export default AlternativeTextButton
