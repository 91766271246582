import generateCrudRoutes from 'services/generateCrudRoutes'

export const buildWorkflowPayload = workflow => _.pick(workflow, [
  'id',
  'name',
  'description',
])

const workflows = generateCrudRoutes(
  '/api/admin/ai/workflows',
  'workflow',
  {},
  buildWorkflowPayload
)

export default workflows
