import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import SocialShareComponent from 'components/common/tiptap/extensions/nodeViews/socialShare/socialShareComponent'

// eslint-disable-next-line import/prefer-default-export
export const SocialShare = Node.create({
  name: 'socialShare',
  group: 'block',
  draggable: false,
  contentEditable: false,

  addAttributes() {
    return {
      ...this.parent?.(),

      url: {
        default: '',

        parseHTML: element => element.getAttribute('data-url'),

        renderHTML: attributes => ({
          'data-url': attributes.url,
        }),
      },

      suggestedText: {
        default: '',

        parseHTML: element => element.getAttribute('data-suggested-text'),

        renderHTML: attributes => ({
          'data-suggested-text': attributes.suggestedText,
        }),
      },

      title: {
        default: '',

        parseHTML: element => element.getAttribute('data-title'),

        renderHTML: attributes => ({
          'data-title': attributes.title,
        }),
      },

      imageUrl: {
        default: '',

        parseHTML: element => element.getAttribute('data-image-url'),

        renderHTML: attributes => ({
          'data-image-url': attributes.imageUrl,
        }),
      },

      editMode: {
        default: false,

        parseHTML: element => element.getAttribute('data-edit-mode') === 'true',

        renderHTML: attributes => ({
          'data-edit-mode': attributes.editMode,
        }),
      },

      croppedArea: {
        default: false,

        parseHTML: element => JSON.parse(element.getAttribute('data-cropped-area') || '{}'),

        renderHTML: attributes => ({
          'data-cropped-area': JSON.stringify(attributes.croppedArea),
        }),
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'div.social-share',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { class: 'social-share', ...HTMLAttributes }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SocialShareComponent)
  },
})

