import Axios from 'axios'

import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'
import { appendCroppedFormData } from 'utils/imageCrop'

export const buildGroupPayload = (group) => {
  const payload = _.pick(group, [
    'id',
    'name',
    'groupTypeId',
    'active',
    'classification',
    'parentId',
    'description',
    'email',
    'coverImage',
    'coverImageCroppedArea',
    'links',
    'slackChannelId',
    'msTeamChannelId',
  ])

  payload.slackChannelId = group.slackChannel?.id
  payload.msTeamChannelId = group.msTeamChannel?.id

  return payload
}

const crudRoutes = generateCrudRoutes('/api/groups', 'group', {}, buildGroupPayload)

const groups = {
  ...crudRoutes,

  fetchCurrentUserGroups() {
    return Axios.get('/api/groups/current_user_groups')
  },

  fetchCurrentUserEditableGroups() {
    return Axios.get('/api/groups/current_user_editable_groups')
  },

  uploadCoverImage(id, coverImage, croppedAreaPixels) {
    return crudRoutes.update({ id, coverImage, coverImageCroppedArea: croppedAreaPixels })
  },

  repositionCoverImage(id, croppedAreaPixels) {
    return crudRoutes.update({ id, coverImageCroppedArea: croppedAreaPixels })
  },

  removeCoverImage(groupId) {
    return Axios.delete(`/api/groups/${groupId}/cover_image`)
  },

  fetchNewsFeed(opts) {
    return Axios.get(searchURI(`/api/groups/${opts.groupId}/feed`, opts))
  },

  fetchGroupArticleTypes() {
    return Axios.get('/api/article_types')
  },

  searchMembers(groupId, queryParams) {
    return Axios.get(searchURI(`/api/groups/${groupId}/group_members`, queryParams))
  },

  searchFollowers(groupId, queryParams) {
    return Axios.get(searchURI(`/api/groups/${groupId}/group_followers`, queryParams))
  },

  simple(groupId) {
    return Axios.get(`/api/groups/${groupId}/simple`)
  },

  follows: {
    follow(groupId) {
      return Axios.post(`/api/groups/${groupId}/group_follows/follow`)
    },

    unfollow(groupId) {
      return Axios.delete(`/api/groups/${groupId}/group_follows/unfollow`)
    },

    bulkUpdate(groupId, userIds, groupIds) {
      return Axios.post(`/api/groups/${groupId}/group_follows/bulk_update`, { userIds, groupIds })
    },

    estimateCount(groupId, userIds, groupIds) {
      return Axios.post(`/api/groups/${groupId}/group_follows/estimate_count`, { userIds, groupIds })
    },
  },

  notifications: {
    enable(groupId) {
      return Axios.post(`/api/groups/${groupId}/enable_notifications`)
    },

    disable(groupId) {
      return Axios.post(`/api/groups/${groupId}/disable_notifications`)
    },
  },

  photos: {
    create(image, group, croppedAreaPixels) {
      const formData = new FormData()

      formData.append('photo[image]', image, image.fileName)
      formData.append('photo[owner_id]', group.id)
      appendCroppedFormData(formData, 'photo', 'image_cropped_area', croppedAreaPixels)

      return Axios.post(`/api/groups/${group.id}/profile_photos`, formData)
    },

    destroy(photo) {
      return Axios.delete(`/api/groups/${photo.ownerId}/profile_photos/${photo.id}`)
    },

    update(photo) {
      const formData = new FormData()

      appendCroppedFormData(formData, 'photo', 'image_cropped_area', photo.imageCroppedArea)

      return Axios.patch(`/api/groups/${photo.ownerId}/profile_photos/${photo.id}`, formData)
    },

    promote(photo) {
      return Axios.patch(`/api/groups/${photo.ownerId}/profile_photos/${photo.id}/promote`)
    },
  },
}

export default groups
