import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { I18NCommon } from 'utils/i18nHelpers'

// See SocialShareBubbleMenuOptions for a usage example
// This will toggle the editMode attribute of the nodeType passed
const EditDropdownButton = ({
  editor,
  nodeType,
  tooltipText = I18NCommon('edit'),
  iconPath = '/images/tiptap/pencil.svg',
}) => {
  const onClick = () => {
    const editMode = editor.getAttributes(nodeType).editMode
    editor.chain().focus().updateAttributes(nodeType, { editMode: !editMode }).run()
  }

  return (
    <ToolbarButton
      tooltipText={tooltipText}
      className='TiptapEditDropdownButton'
      onClick={onClick}
    >
      <CdnSvg src={iconPath} />
    </ToolbarButton>
  )
}

export default EditDropdownButton
