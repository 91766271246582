import saveImageFromFile from 'components/common/tiptap/utils/saveImageFromFile'
import { RICH_TEXT_VALID_IMAGE_MIME_TYPES } from 'components/common/tiptap/extensions/clearyImage'
import saveAttachmentFromFile from 'components/common/tiptap/utils/saveAttachmentFromFile'
import { EMBED_VALID_FILE_MIME_TYPES } from 'components/common/tiptap/extensions/embed'
import uploadVideoFromFile from 'components/common/tiptap/utils/uploadVideoFromFile'
import processInsertedNodes from 'components/common/tiptap/handlers/processInsertedNodes'

// returning true will prevent the default paste behavior, and false will allow it
const handleTiptapEditorPaste = (view, event, slice, richTextId, dispatch, config) => {
  processInsertedNodes(view, slice, richTextId)

  return processPastedFiles(view, event, richTextId, dispatch, config)
}

const processPastedFiles = (view, event, richTextId, dispatch, config) => {
  const items = Array.from(event.clipboardData?.items || [])

  if (_.isEmpty(items)) return false

  const files = items.map((item: any) => item.getAsFile()).filter(Boolean)
  if (_.isEmpty(files)) return false

  const { schema } = view.state

  files.forEach((file) => {
    if (RICH_TEXT_VALID_IMAGE_MIME_TYPES.includes(file.type) && config.imagesEnabled) {
      const insertImage = (imageUrl: string, imageId: string) => {
        const node = schema.nodes.inlineImage.create({ 'src': imageUrl, 'data-image-id': imageId })
        const transaction = view.state.tr.replaceSelectionWith(node)
        view.dispatch(transaction)
      }

      saveImageFromFile(file, richTextId, insertImage)
    } else if (EMBED_VALID_FILE_MIME_TYPES.includes(file.type) && config.embedsEnabled) {
      const insertEmbed = (embedUrl: string, embedId: string) => {
        const node = schema.nodes.inlineEmbed.create({ 'src': embedUrl, 'data-file-id': embedId })
        const transaction = view.state.tr.replaceSelectionWith(node)
        view.dispatch(transaction)
      }

      saveAttachmentFromFile(file, richTextId, insertEmbed)
    } else if (file.type.includes('video/') && !!dispatch && config.videosEnabled) {
      const insertVideo = (videoId) => {
        const node = schema.nodes.inlineVideo.create({ 'data-video-id': videoId })
        const transaction = view.state.tr.replaceSelectionWith(node)
        view.dispatch(transaction)
      }

      uploadVideoFromFile(file, richTextId, dispatch, insertVideo)
    } else if (config.filesEnabled) {
      const insertLink = (linkUrl: string, linkId: string) => {
        const linkMark = schema.marks.link.create({ 'href': linkUrl, 'data-file-id': linkId, 'target': '_blank' })
        const node = schema.text(`${file.name}📎`, [linkMark])
        // the second argument in replaceSelectionWith is inheritMarks, which we don't want because we are adding a link mark
        // if we keep it as true it will remove the link mark
        const transaction = view.state.tr.replaceSelectionWith(node, false)
        view.dispatch(transaction)
      }

      saveAttachmentFromFile(file, richTextId, insertLink)
    }
  })

  return true
}

export default handleTiptapEditorPaste
