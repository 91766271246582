import React from 'react'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const ParagraphFormatSelect = ({ editor, isDisabled = false }) => {
  const onToggleHeading = level => editor.chain().focus().toggleHeading({ level }).run()

  return (
    <ToolbarSelect
      className='ParagraphFormatSelect'
      isDisabled={isDisabled}
      options={[
        {
          value: 'paragraph',
          label: I18N('paragraph'),
          onClick: () => editor.chain().focus().setParagraph().run(),
          isActive: editor.isActive('paragraph'),
        },
        {
          value: 'heading-1',
          label: I18N('heading_1'),
          customContent: <h1>{I18N('heading_1')}</h1>,
          onClick: () => onToggleHeading(1),
          isActive: editor.isActive('heading', { level: 1 }),
        },
        {
          value: 'heading-2',
          label: I18N('heading_2'),
          customContent: <h2>{I18N('heading_2')}</h2>,
          onClick: () => onToggleHeading(2),
          isActive: editor.isActive('heading', { level: 2 }),
        },
        {
          value: 'heading-3',
          label: I18N('heading_3'),
          customContent: <h3>{I18N('heading_3')}</h3>,
          onClick: () => onToggleHeading(3),
          isActive: editor.isActive('heading', { level: 3 }),
        },
        {
          value: 'heading-4',
          label: I18N('heading_4'),
          customContent: <h4>{I18N('heading_4')}</h4>,
          onClick: () => onToggleHeading(4),
          isActive: editor.isActive('heading', { level: 4 }),
        },
        {
          value: 'heading-5',
          label: I18N('heading_5'),
          customContent: <h5>{I18N('heading_5')}</h5>,
          onClick: () => onToggleHeading(5),
          isActive: editor.isActive('heading', { level: 5 }),
        },
        {
          value: 'heading-6',
          label: I18N('heading_6'),
          customContent: <h6>{I18N('heading_6')}</h6>,
          onClick: () => onToggleHeading(6),
          isActive: editor.isActive('heading', { level: 6 }),
        },
      ]}
      defaultValue='paragraph'
      tooltipText={I18N('paragraph_format_tooltip')}
    />
  )
}

export default ParagraphFormatSelect
