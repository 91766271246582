import React from 'react'

export const buildMenuItem = Component => ({
  Component,
  height: 38,
})

export const buildSeparator = () => ({
  Component: () => <hr className='my-2' />,
  height: 10,
})
