import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmall } from 'components/common/buttons'
import getCroppedImageUrl from 'components/common/image_crop/helpers/cropImage'

const I18N = i18nPath('views.image_crop')

interface CroppedArea {
  x: number
  y: number
  width: number
  height: number
}

type SaveData = {
  croppedAreaPixels?: CroppedArea
  croppedImageUrl: string | null
}

interface Props {
  imageSrc: string
  areaHeight?: string
  onCancel?: () => void
  onSave?: (data: SaveData) => void
  aspect: number
  initialCroppedArea?: CroppedArea
  minZoom?: number
  maxZoom?: number
  showActions?: boolean
  onCropComplete?: (areaPercentages: CroppedArea, croppedAreaPixels: CroppedArea) => void
  [key: string]: any
}

const InlineImageCrop = ({
  imageSrc,
  areaHeight,
  onCancel,
  onSave,
  aspect,
  initialCroppedArea,
  minZoom = 1,
  maxZoom = 3,
  showActions = true,
  onCropComplete: onCropCompleteProp,
  ...props
}: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(initialCroppedArea)
  const invertedAspectPercentage = (1 / aspect) * 100

  let areaStyle: Record<string, any> = { paddingTop: invertedAspectPercentage + '%' }

  if (areaHeight) {
    areaStyle = { height: areaHeight }
  }

  const onCropComplete = (areaPercentages, newCroppedAreaPixels) => {
    if (!_.isEqual(croppedAreaPixels, newCroppedAreaPixels)) {
      setCroppedAreaPixels(newCroppedAreaPixels)

      if (onCropCompleteProp) onCropCompleteProp(areaPercentages, newCroppedAreaPixels)
    }
  }

  const onSetCrop = (newCrop) => {
    if (!isNaN(newCrop.x) && !isNaN(newCrop.y) && !_.isEqual(newCrop, crop)) {
      setCrop(newCrop)
    }
  }

  const handleOnSave = async () => {
    if (onSave) {
      const croppedImageUrl = await getCroppedImageUrl(imageSrc, croppedAreaPixels)
      onSave({ croppedAreaPixels, croppedImageUrl })
    }
  }

  const zoomIn = () => {
    setZoom(Math.min(zoom + 0.1, maxZoom))
  }
  const zoomOut = () => {
    setZoom(Math.max(zoom - 0.1, minZoom))
  }

  return (
    <div className='CropperWrapper'>
      <div className='position-relative' style={areaStyle}>
        <Cropper
          {...props}
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onCropChange={onSetCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          aspect={aspect}
          showGrid={false}
          zoomWithScroll={false}
          initialCroppedAreaPixels={_.isEmpty(initialCroppedArea) ? undefined : initialCroppedArea}
        />
      </div>
      <div className='controls d-flex justify-content-between align-items-center p-3'>
        <div>
          <p className='m-0'>
            {I18N('controls.drag_to_reposition')}

            <ButtonSmall
              className='ml-2'
              variant='outline-primary'
              onClick={zoomOut}
            >
              {I18N('controls.zoom_out')}
            </ButtonSmall>

            <ButtonSmall
              className='ml-2'
              variant='outline-primary'
              onClick={zoomIn}
            >
              {I18N('controls.zoom_in')}
            </ButtonSmall>
          </p>
        </div>

        {showActions && (
          <div>
            <ButtonSmall
              className='ml-2'
              variant='danger'
              onClick={onCancel}
            >
              {I18N('controls.cancel')}
            </ButtonSmall>
            <ButtonSmall
              className='ml-2'
              onClick={handleOnSave}
            >
              {I18N('controls.save')}
            </ButtonSmall>
          </div>
        )}
      </div>
    </div>
  )
}

export default InlineImageCrop
