import React from 'react'
import InsertColumnAfterItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/insertColumnAfterItem'
import InsertColumnBeforeItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/insertColumnBeforeItem'
import InsertRowAboveItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/insertRowAboveItem'
import InsertRowBelowItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/insertRowBelowItem'
import TableHeaderItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/tableHeaderItem'
import DeleteRowItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/deleteRowItem'
import DeleteColumnItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/deleteColumnItem'
import DeleteTableItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/deleteTableItem'
import TableStyleItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/tableStyleItem'
import MergeSplitCellsItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/mergeSplitCellsItem'
import CellStyleItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/cellStyleItem'
import VerticalAlignmentItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/verticalAlignmentItem'
import HorizontalAlignmentItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/horizontalAlignmentItem'
import CutMenuItem from 'components/common/tiptap/menus/context_menu_items/cutMenuItem'
import CopyMenuItem from 'components/common/tiptap/menus/context_menu_items/copyMenuItem'
import { buildMenuItem, buildSeparator } from 'components/common/tiptap/menus/utils'


export default [
  buildMenuItem(CutMenuItem),
  buildMenuItem(CopyMenuItem),
  buildSeparator(),
  buildMenuItem(InsertRowAboveItem),
  buildMenuItem(InsertRowBelowItem),
  buildMenuItem(InsertColumnAfterItem),
  buildMenuItem(InsertColumnBeforeItem),
  buildSeparator(),
  buildMenuItem(TableHeaderItem),
  buildMenuItem(TableStyleItem),
  buildMenuItem(CellStyleItem),
  buildMenuItem(MergeSplitCellsItem),
  buildMenuItem(VerticalAlignmentItem),
  buildMenuItem(HorizontalAlignmentItem),
  buildSeparator(),
  buildMenuItem(DeleteRowItem),
  buildMenuItem(DeleteColumnItem),
  buildMenuItem(DeleteTableItem),
]
