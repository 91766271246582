import Axios from 'axios'
import searchURI from 'services/searchURI'
import { commentPath, commentablePath } from 'utils/routeHelpers'

export const REMOVE_MEDIA = 'removed'

const buildPayload = (change, media) => {
  let body: any = { comment: change }

  if (media instanceof File) {
    body = new FormData()
    Object.entries(change).forEach(([key, value]) => body.append(`comment[${_.snakeCase(key)}]`, value))
    body.append('comment[media]', media)
  }

  if (media === REMOVE_MEDIA) {
    body.comment.media = null
  }

  return body
}

export default {
  create(commentable, type, comment = {}, media: any = null) {
    return Axios.post(commentablePath(commentable, type), buildPayload(comment, media))
  },

  fetchAll(opts, commentable, type) {
    return Axios.get(searchURI(commentablePath(commentable, type), opts))
  },

  update(commentable, comment, type, change = {}, media: any = null) {
    return Axios.patch(commentPath(commentable, comment, type), buildPayload(change, media))
  },

  destroy(commentable, comment, type) {
    return Axios.delete(commentPath(commentable, comment, type))
  },
}
