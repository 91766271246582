import { toast } from 'react-toastify'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap')

const saveAttachmentFromFile = async (
  file: File,
  richTextId: null | undefined | string,
  callback: (fileUrl: string, fileId: string) => void,
  errorCallback: () => void = () => {}
) => {
  try {
    const response = await API.attachments.create(file, richTextId)
    const { fileUrl, fileId } = response.data

    callback(fileUrl, fileId)
  } catch (e) {
    toast.error(I18N('error_uploading_file'))
    errorCallback()
  }
}

export default saveAttachmentFromFile
