import React from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.tiptap.toolbar.link_bubble_menu')

const OpenLinkButton = ({ editor, nodeName = 'link' }) => {
  const onOpenLink = () => {
    // Get the URL of the currently active link
    // Open link in a new tab
    window.open(editor.getAttributes(nodeName)?.href, '_blank')
  }

  return (
    <ToolbarButton
      onClick={onOpenLink}
      tooltipText={I18N('open_link')}
    >
      <CdnSvg src='/images/tiptap/external-link.svg' />
    </ToolbarButton>
  )
}

export default OpenLinkButton
