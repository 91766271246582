import { DynamicStyles, ThemeStyles } from 'components/appColorFunctions'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ReduxState } from 'redux/redux'

const CustomStylesApplier = () => {
  const styles = useSelector((state: ReduxState) => state.styles)

  useEffect(() => {
    const { variables, cssOverrides } = styles
    const styleTag = document.createElement('style')
    styleTag.id = 'cssOverrides'
    styleTag.innerHTML = '\n' + cssOverrides
    document.body.appendChild(styleTag)

    ThemeStyles.init(variables).then(DynamicStyles.init)

    return () => {
      document.body.removeChild(styleTag)
    }
  }, [styles])

  return null
}

export default CustomStylesApplier
