import React from 'react'

import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import classNames from 'classnames'
import { I18NCommon } from 'utils/i18nHelpers'

const EmployeeMiniCard = ({
  employee: { preferredFullName, title, active = true },
  employee,
  additionalContent = undefined,
  padding = '2',
  photoSize = '35px',
  showEmployeeTitle = true,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  className = '',
}) => (
  <div
    className={classNames(`EmployeeMiniCard p-${padding}`, className)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className='mr-2'>
      <EmployeeThumbnailPhoto employee={employee} size={photoSize} />
    </div>

    <div className='EmployeeMiniCard-content position-relative'>
      <div className='text-primary font-weight-500 truncate-text-at-1-lines'>{preferredFullName}</div>

      {showEmployeeTitle && active && (
        <div className='text-secondary text-small truncate-text-at-1-lines'>{active ? title : I18NCommon('inactive')}</div>
      )}

      {additionalContent}
    </div>
  </div>
)

export default EmployeeMiniCard
