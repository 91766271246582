import checkIfValidUrl from 'utils/checkIfValidUrl'

const isClearyUrl = (url) => {
  if (!url) return false
  if (!checkIfValidUrl(url)) return false

  return url.startsWith(location.origin)
}

export const isClearyApiUrl = url => isClearyUrl(url) && url.startsWith(`${location.origin}/api`)

export default isClearyUrl
