import classNames from 'classnames'
import React, { useState } from 'react'
import { components } from 'react-select'
import ReactSelect from 'components/common/react_select'
import Tooltip from 'components/common/clearyTooltip'

interface Props {
  options: {
    value: string
    label: string | React.ReactNode
    customContent?: React.ReactNode
    isActive?: boolean
    onClick: () => void
    tooltipText?: string
    singleValueContent?: React.ReactNode
  }[],
  className?: string
  defaultValue?: string
  tooltipText: string
  isDisabled?: boolean
  placeholder?: string | React.ReactNode
  disableSelectedOptionHighlighting?: boolean
  customComponents?: any
}

const ToolbarSelect = ({
  options,
  className,
  defaultValue,
  tooltipText,
  placeholder,
  isDisabled = false,
  disableSelectedOptionHighlighting = false,
  customComponents = {},
}: Props) => {
  const selectedOption = options.find(opt => opt.isActive) || options.find(opt => opt.value === defaultValue)
  const [hideTooltip, setHideTooltip] = useState(false)

  return (
    <Tooltip
      placement='bottom'
      show={0}
      hide={0}
      hideTooltip={hideTooltip}
      content={(
        <ReactSelect
          className={classNames('TiptapToolbarSelect', className, { disableSelectedOptionHighlighting })}
          onChange={option => option.onClick()}
          value={disableSelectedOptionHighlighting ? null : selectedOption}
          options={options}
          getOptionLabel={option => option.label}
          onMenuOpen={() => setHideTooltip(true)}
          onMenuClose={() => setHideTooltip(false)}
          isSearchable={false}
          isDisabled={isDisabled}
          components={{
            IndicatorSeparator: () => null,
            Option: props => (
              props.data.tooltipText ? (
                <Tooltip
                  placement='bottom'
                  show={0}
                  hide={0}
                  content={(
                    <components.Option {...props}>{props.data.customContent || props.data.label}</components.Option>
                  )}
                >
                  <span className='text-smallest'>{props.data.tooltipText}</span>
                </Tooltip>
              ) : (
                <components.Option {...props}>{props.data.customContent || props.data.label}</components.Option>
              )
            ),
            SingleValue: props => (
              props.data.singleValueContent ? (
                <components.SingleValue {...props}>{props.data.singleValueContent}</components.SingleValue>
              ) : (
                <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
              )
            ),
            Placeholder: placeholder ? () => placeholder : components.Placeholder,
            ...customComponents,
          }}
        />
      )}
    >
      <span className='text-smallest'>{tooltipText}</span>
    </Tooltip>
  )
}

export default ToolbarSelect
