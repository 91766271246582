import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import classNames from 'classnames'
import { BORDERLESS_TABLE_CLASS } from 'components/common/tiptap/extensions/nodeViews/tableView'

const I18N = i18nPath('views.tiptap.toolbar')

const OPTIONS = ['2', '3', '4', '5']

const ColumnLayoutSelect = ({ editor, isDisabled = false }) => {
  const insertTable = (cols) => {
    editor.chain().focus().insertTable({ cols, rows: 1, withHeaderRow: false }).toggleClass(BORDERLESS_TABLE_CLASS)
.run()
  }

  return (
    <ToolbarSelect
      className={classNames('ColumnLayoutSelect', { disabled: isDisabled })}
      options={OPTIONS.map(value => ({
        value,
        label: I18N(`column_layout_${value}`),
        onClick: () => insertTable(value),
      }))}
      tooltipText={I18N('column_layout_tooltip')}
      placeholder={<div><CdnSvg src='/images/tiptap/slash_commands/threeColumns.svg' /></div>}
      disableSelectedOptionHighlighting
      isDisabled={isDisabled}
    />
  )
}

export default ColumnLayoutSelect
