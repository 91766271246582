import React from 'react'
import BubbleMenuDeleteButton from 'components/common/tiptap/menus/buttons/bubbleMenuDeleteButton'
import ChangeSizeButton from 'components/common/tiptap/menus/buttons/changeSizeButton'
import TextAlignSelect from 'components/common/tiptap/toolbar/items/textAlignSelect'
import VideoDisplaySelect from 'components/common/tiptap/menus/videos/videoDisplaySelect'
import AutoplayButton from 'components/common/tiptap/menus/embeds/autoplayButton'
import VideoThumbnailSelect from 'components/common/tiptap/menus/videos/videoThumbnailSelect'
import { useSelector } from 'react-redux'
import videoSlice from 'redux/slices/videos'

const VideoBubbleMenuOptions = ({ editor }) => {
  const isBlockVideo = editor.isActive('blockVideo')
  const selectedNode = isBlockVideo ? 'blockVideo' : 'inlineVideo'
  const currentAttributes = editor.getAttributes(selectedNode)
  const videoId = currentAttributes['data-video-id']
  const video = useSelector(videoSlice.selectors.getVideo(videoId))

  return (
    <div
      className='VideoBubbleMenuOptions BubbleMenuOptions d-flex p-2 align-items-center justify-content-center'
    >
      <BubbleMenuDeleteButton editor={editor} />
      {video?.encodedState === 'finished' && <VideoThumbnailSelect editor={editor} />}
      <ChangeSizeButton
        editor={editor}
        nodeName={editor.isActive('inlineVideo') ? 'inlineVideo' : 'blockVideo'}
        defaultWidth='100%'
      />
      <AutoplayButton editor={editor} />
      <VideoDisplaySelect editor={editor} />
      <TextAlignSelect editor={editor} />
    </div>
  )
}

export default VideoBubbleMenuOptions
