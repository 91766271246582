import classNames from 'classnames'
import React from 'react'
import { Card } from 'react-bootstrap'


interface ClearyCardProps {
  className?: string
  title?: string | React.ReactNode
  subtitle?: string
  children?: any
  cardBodyClassName?: string
  onClick?: ((e: React.MouseEvent<HTMLDivElement>) => void) | (() => void)
  cardRef?: any
  style?: Record<string, any>
  hasBoxShadow?: boolean
  hideBorder?: boolean
  isDisabled?: boolean
  id?: string
}

const ClearyCard = ({
  className,
  title,
  subtitle,
  children,
  cardBodyClassName,
  onClick,
  cardRef,
  style,
  hasBoxShadow = true,
  hideBorder = true,
  isDisabled = false,
  id,
}: ClearyCardProps) => {
  const boxShadow = hasBoxShadow ? 'var(--card-box-shadow)' : 'none'
  const border = hideBorder ? '0' : 'solid 1px var(--border-color)'

  return (
    <Card
      ref={cardRef}
      onClick={isDisabled ? () => {} : onClick}
      className={classNames(className, isDisabled && 'cursor-not-allowed')}
      style={{
        ...style, boxShadow, border, borderRadius: 'var(--square-border-radius)',
      }}
      id={id}
    >
      <Card.Body className={cardBodyClassName}>
        {title && <Card.Title>{title}</Card.Title>}
        {subtitle && <Card.Subtitle className='mb-2 text-muted'>{subtitle}</Card.Subtitle>}
        {children}
      </Card.Body>
    </Card>
  )
}

export default ClearyCard
