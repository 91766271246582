import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.placeholders')

const placeholders = [
  'type_to_add_emoji',
  'type_for_slash_commands',
  'type_for_mentions',
]

const randomPlaceholder = () => I18N(placeholders[Math.floor(Math.random() * placeholders.length)])

export default randomPlaceholder
