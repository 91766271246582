import Axios from 'axios'

const acknowledgeablePath = (acknowledgeableId: string, type: string) => {
  switch (type) {
  case 'article':
    return `/api/articles/${acknowledgeableId}/acknowledgements`
  default:
    return ''
  }
}

const acknowledgements = {
  acknowledge(acknowledgeableId: string, type: string) {
    return Axios.patch(`${acknowledgeablePath(acknowledgeableId, type)}/acknowledge`)
  },
}

export default acknowledgements
