import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'

export const buildCelebrationPayload = (celebration) => {
  const payload: any = _.pick(celebration, [
    'id',
    'customTitle',
    'effect',
    'coverImage',
    'coverImageCroppedArea',
    'feedImage',
    'feedImageCroppedArea',
    'type',
    'eventDate',
    'useDefaultImages',
    'copyImageFromTemplateId',
  ])

  payload.userId = celebration?.user?.id

  return payload
}

const celebrations = {
  ...generateCrudRoutes('/api/celebrations', 'celebration'),

  fetchCelebrations(opts) {
    const options = { page: 1, ...opts }

    return Axios.get(searchURI('/api/celebrations/celebrations', options))
  },

  fetchCurrentCelebration(userId) {
    return Axios.get(searchURI('/api/celebrations/current_celebration', { userId }))
  },

  fetchUsersWithPendingSignatures(celebrationId) {
    return Axios.get(`/api/celebrations/${celebrationId}/users_with_pending_signatures`)
  },

  openCard(celebrationId) {
    return Axios.put(`/api/celebrations/${celebrationId}/open_card`)
  },

  templates: {
    fetch() {
      return Axios.get('/api/celebration/templates')
    },
  },

  socialShare(id, network, source) {
    return Axios.post(
      `/api/celebrations/${id}/social_share?social_network=${network}&source=${source}`
    )
  },

  sayThanks(id, message) {
    return Axios.post(`/api/celebrations/${id}/say_thanks`, { message })
  },

  loadEffect(effect) {
    return Axios.get(`/javascripts/celebrations_effects/${effect}.json`)
  },
}

export default celebrations
