import React, { useEffect, useRef, useState } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import { Button } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import AiPromptTiptapEditor from 'components/common/tiptap/ai/aiPromptTiptapEditor'
import useAiGenerateText from 'components/common/tiptap/hooks/ai/useAiGenerateText'
import useClickOutside from 'components/common/hooks/useClickOutside'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import AiNodeSideMenu from 'components/common/tiptap/extensions/nodeViews/ai/aiNodeSideMenu'

const I18N = i18nPath('views.tiptap.ai')

const aiSvgPath = '/images/tiptap/ai.svg'

const AiPromptComponent = ({
  editor,
  node,
  getPos,
  updateAttributes,
  isInsideAiBlock = false,
  isDisabled = false,
  setIsDisabled = (isDisabled) => {},
  onClickOutside = () => {},
  deleteNode = () => {},
}) => {
  const editorAttributes = editor?.options?.editorProps?.attributes
  const isEditingTemplate = editorAttributes?.isEditingTemplate
  const clearyRichTextId = editorAttributes?.richTextId
  const prompt = node.attrs.prompt
  const [wasFocused, setWasFocused] = useState(false)

  const onCreateSucess = (id) => {
    const nodePos = getPos()
    const nodeSize = node.nodeSize
    const newNode = editor.schema.nodes.aiTextGeneration.create({ prompt, 'data-text-generation-id': id })
    const transaction = editor.state.tr.delete(nodePos, nodePos + nodeSize).insert(nodePos, newNode)
    editor.view.dispatch(transaction)
  }

  const onCreateClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    generateText()
  }

  const updatePrompt = html => updateAttributes({ prompt: html })

  const { generateText, isSaving } = useAiGenerateText({ prompt, onCreateSucess, extraParams: { clearyRichTextId, creationSource: 'editor' } })

  const promptEditorRef = useRef(null)

  useClickOutside({
    ref: promptEditorRef,
    onClickOutside,
    enableClickOutside: wasFocused && isInsideAiBlock && !isDisabled,
  })

  useEffect(() => {
    if (isDisabled) {
      setWasFocused(false)
    }
  }, [isDisabled])

  return (
    <NodeViewWrapper className={classNames('AiPromptComponent', { isInsideAiBlock, isDisabled })}>
      <div
        className={classNames('AiPromptEditor px-3 py-2 d-flex align-items-center justify-content-between')}
        contentEditable={false}
        ref={promptEditorRef}
        onClick={() => setIsDisabled(false)}
      >
        {isDisabled && <CdnSvg src={aiSvgPath} className='mr-2' />}
        <AiPromptTiptapEditor
          html={prompt}
          onChange={(html: string) => updatePrompt(html)}
          // We wait for the prompt to be focused before we enable the click outside to avoid
          // the click outside to be triggered when the user clicks the edit prompt button
          onFocus={() => setWasFocused(true)}
          editable={!isDisabled}
        />
        {!isDisabled && (
          <div className='d-flex align-items-center ml-1' contentEditable={false}>
            <Button
              onClick={onCreateClick}
              disabled={isSaving || isHtmlStringEmpty(prompt)}
              showLoadingSpinner={isSaving}
            >
              <span>
                <CdnSvg src={aiSvgPath} className='mr-2' />
                {I18NCommon('create')}
              </span>
            </Button>
          </div>
        )}
        {!isInsideAiBlock && <AiNodeSideMenu deleteNode={deleteNode} />}
      </div>
      {isEditingTemplate && !isInsideAiBlock && (
        <div contentEditable={false} className='ai-template-disclaimer p-2 text-secondary'>
          {I18N('ai_template_disclaimer')}
        </div>
      )}
    </NodeViewWrapper>
  )
}

export default AiPromptComponent
