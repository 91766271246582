import React, { useEffect, useRef, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import Tooltip from 'components/common/clearyTooltip'
import classNames from 'classnames'
import ResponsivePopover from 'components/common/responsivePopover'
import useCopyToClipboard from 'components/common/hooks/useCopyToClipboard'

const I18N = i18nPath('views.tiptap.social_share')

const ShareButtonTooltip = ({ tooltipText, iconPath, onClick }) => (
  <Tooltip
    placement='bottom'
    show={0}
    hide={0}
    content={(
      <div className='pointer social-share-button' onClick={onClick}>
        <CdnSvg src={iconPath} />
      </div>
    )}
  >
    <span className='text-smallest'>{tooltipText}</span>
  </Tooltip>
)

const shareOnTwitterUrl = (url, text) => {
  if (text) {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`
  } else {
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`
  }
}

const shareOnLinkedinUrl = (url, _text) => `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
const shareOnFacebookUrl = (url, _text) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`

const socialNetworks = [
  {
    network: 'facebook',
    tooltip: I18N('sharing_modal.share_on_facebook'),
    iconPath: '/images/logos/roundedFacebook.svg',
    getUrl: shareOnFacebookUrl,
  },
  {
    network: 'linkedin',
    tooltip: I18N('sharing_modal.share_on_linkedin'),
    iconPath: '/images/logos/roundedLinkedin.svg',
    getUrl: shareOnLinkedinUrl,
  },
  {
    network: 'twitter',
    tooltip: I18N('sharing_modal.share_on_x'),
    iconPath: '/images/logos/roundedX.svg',
    getUrl: shareOnTwitterUrl,
  },
]

interface NetworksListProps {
  availableNetworks: string[]
  onClick?: (network: string) => void
  url: string | ((network: string) => string)
  suggestedText?: string
}

export const NetworksList = ({
  availableNetworks, onClick, url, suggestedText,
}: NetworksListProps) => {
  const copyUrl = useRef(null)
  const { isCopied, handleCopyClick } = useCopyToClipboard({ copyElement: copyUrl.current })

  const generateClickHandler = (network, link) => async () => {
    if (onClick) onClick(network)

    if (network === 'copy') return

    window.open(link, '_blank', 'width=500,height=800')
  }

  const resolveUrl = (url, network) => {
    if (typeof url === 'function') {
      return url(network)
    } else {
      return url
    }
  }

  return (
    <div className='NetworksList'>
      <ul className='social-network-list d-flex justify-content-center p-0 m-0'>
        {socialNetworks.map(({
          network, tooltip, iconPath, getUrl,
        }) => availableNetworks.includes(network) && (
          <li key={tooltip} className='mx-3'>
            <ShareButtonTooltip
              tooltipText={tooltip}
              iconPath={iconPath}
              onClick={generateClickHandler(network, getUrl(resolveUrl(url, network), suggestedText))}
            />
          </li>
        ))}

        <li className='mx-3' data-clipboard-text={resolveUrl(url, 'copy')} ref={copyUrl}>
          <ShareButtonTooltip
            tooltipText={!isCopied ? I18N('sharing_modal.copy_url') : I18N('sharing_modal.copied')}
            iconPath='/images/logos/roundedCopyUrl.svg'
            onClick={() => {
              generateClickHandler('copy', resolveUrl(url, 'copy'))()
              handleCopyClick()
            }}
          />
        </li>
      </ul>
    </div>
  )
}

export const ShareContent = ({
  onClick,
  availableNetworks,
  url,
  suggestedText,
  large = false,
  title = null,
  children = null,
  showSuggestedText = true,
}) => {
  const copySuggestedText = useRef(null)
  const { isCopied, handleCopyClick } = useCopyToClipboard({ copyElement: copySuggestedText.current })

  return (
    <div className={classNames('ShareContent', 'p-4', { 'ShareContent--large': large })}>
      <h6 className='mb-4 text-center'>
        {title || I18N('sharing_modal.share_with_your_networks')}
      </h6>

      {children}

      <NetworksList
        onClick={onClick}
        availableNetworks={availableNetworks}
        url={url}
        suggestedText={suggestedText}
      />

      {suggestedText && showSuggestedText && (
        <div className='suggested-text-container border-radius p-3 py-2 mt-3'>
          <div className='d-flex justify-content-between'>
            <b className='d-block'>{I18N('sharing_modal.suggested_text_for_sharing')}</b>

            <Tooltip
              placement='bottom'
              show={0}
              hide={0}
              content={(
                <div
                  className='copy-button pointer'
                  ref={copySuggestedText}
                  data-clipboard-text={suggestedText}
                  onClick={handleCopyClick}
                >
                  <span className='wrapper'>
                    <CdnSvg src='/images/copyIcon.svg' />
                  </span>
                </div>
              )}
            >
              <span className='text-smallest'>
                {isCopied ? I18N('sharing_modal.suggested_text_copied') : I18N('sharing_modal.copy_suggested_text')}
              </span>
            </Tooltip>
          </div>

          <p className='font-italic my-3'>{suggestedText}</p>
        </div>
      )}
    </div>
  )
}

const ShareWidget = ({
  onClick,
  url,
  suggestedText,
  target,
  availableNetworks,
  title = null,
  children = null,
  showSuggestedText = true,
}) => {
  const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false)

  const toggle = () => {
    setIsShareDropdownOpen(oldValue => !oldValue)
  }

  useEffect(() => {
    target?.addEventListener('click', toggle)

    return () => {
      target?.removeEventListener('click', toggle)
    }
  }, [])

  return (
    <ResponsivePopover
      target={target}
      isOpen={isShareDropdownOpen}
      toggle={toggle}
    >
      <ShareContent
        title={title}
        onClick={onClick}
        availableNetworks={availableNetworks}
        url={url}
        suggestedText={suggestedText}
        showSuggestedText={showSuggestedText}
        large
      >
        {children}
      </ShareContent>
    </ResponsivePopover>
  )
}

export default ShareWidget
