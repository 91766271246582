import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import { toast } from 'react-toastify'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { i18nPath } from 'utils/i18nHelpers'
import ToolbarDropdownTabs from 'components/common/tiptap/toolbar/items/toolbarDropdownTabs'
import { ButtonSmallNarrow } from 'components/common/buttons'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { RICH_TEXT_VALID_IMAGE_EXTENSIONS } from 'components/common/tiptap/extensions/clearyImage'
import saveImageFromFile from 'components/common/tiptap/utils/saveImageFromFile'
import FileSelectDropzone from 'components/common/file_select_dropzone'

const I18N = i18nPath('views.tiptap.toolbar')
const I18NBase = i18nPath('views.tiptap')

const insertImage = (editor: any, imageUrl: string, imageId: string) => {
  if (editor.isActive('blockImage')) {
    // if replacing an image we want to keep the current width and height
    const { width, height } = editor.getAttributes('blockImage')
    editor.chain().focus().setBlockImage({
      'src': imageUrl,
      'data-image-id': imageId,
      'width': width,
      'height': height,
    }).run()
  } else {
    // if no image is selected we will add an inline image,
    // if replacing inline image, keep width and height
    const { width, height } = editor.getAttributes('inlineImage')
    editor.chain().focus().setInlineImage({
      'src': imageUrl,
      'data-image-id': imageId,
      'width': width,
      'height': height,
    }).run()
  }
}

interface InsertImageByUrlProps {
  editor: any
  closeDropdown: () => void
}

const InsertImageByUrl = ({ editor, closeDropdown }: InsertImageByUrlProps) => {
  const [url, setUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const richTextId = editor?.options?.editorProps?.attributes?.richTextId

  const insertImageAndClose = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      const response = await API.temporaryImageUpload.create(url, richTextId)
      const { imageUrl, imageId } = response.data

      insertImage(editor, imageUrl, imageId)
      setIsLoading(false)
      closeDropdown()
    } catch (e) {
      toast.error(I18NBase('error_uploading_image'))
      setIsLoading(false)
    }
  }

  return (
    <form className='d-flex flex-column align-items-end' onSubmit={insertImageAndClose}>
      <input
        className='mb-2 w-100'
        value={url}
        onChange={e => setUrl(e.target.value)}
        placeholder={I18N('insert_url')}
        autoFocus
      />
      <ButtonSmallNarrow type='submit' showLoadingSpinner={isLoading} disabled={isLoading}>
        {I18N('insert')}
      </ButtonSmallNarrow>
    </form>
  )
}

interface InsertImageFromUploadProps {
  editor: any
  closeDropdown: () => void
}
const InsertImageFromUpload = ({ editor, closeDropdown }: InsertImageFromUploadProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const richTextId = editor?.options?.editorProps?.attributes?.richTextId

  const onDrop = (files) => {
    setIsLoading(true)

    const insertImageAndClose = (imageUrl, imageId) => {
      insertImage(editor, imageUrl, imageId)
      setIsLoading(false)
      closeDropdown()
    }

    saveImageFromFile(files[0], richTextId, insertImageAndClose, () => setIsLoading(false))
  }

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <FileSelectDropzone
      disabled={isLoading}
      accept={RICH_TEXT_VALID_IMAGE_EXTENSIONS}
      onDrop={onDrop}
    >
      <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
        <div className='font-weight-700'>{I18N('drop_image')}</div>
        <div className='text-secondary'>{I18N('or_click')}</div>
      </div>
    </FileSelectDropzone>
  )
}

export const InsertImageDropdownContent = ({
  editor,
  closeDropdown,
}) => {
  const tabs = [
    {
      buttonContent: <CdnSvg src='/images/tiptap/upload.svg' />,
      tooltipText: I18N('upload_image_tooltip'),
      content: <InsertImageFromUpload editor={editor} closeDropdown={closeDropdown} />,
    },
    {
      buttonContent: <CdnSvg src='/images/tiptap/link.svg' />,
      tooltipText: I18N('by_url_tooltip'),
      content: <InsertImageByUrl editor={editor} closeDropdown={closeDropdown} />,
    },
  ]

  return (
    <ToolbarDropdownTabs tabs={tabs} />
  )
}

const InsertImageDropdownButton = ({
  editor,
  isDisabled = false,
  tooltipText = I18N('insert_image_tooltip'),
  iconPath = '/images/tiptap/image.svg',
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <ToolbarDropdownButton
      tooltipText={tooltipText}
      className='TiptapInsertImageDropdownButton'
      dropdownContent={(
        <InsertImageDropdownContent
          editor={editor}
          closeDropdown={() => setIsDropdownOpen(false)}
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src={iconPath} />
    </ToolbarDropdownButton>
  )
}

export default InsertImageDropdownButton
