import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import LoadingSpinnerText from 'components/common/loadingSpinnerText'
import useWindowResize from 'components/common/hooks/useWindowResize'

const ButtonLarge = ({
  className = '',
  id = null,
  disabled = false,
  onClick,
  children,
  variant = 'primary',
  showLoadingSpinner = false,
}) => {
  const { isSmallScreen } = useWindowResize()

  return (
    <BootstrapButton
      id={id}
      className={className}
      // large buttons look terrible in small screens
      size={isSmallScreen ? null : 'lg'}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
    >
      <LoadingSpinnerText showLoadingSpinner={showLoadingSpinner}>
        {children}
      </LoadingSpinnerText>
    </BootstrapButton>
  )
}

// eslint-disable-next-line import/prefer-default-export
export { ButtonLarge }
