import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ColorPicker, { ColorPickerPreview } from 'components/common/tiptap/menus/context_menu_items/colorPicker'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const DEFAULT_CELL_BACKGROUND_COLOR = '#ffffff'

const BackgroundColorItem = ({ editor, onClose }) => {
  const [backgroundColorOpened, setBackgroundColorOpened] = useState(false)
  const headerBackgroundColor = editor.getAttributes('tableHeader')?.backgroundColor
  const cellBackgroundColor = editor.getAttributes('tableCell')?.backgroundColor
  const backgroundColor = cellBackgroundColor || headerBackgroundColor || DEFAULT_CELL_BACKGROUND_COLOR

  const buttonRef = React.useRef<HTMLDivElement>(null)

  return (
    <BaseContextMenuItem
      label={I18N('cell_style.submenu.background_color')}
      onClick={() => setBackgroundColorOpened(!backgroundColorOpened)}
      ref={buttonRef}
      component={(
        <>
          <ColorPickerPreview color={backgroundColor} />

          <ColorPicker
            isOpen={backgroundColorOpened}
            onClose={() => setBackgroundColorOpened(false)}
            color={backgroundColor}
            onChange={(color) => {
              onClose()
              editor.chain().focus().setCellAttribute('backgroundColor', color).run()
            }}
            onRemove={() => {
              onClose()
              editor.chain().focus().setCellAttribute('backgroundColor', null).run()
            }}
            buttonRef={buttonRef}
          />
        </>
      )}
    />
  )
}

export default BackgroundColorItem
