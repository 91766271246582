import generateCrudRoutes from 'services/generateCrudRoutes'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const buildBannerPayload = (banner) => {
  const bannerAttributes = _.pick(banner, [
    'id',
    'text',
    'title',
    'link',
    'startTime',
    'endTime',
    'closeable',
    'targetingRules',
  ])

  if (banner.targetingRules) {
    bannerAttributes.targetingRules = normalizeTargetingRules(banner.targetingRules)
  }

  return bannerAttributes
}

const banners = generateCrudRoutes(
  '/api/admin/banners',
  'banner',
  {},
  buildBannerPayload
)

export default banners
