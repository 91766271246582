import React from 'react'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'
import { i18nPath } from 'utils/i18nHelpers'
import isAppleOS from 'utils/isAppleOS'

const I18N = i18nPath('views.tiptap.context_menu')

const CutMenuItem = ({ editor, onClose }) => (
  <BaseContextMenuItem
    iconPath='/images/tiptap/scissors.svg'
    label={I18N('cut')}
    onClick={() => {
      editor.commands.focus()
      onClose()

      // The timeout here is needed to make sure the tiptap editor is enable to copy the content
      // I couldn't figure out why exactly it is needed, but it works.
      setTimeout(() => {
        document.execCommand('cut')
      }, 100)
    }}
    disabled={editor.state.selection.empty}
    shortcut={isAppleOS() ? '⌘ + X' : 'Ctrl + X'}
  />
)

export default CutMenuItem
