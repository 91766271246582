import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { i18nPath } from 'utils/i18nHelpers'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const I18N = i18nPath('views.tiptap.toolbar')

const InsertEmojiDropdownButton = ({ editor, isDisabled = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const onInsert = (emoji) => {
    editor.chain().focus().insertContent(emoji).run()
    setIsDropdownOpen(false)
  }

  return (
    <ToolbarDropdownButton
      tooltipText={I18N('insert_emoji_tooltip')}
      className='InsertEmojiDropdownButton'
      dropdownContent={(
        <Picker
          onEmojiSelect={({ native }) => onInsert(native)}
          data={data}
          theme='light'
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
      dynamicDirection={false}
    >
      <CdnSvg src='/images/tiptap/emoji.svg' />
    </ToolbarDropdownButton>
  )
}

export default InsertEmojiDropdownButton
