import Appsignal from '@appsignal/javascript'
import camelCase from 'lodash/camelCase'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import isClearyUrl from 'utils/isClearyUrl'
import pageVariables from 'utils/pageVariables'

const { revision, currentUser, reactAppAppsignalPushKey } = pageVariables

const denormalizedCurrentUser = currentUser && denormalizedJsonApiResponse({ data: currentUser }, 'user')

const ignoreErrors = [
  /Request aborted/,
  /timeout of .* exceeded/,
  /Network Error/,
]

const appSignal = new Appsignal({
  key: reactAppAppsignalPushKey,
  revision,
  ignoreErrors,
})

// Parses the javascript Error object into a proper name for Appsignal errors
// E.g.: TypeError: Cannot read property 'startsWith' of undefined
//     at startsWith (https://d207yhv6tyrx46.cloudfront.net/packs/js/application-eef71b992a9726a71f00.js:2:31)
// returns -> 'CannotReadPropertyStartsWithOfUndefined:2:31 - TypeError'
//
// The error name can't be used because it's not readable in production due to minification and compression
export const appSignalErrorName = (error) => {
  if (!error) {
    return null
  }

  // the error stack is a string with line breakers so we split by \n
  const backtrace = (error?.stack || '').split('\n')

  // the second line [1] contains the file:line:column it happened
  const errorLocationLine = (backtrace[1] || '')

  // converting 'Cannot read property 'startsWith' of undefined' into 'CannotReadPropertyStartsWithOfUndefined'
  const errorMessageCamelized = camelCase(error.message || backtrace[0])

  // Extracting 'application-eef71b992a9726a71f00.js:2:31' from '... (https://d207yhv6tyrx46.cloudfront.net/packs/js/application-eef71b992a9726a71f00.js:2:31)'
  const errorFileLineColumn = (errorLocationLine.split('/').slice(-1)[0] || '').replace(')', '')

  const errorLineColumn = errorFileLineColumn.split('.js')[1]

  // If both don't exist it's just noise
  if (!errorMessageCamelized && !errorLineColumn) {
    return null
  }

  return `${errorMessageCamelized}${errorLineColumn}${error?.name ? ` - ${error?.name}` : ''}`
}

export const appSignalTags = () => {
  const { id: userId = '', username = '' } = denormalizedCurrentUser || {}
  const { origin: domain } = window.location

  const subdomain = (domain.split('//')[1] || '').split('.')[0]

  return {
    userId,
    username,
    subdomain,
  }
}

// ignore errors constructed inside Axios https://github.com/axios/axios/blob/d99d5faac29899eba68ce671e6b3cbc9832e9ad8/lib/core/settle.js#L18
// they happened on the backend, hence they should have been reported by the backend instead
export const ignoreClearyBackendError = (error) => {
  const url = error.request?.responseURL
  const requestId = error.response?.headers['x-request-id']

  return error.isAxiosError && isClearyUrl(url) && !!requestId
}

const errorSender = {
  sendErrorUnlessClearyBackendError: (error, params = {}) => {
    if (ignoreClearyBackendError(error)) {
      return
    }

    const errorName = appSignalErrorName(error)

    if (errorName) {
      const span = appSignal.createSpan()
      span.setTags(appSignalTags())
      span.setError({
        name: appSignalErrorName(error),
        message: error.message,
        stack: error.stack,
      })
      span.setParams({
        ...params,
        url: window.location.href,
        requestURL: error.request?.responseURL,
      })
      appSignal.send(span)
    }
  },
}

export default errorSender
