import React, { useMemo, useState } from 'react'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { useDispatch } from 'react-redux'

import settingSlice from 'redux/slices/settings'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import currentUserSlice from 'redux/slices/currentUser'
import classNames from 'classnames'
import ResponsivePopover from 'components/common/responsivePopover'
import { useSlideShow } from 'pages/celebrations/slide_show_page/useSlideShow'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import API from 'services/api'
import LoadingSpinner from 'components/common/loadingSpinner'

const REACTIONS_PREFERRED_SKIN_TONE = 'reactions.preferred_skin_tone'

interface EmojiPickerProps {
  className?: string
  onEmojiSelect: (emoji: any) => void
  trigger: any
  triggerClassName?: string
  disablePicker?: boolean
  disableCustomReactions?: boolean
}

const EmojiPicker = ({
  className,
  onEmojiSelect,
  trigger,
  triggerClassName,
  disablePicker = false,
  disableCustomReactions = false,
}: EmojiPickerProps) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const [isPickerExpanded, setIsPickerExpanded] = useState(false)
  const {
    data: customReactionsByWorkspace,
    isLoading,
  } = useGlobalStateBucket(API.customReactions.fetchAll, 'customReactionsByWorkspace', isPickerExpanded)
  const [popoverTarget, setPopoverTarget] = useState<any>(null)
  const slideShow = useSlideShow()

  // We need to clone deep because the emoji picker requires the custom prop to be extensible
  // in order to add the ability to search for custom emojis.
  const customReactions = useMemo(() => (
    disableCustomReactions ? [] : _.cloneDeep(customReactionsByWorkspace)
  ), [customReactionsByWorkspace])

  const togglePicker = (e?: any) => {
    if (disablePicker) return

    e?.stopPropagation()

    if (!isPickerExpanded) {
      slideShow?.pause()
    } else {
      slideShow?.resume()
    }

    setIsPickerExpanded(!isPickerExpanded)
  }

  const handleSelect = (emoji) => {
    onEmojiSelect(emoji)
    setIsPickerExpanded(false)
    slideShow?.resume()

    // We update the current user preferred skin tone if the user has selected a different skin tone.
    // This way we will always have the new updated value.
    if (emoji.skin && emoji.skin !== currentUser.preferredSkinTone) {
      dispatch(settingSlice.asyncActions.update(REACTIONS_PREFERRED_SKIN_TONE, { tone: emoji.skin }))
      dispatch(currentUserSlice.actions.updateCurrentUser({ ...currentUser, preferredSkinTone: emoji.skin }))
    }
  }


  return (
    <div className={classNames('EmojiPicker', className)} ref={popoverTarget => setPopoverTarget(popoverTarget)}>
      <div onClick={togglePicker} className={classNames('EmojiPicker__trigger pointer', triggerClassName)}>
        {trigger}
      </div>

      <ResponsivePopover
        isOpen={isPickerExpanded}
        toggle={togglePicker}
        target={popoverTarget}
      >
        {isLoading ? <LoadingSpinner className='pl-4 pb-3' /> : (
          <Picker
            onEmojiSelect={handleSelect}
            data={data}
            theme='light'
            onClickOutside={() => setIsPickerExpanded(false)}
            custom={customReactions}
          />
        )}
      </ResponsivePopover>
    </div>
  )
}

export default EmojiPicker
