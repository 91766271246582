import Link from '@tiptap/extension-link'

export const OPEN_LINK_DROPDOWN_EVENT = 'open_link_dropdown_event'

const dispatchOpenLinkDropdownEvent = (editorUniqueId: string) => {
  const event = new CustomEvent(OPEN_LINK_DROPDOWN_EVENT, { detail: { editorUniqueId } })
  document.dispatchEvent(event)
}

const ClearyLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      'data-file-id': {
        default: null,
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-k': ({ editor }) => {
        const editorUniqueId = editor.view.dom.getAttribute('editorUniqueId') || ''
        dispatchOpenLinkDropdownEvent(editorUniqueId)
        return true
      },
    }
  },

  inclusive: false,
})

export default ClearyLink
