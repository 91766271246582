import React, { useEffect, useState } from 'react'
import usePrevious from 'components/common/hooks/usePrevious'

export const EDIT_VIEW = 'edit'
export const SOURCE_CODE_VIEW = 'source_code'

const TiptapEditorSourceCodeView = ({
  initialCode,
  onCodeChange,
  currentView,
}) => {
  const [htmlCode, setHtmlCode] = useState(initialCode)
  const previousView = usePrevious(currentView)

  useEffect(() => {
    if (currentView === EDIT_VIEW && previousView === SOURCE_CODE_VIEW) {
      onCodeChange(htmlCode)
    } else if (currentView === SOURCE_CODE_VIEW && previousView === EDIT_VIEW) {
      // We add a newline after every closing tag to make the source code view more readable
      const prettifiedHtml = initialCode.replace(/(>)(<)(?!\/)/g, '$1\n$2')
      setHtmlCode(prettifiedHtml)
    }
  }, [currentView])

  if (currentView !== SOURCE_CODE_VIEW) {
    return null
  }

  return (
    <textarea
      value={htmlCode}
      className='TiptapEditorSourceCodeView w-100 monospaced'
      onChange={e => setHtmlCode(e.target.value)}
    />
  )
}

export default TiptapEditorSourceCodeView
