import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import classNames from 'classnames'

const I18N = i18nPath('views.common')

const EmployeeMultiLinkWithPopover = ({
  children,
  users,
  id,
  history = undefined,
  employeesNotDisplayed = 0,
  active = true,
  scrollable = false,
  placement = 'bottom-start',
  linkTo = 'profile',
  onClick = () => null,
}) => (
  <HoverAndClickPopover target={children} id={id} placement={placement} popoverClassName='EmployeePopover customPopover' onClick={onClick}>
    {active && (
      <>
        <div className={classNames('EmployeeMiniCardContainer', scrollable && users?.length > 4 ? 'ScrollablePopoverlist' : '')}>
          {users?.map((user, ind) => (
            <EmployeeMiniCardLink
              key={`multi-user-popover-user-${user.id}-${ind}`}
              employee={user}
              history={history}
              link={linkTo === 'journeys' ? `/admin/users/${user.id}/journeys` : `/profile/${user.username}`}
            />
          ))}
        </div>
        {employeesNotDisplayed > 0 && users.length > 0 && (
          <div className='text-small text-secondary d-flex flex-inline ml-2'>
            {I18N('other', { count: employeesNotDisplayed })}
          </div>
        )}
      </>
    )}

  </HoverAndClickPopover>
)

export default EmployeeMultiLinkWithPopover
