import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { i18nPath } from 'utils/i18nHelpers'
import ToolbarDropdownTabs from 'components/common/tiptap/toolbar/items/toolbarDropdownTabs'
import { ButtonSmallNarrow } from 'components/common/buttons'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import { EMBED_VALID_FILE_EXTENSIONS } from 'components/common/tiptap/extensions/embed'
import VideoUpload from 'components/common/tiptap/toolbar/items/videoUpload'
import saveAttachmentFromFile from 'components/common/tiptap/utils/saveAttachmentFromFile'
import InsertZoomVideoForm from 'components/common/tiptap/toolbar/items/insertZoomVideoForm'

const I18N = i18nPath('views.tiptap.toolbar')

const insertEmbed = (editor, attrs, closeDropdown) => {
  const nodeName = editor.isActive('blockEmbed') ? 'blockEmbed' : 'inlineEmbed'
  const { width, height } = editor.getAttributes(nodeName)

  const attributes: any = {
    width,
    height,
    ...attrs,
  }

  if (nodeName === 'blockEmbed') {
    editor.chain().focus().setBlockEmbed(attributes).run()
  } else {
    editor.chain().focus().setInlineEmbed(attributes).run()
  }

  closeDropdown()
}

interface EmbedFormProps {
  editor: any
  closeDropdown: () => void
  isEmbedCode?: boolean
}

const EmbedForm = ({
  editor, closeDropdown, isEmbedCode = false,
}: EmbedFormProps) => {
  const [value, setValue] = useState('')
  const attrs = isEmbedCode ? { code: value } : { src: value }

  const handleSubmit = (e) => {
    e.preventDefault()

    insertEmbed(editor, attrs, closeDropdown)
  }

  return (
    <form className='d-flex flex-column align-items-end' onSubmit={handleSubmit}>
      {isEmbedCode ? (
        <textarea
          className='w-100 mb-1'
          value={value}
          cols={6}
          onChange={e => setValue(e.target.value)}
          placeholder={I18N('embed_code')}
          autoFocus
        />
      ) : (
        <input
          className='w-100 mb-1'
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={I18N('insert_url')}
          autoFocus
        />
      )}
      <div className='text-small text-secondary mb-2'>{I18N('embed_warning')}</div>
      <ButtonSmallNarrow
        type='submit'
      >
        {I18N('insert')}
      </ButtonSmallNarrow>
    </form>
  )
}

const EmbedFileUpload = ({ editor, closeDropdown }) => {
  const [isLoading, setIsLoading] = useState(false)
  const richTextId = editor?.options?.editorProps?.attributes?.richTextId

  const onDrop = async (files) => {
    setIsLoading(true)

    const file = files[0]

    const insertFileAndClose = (fileUrl, fileId) => {
      setIsLoading(false)
      insertEmbed(
        editor,
        {
          'src': fileUrl,
          'data-file-id': fileId,
          'width': '9999px', // iframes have a max width of 100%
          'height': '1000px',
        },
        closeDropdown
      )
    }

    saveAttachmentFromFile(file, richTextId, insertFileAndClose)
  }

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <FileSelectDropzone
      disabled={isLoading}
      onDrop={onDrop}
      maxSizeInMB={50}
      accept={EMBED_VALID_FILE_EXTENSIONS}
    >
      <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
        <div className='font-weight-700'>{I18N('drop_file')}</div>
        <div className='text-secondary'>{I18N('or_click')}</div>
      </div>
    </FileSelectDropzone>
  )
}

export const EmbedDropdownContent = ({
  editor,
  onCloseDropdown,
  embedVideoOnly = false,
}) => {
  const tabs = [
    {
      buttonContent: <CdnSvg src='/images/tiptap/link.svg' />,
      tooltipText: I18N('by_url_tooltip'),
      content: <EmbedForm editor={editor} closeDropdown={onCloseDropdown} />,
    },
    {
      buttonContent: <CdnSvg src='/images/tiptap/code-brackets.svg' />,
      tooltipText: I18N('embed_code'),
      content: (
        <EmbedForm
          editor={editor}
          closeDropdown={onCloseDropdown}
          isEmbedCode
        />
      ),
    },
    embedVideoOnly && editor.options.editorProps.attributes.videosEnabled && {
      buttonContent: <CdnSvg src='/images/tiptap/upload.svg' />,
      tooltipText: I18N('upload_file_tooltip'),
      content: <VideoUpload editor={editor} closeDropdown={onCloseDropdown} />,
    },
    !embedVideoOnly && {
      buttonContent: <CdnSvg src='/images/tiptap/upload.svg' />,
      tooltipText: I18N('upload_file_tooltip'),
      content: <EmbedFileUpload editor={editor} closeDropdown={onCloseDropdown} />,
    },
    embedVideoOnly && editor.options.editorProps.attributes.isZoomEnabled && {
      buttonContent: <CdnSvg className='ZoomTextIcon' src='/images/zoomTextIcon.svg' />,
      tooltipText: I18N('zoom_tooltip'),
      content: <InsertZoomVideoForm editor={editor} closeDropdown={onCloseDropdown} />,
    },
  ].filter(Boolean) as any[]

  return <ToolbarDropdownTabs tabs={tabs} />
}

const EmbedDropdownButton = ({
  editor,
  isDisabled = false,
  iconPath = '/images/tiptap/share.svg',
  tooltipText = I18N('embed_tooltip'),
  embedVideoOnly = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <ToolbarDropdownButton
      tooltipText={tooltipText}
      className='TiptapEmbedDropdownButton'
      dropdownContent={(
        <EmbedDropdownContent
          editor={editor}
          onCloseDropdown={() => setIsDropdownOpen(false)}
          embedVideoOnly={embedVideoOnly}
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src={iconPath} />
    </ToolbarDropdownButton>
  )
}

export const VideoEmbedDropdownButton = ({ editor, isDisabled = false }) => (
  <EmbedDropdownButton
    editor={editor}
    isDisabled={isDisabled}
    iconPath='/images/tiptap/video.svg'
    tooltipText={I18N('embed_video_tooltip')}
    embedVideoOnly
  />
)

export default EmbedDropdownButton
