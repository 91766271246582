import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import JourneyType from 'types/journeys/journey'
import snakeCaseKeysObjectsOnly from 'utils/snakeCaseKeysObjectsOnly'

export const buildJourneyPayload = (journey: JourneyType) => (
  _.pick(journey, [
    'id',
    'journeyBlueprintId',
    'userId',
    'state',
  ])
)

const journeys = _.merge(
  generateCrudRoutes('/api/admin/journey/journeys', 'journey', {}, buildJourneyPayload), {
    fetchAll(params) {
      const request = {
        params,
      }
      return Axios.get('/api/admin/journey/journeys', snakeCaseKeysObjectsOnly(request))
    },

    batchCreate(params) {
      return Axios.post('/api/admin/journey/journeys/batch_create', params)
    },
  }
)

export default journeys
