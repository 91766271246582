const TABLE_REGEX = /(<table\b[^>]*)>/gi
const BR_REGEX = /<br\s*\/?>/gi
const USERLINK_REGEX = /<a [^>]*id="([^"]+)"[^>]+class="UserLink"[^>]*><span[^>]+>([^<]+)<\/span><\/a>/gi


const transformPastedHtml = (html: string) => {
  // Add class to table, if other classes are present prepend the pasted-table class
  let newHtml = html.replace(TABLE_REGEX, (match, p1) => {
    if (p1.includes('class="')) {
      return match.replace('class="', 'class="pasted-table ')
    } else {
      return `${p1} class="pasted-table">`
    }
  })

  // Replace <br />, <br>, <br /> with <p />, if not Tiptap adds wraps the <br> in a <p> resulting in a double line break
  newHtml = newHtml.replace(BR_REGEX, '<p />')

  // When we paste a mention it pastes as an <a> tag and we need to convert it to our mention format
  newHtml = newHtml.replace(USERLINK_REGEX, (match, aTagId, userName) => {
    // The id has this format: {`${containerId}-popover-user-${mentionedUser.id}-${i}`}
    // ex: page-53-content-rich-text-view-popover-user-15-0
    // So the user id is the second to last part of the id
    const idParts = aTagId.split('-')
    const userId = idParts.length > 2 ? idParts[idParts.length - 2] : null

    if (!userId) {
      return match // if no userId return the original match
    }

    return `<span data-type="mention" class="link-color" data-id="${userId}" data-label="${userName}">@${userName}</span>`
  })

  // Replace all ai-text-generation nodes (class="ai-text-generation") with ai prompt nodes (class="ai-prompt")
  newHtml = newHtml.replace(/class="ai-text-generation"/gi, 'class="ai-prompt"')

  // Replace &nbsp; with a regular space, it does not cause issues in the editor, but it does in the notifications
  newHtml = newHtml.replace(/&nbsp;/g, ' ')

  return newHtml
}

export default transformPastedHtml
