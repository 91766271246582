import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const SocialShareButton = ({
  editor,
  isDisabled = false,
}) => (
  <ToolbarButton
    className='SocialShareButton d-flex align-items-center justify-content-center'
    tooltipText={I18N('social_share_tooltip')}
    onClick={() => editor.chain().focus().insertContent({ type: 'socialShare', attrs: { editMode: true } })
.run()}
    isDisabled={isDisabled}
  >
    <div className='icon-container w-100'>
      <CdnSvg src='/images/tiptap/socialShare.svg' />
    </div>
  </ToolbarButton>
)

export default SocialShareButton
