import React from 'react'
import UserLink from 'components/common/userLink'
import EmployeeMultiLinkWithPopover from 'components/common/employeeMultiLinkWithPopover'
import { I18NCommon } from 'utils/i18nHelpers'
import classNames from 'classnames'

const EmployeeLinkWithPopover = ({
  user = {},
  id = '',
  className = '',
  hideImage = false,
  hideName = false,
  showLinkColor = false,
  userLinkClassName = '',
  unknownUserText = I18NCommon('unknown_user'),
}) => {
  // If only id and type are present, it could be that the user was deleted
  // Or that we are still fetching the user information and we only have the relationship from other entity
  const isUserPresent = !_.isEmpty(_.omit(user, ['id', 'type']))

  if (!isUserPresent) {
    return (
      <span className={className}>
        {unknownUserText}
      </span>
    )
  }

  if (!user.active) {
    return (
      <span className={classNames('text-small text-primary', className)}>
        {user?.preferredFullName}
      </span>
    )
  }

  const targetId = id || `profile-link-${user.id}`

  return (
    <span className={className}>
      <EmployeeMultiLinkWithPopover users={[user]}>
        <UserLink
          user={user}
          targetId={targetId}
          hideImage={hideImage}
          hideName={hideName}
          showLinkColor={showLinkColor}
          customClassName={userLinkClassName}
        />
      </EmployeeMultiLinkWithPopover>
    </span>
  )
}

export default EmployeeLinkWithPopover
