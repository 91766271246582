import React from 'react'
import { Link } from 'react-router-dom'
import { I18NCommon } from 'utils/i18nHelpers'

import classNames from 'classnames'

import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'

const UserLink = (props) => {
  // by default images will show unless hideImage is passed in
  const {
    user,
    targetId,
    hideImage,
    hideName = false,
    showLinkColor,
    customClassName,
    avatarSize = '22px',
    unknownUserText = I18NCommon('unknown_user'),
  } = props

  const className = customClassName || classNames(
    { 'text-small': !hideImage },
    showLinkColor || hideImage ? 'link-color' : 'text-primary'
  )

  // If the link is inside a component that has an onClick handler, we need to stop propagation
  // so that the link doesn't trigger the onClick handler of the parent component
  const onClick = (e) => {
    e.stopPropagation()
  }

  const isUserPresent = !_.isEmpty(_.omit(user, ['id', 'type']))

  if (!isUserPresent) {
    return (
      <span className={className}>
        {unknownUserText}
      </span>
    )
  }

  return (
    <>
      {!hideImage && <EmployeeThumbnailPhoto employee={user} size={avatarSize} className='mr-1' />}
      {!hideName && (
        <Link to={`/profile/${user.username}`} id={targetId} className='UserLink' onClick={onClick}>
          <span
            className={className}
          >
            {user.preferredFullName}
          </span>
        </Link>
      )}
    </>
  )
}

export default UserLink
