import React from 'react'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { findListType } from './utils'

const I18N = i18nPath('views.tiptap.toolbar')

const DEFAULT_FONT_SIZE = '15'

const SIZE_OPTIONS = [
  8, 9, 10, 11, 12, 13, 14, 15, 18, 21, 24, 30, 36, 48, 60, 72, 96,
]

// returns a string to 3 decimal places
function remToPx(rem) {
  return (rem * parseFloat(DEFAULT_FONT_SIZE)).toFixed(1)
}

function ptToPx(pt) {
  return ((pt * 4) / 3).toFixed(1)
}

const FontSizeSelect = ({ editor, isDisabled = false }) => {
  const { customStyles } = useCurrentCompany()
  const { fontSize } = editor.getAttributes('textStyle')

  let size = fontSize
  if (fontSize?.includes('rem')) {
    size = remToPx(parseFloat(fontSize))
  } else if (fontSize?.includes('pt')) {
    size = ptToPx(parseFloat(fontSize))
  }

  if (!size) {
    const { level } = editor.getAttributes('heading')
    if (level) {
      size = customStyles?.variables?.fonts?.[`h${level}FontSize`]?.replace('px', '')
    } else {
      size = DEFAULT_FONT_SIZE
    }
  }

  const options = [...new Set([...SIZE_OPTIONS, parseFloat(size)])].filter(Boolean).sort((a, b) => a - b).map(String)

  const handleOnClick = (fontSize) => {
    editor.chain().focus().setFontSize(fontSize).run()

    const listType = findListType(editor)

    if (listType) {
      editor.chain().focus().setMarkerSize(fontSize, listType).run()
    }
  }

  return (
    <ToolbarSelect
      className='FontSizeSelect'
      isDisabled={isDisabled}
      options={options.map(fontSize => ({
        value: fontSize,
        label: fontSize,
        onClick: () => handleOnClick(fontSize),
        isActive: parseFloat(fontSize) === parseFloat(size),
      }))}
      tooltipText={I18N('font_size_tooltip')}
    />
  )
}

export default FontSizeSelect
