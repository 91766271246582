import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

// Parse ai-block-mention nodes. Convert them to "@type(id)"
const parsePromptTextAndRecordMentions = (prompt) => {
  // Replace ai-block-mention nodes with "@type(id)"
  let parsedPrompt = prompt.replace(/<span id="([a-zA-Z0-9_]+)" name="(.+?)" type="(.+?)" class="ai-prompt-record-mention"><\/span>/g, (match, id, name, type) => ` @${type}(${id})`)

  // Remove preceding and trailing <p> tags
  parsedPrompt = parsedPrompt.replace(/^<p>/, '').replace(/<\/p>$/, '')

  return parsedPrompt
}

const useAiGenerateText = ({
  onCreateSucess = (id) => {},
  prompt = '',
  extraParams = {},
}) => {
  const [createTextGeneration, { isLoading: isSaving }] = useApi(API.ai.textGenerations.create)

  const generateText = () => {
    const payload = {
      prompt: parsePromptTextAndRecordMentions(prompt),
      ...extraParams,
    }

    createTextGeneration(payload).then((data) => {
      if (data?.id) {
        onCreateSucess(data.id)
      }
    })
  }

  return {
    generateText,
    isSaving,
  }
}

export default useAiGenerateText
