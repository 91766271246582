import React from 'react'
import classNames from 'classnames'

interface LabeledFormFieldContainerProps {
  label: any
  isRequired?: boolean
  footNote?: string
  className?: string
  children?: any
  // The form-group class messes with the tiptap editor icons in the toolbar so we absolutely need to remove it.
  // Unfortunately it is needed for many other existing form fields so we need to add a prop to conditionally remove it.
  hasTiptapEditor?: boolean
}

const LabeledFormFieldContainer = ({
  label,
  isRequired,
  footNote,
  className = '',
  children,
  hasTiptapEditor = false,
}: LabeledFormFieldContainerProps) => {
  const isStringLabel = typeof label === 'string'

  return (
    <div className={classNames('LabeledFormFieldContainer', { 'form-group': !hasTiptapEditor }, className)}>
      {isStringLabel ? <label className={classNames(isRequired && 'required-form-label')}>{label}</label> : label}
      {children}
      {footNote && <small className='form-text text-secondary'>{footNote}</small>}
    </div>
  )
}


export default LabeledFormFieldContainer
