import React, { useState } from 'react'
import { BubbleMenu, posToDOMRect } from '@tiptap/react'
import CdnSvg from 'components/common/cdnSvg'
import RichTextInlineCommentEditor from 'components/common/richtTextInlineComments/richTextInlineCommentEditor'

const AddCommentCircle = ({ setShowCommentEditor }) => (
  <div className='AddCommentCircle pointer' onClick={() => setShowCommentEditor(true)}>
    <CdnSvg src='/images/commentIcon.svg' className='CommentIcon' />
  </div>
)

const AddCommentBubbleMenu = ({ editor, richTextId }) => {
  const editorContainer = document.querySelector('.TiptapEditor')
  const containerRight = editorContainer?.getBoundingClientRect().right || 0
  const [showCommentEditor, setShowCommentEditor] = useState(false)
  const [referenceText, setReferenceText] = useState('')

  const getReferenceClientRect = () => {
    // Keep the y axis position of the menu, but calculate the x axis position as the end of the editor to the right
    const { from, to } = editor.state.selection

    const width = showCommentEditor ? 300 : 40

    const newDomRect = {
      ...posToDOMRect(editor.view, from, to) || {},
      left: containerRight,
      width,
    }

    return newDomRect
  }

  const showBubbleMenu = ({ editor }) => {
    if (editor.isActive('inlineComment')) {
      return false
    }

    // Get the text selection
    const { from, to } = editor.state.selection
    const fragment = editor.state.doc.content.cut(from, to)
    const texts: any = []
    fragment.forEach((node) => {
      if (!_.isEmpty(node.textContent)) {
        texts.push(node.textContent)
      }
    })

    const newReferenceText = texts.join(' ') || ''
    setReferenceText(newReferenceText)

    const willShow = from !== to && !_.isEmpty(newReferenceText)

    if (!willShow) {
      setShowCommentEditor(false)
    }

    return willShow
  }

  const onSendCommentSuccess = ({ id }) => {
    // eslint-disable-next-line no-unused-expressions
    editor?.chain().setInlineComment(id).run()

    setShowCommentEditor(false)
  }

  return (
    <BubbleMenu
      className='AddCommentBubbleMenu'
      editor={editor}
      tippyOptions={{
        duration: 0,
        interactive: true,
        placement: 'left',
        offset: [0, 0],
        getReferenceClientRect,
      }}
      shouldShow={showBubbleMenu}
    >
      {!showCommentEditor ? (
        <AddCommentCircle setShowCommentEditor={setShowCommentEditor} />
      ) : (
        <div className='comment-editor-container p-3'>
          <RichTextInlineCommentEditor
            richTextId={richTextId}
            referenceText={referenceText}
            onCreateSuccess={onSendCommentSuccess}
          />
        </div>
      )}
    </BubbleMenu>
  )
}

export default AddCommentBubbleMenu
