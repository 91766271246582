import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'

const AppModal = ({
  children,
  className = '',
  closeButton = true,
  fullscreen = false,
  size = 'md',
  title = '',
  toggle = () => {},
  visible = false,
  modalTitleClassName = 'h4',
  modalContentClassName = '',
}) => {
  let modalContent = (
    <>
      <Modal.Header closeButton={closeButton}>
        {title && <Modal.Title className={classNames('modal-title', modalTitleClassName)}>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </>
  )

  if (modalContentClassName) {
    // Allows us to wrap modal content and adjust whitespace
    modalContent = <div className={modalContentClassName}>{modalContent}</div>
  }

  return (
    ReactDOM.createPortal(
      <Modal
        size={size}
        dialogClassName={classNames(
          'd-flex justify-content-center AppModal',
          { 'cleary-modal-md': size === 'md', 'cleary-modal-fullscreen': fullscreen === true },
          className
        )}
        show={visible}
        onHide={toggle}
      >
        {modalContent}
      </Modal>,
      document.body
    )
  )
}

export default AppModal
