import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import IframeResizer from 'components/common/tiptap/extensions/nodeViews/iframeResizer'
import getEmbedUrl from 'components/common/tiptap/extensions/utils/getEmbedUrl'
import styleAttribute from 'components/common/tiptap/extensions/utils/styleAttribute'

export const EMBED_VALID_FILE_EXTENSIONS = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']
export const EMBED_VALID_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]
export const STYLE_ATTRIBUTES = ['textAlign', 'floatLeft', 'width', 'height']

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embed: {
      setEmbed: (options: { src?: string, code?: string }) => ReturnType,
    }
  }
}

const insertEmbed = (options, type) => ({ commands }) => {
  if (options.code) {
    return commands.insertContent(options.code)
  } else {
    const embedUrl = getEmbedUrl(options.src)

    return commands.insertContent({
      type,
      attrs: {
        ...options,
        src: embedUrl,
      },
    })
  }
}

const DEFAULT_WIDTH = 600
const DEFAULT_HEIGHT = 400

const Embed = Node.create({
  name: 'embed',

  group: 'block',

  draggable: true,

  addAttributes() {
    return {
      'src': {
        default: null,
      },
      'width': styleAttribute('width', `${DEFAULT_WIDTH}px`),
      'height': styleAttribute('height', `${DEFAULT_HEIGHT}px`),
      'float': styleAttribute('float'),
      'display': {
        default: null,
      },
      'data-file-id': {
        default: null,
      },

      'allowfullscreen': {
        default: true,
      },
    }
  },

  parseHTML() {
    return [{
      tag: 'iframe',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['iframe', mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframeResizer)
  },
})

export const ClearyBlockEmbed = Embed.extend({
  name: 'blockEmbed',
  group: 'block',
  inline: false,

  addAttributes() {
    return {
      ...this.parent?.(),

      display: {
        default: 'block',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
        getAttrs: (node: any) => node.attributes.display?.value === 'block' && null, // ProseMirror expects null when the check is successful
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    // we need to pass the textAlign property to the div tag otherwise the embed won't align as expected
    const match = HTMLAttributes.style?.match(/text-align:\s*(.*?)(?:;|$)/)
    const divStyle = match ? match[0] : ''
    return [
      'div',
      { style: divStyle },
      ['iframe', HTMLAttributes],
    ]
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setBlockEmbed: options => ({ commands }) => insertEmbed(options, this.name)({ commands }),
    }
  },
})

export const ClearyInlineEmbed = Embed.extend({
  name: 'inlineEmbed',
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      ...this.parent?.(),

      display: {
        default: 'inline',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
        getAttrs: (node: any) => node.attributes.display?.value !== 'block' && null, // ProseMirror expects null when the check is successful
      },
    ]
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setInlineEmbed: options => ({ commands }) => insertEmbed(options, this.name)({ commands }),
    }
  },
})

export default Embed
