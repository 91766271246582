import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'
import BackgroundColorItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/cell_style_item/backgroundColorItem'
import OutlineColorItem from 'components/common/tiptap/menus/context_menu_items/table_menu_items/cell_style_item/outlineColorItem'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const CellStyleItem = ({ editor, onClose }) => (
  <BaseContextMenuItem
    iconPath='/images/tiptap/table-cell-with-star.svg'
    label={I18N('cell_style.label')}
  >
    <BaseContextMenuItem
      label={I18N('cell_style.submenu.thick_border')}
      isActive={
        editor.getAttributes('tableCell').thickBorder
          || editor.getAttributes('tableHeader').thickBorder
      }
      onClick={() => {
        onClose()
        editor.chain().focus().toggleTableCellThickBorder().run()
      }}
    />

    <BackgroundColorItem editor={editor} onClose={onClose} />
    <OutlineColorItem editor={editor} onClose={onClose} />
  </BaseContextMenuItem>
)

export default CellStyleItem

