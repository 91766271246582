import moment from 'moment'
import { systemTypes } from 'pages/people/celebration_editor_page/generateWorkingCopyFromTemplate'
import { i18nDisplayDateWithoutYear, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.invited_people')

const invitationHelperText = (celebration) => {
  const celebratedUserName = celebration.user.preferredFullName
  const celebrationDate = moment(celebration.eventDate)
  const notificationDate = celebrationDate.clone().subtract(7, 'days')
  const notificationDateFormatted = i18nDisplayDateWithoutYear(notificationDate)
  const willBeOrWere = !systemTypes.includes(celebration.type) || moment().isAfter(notificationDate) ? 'were' : 'will_be'
  return I18N(`helper_text.${willBeOrWere}`, { user: celebratedUserName, date: notificationDateFormatted })
}

export default invitationHelperText
