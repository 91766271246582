import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import VideoResizer from 'components/common/tiptap/extensions/nodeViews/videoResizer'
import styleAttribute from 'components/common/tiptap/extensions/utils/styleAttribute'

export const STYLE_ATTRIBUTES = ['textAlign', 'floatLeft', 'width', 'height']

const insertVideo = (attrs, type) => ({ commands }) => commands.insertContent({
  type,
  attrs,
})

const Video = Node.create({
  name: 'video',

  group: 'block',

  draggable: true,

  addAttributes() {
    return {
      'src': {
        default: null,
      },
      'width': {
        default: '100%',
        renderHTML: attributes => ({
          style: `width: ${attributes.width}`,
        }),
        parseHTML: element => element.style.width,
      },
      'height': styleAttribute('height', '100%'),
      'float': styleAttribute('float'),
      'display': {
        default: null,
      },
      'data-video-id': {
        default: null,
      },
      'controls': {
        default: true,
      },
      'autoplay': {
        default: false,
      },
      'textAlign': styleAttribute('textAlign'),
    }
  },

  parseHTML() {
    return [{
      tag: 'video',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['video', mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoResizer)
  },
})


export const ClearyBlockVideo = Video.extend({
  name: 'blockVideo',
  group: 'block',
  inline: false,

  addAttributes() {
    return {
      ...this.parent?.(),

      display: {
        default: 'block',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'video',
        getAttrs: (node: any) => node.attributes.display?.value === 'block' && null, // ProseMirror expects null when the check is successful
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    // we need to pass the textAlign property to the div tag otherwise the video won't align as expected
    const match = HTMLAttributes.style?.match(/text-align:\s*(.*?)(?:;|$)/)
    const divStyle = match ? match[0] : ''
    return [
      'div',
      { style: divStyle },
      ['video', HTMLAttributes],
    ]
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setBlockVideo: options => ({ commands }) => insertVideo(options, this.name)({ commands }),
    }
  },
})

export const ClearyInlineVideo = Video.extend({
  name: 'inlineVideo',
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      ...this.parent?.(),

      display: {
        default: 'inline',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'video',
        getAttrs: (node: any) => node.attributes.display?.value !== 'block' && null, // ProseMirror expects null when the check is successful
      },
    ]
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setInlineVideo: options => ({ commands }) => insertVideo(options, this.name)({ commands }),
    }
  },
})

export default Video
