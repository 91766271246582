import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import React, { useState } from 'react'

interface Props {
  tabs: {
    buttonContent: any
    tooltipText: string
    content: any
  }[]
}

const ToolbarDropdownTabs = ({ tabs }: Props) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <>
      <div className='d-flex align-items-center mb-2'>
        {tabs.map((tab, index) => (
          <ToolbarButton
            key={index}
            onClick={() => setSelectedTab(tab)}
            isActive={selectedTab === tab}
            tooltipText={tab.tooltipText}
            className='mr-1'
          >
            {tab.buttonContent}
          </ToolbarButton>
        ))}
      </div>
      {selectedTab.content}
    </>
  )
}

export default ToolbarDropdownTabs
