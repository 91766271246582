import { Extension } from '@tiptap/core'
import { Suggestion } from 'components/common/tiptap/extensions/suggestion'
import emojiSuggestion from 'components/common/tiptap/suggestions/emojis/emojiSuggestion'
import { PluginKey } from '@tiptap/pm/state'

const onInsertEmoji = ({ editor, range, props }) => {
  const emoji = props.emoji

  if (!emoji) return

  const rangeStart = range.from - 1
  editor.chain().focus().deleteRange({ from: rangeStart, to: range.to }).insertContent(emoji)
        .run()
}

const emojiPluginKey = new PluginKey('emoji-plugin')

const Emoji = Extension.create({
  name: 'emoji',

  addOptions() {
    return {
      suggestion: {
        char: ':',
        pluginKey: emojiPluginKey,
        command: onInsertEmoji,
        ...emojiSuggestion,
      },
    }
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})


export default Emoji
