import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const AiAssistantButton = ({
  editor,
  isDisabled = false,
}) => (
  <ToolbarButton
    className='AiAssistantButton d-flex align-items-center justify-content-center'
    tooltipText={I18N('ai_assistant_tooltip')}
    onClick={() => editor.chain().focus().insertContent({ type: 'aiPrompt' })
.run()}
    isDisabled={isDisabled}
  >
    <div className='icon-container w-100'>
      <CdnSvg src='/images/tiptap/ai.svg' />
    </div>
  </ToolbarButton>
)

export default AiAssistantButton
