import Axios from 'axios'

const promptCollectionsApi = {
  fetchMyPrompts() {
    return Axios.get('/api/ai/chat/my_prompts')
  },
}


export default promptCollectionsApi
