import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const buildJourneyBlueprintPayload = (journeyBlueprint) => {
  const journeyBlueprintAttrs = _.pick(journeyBlueprint, [
    'id',
    'name',
    'state',
    'howUsersAreAdded',
    'automaticallyAddUsersDaysAfterMilestone',
    'journeyAdminId',
    'targetingRules',
    'type',
    'templateId',
    'collaboratorIds',
    'notifyCollaboratorsOnError',
  ])

  if (journeyBlueprintAttrs.targetingRules) {
    journeyBlueprintAttrs.targetingRules = normalizeTargetingRules(journeyBlueprintAttrs.targetingRules)
  }

  return journeyBlueprintAttrs
}

const journeyBlueprints = _.merge(
  generateCrudRoutes('/api/admin/journey/journey_blueprints', 'journey_blueprint', {}, buildJourneyBlueprintPayload), {
    launch(journeyBlueprintId) {
      return Axios.patch(`/api/admin/journey/journey_blueprints/${journeyBlueprintId}/launch`)
    },
    copy(journeyBlueprintId, params) {
      return Axios.post(`/api/admin/journey/journey_blueprints/${journeyBlueprintId}/copy`, params)
    },
    fetchActiveBlueprintsCount() {
      return Axios.get('/api/admin/journey/journey_blueprints/active_count')
    },
    fetchJourneyBlueprintAvailableTemplates() {
      return Axios.get('/api/admin/journey/journey_blueprints/available_templates')
    },
    fetchSimpleJourneys(journeyBlueprintId) {
      return Axios.get(`/api/admin/journey/journey_blueprints/${journeyBlueprintId}/simple_journeys`)
    },
  }
)

export default journeyBlueprints
