import CdnSvg from 'components/common/cdnSvg'
import React, { forwardRef, useEffect, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'
import useActionCable from 'components/common/hooks/useActionCable'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import { useSelector } from 'react-redux'
import videoUploadSlice from 'redux/slices/videoUploads'
import ProgressBar from 'components/common/progressBar'
import classNames from 'classnames'
import useAnalyticsTracking from './useAnalyticsTracking'

const ENCODING = 'encoding'
const UPLOADING = 'uploading'
const FAILED = 'failed'
const FINISHED = 'finished'
const UPLOAD_PROGRESS = 'upload_progress'
const TRANSCODE_PROGRESS = 'transcoding_progress'
const VIDEO_UPDATED = 'video_updated'

const I18N = i18nPath('views.common.video_player')

const videoPath = '/images/tiptap/video.svg'

interface ClearyVideoPlayerProps {
  videoId?: string | null
  src?: string | null
  style?: React.CSSProperties
  autoPlay?: boolean
  controls?: boolean
  isEditing?: boolean
  recordType?: string
  recordId?: string
}

interface PlayerProps {
  video: any
  style?: React.CSSProperties
  autoPlay?: boolean
  controls?: boolean
  onClick?: (e) => any
}

const Player = forwardRef<HTMLVideoElement, PlayerProps>((props, ref) => {
  const {
    video,
    controls = true,
    autoPlay = false,
    style = {},
    onClick = _e => null,
  } = props

  return (
    <video
      id={`video-player-${video?.id}`}
      poster={video.thumbnailUrl}
      playsInline
      controls={controls}
      controlsList='nodownload'
      style={style}
      ref={ref}
      autoPlay={autoPlay}
      muted={autoPlay}
      onClick={onClick}
    >
      {video.src && <source src={video.src} />}
      {video.webmUrl && <source src={video.webmUrl} type='video/webm' />}
      {video.mp4Url && <source src={video.mp4Url} type='video/mp4' />}
      {video.originalUrl && <source src={video.originalUrl} />}
    </video>
  )
})

const EncodingVideoContainer = ({
  video,
  progress = 0,
  isEditing = false,
}) => {
  const state = video?.encodedState
  const encodingFailed = state === FAILED

  if (!isEditing && encodingFailed) {
    return null
  }

  return (
    <div className={classNames('encoding-container d-flex align-items-center justify-content-center py-3 h-100 w-100', { encodingFailed })}>
      <CdnSvg src={videoPath} className='VideoIcon' />
      <div className='w-100'>
        <div className='font-weight-700'>{video?.name}</div>
        <div className={classNames('text-small', encodingFailed && 'text-danger')}>{I18N(`video_processing_${state}`, { progress })}</div>
      </div>
      {[ENCODING, UPLOADING].includes(state) && (
        <ProgressBar
          completedCount={progress}
          totalCount={100}
          className='w-100'
          showProgressInfo={false}
        />
      )}
    </div>
  )
}

const ClearyVideoPlayer = ({
  videoId,
  recordType,
  recordId,
  src,
  style = {},
  autoPlay = true,
  controls = true,
  isEditing = false,
}: ClearyVideoPlayerProps) => {
  const [video, setVideo] = useState<any>(null)

  const [fetchVideo, { isLoading, error }] = useApi(API.videos.fetch, { addEntitySlice: true, updateEntitySlice: true })
  const encodingState = video?.encodedState
  const videoUploadData = useSelector(videoUploadSlice.selectors.getVideoUploadData(video?.id)) as any
  const encodingFinished = encodingState === FINISHED
  const [progress, setProgress] = useState(0)
  const { videoRef } = useAnalyticsTracking(recordType, recordId, videoId, isEditing)

  const onDataReceived = (receivedData) => {
    if (receivedData?.type === VIDEO_UPDATED) {
      const videoAttributes = denormalizedJsonApiResponse(receivedData, 'video')

      setVideo(prevVideo => ({
        ...prevVideo,
        ...videoAttributes,
      }))

      if (videoAttributes?.encodedState === FINISHED) {
        getVideo()
      }
    } else if ([UPLOAD_PROGRESS, TRANSCODE_PROGRESS].includes(receivedData?.type)) {
      setProgress(receivedData?.progress)
      setVideo(prevVideo => ({
        ...prevVideo,
        encodedState: receivedData?.type === UPLOAD_PROGRESS ? UPLOADING : ENCODING,
      }))
    }
  }

  const subscription = useActionCable({
    channel: 'VideoChannel',
    recordId: videoId,
    recordType: 'video',
    isActive: !_.isEmpty(encodingState) && !encodingFinished,
    onDataReceived,
  })

  const getVideo = async () => {
    const params = isEditing ? { includeAllThumbnails: isEditing } : {}
    const newVideo = await fetchVideo(videoId, params)
    setVideo(newVideo)
  }

  useEffect(() => {
    setProgress(0)
  }, [encodingState])

  useEffect(() => {
    if (videoId) {
      getVideo()
    }
  }, [videoId])

  useEffect(() => {
    if (videoUploadData?.progress && progress !== videoUploadData?.progress) {
      setProgress(videoUploadData?.progress)
      subscription?.perform(UPLOAD_PROGRESS, { progress: videoUploadData?.progress })
    }
  }, [JSON.stringify(videoUploadData)])

  if (!videoId && src) {
    return <Player video={{ src }} style={style} controls={controls} autoPlay={autoPlay} />
  }

  if (isLoading || error) {
    return null
  }

  const playerProps: PlayerProps = {
    video,
    style,
    controls,
    autoPlay,
  }

  if (isEditing) {
    playerProps.onClick = e => e.preventDefault()
  }

  return (
    <div className={classNames('ClearyVideoPlayer position-relative', { isEditing })} style={style}>
      {encodingFinished ? (
        <Player {...playerProps} ref={videoRef} />
      ) : (
        <EncodingVideoContainer video={video} progress={progress} isEditing={isEditing} />
      )}
    </div>
  )
}

export default ClearyVideoPlayer
