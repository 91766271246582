import React, { useRef } from 'react'

interface TapAndHoldProps {
  onTapAndHold: () => void
  onRelease?: () => void
  onSimpleTap?: (e) => void
  children: React.ReactNode
}

export const useTapAndHold = (
  onTapAndHold: () => void,
  onRelease: (() => void) | null = null,
  onSimpleTap: ((e) => void) | null = null
) => {
  const timer = useRef<any>()
  const isHeld = useRef<boolean>(false)

  const onTouchStart = () => {
    timer.current = setTimeout(() => {
      isHeld.current = true
      if (onTapAndHold) onTapAndHold()
    }, 300)
  }

  const onTouchEnd = (e) => {
    if (isHeld.current) {
      isHeld.current = false

      if (onRelease) onRelease()
    } else {
      if (onSimpleTap) onSimpleTap(e)
    }

    clearTimeout(timer.current)
  }

  return {
    onTouchStart,
    onTouchEnd,
  }
}

const TapAndHold = ({
  onTapAndHold, onRelease, onSimpleTap, children,
}: TapAndHoldProps) => {
  const handlers = useTapAndHold(onTapAndHold, onRelease, onSimpleTap)

  return (
    <div {...handlers}>
      {children}
    </div>
  )
}

export default TapAndHold
