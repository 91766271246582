// This function will return actions updating the meta property
// of a store for a provided prefix. Makes easy to have default
// actions/reducers inside the same slice.
// If you call buildDefaultMetaActions('group'), it will return a object
// with the following actions:
//
//   {
//     groupIsLoading,
//     groupIsNotFound,
//     groupIsSaving,
//     groupSetError,
//   }
//
// and you can build a state object like this:
//
// const initialState = {
//   ...stateProps,
//   meta: {
//     groupParentOptions: {
//       ...defaultMeta,
//     },
//     groupTypes: {
//       ...defaultMeta,
//     },
//   },
// }
//
// You can create a slice like:
//
// const adminGroupSlice = createSlice({
//   name: 'adminGroups',
//   initialState,
//   reducers: {
//     ...buildDefaultMetaActions('groupParentOptions'),
//     ...buildDefaultMetaActions('groupTypes'),
//   },
// })
//
// Calling adminGroupSlice.actions.groupParentOptionsIsLoading(true) will set the state to:
//   state.meta.groupParentOptions.isLoading = true


export const buildDefaultMetaActions = (prefix) => {
  const isLoading = (state, action) => {
    state.meta[prefix].isLoading = action.payload
  }

  const isNotFound = (state, action) => {
    state.meta[prefix].isNotFound = action.payload
  }

  const isSaving = (state, action) => {
    state.meta[prefix].isSaving = action.payload
  }

  const setError = (state, action) => {
    state.meta[prefix].error = action.payload
  }

  return {
    [`${prefix}IsLoading`]: isLoading,
    [`${prefix}IsNotFound`]: isNotFound,
    [`${prefix}IsSaving`]: isSaving,
    [`${prefix}SetError`]: setError,
  }
}

export const isLoading = (state, action) => {
  state.meta.isLoading = action.payload
}

export const isNotFound = (state, action) => {
  state.meta.isNotFound = action.payload
}

export const isSaving = (state, action) => {
  state.meta.isSaving = action.payload
}

export const setError = (state, action) => {
  state.meta.error = action.payload
}

export const defaultActions = {
  isLoading,
  isNotFound,
  isSaving,
  setError,
}

export const defaultMeta = {
  isNotFound: false,
  isSaving: false,
  error: null,
  isLoading: false,
}
