import React, { useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import CdnSvg from 'components/common/cdnSvg'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { ButtonNarrow } from 'components/common/buttons'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'
import { useDispatch } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import classNames from 'classnames'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.celebrations.say_thanks_modal')

const thankYouPath = '/images/illustrations/thank_you.svg'

const ThanksMessage = ({
  isResend, celebration, isMissingSayThanksUsersLoaded, missingSayThanksUsers, setThanksMessage,
}) => (
  isResend ? (
    <>
      <div className='your-message bordered p-4 mb-2'>
        <TipTapRichTextView html={celebration.thanksMessage} />
      </div>

      <div className='text-helper'>
        {I18N('resend_text_helper')}
        <div className='d-flex flex-wrap gap-2 mt-2'>
          {isMissingSayThanksUsersLoaded && missingSayThanksUsers.map(user => (
            <div key={user.id} className='d-flex gap-2 p-2 bordered user-tag'>
              <EmployeeThumbnailPhoto employee={user} size='1.5rem' />
              <span>{user.preferredFullName}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <TiptapEditor
      html=''
      className='TipTapEditor'
      onChange={setThanksMessage}
      toolbarItems={SMALL_TOOLBAR}
      configuration={{ focusOnInitialize: true, placeholder: I18N('placeholder') }}
      editorContentClassName='p-3'
    />
  )
)

const SayThanksModal = ({
  celebration,
  onClose,
  visible,
  missingSayThanksRecipientIds,
  onThanksSent = (isResend?: boolean) => {},
  className = '',
  thanksSaidContent = <></>,
}) => {
  const dispatch = useDispatch()
  const [thanksMessage, setThanksMessage] = useState('')
  const isResend = !_.isEmpty(celebration.thanksMessage)
  const callSayThanks = () => API.celebrations.sayThanks(celebration.id, thanksMessage)
  const [thanksSaid, setThanksSaid] = useState(false)

  const [sayThanks, { isLoading }] = useApi(callSayThanks, {
    updateEntitySlice: true,
    onSuccess: () => {
      setThanksSaid(true)
      onThanksSent(isResend)
    },
    onError:
      error => dispatch(showToastMessage({ message: I18N(`errors.${error}`), type: 'error' })),
  })

  const thanksMessageForSubmit = isResend ? celebration.thanksMessage : thanksMessage
  const isSubmitDisabled = isLoading || isHtmlStringEmpty(thanksMessageForSubmit)

  const {
    data: missingSayThanksUsers,
    isLoaded: isMissingSayThanksUsersLoaded,
  } = useFetch(API.users.fetchAll, [missingSayThanksRecipientIds], {
    load: !_.isEmpty(missingSayThanksRecipientIds),
  })

  return (
    <Modal
      className={classNames('SayThanksModal', className)}
      size='md'
      visible={visible}
      toggle={onClose}
      modalContentClassName='px-3 confetti-background'
    >
      <div className='position-relative d-flex flex-column align-items-center'>
        <h1 className='px-5 text-center'>
          {I18N('title')}
        </h1>
        <hr className='w-100' />
        <div className='w-100'>
          {!thanksSaid ? (
            <>
              <div className='font-weight-500 mb-2'>
                {isResend ? I18N('your_message') : I18N('label')}
              </div>

              <ThanksMessage
                celebration={celebration}
                isResend={isResend}
                isMissingSayThanksUsersLoaded={isMissingSayThanksUsersLoaded}
                missingSayThanksUsers={missingSayThanksUsers}
                setThanksMessage={setThanksMessage}
              />

              <div className='d-flex mt-3 justify-content-end'>
                <ButtonNarrow
                  onClick={onClose}
                  variant='link'
                  className='text-secondary'
                >
                  {I18NCommon('cancel')}
                </ButtonNarrow>
                <ButtonNarrow
                  disabled={isSubmitDisabled}
                  showLoadingSpinner={isLoading}
                  onClick={sayThanks}
                >
                  {I18N('send')}
                </ButtonNarrow>
              </div>
            </>
          ) : (
            <>
              <p className='text-center p-4'>
                {I18N('thanks_sent')}
              </p>

              {thanksSaidContent}
            </>
          )}
        </div>
        <CdnSvg className='ThankYouImage' src={thankYouPath} />
      </div>
    </Modal>
  )
}

export default SayThanksModal
