export const articlePath = (article) => {
  if (!article || !article.slug) {
    return '#'
  } else if (!article.articleType) {
    return `/news/article_type/${article.slug}`
  } else {
    return `/news/${article.articleType.nameParam}/${article.slug}`
  }
}

export const adminArticlePath = article => `/news/${article.slug}`

export const groupPath = (group, groupType) => `/people/teams/type/${_.toLower(groupType.name)}/team/${group.slug}`

export const userPath = user => (user.username ? `/profile/${user.username.toLowerCase()}` : null)

export const commentablePath = (commentable, type) => {
  switch (type) {
  case 'article':
    return `/api/articles/${commentable.id}/comments`
  case 'answer':
    return `/api/qna/answers/${commentable.id}/comments`
  case 'awarded_badge':
    return `/api/awarded_badges/${commentable.id}/comments`
  case 'celebration':
    return `/api/celebrations/${commentable.id}/comments`
  case 'question':
    return `/api/qna/questions/${commentable.id}/comments`
  case 'shoutout':
    return `/api/shoutouts/${commentable.id}/comments`
  case 'task':
    return `/api/journey/tasks/${commentable.id}/comments`
  case 'introduction':
    return `/api/journey/introductions/${commentable.id}/comments`
  case 'comment':
    return `/api/comments/${commentable.id}/comments`
  default:
    return ''
  }
}

export const invitablePath = (invitable, type) => {
  switch (type) {
  case 'celebration':
    return `/api/celebrations/${invitable.id}/invitations`
  default:
    return ''
  }
}

export const commentPath = (commentable, comment, type) => {
  const path = commentablePath(commentable, type)

  return path.concat(`/${comment.id}`)
}
