import { createSlice } from '@reduxjs/toolkit'
import { ReduxState } from 'redux/redux'
import { SimpleUserType } from 'types/user'
import { HocuspocusProvider } from '@hocuspocus/provider'

export interface CollaborativeEditorState {
  usersCurrentlyEditing: (SimpleUserType & { color: string, clientId: string, lastContributedAt: string })[]
  provider: any
  areChangesPresent: boolean
  editor: any
}

export const initialState: CollaborativeEditorState = {
  usersCurrentlyEditing: [],
  provider: null,
  areChangesPresent: false,
  editor: null,
}

const slice = createSlice({
  name: 'collaborativeEditor',
  initialState,
  reducers: {
    setUsersCurrentlyEditing(state, action) {
      state.usersCurrentlyEditing = action.payload
    },

    setProvider(state, action) {
      state.provider = action.payload
    },

    setAreChangesPresent(state, action) {
      state.areChangesPresent = action.payload
    },

    setEditor(state, action) {
      state.editor = action.payload
    },

    reset() {
      return initialState
    },
  },
})

const asyncActions = {
  applyContent: content => async (_dispatch, getState) => {
    const editor = getState().collaborativeEditor.editor

    if (!editor) { return }

    editor.chain().focus().setContent(content).setTextSelection(0)
.run()
  },

  broadcastRecordChanges: record => async (_dispatch, getState) => {
    const provider = getState().collaborativeEditor.provider as HocuspocusProvider | undefined

    if (!provider) { return }

    const recordId = record?.data?.id
    const recordType = record?.data?.type

    if (!recordId || !recordType) { return }

    const recordAttributes = provider.document.getMap('recordAttributes')
    recordAttributes.set(`${recordType}-${recordId}`, record)
  },
}


const selectors = {
  getUsersCurrentlyEditing: () => (state: ReduxState) => state.collaborativeEditor.usersCurrentlyEditing,

  getAreChangesPresent: () => (state: ReduxState) => state.collaborativeEditor.areChangesPresent,

  getProvider: () => (state: ReduxState) => state.collaborativeEditor.provider,
}

const collaborativeEditorSlice = {
  ...slice,
  selectors,
  asyncActions,
}

export default collaborativeEditorSlice
