import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { i18nPath } from 'utils/i18nHelpers'
import ToolbarDropdownTabs from 'components/common/tiptap/toolbar/items/toolbarDropdownTabs'
import { ButtonSmallNarrow } from 'components/common/buttons'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import saveAttachmentFromFile from 'components/common/tiptap/utils/saveAttachmentFromFile'

const I18N = i18nPath('views.tiptap.toolbar')

const insertFile = (editor: any, link: string, filename: string, fileId?: number) => {
  const fileIdAttr = fileId ? `data-file-id="${fileId}"` : ''
  const content = `<a href="${link}" target="_blank" ${fileIdAttr}>${filename}📎</a>`

  editor.chain().focus().insertContent(content).run()
}

interface InsertFileByUrlProps {
  editor: any
  closeDropdown: () => void
}

const InsertFileByUrl = ({ editor, closeDropdown }: InsertFileByUrlProps) => {
  const [url, setUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const insertFileAndClose = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const splitUrl = url.split('/')
    insertFile(editor, url, splitUrl[splitUrl.length - 1])

    setIsLoading(false)
    closeDropdown()
  }

  return (
    <form className='d-flex flex-column align-items-end' onSubmit={insertFileAndClose}>
      <input
        className='mb-2 w-100'
        value={url}
        onChange={e => setUrl(e.target.value)}
        placeholder={I18N('insert_url')}
        autoFocus
      />
      <ButtonSmallNarrow type='submit' showLoadingSpinner={isLoading} disabled={isLoading}>
        {I18N('insert')}
      </ButtonSmallNarrow>
    </form>
  )
}

interface InsertFileFromUploadProps {
  editor: any
  closeDropdown: () => void
}
const InsertFileFromUpload = ({ editor, closeDropdown }: InsertFileFromUploadProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const richTextId = editor?.options?.editorProps?.attributes?.richTextId

  const onDrop = (files) => {
    setIsLoading(true)

    const file = files[0]
    const insertFileAndClose = (fileUrl, fileId) => {
      insertFile(editor, fileUrl, file.name, fileId)
      setIsLoading(false)
      closeDropdown()
    }

    saveAttachmentFromFile(file, richTextId, insertFileAndClose)
  }

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <FileSelectDropzone
      disabled={isLoading}
      onDrop={onDrop}
      maxSizeInMB={50}
    >
      <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
        <div className='font-weight-700'>{I18N('drop_file')}</div>
        <div className='text-secondary'>{I18N('or_click')}</div>
      </div>
    </FileSelectDropzone>
  )
}

export const InsertFileDropdownContent = ({
  editor,
  closeDropdown,
}) => {
  const tabs = [
    {
      buttonContent: <CdnSvg src='/images/tiptap/upload.svg' />,
      tooltipText: I18N('upload_file_tooltip'),
      content: <InsertFileFromUpload editor={editor} closeDropdown={closeDropdown} />,
    },
    {
      buttonContent: <CdnSvg src='/images/tiptap/link.svg' />,
      tooltipText: I18N('by_url_tooltip'),
      content: <InsertFileByUrl editor={editor} closeDropdown={closeDropdown} />,
    },
  ]

  return (
    <ToolbarDropdownTabs tabs={tabs} />
  )
}

const InsertFileDropdownButton = ({
  editor,
  isDisabled = false,
  tooltipText = I18N('insert_file_tooltip'),
  iconPath = '/images/tiptap/folder.svg',
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <ToolbarDropdownButton
      tooltipText={tooltipText}
      className='TiptapInsertFileDropdownButton'
      dropdownContent={(
        <InsertFileDropdownContent
          editor={editor}
          closeDropdown={() => setIsDropdownOpen(false)}
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src={iconPath} />
    </ToolbarDropdownButton>
  )
}

export default InsertFileDropdownButton
