import { ButtonSmallNarrow } from 'components/common/buttons'
import Gif from 'components/common/tenor/gif'
import GifSearcher from 'components/common/tenor/gifSearcher'
import { GifPickerProps } from 'gif-picker-react'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

interface Props extends Omit<GifPickerProps, 'tenorApiKey'> {
  initialTerm?: string
  gifUrl?: string | null
  setGifUrl: (gifUrl: string | null) => void
}

const GifSelector = ({
  initialTerm, gifUrl, setGifUrl, ...props
}: Props) => {
  if (gifUrl) {
    return (
      <div className='d-flex flex-column align-items-center GifSelector--Wrapper'>
        <Gif gifUrl={gifUrl} />

        <ButtonSmallNarrow
          variant='danger'
          onClick={() => setGifUrl(null)}
          className='mt-2'
        >
          {I18NCommon('remove_gif')}
        </ButtonSmallNarrow>
      </div>
    )
  }

  return (
    <GifSearcher
      initialTerm={initialTerm}
      onGifClick={gif => setGifUrl(gif.url)}
      {...props}
    />
  )
}

export default GifSelector
