import React from 'react'
import Select from 'react-select'
import Async from 'react-select/async'
import RSCreatable from 'react-select/creatable'
import RSAsyncCreatable from 'react-select/async-creatable'
import { I18NCommon } from 'utils/i18nHelpers'

export const CLEARY_REACT_SELECT_CLASS = 'ClearyReactSelect'

export const clearyTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--highlight-color)',
    primary25: 'var(--search-bg-color)',
    neutral20: 'var(--border-color)',
    neutral50: 'var(--text-color-muted)',
  },
})

const ReactSelect = React.forwardRef<any, Record<string, any>>(({
  className,
  classNamePrefix,
  ...props
}, ref) => (
  <Select
    className={className || CLEARY_REACT_SELECT_CLASS}
    ref={ref}
    theme={clearyTheme}
    classNamePrefix={classNamePrefix || CLEARY_REACT_SELECT_CLASS}
    {...props}
  />
))

export default ReactSelect

export const Creatable = React.forwardRef<any, Record<string, any>>(({
  className = '',
  classNamePrefix = undefined,
  ...props
}, ref) => {
  const handleChange = (newValue, actionMeta) => {
    if (typeof newValue?.value === 'string') {
      newValue.value = newValue.value.trim()
    }

    if (props.onChange) {
      props.onChange(newValue, actionMeta)
    }
  }

  return (
    <RSCreatable
      className={className || CLEARY_REACT_SELECT_CLASS}
      classNamePrefix={classNamePrefix || CLEARY_REACT_SELECT_CLASS}
      theme={clearyTheme}
      ref={ref}
      {...props}
      onChange={handleChange}
    />
  )
})

export const AsyncCreatable = ({ className = undefined, classNamePrefix = undefined, ...props }) => (
  <RSAsyncCreatable
    className={className || CLEARY_REACT_SELECT_CLASS}
    classNamePrefix={classNamePrefix || CLEARY_REACT_SELECT_CLASS}
    theme={clearyTheme}
    {...props}
  />
)

const noOptionHandler = minCharsToSearch => ({ inputValue }) => {
  if (inputValue.length < minCharsToSearch) {
    return I18NCommon('type_to_search')
  }

  return I18NCommon('no_results_found')
}

const loadingHandler = minCharsToSearch => ({ inputValue }) => {
  if (inputValue.length < minCharsToSearch) {
    return I18NCommon('type_to_search')
  }

  return I18NCommon('loading')
}

const defaultLoadOptions = (getOptions, minCharsToSearch) => _.debounce((inputValue, callback) => {
  if (inputValue.length < minCharsToSearch) {
    callback([])
  } else {
    getOptions(inputValue, callback)
  }

  // not necessary in this case because the arrow function is multiline
  // but kept here in case others copy this debounce function,
  // when in combine with AsyncSelect it must return null or undefined to work properly.
  // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
  return null
}, 300)

type AsyncSelectProps = {
  minCharsToSearch?: number
  [key: string]: any
}

export const AsyncSelect = ({
  className,
  classNamePrefix,
  minCharsToSearch = 3,
  getOptions,
  loadOptions,
  noOptionsMessage,
  loadingMessage,
  ...props
}: AsyncSelectProps) => (
  <Async
    className={className || CLEARY_REACT_SELECT_CLASS}
    classNamePrefix={classNamePrefix || CLEARY_REACT_SELECT_CLASS}
    theme={clearyTheme}
    noOptionsMessage={noOptionsMessage || noOptionHandler(minCharsToSearch)}
    loadingMessage={loadingMessage || loadingHandler(minCharsToSearch)}
    loadOptions={getOptions ? defaultLoadOptions(getOptions, minCharsToSearch) : loadOptions}
    {...props}
  />
)
