import Axios from 'axios'

export interface VideoAnalyticsType {
  name: string,
  duration: number,
  uploadedAt: string,
  displayedVideoAudienceCount: number
  videoAudienceCount: number
  videoFinishedAudienceCount: number
  averageWatchTime: number
  thumbnailUrl: string
  dropoffDataSeries: {
    [key: number]: number
  }
}

export default {
  analytics(resourceId, resourceType, videoId): Promise<{ data: VideoAnalyticsType }> {
    const endpoint = `/api/admin/videos/${videoId}/${resourceType}/${resourceId}/analytics`

    return Axios.get(endpoint)
  },
}
