import Table from '@tiptap/extension-table'

import { ClearyTableView } from 'components/common/tiptap/extensions/nodeViews/tableView'
import { mergeAttributes } from '@tiptap/core'

const computeTableWidthStyle = (colWidths, cellMinWidth) => {
  const colWidthSum = colWidths?.reduce((acc, width) => acc + width, 0)

  // If all columns have a width, then the table has a fixed width
  if (colWidths.every(width => width !== null)) {
    return `width: ${colWidthSum}px`
  }

  // If some columns have a width, then the table should respect the cellMinWidth for the columns that don't have a width
  if (colWidths?.some(width => width !== null)) {
    const nullWidthCount = colWidths?.filter(width => width === null).length || 0
    const minWidth = colWidthSum + (nullWidthCount * cellMinWidth)

    return `min-width: ${minWidth}px`
  }

  // If all columns have no width, we don't need to set a width on the table
  return ''
}

const insertNewlineIfLastCellAndNoMoreContent = ({ editor }) => {
  const { state } = editor
  const { tableCell } = editor.schema.nodes

  const $head = state.selection.$head

  // Check if the selection is inside a table cell
  let depth = $head.depth
  while (depth > 0) {
    const nodeType = $head.node(depth).type
    if (nodeType === tableCell) break
    depth -= 1
  }

  if (depth === 0) return false // Not inside a table cell


  // Check if selection is at the end of the content in the cell
  if ($head.parentOffset !== $head.parent.content.size) return false

  // Check if the cell is the last one in the table and there's no content after the table
  const tableRow = $head.node(depth - 1) // Table row node
  const table = $head.node(depth - 2) // Table node
  const tablePos = $head.start(depth - 2)
  const tableEndPos = tablePos + table.nodeSize

  const isLastRow = table.lastChild === tableRow
  const isLastCell = $head.index(depth - 1) === tableRow.childCount - 1

  if (
    isLastRow
    && isLastCell
    && tableEndPos >= state.doc.content.size // No content after the table or at the end of the document
  ) {
    let targetPos = tableEndPos
    if (targetPos > state.doc.content.size) {
      targetPos = state.doc.content.size
    }

    // Insert a newline at the target position
    editor.chain().insertContentAt(targetPos, '<p></p>').run()
    return true
  }

  return false
}

const ClearyTable = Table.extend({
  // We override the options here because of this bug: https://github.com/ueberdosis/tiptap/issues/4081
  addOptions() {
    return {
      ...this.parent?.(),
      resizable: true,
      View: ClearyTableView as any,
      lastColumnResizable: true,
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: 'tiptap-table',
        parseHTML: element => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) return {}

          return {
            class: attributes.class,
          }
        },
      },
    }
  },
  addCommands() {
    return {
      ...this.parent?.(),
      toggleClass: newClass => ({ commands }) => {
        const classes = this.editor.getAttributes('table').class?.split(' ') || []
        if (classes.includes(newClass)) {
          commands.updateAttributes('table', { class: classes.filter(c => c !== newClass).join(' ') })
        } else {
          commands.updateAttributes('table', { class: `${classes.join(' ')} ${newClass}` })
        }
      },
    }
  },
  // We need to override this method so we can add the colwidth attribute to the colgroup tag
  // Using that, we can correctly render the table and each column width when displaying the HTML
  renderHTML({ HTMLAttributes, node }) {
    const colgroup: any[] = ['colgroup']
    const colWidths: number[] = []

    if (node.firstChild) {
      const content = node.firstChild.content

      let colIndex = 0
      for (let i = 0; i < content.childCount; i += 1) {
        const { colwidth, colspan } = content.child(i).attrs
        for (let j = 0; j < colspan; j += 1) {
          const width = colwidth && colwidth.length > j ? colwidth[j] : null
          const colAttributes = width ? { style: `width: ${width}px` } : {}
          colWidths.push(width)
          colgroup.push(['col', colAttributes])
          colIndex += 1
        }
      }
    }

    const tableAttributes = mergeAttributes(
      this.options.HTMLAttributes,
      HTMLAttributes,
      { style: computeTableWidthStyle(colWidths, this.options.cellMinWidth) }
    )

    return ['table', tableAttributes, colgroup, ['tbody', 0]]
  },

  addKeyboardShortcuts() {
    return {
      ArrowRight: insertNewlineIfLastCellAndNoMoreContent,
      ArrowDown: insertNewlineIfLastCellAndNoMoreContent,
    }
  },
})

export default ClearyTable
