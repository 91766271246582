import qs from 'qs'
import { useLocation } from 'react-router-dom'
import camelCaseKeys from 'utils/camelCaseKeys'

function useQueryParams() {
  const searchParams = qs.parse(useLocation().search, { ignoreQueryPrefix: true })

  return camelCaseKeys(searchParams)
}

export default useQueryParams
