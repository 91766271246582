import React from 'react'
import ResizeOverlay from 'components/common/tiptap/extensions/nodeViews/resizeOverlay'
import { STYLE_ATTRIBUTES } from 'components/common/tiptap/extensions/clearyImage'

const ImageResizer = (props:any) => (
  <ResizeOverlay {...props}>
    <img
      key={props.node.attrs['data-image-id']}
      {..._.omit(props.node.attrs, STYLE_ATTRIBUTES)}
      className='postimage'
      style={_.pick(props.node.attrs, STYLE_ATTRIBUTES)}
    />
  </ResizeOverlay>
)

export default ImageResizer
