import useRenderedCount from 'components/common/hooks/useRenderedCount'
import SocialShare from 'components/common/social_share/socialShare'
import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'


const useSocialSharePortals = (recordType: string, recordId: string, containerId: string, richTextHtml: string) => {
  // we can only execute this hook once the rich text has been rendered
  // So the parent component call this hook, and the renderedCount will be 0
  // The document.querySelectorAll(`#${containerId} div.social-share`) will return an empty array
  // since nothing is rendered yet
  // The effect will run again after being rendered, with a renderedCount as 1
  // The document.querySelectorAll(`#${containerId} div.social-share`) will return the elements
  const renderedCount = useRenderedCount([richTextHtml, containerId])

  const portals = useMemo(() => {
    const socialShares = document.querySelectorAll(`#${containerId} div.social-share`)

    const socialShareProps = recordType === 'article' ? { articleId: recordId } : {}

    return Array.from(socialShares)
      // This prevents rendering a SocialShare without required attributes
      // Happens when you add a social share component and click in preview without submitting the form
      .filter(el => el.getAttribute('data-edit-mode') !== 'true')

      .map((el: HTMLDivElement) => (
        ReactDOM.createPortal(
          <SocialShare
            {...socialShareProps}
            url={el.getAttribute('data-url')!}
            title={el.getAttribute('data-title')!}
            imageUrl={el.getAttribute('data-image-url')!}
            suggestedText={el.getAttribute('data-suggested-text')!}
            croppedArea={JSON.parse(el.getAttribute('data-cropped-area') || '{}')}
          />,
          el
        )
      ))
  }, [renderedCount])

  return portals
}

export default useSocialSharePortals
