import Axios from 'axios'
import qs from 'qs'
import snakeCaseKeys from 'utils/snakeCaseKeys'

export default {
  fetchAll({ lastId }) {
    const params = lastId ? qs.stringify(snakeCaseKeys({ lastId })) : ''

    return Axios.get(`/api/activities?${params}`)
  },
}
