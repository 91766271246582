import React from 'react'
import DOMPurify from 'dompurify'
import hljs from 'highlight.js/lib/core'
import { common } from 'lowlight'

Object.entries(common).forEach(([name, language]) => hljs.registerLanguage(name, language))

interface Props {
  html: string
}

const TipTapRichTextView = ({
  html,
}: Props) => {
  const sanitizedHtml = DOMPurify.sanitize(html, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['frameborder', 'target', 'allowfullscreen'],
  })

  const highlightedHtml = highlightCodeBlocks(sanitizedHtml)

  return (
    <div className='TiptapView' dangerouslySetInnerHTML={{ __html: highlightedHtml }} />
  )
}

const highlightCodeBlocks = (htmlString) => {
  if (!htmlString?.includes('<code')) {
    return htmlString
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  const codeBlocks = doc.querySelectorAll('pre code')

  codeBlocks.forEach((block) => {
    const language = block.className ? block.className.split('-')[1] : null
    const isValidLanguage = language && hljs.getLanguage(language)

    const highlightedHtml = isValidLanguage ? hljs.highlight(language, block.textContent || '') : hljs.highlightAuto(block.textContent || '')
    block.innerHTML = highlightedHtml.value
  })

  return doc.body.innerHTML
}

export default TipTapRichTextView
