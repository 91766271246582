import Axios from 'axios'
import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'
import generateCrudRoutes from 'services/generateCrudRoutes'

export const COMMUNICATION_TEMPLATE_ATTRIBUTES = [
  'subject',
  'content',
  'contentId',
  'sendToEmail',
  'createFeedCard',
  'replyToSetting',
  'sendToPersonalEmail',
  'sendSms',
  'feedTitle',
  'feedContent',
  'smsContent',
]

const buildCommunicationTemplatePayload = (communicationTemplate) => {
  const communicationTemplateAttrs = _.pick(communicationTemplate, [...COMMUNICATION_TEMPLATE_ATTRIBUTES, 'id'])

  if (communicationTemplate.stepTemplateAttributes) {
    communicationTemplateAttrs.stepTemplateAttributes = buildStepTemplatePayload(
      communicationTemplate.stepTemplateAttributes
    )
  }

  return communicationTemplateAttrs
}

const communicationTemplates = {
  ...generateCrudRoutes(
    '/api/admin/journey/communication_templates',
    'communication_template',
    {},
    buildCommunicationTemplatePayload
  ),

  sendPreviewEmail(id, userIds) {
    return Axios.post(`/api/admin/journey/communication_templates/${id}/send_preview_email`, { userIds })
  },

  sendPreviewSms(id, userIds) {
    return Axios.post(`/api/admin/journey/communication_templates/${id}/send_preview_sms`, { userIds })
  },

  copyContent(content, clearyRichTextId) {
    return Axios.post('/api/admin/journey/communication_templates/copy_content', { content, clearyRichTextId })
  },
}

export default communicationTemplates
