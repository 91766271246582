import React, { useEffect } from 'react'
import { EmbedDropdownContent } from 'components/common/tiptap/toolbar/items/embedDropdownButton'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { trackAmplitudeEvent } from 'services/tracker'
import { InsertImageDropdownContent } from 'components/common/tiptap/toolbar/items/insertImageDropdownButton'
import LinkDropdownContent from 'components/common/tiptap/toolbar/items/linkDropdownContent'
import { InsertFileDropdownContent } from 'components/common/tiptap/toolbar/items/insertFileDropdownButton'
import { TableDropdownContent } from 'components/common/tiptap/toolbar/items/insertTableDropdownButton'

const SlashCommandSecondaryMenu = ({
  secondaryMenu,
  closeSecondaryMenu,
  editor,
  range,
}) => {
  useEffect(() => {
    trackAmplitudeEvent('tiptap_insert_menu_item_selected', { item: secondaryMenu })
  }, [secondaryMenu])

  const onClose = () => {
    editor.chain().focus().deleteRange({ from: range.from - 1, to: range.to }).run()
    closeSecondaryMenu()
  }

  const onInsertEmoji = (emoji) => {
    editor.chain().focus().insertContent(emoji).run()
    onClose()
  }

  switch (secondaryMenu) {
  case 'video':
  case 'embed':
    return (
      <EmbedDropdownContent
        editor={editor}
        onCloseDropdown={onClose}
        embedVideoOnly={secondaryMenu === 'video'}
      />
    )

  case 'emoji':
    return (
      <Picker
        onEmojiSelect={({ native }) => onInsertEmoji(native)}
        data={data}
        theme='light'
        onClickOutside={closeSecondaryMenu}
      />
    )

  case 'image':
    return (
      <InsertImageDropdownContent
        editor={editor}
        closeDropdown={onClose}
      />
    )

  case 'link':
    return (
      <LinkDropdownContent
        editor={editor}
        onUpdateSuccess={onClose}
      />
    )

  case 'file_attachment':
    return (
      <InsertFileDropdownContent
        editor={editor}
        closeDropdown={onClose}
      />
    )

  case 'table':
    return (
      <TableDropdownContent
        editor={editor}
        onInsertSuccess={onClose}
      />
    )

  default:
    return null
  }
}


export default SlashCommandSecondaryMenu
