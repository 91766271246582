import { useEffect, useState } from 'react'

// Use this hook to track how many times a component has been rendered
// Useful when you want to execute a hook only after the first render
const useRenderedCount = (deps: any[] = []) => {
  const [renderedCount, setRenderedCount] = useState(0)

  useEffect(() => {
    setRenderedCount(renderedCount + 1)
  }, deps)

  return renderedCount
}

export default useRenderedCount
