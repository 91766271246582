import React, { Component } from 'react'

import { connect } from 'react-redux'
import appSignal from 'services/appSignal'
import DefaultErrorMessage from './defaultErrorMessage'

// This component is not using React Hooks because there isn't a hook for the getDerivedStateFromError method
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes

class ErrorBoundaryWithoutConnect extends Component {
  state = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidMount() {
    const { history } = this.props
    history && this.listen()
  }

  componentDidCatch(error, errorInfo) { // eslint-disable-line react/sort-comp
    const { currentUser } = this.props
    if (currentUser?.permissions?.clearyAdmin) console.error(error) // eslint-disable-line no-console
    appSignal.sendErrorUnlessClearyBackendError(error)
  }

  componentWillUnmount() {
    this.unlisten?.()
  }

  listen = () => {
    const { history } = this.props

    // We need to reset the ErrorBoundary when user switches pages, otherwise the error message will persist.
    this.unlisten = history.listen(() => {
      this.setState({ error: null })
    })
  }

  render() {
    const { error } = this.state
    const {
      className, history, onErrorRender, showTitle = true, showDefault = true,
    } = this.props

    if (error) {
      return (
        <>
          {showDefault && (
            <DefaultErrorMessage
              className={className}
              history={history}
              onErrorRender={onErrorRender}
              showTitle={showTitle}
            />
          )}
        </>
      )
    }
    return this.props.children
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
})

export default connect(mapStateToProps)(ErrorBoundaryWithoutConnect)

export { ErrorBoundaryWithoutConnect }
