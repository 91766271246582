import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

const I18N = i18nPath('views.tiptap.toolbar')

const ALIGN_OPTIONS = ['left', 'center', 'right', 'justify']

const TextAlignSelect = ({ editor, isDisabled = false }) => (
  <ToolbarSelect
    className={classNames('TextAlignSelect', { disabled: isDisabled })}
    options={ALIGN_OPTIONS.map(align => ({
      value: align,
      label: <CdnSvg src={`/images/tiptap/align-${align}.svg`} />,
      tooltipText: I18N(`align_${align}_tooltip`),
      onClick: () => editor.chain().focus().setTextAlign(align).run(),
      isActive: editor.isActive({ textAlign: align }),
    }))}
    defaultValue='left'
    tooltipText={I18N('text_align_tooltip')}
    isDisabled={isDisabled}
  />
)

export default TextAlignSelect
