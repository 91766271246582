import React, { forwardRef, useEffect, useState } from 'react'
import BaseItemList from 'components/common/tiptap/suggestions/baseItemList'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import SlashCommandSecondaryMenu from 'components/common/tiptap/suggestions/slash_commands/slashCommandSecondaryMenu'

const I18N = i18nPath('views.tiptap.slash_commands')

interface Props {
  items: any[]
  command: (any) => void
  editor: any
  range: {
    from: number
    to: number
  }
}

const SlashCommandItem = ({ item }) => (
  <>
    <div className='IconBox bordered mr-2'>
      <CdnSvg src={item.svgPath} />
    </div>
    <a className='text-secondary font-weight-400'>
      {item.name}
    </a>
  </>
)

const SlashCommandList = forwardRef(({
  items, command, editor, range,
}: Props, ref) => {
  const [secondaryMenu, setSecondaryMenu] = useState(null)

  const onItemSelect = (item: any) => {
    if (item.hasSecondaryMenu) {
      setSecondaryMenu(item.id)
    } else {
      command(item)
    }
  }

  if (secondaryMenu) {
    return (
      <div className='SlashCommandSecondaryMenu p-2'>
        <SlashCommandSecondaryMenu
          secondaryMenu={secondaryMenu}
          closeSecondaryMenu={() => setSecondaryMenu(null)}
          editor={editor}
          range={range}
        />
      </div>
    )
  }

  if (!items.length) {
    return null
  }

  return (
    <div className='SlashCommandListContainer'>
      <span className='NavTitle text-small text-secondary font-weight-600 p-2'>
        {I18N('text_format_and_layouts')}
      </span>
      <BaseItemList
        ref={ref}
        onItemSelect={onItemSelect}
        items={items}
        itemRenderer={item => <SlashCommandItem item={item} />}
        className='SlashCommandList'
      />
    </div>
  )
})

export default SlashCommandList
