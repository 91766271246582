import Axios from 'axios'

const pagesNavigation = {
  root() {
    return Axios.get('/api/page/navigation/root')
  },
  navigate(pageSlug: string) {
    return Axios.get(`/api/page/navigation/${pageSlug}`)
  },
  expand(resourceType: string, resourceId: string) {
    return Axios.get(`/api/page/navigation/${resourceType}/${resourceId}`)
  },
}

export default pagesNavigation
