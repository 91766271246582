import React from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'

const noop = () => { }

interface FormEditableFieldProps {
  fieldName?: string
  currentValue: any
  label?: string | React.ReactNode
  isRequired?: boolean
  inputType?: string
  textarea?: boolean
  footNote?: string
  onChange?: (value: any) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  autoFocus?: boolean
}

const FormEditableField = ({
  fieldName,
  label,
  isRequired,
  inputType = 'text',
  textarea = false,
  footNote,
  currentValue,
  onChange,
  className = '',
  placeholder = '',
  disabled = false,
  autoFocus = false,
}: FormEditableFieldProps) => (
  <LabeledFormFieldContainer
    isRequired={isRequired}
    footNote={footNote}
    className={className}
    label={label || fieldName}
  >
    {textarea ? (
      <textarea
        disabled={disabled}
        name={fieldName}
        placeholder={placeholder}
        value={currentValue}
        onChange={onChange || noop}
        autoFocus={autoFocus}
      />
    ) : (
      <input
        type={inputType}
        disabled={disabled}
        name={fieldName}
        placeholder={placeholder}
        value={currentValue}
        onChange={onChange || noop}
        autoFocus={autoFocus}
      />
    )}
  </LabeledFormFieldContainer>
)


export default FormEditableField
