import { BORDERLESS_TABLE_CLASS } from 'components/common/tiptap/extensions/nodeViews/tableView'
import baseSuggestion from 'components/common/tiptap/suggestions/baseSuggestion'
import SlashCommandList from 'components/common/tiptap/suggestions/slash_commands/slashCommandList'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.slash_commands')

const basicOptions = [
  {
    id: 'ai_assistant',
    name: I18N('ai_assistant'),
    svgPath: '/images/tiptap/ai.svg',
    onClick: editor => editor.chain().focus().insertContent({ type: 'aiPrompt' })
.run(),
  },
  {
    id: 'bullet_list',
    name: I18N('bullet_list'),
    svgPath: '/images/tiptap/slash_commands/bulletList.svg',
    onClick: editor => editor.chain().focus().toggleBulletList().run(),
  },
  {
    id: 'numbered_list',
    name: I18N('numbered_list'),
    svgPath: '/images/tiptap/slash_commands/numberedList.svg',
    onClick: editor => editor.chain().focus().toggleOrderedList().run(),
  },
  ...[1, 2, 3, 4, 5, 6].map(level => ({
    id: `heading_${level}`,
    name: I18N(`heading_${level}`),
    svgPath: `/images/tiptap/slash_commands/h${level}.svg`,
    onClick: editor => editor.chain().focus().toggleHeading({ level }).run(),
  })),
  {
    id: 'horizontal_divider',
    name: I18N('horizontal_divider'),
    svgPath: '/images/tiptap/slash_commands/horizontalDivider.svg',
    onClick: editor => editor.chain().focus().setHorizontalRule().run(),
  },
]

const columnsOptions = ['two', 'three', 'four', 'five'].map((num, i) => ({
  id: `${num}_columns`,
  name: I18N(`${num}_columns`),
  value: i + 2, // values start from 2
  svgPath: `/images/tiptap/slash_commands/${num}Columns.svg`,
  onClick: editor => editor
    .chain()
    .focus()
    .insertTable({
      cols: i + 2,
      rows: 1,
      withHeaderRow: false,
    }).toggleClass(BORDERLESS_TABLE_CLASS)
    .run(),
}))

const secondaryOptions = [
  {
    id: 'image',
    name: I18N('image'),
    svgPath: '/images/tiptap/image.svg',
    hasSecondaryMenu: true,
  },
  {
    id: 'link',
    name: I18N('link'),
    svgPath: '/images/tiptap/link.svg',
    hasSecondaryMenu: true,
  },
  {
    id: 'emoji',
    name: I18N('emoji'),
    svgPath: '/images/tiptap/emoji.svg',
    hasSecondaryMenu: true,
  },
  {
    id: 'video',
    name: I18N('video'),
    svgPath: '/images/tiptap/video.svg',
    hasSecondaryMenu: true,
  },
  {
    id: 'embed',
    name: I18N('embed'),
    svgPath: '/images/tiptap/share.svg',
    hasSecondaryMenu: true,
  },
  {
    id: 'file_attachment',
    name: I18N('file_attachment'),
    svgPath: '/images/tiptap/folder.svg',
    hasSecondaryMenu: true,
  },
  {
    id: 'table',
    name: I18N('table'),
    svgPath: '/images/tiptap/table.svg',
    hasSecondaryMenu: true,
  },
  {
    id: 'social_share',
    name: I18N('social_share'),
    svgPath: '/images/tiptap/socialShare.svg',
    onClick: editor => editor.chain().focus().insertContent({ type: 'socialShare', attrs: { editMode: true } })
.run(),
  },
]


export const ALL_OPTIONS = [
  ...basicOptions,
  ...columnsOptions,
  ...secondaryOptions,
]

const slashCommandSuggestion = ({ aiEnabled = false, socialShareEnabled = false } = {}) => ({
  items: ({ query }) => {
    const options = [...ALL_OPTIONS]

    if (!aiEnabled) { _.remove(options, { id: 'ai_assistant' }) }
    if (!socialShareEnabled) { _.remove(options, { id: 'social_share' }) }

    return options.filter(option => option.name.toLowerCase().includes(query.toLowerCase()))
  },

  ...baseSuggestion(SlashCommandList, '/'),
})

export default slashCommandSuggestion
