import React from 'react'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import RichTextView from 'components/common/richTextView'
import { i18nMoment } from 'utils/i18nHelpers'

const RichTextInlineComment = ({ richTextInlineComment, isExpanded }) => {
  const { user, createdAt } = richTextInlineComment

  if (isExpanded) {
    return (
      <div className='RichTextInlineComment mb-3 p-2'>
        <div className='d-flex align-items-center mb-2'>
          <EmployeeThumbnailPhoto employee={user} size='28px' className='flex-grow-0 flex-shrink-0' />
          <div className='ml-2'>
            <div className='font-weight-600 text-small'>
              {user.preferredFullName}
            </div>
            <div className='text-smallest'>
              {i18nMoment(createdAt).format('lll')}
            </div>
          </div>
        </div>
        <RichTextView
          record={richTextInlineComment}
          recordType='richTextInlineComment'
          richTextKey='content'
        />
      </div>
    )
  }

  return (
    <div className='mb-3'>
      <EmployeeThumbnailPhoto
        employee={user}
        size='28px'
        className='flex-grow-0 flex-shrink-0'
      />
    </div>
  )
}

export default RichTextInlineComment
