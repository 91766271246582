import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'
import ToolbarSelect from 'components/common/tiptap/toolbar/items/toolbarSelect'

const I18N = i18nPath('views.tiptap.toolbar')

const OPTIONS = [
  'default',
  'lower-alpha',
  'lower-greek',
  'lower-roman',
  'upper-alpha',
  'upper-roman',
]

const OrderedListButton = ({ editor, isDisabled = false }) => {
  const isOrderedListActive = editor.isActive('orderedList')
  const currentStyle = editor.getAttributes('orderedList').listStyleType

  const onStyleOptionClick = (option) => {
    const styleToApply = option === 'default' ? null : option

    if (isOrderedListActive) {
      editor.chain().focus().setListItemStyleType(styleToApply).run()
    } else {
      editor.chain().focus().toggleOrderedList().setListItemStyleType(styleToApply)
.run()
    }
  }

  return (
    <div className='TiptapOrderedListButton d-flex'>
      <ToolbarButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        tooltipText={I18N('ordered_list_tooltip')}
        isActive={editor.isActive('orderedList')}
        isDisabled={isDisabled}
      >
        <CdnSvg src='/images/tiptap/ordered-list.svg' />
      </ToolbarButton>
      <ToolbarSelect
        className='BulletListStyleSelect'
        options={
          OPTIONS.map(option => ({
            value: option,
            label: I18N(`list_item_styles.${option}`),
            onClick: () => onStyleOptionClick(option),
            isActive: currentStyle === option || (!currentStyle && option === 'default'),
          }))
        }
        isDisabled={isDisabled}
        defaultValue=''
        tooltipText={I18N('ordered_list_tooltip')}
      />
    </div>
  )
}

export default OrderedListButton
