import React, { useState } from 'react'

import CommentsWidget from 'components/comments/commentsWidget'
import ReactionsWidget from 'components/common/reactions/reactionsWidget'

interface ReactionsAndCommentsProps {
  record: any
  type: string
  disableComments?: boolean
  disableActions?: boolean
  hideDeleteOption?: boolean
  showCommentsOnInit?: boolean
  disableNewComments?: boolean
  onToggleComments?: () => void
  onlyLike?: boolean
}

const ReactionsAndComments = ({
  record,
  type,
  disableComments,
  hideDeleteOption,
  showCommentsOnInit,
  disableNewComments,
  onToggleComments,
  disableActions = false,
  onlyLike = false,
}: ReactionsAndCommentsProps) => {
  const [commentsCount, setCommentsCount] = useState(record.commentsCount)

  return (
    <div className='ReactionsAndComments d-flex flex-column mb-3'>
      <ReactionsWidget
        reactableId={record.id}
        reactableType={type}
        reactions={record.reactions}
        currentUserReactions={record.currentUserReactions}
        disablePicker={disableActions}
        onlyLike={onlyLike}
      />

      <CommentsWidget
        type={type}
        commentable={record}
        key={record.id}
        commentsCount={commentsCount}
        setCommentsCount={setCommentsCount}
        disableComments={disableComments}
        isReadOnly={disableActions}
        hideDeleteOption={hideDeleteOption}
        showCommentsOnInit={showCommentsOnInit}
        disableNewComments={disableNewComments}
        onToggleComments={onToggleComments}
      />
    </div>
  )
}

export default ReactionsAndComments
