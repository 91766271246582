import { isAtEndOfNode } from '@tiptap/core'
import Heading from '@tiptap/extension-heading'

const ClearyHeading = Heading.extend({
  addCommands() {
    return {
      setHeading: attributes => ({ chain, editor }) => {
        if (!this.options.levels.includes(attributes.level)) {
          return false
        }

        const currentNodeName = editor.state.selection.$head.parent.type.name
        const currentAttributes = editor.getAttributes(currentNodeName)

        return chain()
          .selectParentNode()
          .setMark('textStyle', { fontSize: null })
          .removeEmptyTextStyle()
          .setNode(this.name, { ...currentAttributes, ...attributes })
          .run()
      },
      toggleHeading: attributes => ({ chain, editor }) => {
        if (!this.options.levels.includes(attributes.level)) {
          return false
        }

        const currentNodeName = editor.state.selection.$head.parent.type.name
        const currentAttributes = editor.getAttributes(currentNodeName)

        return chain()
          .selectParentNode()
          .setMark('textStyle', { fontSize: null })
          .removeEmptyTextStyle()
          .toggleNode(this.name, 'paragraph', { ...currentAttributes, ...attributes })
          .run()
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),

      Enter: ({ editor }) => {
        // even though this is scoped to the heading extension, it will still fire for all nodes, so we check if heading is active
        if (editor.isActive('heading') && isAtEndOfNode(editor.state)) {
          return editor.commands.insertContent('<p />')
        }

        return false
      },
    }
  },
})

export default ClearyHeading
