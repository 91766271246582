import Axios from 'axios'

export default {
  mostEngagingArticles(params) {
    return Axios.post('/api/admin/analytics/news/most_engaging_articles', params)
  },
  mostEngagingAuthors(params) {
    return Axios.post('/api/admin/analytics/news/most_engaging_authors', params)
  },
  articlesPublished(params) {
    return Axios.post('/api/admin/analytics/news/articles_published', params)
  },
  summary(params) {
    return Axios.post('/api/admin/analytics/news/summary', params)
  },
  engagement(params) {
    return Axios.post('/api/admin/analytics/news/engagement', params)
  },
}
