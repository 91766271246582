import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import GreetingCardInvitations from 'components/celebrations/greetingCardInvitations'
import Modal from 'components/common/modal'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.celebrations.sign_greeting_card_page')

const groupHighFivePath = '/images/groupHighFive.svg'

const SendInvitationsModal = ({
  celebration, onClose, visible, showNicelyDone,
}) => (
  <Modal
    className='SendInvitationsModal'
    size='lg'
    visible={visible}
    toggle={onClose}
    modalContentClassName='px-3'
  >
    <div className='position-relative d-flex flex-column align-items-center'>
      {showNicelyDone && (
        <>
          <h1 className='mb-3'>
            {I18N('nicely_done')}
          </h1>
          <div className='ThankYouMessage mb-3'>
            {I18N(`thank_you_message.${celebration.type}`)}
          </div>
          <hr className='w-100' />
        </>
      )}

      <GreetingCardInvitations celebration={celebration} onClose={onClose} />

      {showNicelyDone && (
        <CdnSvg className='GroupHighFiveImage' src={groupHighFivePath} />
      )}
    </div>
  </Modal>
)

export default SendInvitationsModal
