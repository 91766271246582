import React from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.tiptap.toolbar')

const RemoveImageLinkButton = ({ editor, nodeName }) => {
  const onRemoveLink = () => {
    editor.commands.updateAttributes(nodeName, { href: null, target: null })
  }

  return (
    <ToolbarButton
      onClick={onRemoveLink}
      tooltipText={I18N('link_bubble_menu.remove_link')}
    >
      <CdnSvg src='/images/tiptap/unlink.svg' />
    </ToolbarButton>
  )
}

export default RemoveImageLinkButton
