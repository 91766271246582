import { EditorView } from '@tiptap/pm/view'
import { Node } from '@tiptap/pm/model'
import API from 'services/api'
import appSignal from 'services/appSignal'


const findDocumentNodePosition = (
  view: EditorView,
  customMatch: (documentNode: Node) => boolean
): number | null => {
  let nodePosition: number | null = null

  view.state.doc.descendants((documentNode, pos) => {
    if (nodePosition !== null) return false

    if (customMatch(documentNode)) {
      nodePosition = pos
      return false
    }

    return true
  })

  return nodePosition
}

const processInsertedImage = async (view: EditorView, node: Node, richTextId: string) => {
  try {
    const response = await API.temporaryImageUpload.create(node.attrs.src, richTextId)
    const { imageUrl, imageId } = response.data

    const currentNodePos = findDocumentNodePosition(
      view,
      documentNode => documentNode.type.name === node.type.name && documentNode.attrs.src === node.attrs.src
    )

    if (currentNodePos === null) return

    const transaction = view.state.tr.setNodeAttribute(currentNodePos, 'src', imageUrl).setNodeAttribute(currentNodePos, 'data-image-id', imageId)
    view.dispatch(transaction)
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processInsertedVideo = async (view, node, richTextId) => {
  try {
    let response
    const dataVideoId = node.attrs['data-video-id']

    if (!dataVideoId && node.attrs.src) {
      // When it's a video from an external source, we need to create a new video record
      // This way we can track the video playback events using our player

      const src = node.attrs.src

      response = await API.videos.create({
        richTextId,
        name: src.split('/').pop(),
        externalUrl: src,
        encodedState: 'finished',
      })

      const currentNodePos = findDocumentNodePosition(
        view,
        documentNode => documentNode.type.name === node.type.name && documentNode.attrs.src === node.attrs.src
      )

      if (currentNodePos === null) return

      const transaction = view.state.tr.setNodeAttribute(currentNodePos, 'data-video-id', response.data.data.id)
      view.dispatch(transaction)
    }
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processPastedClearyEmbed = async (view, node, richTextId) => {
  if (!node.attrs['data-file-id']) return

  try {
    const response = await API.attachments.duplicate(node.attrs['data-file-id'], richTextId)
    const { fileUrl, fileId } = response.data

    const currentNodePos = findDocumentNodePosition(
      view,
      documentNode => documentNode.type.name === node.type.name && documentNode.attrs['data-file-id'] === node.attrs['data-file-id']
    )

    if (currentNodePos === null) return

    const transaction = view.state.tr.setNodeAttribute(currentNodePos, 'src', fileUrl).setNodeAttribute(currentNodePos, 'data-file-id', fileId)
    view.dispatch(transaction)
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processPastedClearyLink = async (view: EditorView, node: Node, richTextId: string) => {
  const currentLinkMark = node.marks?.find((mark: any) => mark.type.name === 'link' && mark.attrs['data-file-id'])
  if (!currentLinkMark) return

  try {
    const response = await API.attachments.duplicate(currentLinkMark.attrs['data-file-id'], richTextId)
    const { fileUrl, fileId } = response.data

    const currentNodePos = findDocumentNodePosition(
      view,
      documentNode => documentNode.type.name === node.type.name && documentNode.marks.includes(currentLinkMark)
    )

    if (currentNodePos === null) return

    const transaction = view.state.tr.removeMark(
      currentNodePos,
      currentNodePos + node.nodeSize,
      view.state.schema.marks.link
    ).addMark(
      currentNodePos,
      currentNodePos + node.nodeSize,
      view.state.schema.marks.link.create({ ...currentLinkMark.attrs, 'href': fileUrl, 'data-file-id': fileId })
    )

    view.dispatch(transaction)
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processInsertedNodes = (view: EditorView, slice, richTextId) => {
  slice.content.descendants(async (node: Node) => {
    if (node.type.name === 'inlineImage' || node.type.name === 'blockImage') {
      await processInsertedImage(view, node, richTextId)
    } else if (node.type.name === 'inlineVideo' || node.type.name === 'blockVideo') {
      await processInsertedVideo(view, node, richTextId)
    } else if (node.type.name === 'inlineEmbed' || node.type.name === 'blockEmbed') {
      await processPastedClearyEmbed(view, node, richTextId)
    } else {
      await processPastedClearyLink(view, node, richTextId)
    }
  })
}

export default processInsertedNodes
