import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import API from 'services/api'
import appSignal from 'services/appSignal'

export interface ReactionState {
  meta: DefaultMetaType
}

export const initialState: ReactionState = {
  meta: defaultMeta,
}

const slice = createSlice({
  name: 'reactions',
  initialState,
  reducers: defaultActions,
})

const asyncActions = {
  create: (reactableId: string, type: string, params) => async (dispatch: any) => {
    try {
      dispatch(reactionSlice.actions.isSaving(true))

      const response = await API.reactions.create(reactableId, type, params)

      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(reactionSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(reactionSlice.actions.isSaving(false))
    }
  },

  update: (reactableId: string, type: string, params) => async (dispatch: any) => {
    try {
      dispatch(reactionSlice.actions.isSaving(true))

      const response = await API.reactions.update(reactableId, type, params)

      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(reactionSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(reactionSlice.actions.isSaving(false))
    }
  },

  destroy: (reactableId: string, type: string, emojiId: string) => async (dispatch: any) => {
    try {
      dispatch(reactionSlice.actions.isLoading(true))

      const response = await API.reactions.destroy(reactableId, type, emojiId)

      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(reactionSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(reactionSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.reactions.meta,
}

const reactionSlice = {
  ...slice,
  asyncActions,
  selectors,
}

export default reactionSlice
