import { Extension } from '@tiptap/core'

interface IndentOptions {
  textTypes: string[]
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    marginLeft: {
      increaseIndent: () => ReturnType,
      decreaseIndent: () => ReturnType,
    }
  }
}

export const DEFAULT_INDENT = '0px'
export const MAX_INDENT = 400
export const INDENT_STEP = 20

const calculateNewMarginLeft = (editor, step) => {
  const nodeType = editor.isActive('heading') ? 'heading' : 'paragraph'

  // If the current marginLeft doesn't have a px unit, it means it was pasted from another source
  // In this case, we just reset the marginLeft to the default value
  const marginLeft = editor.getAttributes(nodeType)?.marginLeft || DEFAULT_INDENT
  if (marginLeft !== DEFAULT_INDENT && !marginLeft?.includes('px')) {
    return DEFAULT_INDENT
  }

  const newIndent = parseInt(marginLeft) + step

  if (step > 0) {
    return `${Math.min(newIndent, MAX_INDENT)}px`
  } else {
    return `${Math.max(newIndent, 0)}px`
  }
}

const canModifyIndent = (editor, isOutdent = false) => {
  if (editor.isActive('listItem')) {
    return isOutdent ? editor.can().liftListItem('listItem') : editor.can().sinkListItem('listItem')
  }

  if (editor.isActive('tableCell')) {
    return isOutdent ? editor.can().goToPreviousCell() : editor.can().goToNextCell()
  }

  const isHeading = editor.isActive('heading')
  const currentMarginLeft = editor.getAttributes(isHeading ? 'heading' : 'paragraph').marginLeft

  // If the current marginLeft doesn't have a px unit, it means it was pasted from another source
  // In this case, we only allow outdenting to reset the marginLeft to the default value
  if (currentMarginLeft && !currentMarginLeft?.includes('px')) {
    return isOutdent
  }

  const marginLeft = parseInt(currentMarginLeft || '0', 10)

  if (isOutdent) {
    return marginLeft - INDENT_STEP >= 0
  } else {
    return marginLeft + INDENT_STEP <= MAX_INDENT
  }
}

const increaseIndent = ({ editor }) => {
  if (editor.can().increaseIndent()) {
    editor.commands.increaseIndent()
    return true
  }

  return false
}

const Indent = Extension.create<IndentOptions>({
  name: 'indent',

  addOptions() {
    return {
      textTypes: ['heading', 'paragraph'],
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.textTypes,
        attributes: {
          marginLeft: {
            default: DEFAULT_INDENT,
            parseHTML: element => element.style.marginLeft,
            renderHTML: (attributes) => {
              if (!attributes.marginLeft || attributes.marginLeft === DEFAULT_INDENT) {
                return {}
              }

              return { style: `margin-left: ${attributes.marginLeft}` }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      increaseIndent: () => ({ commands, editor }) => {
        if (!canModifyIndent(editor)) {
          return false
        }

        if (editor.isActive('listItem')) {
          return commands.sinkListItem('listItem')
        }

        if (editor.isActive('tableCell')) {
          return commands.goToNextCell()
        }

        const newMarginLeft = calculateNewMarginLeft(editor, INDENT_STEP)
        return this.options.textTypes.every(type => commands.updateAttributes(type, { marginLeft: `${newMarginLeft}` }))
      },

      decreaseIndent: () => ({ commands, editor }) => {
        if (!canModifyIndent(editor, true)) {
          return false
        }

        if (editor.isActive('listItem')) {
          return commands.liftListItem('listItem')
        }

        if (editor.isActive('tableCell')) {
          return commands.goToPreviousCell()
        }

        const newMarginLeft = calculateNewMarginLeft(editor, -INDENT_STEP)
        return this.options.textTypes.every(type => commands.updateAttributes(type, { marginLeft: `${newMarginLeft}` }))
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Tab': increaseIndent,
      'Mod-]': increaseIndent,
      'Shift-Tab': ({ editor }) => {
        if (editor.can().decreaseIndent()) {
          editor.commands.decreaseIndent()
          return true
        }

        return false
      },
      'Mod-[': ({ editor }) => {
        if (editor.can().decreaseIndent()) {
          editor.commands.decreaseIndent()
        }

        return true // avoid default behaviour (going to the previous page)
      },
    }
  },
})

export default Indent
