
import {
  useEffect, useImperativeHandle, useState, useRef
} from 'react'

const useHandleSuggestionListEvents = ({
  items,
  ref,
  onItemSelect,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleItemSelect = (index: number) => {
    const item = items[index]
    onItemSelect(item)
  }

  const listItemsRef = useRef(new Map()).current

  useEffect(() => {
    setSelectedIndex(0)
  }, [JSON.stringify(items)])

  useEffect(() => {
    const node = listItemsRef.get(selectedIndex)
    // eslint-disable-next-line no-unused-expressions
    node?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [selectedIndex, listItemsRef])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (_.isEmpty(items)) return false

      if (event.key === 'ArrowUp') {
        setSelectedIndex((selectedIndex + items.length - 1) % items.length)
        return true
      }

      if (event.key === 'ArrowDown') {
        setSelectedIndex((selectedIndex + 1) % items.length)
        return true
      }

      if (event.key === 'Enter') {
        handleItemSelect(selectedIndex)
        return true
      }

      return false
    },
  }))

  return {
    selectedIndex,
    handleItemSelect,
    listItemsRef,
  }
}

export default useHandleSuggestionListEvents
