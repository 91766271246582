import React, { useState } from 'react'
import ModalImageCrop from 'components/common/image_crop/modalImageCrop'
import classNames from 'classnames'
import FileSelectDropzone from 'components/common/file_select_dropzone'

interface CroppedAreaPixels {
  x: number
  y: number
  width: number
  height: number
}

interface ImageData {
  croppedAreaPixels: CroppedAreaPixels
  croppedImageUrl: string
  imageData: string
}

interface ImageSelectorProps {
  onImageAdded?: (image: any, data?: ImageData) => void
  maxSizeInMB?: number
  className?: string
  style?: any
  dropzoneClassName?: string
  width?: string
  height?: string
  placeholderUrl?: string
  showLoadedImage?: boolean
  cropImage?: boolean
  imageText?: string | React.ReactNode
  accept?: string[]
  aspect?: number
  cropShape?: 'rect' | 'round'
  clearAfterCropSave?: boolean
}

const ImageSelector = ({
  onImageAdded = () => {},
  maxSizeInMB,
  className,
  style,
  dropzoneClassName,
  width = '',
  height = '',
  placeholderUrl,
  showLoadedImage = true,
  cropImage = false,
  accept = undefined,
  imageText = <div className='ImageSelector-text'>{I18n.t('views.common.image_selector.add_image')}</div>,
  aspect = 1,
  cropShape = 'round',
  clearAfterCropSave = false,
}: ImageSelectorProps) => {
  const [imageData, setImageData] = useState<string | null>(null)
  const [isPhotoLoading, setIsPhotoLoading] = useState(false)
  const [imageFile, setImageFile] = useState(null)

  const onDrop = (files) => {
    setIsPhotoLoading(true)

    const reader = new FileReader()
    reader.onload = () => {
      const imageData = reader.result
      setIsPhotoLoading(false)
      setImageData(imageData as string)

      if (!cropImage) {
        onImageAdded(files[0])
      } else {
        setImageFile(files[0])
      }
    }
    reader.readAsDataURL(files[0])
  }

  const cancelCrop = () => {
    setImageData(null)
    setImageFile(null)
  }

  const onCropSave = ({ croppedAreaPixels, croppedImageUrl }) => {
    onImageAdded(imageFile, { croppedAreaPixels, croppedImageUrl, imageData: imageData! })
    if (clearAfterCropSave) {
      setImageData(null)
      setImageFile(null)
    }
  }

  const imageSrc = imageData || placeholderUrl || null

  return (
    <div className={classNames('ImageSelector', className)}>
      <div className='ImageSelector-image'>
        <FileSelectDropzone
          data-testid='cy_dropzone_file_selector'
          style={style}
          maxSizeInMB={maxSizeInMB}
          className={classNames('ImageSelector-imagePreview ImageSelector-dropzone', dropzoneClassName)}
          onDrop={onDrop}
          disabled={isPhotoLoading}
          accept={accept}
        >
          {!cropImage && imageSrc && showLoadedImage ? <img src={imageSrc} style={{ width, height }} /> : imageText}
        </FileSelectDropzone>

        {cropImage && (
          <ModalImageCrop
            visible={!!imageSrc}
            imageSrc={imageSrc}
            onCancel={cancelCrop}
            onSave={onCropSave}
            aspect={aspect}
            cropShape={cropShape}
          />
        )}
      </div>
    </div>
  )
}

export default ImageSelector
