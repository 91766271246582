import moment from 'moment'

export const systemTypes = ['work_anniversary', 'birthday', 'new_employee_welcome']

const generateWorkingCopyFromTemplate = (template: any, company: any, currentUser: any, workingCopy: any) => {
  const customTitle = mergeTemplateTitle(workingCopy, template.title, currentUser, company)

  const isCustomCelebration = !systemTypes.includes(template.templateType)
  const isNewEvent = !workingCopy.id

  const result = {
    ...workingCopy,
    templateTitle: template.title,
    customTitle,
    coverImage: null,
    coverImageCroppedArea: template.coverImageCroppedArea || {},
    coverImageUrl: template.coverImageUrl,
    originalCoverImageUrl: template.originalCoverImageUrl,
    copyImageFromTemplateId: template.id,
    useDefaultImages: false,
  }

  if (isCustomCelebration && isNewEvent) {
    result.type = template.templateType
  }

  return result
}

export const mergeTemplateTitle = (
  workingCopy: any, templateTitle: string, currentUser: any, company: any
) => {
  let title = templateTitle

  if (workingCopy.user) {
    title = title.replace(/{{name}}/g, workingCopy.user.preferredName)
  }

  title = title.replace(/{{company}}/g, company.name)

  if (workingCopy.workAnniversaryYears) {
    title = title.replace(/{{work_anniversary_years}}/g, workingCopy.workAnniversaryYears)
  }

  if (workingCopy.eventDate) {
    const momentDate = moment(workingCopy.eventDate)
    const format = currentUser.locale === 'en' ? 'MMMM D' : 'DD/MM'

    title = title.replace(/{{date}}/g, momentDate.format(format))
  }

  return title
}

export default generateWorkingCopyFromTemplate
