import React from 'react'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import { NetworksList } from 'components/common/social_share/shareWidget'
import PartyHatIcon from 'components/icons/partyHatIcon'
import SocialSharePreviewCTA from 'components/celebrations/socialSharePreviewCTA'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'

const I18N = i18nPath('views.celebrations.social_share_modal')

const SocialShareModal = ({
  onClose, visible, handleShareClick, resolveUrl, availableNetworks, onPreview, suggestedText, className,
}) => (
  <Modal
    className={classNames('SocialShareModal', className)}
    visible={visible}
    toggle={onClose}
    size='md'
  >
    <header className='d-flex align-items-center flex-column pb-4 pt-1 px-2 text-center'>
      <PartyHatIcon className='mb-4' />
      <h2 className='mb-2'>{I18N('title')}</h2>
    </header>
    <main>
      <div>
        <div className='paragraph text-center'>{I18N('label')}</div>
        <SocialSharePreviewCTA onPreview={onPreview} />
        <NetworksList
          onClick={handleShareClick}
          availableNetworks={availableNetworks}
          suggestedText={suggestedText}
          url={resolveUrl}
        />
        <CdnSvg src='/images/illustrations/social_share.svg' className='illustration' />
      </div>
    </main>
  </Modal>
)

export default SocialShareModal
