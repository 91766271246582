import React, { useEffect, useState } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import { LiveAudioVisualizer } from 'react-audio-visualize'
import CdnSvg from 'components/common/cdnSvg'
import { REMOVE_MEDIA } from 'services/api/comments'
import classNames from 'classnames'

const SignWithAudio = ({ comment, setMedia, media }) => {
  const [previewWidth, setPreviewWidth] = useState<any>(0)

  const {
    startRecording,
    stopRecording,
    isRecording,
    mediaRecorder,
    recordingTime,
    recordingBlob,
  } = useAudioRecorder()

  const currentRecordingUrl = (recordingBlob && URL.createObjectURL(recordingBlob))
    || (comment?.media?.type === 'audio' && comment.media.url)

  useEffect(() => {
    if (recordingBlob) {
      const audioFile = new File([recordingBlob], `recording-${new Date().getTime()}.wav`, {
        type: recordingBlob.type,
      })

      setMedia({ type: 'audio_record', file: audioFile })
    }
  }, [recordingBlob])

  return (
    <div className='SignWithAudio'>
      {currentRecordingUrl && !(media.type === REMOVE_MEDIA) ? (
        <div className='audio'>
          <audio controls src={currentRecordingUrl} />
          <button
            className='delete'
            onClick={() => {
              setMedia({ type: REMOVE_MEDIA, file: REMOVE_MEDIA })
            }}
          >
            <CdnSvg src='/images/trashIcon.svg' />
          </button>
        </div>
      ) : (
        <div
          className={classNames('controls', { 'not-recording': !isRecording })}
          ref={(el) => {
            if (el) setPreviewWidth(el.offsetWidth - 185)
          }}
        >
          <button
            className='record'
            onClick={() => {
              if (isRecording) {
                stopRecording()
              } else {
                startRecording()
              }
            }}
          >
            {isRecording ? (
              <div className='stop' />
            ) : (
              <CdnSvg src='/images/micIcon.svg' />
            )}
          </button>

          {mediaRecorder && (
            <LiveAudioVisualizer
              mediaRecorder={mediaRecorder}
              width={previewWidth}
              height={25}
            />
          )}

          <div className='duration'>
            {Math.floor(recordingTime / 60)}:
            {recordingTime % 60 < 10 ? `0${recordingTime % 60}` : recordingTime % 60}
          </div>
        </div>
      )}
    </div>
  )
}

export default SignWithAudio
