import snakeCase from 'lodash/snakeCase'
import deepMapKeys from 'utils/deepMapKeys'

const snakeCaseKeys = data => deepMapKeys(data, (_, key) => {
  if (key?.startsWith('_')) {
    return `_${snakeCase(key.substring(1))}`
  }

  return snakeCase(key)
})

export default snakeCaseKeys
