import React, { useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.tiptap.toolbar')

const ChangeSizeForm = ({
  editor, closeDropdown, nodeName, defaultWidth,
}) => {
  const { width: initialWidth, height: initialHeight } = editor.getAttributes(nodeName)
  const [width, setWidth] = useState(initialWidth || '')
  const [height, setHeight] = useState(initialHeight || '')

  const saveValueAndClose = (e) => {
    e.preventDefault()
    editor.commands.updateAttributes(nodeName, {
      width: _.isEmpty(width) ? defaultWidth : width,
      height,
    })
    closeDropdown()
  }

  return (
    <form className='d-flex flex-column align-items-end' onSubmit={saveValueAndClose}>
      <div className='d-flex align-items-center mb-1'>
        <label className='w-30 mb-0'>{I18N('width')}</label>
        <input
          className='w-70'
          value={width}
          onChange={e => setWidth(e.target.value)}
          placeholder={I18N('width')}
          autoFocus
        />
      </div>
      <div className='d-flex align-items-center mb-2'>
        <label className='w-30 mb-0'>{I18N('height')}</label>
        <input
          className='w-70'
          value={height}
          onChange={e => setHeight(e.target.value)}
          placeholder={I18N('height')}
        />
      </div>
      <ButtonSmallNarrow type='submit'>
        {I18NCommon('save')}
      </ButtonSmallNarrow>
    </form>
  )
}

const ChangeSizeButton = ({
  editor, nodeName, isDisabled = false, defaultWidth = '',
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <ToolbarDropdownButton
      tooltipText={I18N('change_size')}
      className='TiptapChangeSizeButton'
      dropdownContent={(
        <ChangeSizeForm
          editor={editor}
          closeDropdown={() => setIsDropdownOpen(false)}
          nodeName={nodeName}
          defaultWidth={defaultWidth}
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src='/images/tiptap/ruler.svg' />
    </ToolbarDropdownButton>
  )
}

export default ChangeSizeButton
