import Axios from 'axios'
import qs from 'qs'
import snakeCaseKeys from 'utils/snakeCaseKeys'

export default {
  fetchOverview(params) {
    const queryString = qs.stringify(snakeCaseKeys(params))

    return Axios.get(`/api/admin/analytics/ai/assistant/answers_overview?${queryString}`)
  },

  fetchAnswers(params) {
    const queryString = qs.stringify(snakeCaseKeys(params))

    return Axios.get(`/api/admin/analytics/ai/assistant/answers?${queryString}`)
  },

  fetchAnswer(id) {
    return Axios.get(`/api/admin/analytics/ai/assistant/answers/${id}`)
  },

  fetchInsights(params) {
    return Axios.post('/api/admin/analytics/ai/assistant/insight/theme_collections', params)
  },
}
