import React from 'react'
import moment from 'moment'

export const i18nMoment = (...args) => {
  const localizedMoment = moment(...args)
  localizedMoment.locale(I18n.locale || I18n.defaultLocale)
  return localizedMoment
}

export const i18nPath = rootPath => (path, interpolations = {}) => I18n.t(`${rootPath}.${path}`, { defaultValue: path, ...interpolations })

export const I18NCommon = i18nPath('views.common')

// Replace placeholders of the form {0} with the corresponding content from an interpolations array
export const i18nFormat = (i18nText, ...interpolations) => {
  // Split on bracketed numbers and capture the number
  // If {0} is first occurrence in the string, split will create ["", 0, ...]
  const parts = i18nText.split(/{(\d)}/)
  return (
    <span>
      {parts.map((part, index) => {
        let result = null

        if (index % 2 === 0) {
          if (part !== '') {
            result = part
          }
        } else {
          const position = parseInt(part)
          if (Number.isNaN(position)) { // TODO: CA-2235
            result = part
          } else {
            result = interpolations[position]
          }
        }
        return <span key={index}>{result}</span>
      })}
    </span>
  )
}

export const i18nLabelWithTimeZone = (label, timezone) => (
  <>
    {label} <small className='text-secondary'>({I18NCommon('timezone_label', { timezone })})</small>
  </>
)

// This will transform the array e.g ['en', 'pt-BR'] in:
//
//     [
//       { value: 'en', label: I18N(`languages.en`) },
//       { value: 'pt-BR', label: I18N(`languages.pt-BR`) },
//     ]
export const i18nLocalesSelectOptions = locales => locales.map(value => ({ value, label: I18NCommon(`languages.${value}`) }))

// moment.js doesn't have a way to display localized date without showing the year,
// it's a long ago requested feature and refused by the maintainers https://github.com/moment/moment/issues/4325
// We don't want to show year but we don't want to have it the same position for every language
// So we decided to keep english as 'MMMM Do' and defaults other languages to 'DD/MM'
// We'll probably need to maintain other languages specific formats in the future too
export const i18nDisplayDateWithoutYear = (date) => {
  const momentDate = i18nMoment(date)
  return I18n.locale === 'en' ? momentDate.format('MMMM Do') : momentDate.format('DD/MM')
}

export const i18nMomentWithTimezone = ({ date, timezone, format }) => i18nMoment(date).tz(timezone).format(format)
