import React from 'react'

import Modal from 'components/common/modal'
import { I18NCommon } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import classNames from 'classnames'

interface ConfirmationModalProps {
  className?: string,
  modalContentClassName?: string,
  visible: boolean,
  toggle: () => void,
  isSaving?: boolean,
  onConfirmClick: (e: any) => void,
  onCancelClick?: () => void,
  title: string | React.ReactNode,
  description?: string,
  confirmText?: string
  cancelText?: string
  children?: React.ReactNode
  confirmationDisabled?: boolean
  variant?: string
}

const ConfirmationModal = ({
  className = '',
  title,
  description,
  visible = false,
  toggle = () => null,
  isSaving = false,
  onConfirmClick = () => null,
  onCancelClick = undefined,
  confirmText = I18NCommon('confirm'),
  cancelText = I18NCommon('cancel'),
  variant = 'primary',
  confirmationDisabled = false,
  children,
}: ConfirmationModalProps) => (
  <Modal
    size='md'
    className={classNames('ConfirmationModal', className)}
    visible={visible}
    toggle={toggle}
  >
    <div className='p-2 text-center'>
      {typeof title === 'string' ? <h4>{title}</h4> : title}
      {description && <p className='text-secondary'>{description}</p>}
      {children}
      <div className='d-flex align-items-center justify-content-center mt-4'>
        <div role='button' tabIndex={0} onClick={onCancelClick || toggle} className='mr-3 text-muted-dark'>
          {cancelText}
        </div>
        <Button
          showLoadingSpinner={isSaving}
          onClick={onConfirmClick}
          disabled={confirmationDisabled}
          variant={variant}
        >{confirmText}
        </Button>
      </div>
    </div>
  </Modal>
)

export default ConfirmationModal
