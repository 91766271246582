import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import getCdnUrl from 'utils/getCdnUrl'
import pageVariables from 'utils/pageVariables'

const blueBalloonPath = '/images/blueBalloon.svg'
const yellowBalloonPath = '/images/yellowBalloon.svg'
const birthdayPath = '/images/birthday.svg'
const upcomingBirthdayPath = '/images/upcomingBirthday.svg'
const personJumping1Path = '/images/personJumping1.svg'
const personJumping2Path = '/images/personJumping2.svg'
const highFiveImageWithBackgroundPath = '/images/highFiveImageWithBackground.svg'
const groupHighFivePath = '/images/groupHighFive.svg'

const CelebrationImage = ({ type, isCustomCelebration = false }) => {
  const { cdnDomain } = pageVariables

  if (isCustomCelebration) {
    return (
      <div className='d-flex h-100 align-items-end pt-3 z-index-0 position-relative birthday-celebration-image'>
        <img src={getCdnUrl(cdnDomain, '/images/celebrations/custom_card_background.jpg')} />
      </div>
    )
  }

  if (type === 'birthday') {
    return (
      <div className='d-flex h-100 align-items-end pt-3 z-index-0 position-relative birthday-celebration-image'>
        <div className='align-self-center balloon balloon-1'><CdnSvg src={blueBalloonPath} /></div>
        <div className='align-self-start balloon balloon-2'><CdnSvg src={yellowBalloonPath} /></div>
        <CdnSvg src={birthdayPath} className='illustration' />
        <div className='align-self-start mirror balloon balloon-3'><CdnSvg src={blueBalloonPath} /></div>
        <div className='align-self-center mirror balloon balloon-4'><CdnSvg src={yellowBalloonPath} /></div>
      </div>
    )
  }

  if (type === 'upcoming_birthday') {
    return (
      <div className='h-100 d-flex align-items-end z-index-0 position-relative upcoming-birthday-celebration-image'>
        <CdnSvg src={upcomingBirthdayPath} className='illustration' />
      </div>
    )
  }

  if (type === 'work_anniversary') {
    return (
      <div className='h-100 d-flex align-items-end z-index-0 position-relative work-anniversary-celebration-image'>
        <CdnSvg src={highFiveImageWithBackgroundPath} className='illustration' />
      </div>
    )
  }

  if (type === 'upcoming_work_anniversary') {
    return (
      <div className='h-100 d-flex align-items-end z-index-0 position-relative upcoming-work-anniversary-celebration-image'>
        <CdnSvg className='mr-3 person-1' src={personJumping1Path} />
        <CdnSvg src={personJumping2Path} className='illustration person-2' />
      </div>
    )
  }

  if (type === 'new_employee_welcome' || type === 'upcoming_new_employee_welcome') {
    return (
      <div className='h-100 d-flex align-items-end z-index-0 position-relative'>
        <CdnSvg
          src={groupHighFivePath}
          className='z-index-0 position-relative illustration new-employee-welcome-illustration'
        />
      </div>
    )
  }

  return null
}

export default CelebrationImage
