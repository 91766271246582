import { Node } from '@tiptap/pm/model'
import { TableView } from '@tiptap/pm/tables' // replace 'your-file' with the actual file name

export const BORDERLESS_TABLE_CLASS = 'borderless-table'
export const DASHED_TABLE_CLASS = 'dashed-borders-table'
export const ALTERNATING_ROWS_TABLE_CLASS = 'alternating-rows-table'

export class ClearyTableView extends TableView {
  constructor(node: Node, cellMinWidth: number) {
    super(node, cellMinWidth)

    this.updateTableClasses(node.attrs)
  }

  updateTableClasses(attrs) {
    this.table.className = attrs.class || ''
  }

  update(node: Node): boolean {
    // If the super method decided not to update, we follow that decision
    if (!super.update(node)) {
      return false
    }

    this.updateTableClasses(node.attrs)

    return true
  }
}
