import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'
import { normalizeTargetingRulesPayload } from 'utils/normalizeTargetingRules'

const buildArticlePayload = (article) => {
  const articleAttributes = _.pick<any, any>(article, [
    'id',
    'title',
    'video',
    'cardContent',
    'content',
    'chatNotificationContent',
    'draft',
    'slug',
    'commentable',
    'publishedAt',
    'articleTypeId',
    'restrictAccess',
    'displayAuthorText',
    'authorIds',
    'tagIds',
    'tagsAttributes',
    'notificationChannelOverrides',
    'acknowledgementDueAt',
    'alwaysShowInFeed',
    'distributionLists',
    'groupId',
    'notificationPreference',
    'thumbnailImage',
    'thumbnailImageCroppedArea',
    'templateId',
  ])

  if (article.articleType) {
    articleAttributes.articleTypeId = article.articleType.id
  }

  const notificationChannels = article.notificationChannels || []

  if (article.sendSlackNotificationAs) {
    if (article.sendSlackNotificationAs === 'user' && notificationChannels.length > 0) {
      articleAttributes.chatNotificationSenderId = article.chatNotificationSender?.id
    } else {
      articleAttributes.chatNotificationSenderId = null
    }
  }

  if (article.tags) {
    // eslint-disable-next-line no-underscore-dangle
    articleAttributes.tagIds = article.tags.filter(tag => !tag.__isNew__).map(tag => tag.id)
    // eslint-disable-next-line no-underscore-dangle
    articleAttributes.tagsAttributes = article.tags.filter(tag => tag.__isNew__).map(tag => ({
      name: tag.name,
    }))
  }

  if (article.authors) {
    articleAttributes.authorIds = article.authors.map(author => author.id)
  }

  if (article.targetingRules) {
    articleAttributes.targetingRules = normalizeTargetingRulesPayload(article.targetingRules)
  }

  if (article.notificationChannels) {
    articleAttributes.notificationChannelIds = article.notificationChannels.map(c => c.id)
  }

  return articleAttributes
}

const articles = _.merge(generateCrudRoutes('/api/admin/articles', 'article', {}, buildArticlePayload), {
  fetch(id, queryParams = {}) {
    return Axios.get(searchURI(`/api/admin/articles/${id}`, queryParams))
  },
  fetchEmailEngagementUsers(id, queryParams = {}) {
    return Axios.get(searchURI(`/api/admin/articles/${id}/email_engagement_users`, queryParams))
  },
  fetchEmailOpens(id, userId) {
    return Axios.get(`/api/admin/articles/${id}/email_opens?user_id=${userId}`)
  },
  exportEmailEngagementUsersCsv(id) {
    return Axios.post(`/api/admin/articles/${id}/export_email_engagement_users_csv`)
  },
  fetchEmailClicks(id) {
    return Axios.get(`/api/admin/articles/${id}/email_clicks`)
  },
  resendEmail(id, userId) {
    return Axios.post(`/api/admin/articles/${id}/resend_email?user_id=${userId}`)
  },
  uploadImage(image) {
    const formData = new FormData()
    formData.append('image', image)
    return Axios.post('/api/admin/articles/upload_image', formData)
  },
  sendPreviewEmail(id, userIds) {
    return Axios.post(`/api/admin/articles/${id}/send_preview_email`, { userIds })
  },
  sendPreviewChatMessage(id, userIds) {
    return Axios.post(`/api/admin/articles/${id}/send_preview_chat_message`, { userIds })
  },
  resendNotifications(id) {
    return Axios.post(`/api/admin/articles/${id}/resend_notifications`)
  },
  copyArticle(model) {
    return Axios.post(`/api/admin/articles/${model.id}/copy`, { article: model })
  },
  applyTemplate(articleId, templateId) {
    return Axios.patch(`/api/admin/articles/${articleId}/apply_template`, { templateId })
  },
})

export default articles
