import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'

const buildLinkPayload = link => _.pick(link, [
  'id',
  'name',
  'displayName',
  'url',
  'parameterizedUrl',
  'description',
  'featuredOrderPosition',
  'icon',
])


const golinks = _.merge(generateCrudRoutes('/api/go_links', 'go_link', {}, buildLinkPayload), {
  claim(goLink) {
    return Axios.patch(`/api/go_links/${goLink.name}/claim`)
  },
  addFeatured(goLinkName) {
    return Axios.patch(`/api/go_links/${goLinkName}/featured`)
  },
  removeFeatured(goLink) {
    return Axios.delete(`/api/go_links/${goLink.name}/featured`)
  },
  changeFeaturedGolinkOrderPosition(goLink, position) {
    const params = { featured_order_position: position }

    return Axios.patch(`/api/go_links/${goLink.name}/featured_position`, { goLink: params })
  },
  fetchGroupGoLinks(groupId) {
    return Axios.get(searchURI('/api/go_link_collections/group', { groupId }))
  },
  updateLinkInCollection(goLinkId, goLinkCollectionId, orderPosition) {
    return Axios.patch('/api/go_link_collection_items/', { goLinkId, goLinkCollectionId, orderPosition })
  },
  addLinkToCollection(goLinkId, goLinkCollectionId) {
    return Axios.post('/api/go_link_collection_items', { goLinkId, goLinkCollectionId })
  },
  removeLinkFromCollection(goLinkId, goLinkCollectionId) {
    return Axios.delete('/api/go_link_collection_items', { data: { goLinkId, goLinkCollectionId } })
  },

  findByUrl(url) {
    return Axios.get(`/api/go_links/find_by_url?url=${encodeURIComponent(url)}`)
  },

  checkNameAvailability(name) {
    return Axios.get(`/api/go_links/check_name_availability?name=${name}`)
  },

  aliases: {
    fetchAll: params => Axios.get(`/api/go_links/${params.goLinkId}/aliases`),
    create: params => Axios.post(`/api/go_links/${params.goLinkId}/aliases`, { go_link_alias: { name: params.name } }),
    destroy: params => Axios.delete(`/api/go_links/${params.goLinkId}/aliases/${params.aliasId}`),
    update: params => Axios.patch(`/api/go_links/${params.goLinkId}/aliases/${params.aliasId}`, { go_link_alias: { name: params.name } }),
  },
})

export default golinks
