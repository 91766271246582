import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const useUserPortals = (containerId: string, mentionedUsers) => {
  const [portals, setPortals] = useState<React.ReactPortal[]>([])
  const mappedUsers = JSON.stringify(mentionedUsers.map(user => [user.id, user.preferredFullName]))

  useEffect(() => {
    const newPortals: React.ReactPortal[] = []

    mentionedUsers.forEach((mentionedUser) => {
      document
        .querySelectorAll(`#${containerId} [data-type='mention'][data-id='${mentionedUser.id}'`)
        .forEach((el: HTMLSpanElement, i: number) => {
          if (el.textContent?.startsWith('@')) {
            el.textContent = ''
          }

          const portal = ReactDOM.createPortal(
            <EmployeeLinkWithPopover
              user={mentionedUser}
              id={`${containerId}-popover-user-${mentionedUser.id}-${i}`}
              className='cy-employee-popover-portal'
              hideImage
              showLinkColor
            />,
            el
          )

          newPortals.push(portal)
        })
    })

    setPortals(newPortals)
  }, [mappedUsers, containerId])

  return portals
}

export default useUserPortals
