import Axios from 'axios'

export default {
  uniquePageViews(params) {
    return Axios.post('/api/admin/analytics/pages/unique_page_views', params)
  },

  popularPages(params) {
    return Axios.post('/api/admin/analytics/pages/popular_pages', params)
  },

  stalePages(params) {
    return Axios.post('/api/admin/analytics/pages/stale_pages', params)
  },

  inactiveOwnerPages(params) {
    return Axios.post('/api/admin/analytics/pages/inactive_page_owners', params)
  },

  readTime(pageId, params) {
    return Axios.post(`/api/admin/analytics/pages/${pageId}/read_time`, params)
  },

  pageUsage(pageId, params) {
    return Axios.post(`/api/admin/analytics/pages/${pageId}/page_usage`, params)
  },
}
