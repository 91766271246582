import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ClearyVideoPlayer from 'components/common/video/clearyVideoPlayer'
import getStyleObjectFromString from 'utils/getStyleObjectFromString'

export const replaceVideosWithCustomVideoPlayer = (html: string) => {
  if (!html) return html

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const videos = doc.querySelectorAll('video[data-video-id]')

  videos.forEach((video) => {
    const videoContainer = document.createElement('span')

    videoContainer.setAttribute('class', 'video-player-container')
    videoContainer.setAttribute('data-video-id', video.getAttribute('data-video-id') || '')
    videoContainer.setAttribute('data-video-autoplay', video.getAttribute('autoplay') || '')
    videoContainer.setAttribute('style', video.getAttribute('style') || '')

    video.parentElement?.replaceChild(videoContainer, video)
  })

  return doc.body.innerHTML
}

const useVideoPortals = (containerId: string, richTextHtml: string, recordType: string, recordId: string) => {
  const [portals, setPortals] = useState<React.ReactPortal[]>([])

  useEffect(() => {
    const newPortals: React.ReactPortal[] = []

    const videos = document.querySelectorAll(`#${containerId} .video-player-container[data-video-id]`)

    videos.forEach((el: HTMLAnchorElement) => {
      const portal = ReactDOM.createPortal(
        <ClearyVideoPlayer
          videoId={el.getAttribute('data-video-id')}
          recordType={recordType}
          recordId={recordId}
          style={getStyleObjectFromString(el.getAttribute('style') || '')}
          autoPlay={el.getAttribute('data-video-autoplay') === 'true'}
        />,
        el
      )

      newPortals.push(portal)
    })

    setPortals(newPortals)
  }, [richTextHtml, containerId])

  return portals
}

export default useVideoPortals
