import React from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'
import InfoTooltip from 'components/common/infoTooltip'

interface CardDropdownMenuItemProps {
  className?: string
  link?: string
  primaryText?: any
  subtext?: string
  onClick?: () => void
  width?: string
  icon?: any
  disabled?: boolean
  tooltipText?: string
}

const CardDropdownMenuItem = ({
  className,
  link,
  primaryText,
  subtext,
  onClick,
  width = '300px',
  icon = null,
  disabled = false,
  tooltipText,
}: CardDropdownMenuItemProps) => {
  const renderContent = () => (
    <div className='d-flex justify-content-start align-items-center'>
      <div>
        <div className='d-flex flex-row align-items-center'>{icon}{primaryText}</div>
        {subtext && <div className='text-secondary text-small'>{subtext}</div>}
      </div>
      {tooltipText && <InfoTooltip text={tooltipText} />}
    </div>
  )

  const onClickAction = disabled ? undefined : onClick

  return (
    <div
      className={classNames('Card-dropdownMenuItem pointer px-2 py-1', className, { disabled })}
      onClick={onClickAction}
      style={{ width }}
    >
      {link ? (
        <Link to={link} className='text-primary'>
          {renderContent()}
        </Link>
      ) : (
        renderContent()
      )}
    </div>
  )
}

export default CardDropdownMenuItem
