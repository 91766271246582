import React from 'react'
import Modal from 'components/common/modal'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import { ButtonSmallNarrow } from 'components/common/buttons'
import PaginationContainer from 'components/common/tables/paginationContainer'
import useMemoryPaginationAndSearch from 'components/common/hooks/useMemoryPaginationAndSearch'

const I18N = i18nPath('views.celebrations.invited_people')


const UserRow = ({ user, isFollower }) => (
  <>
    <div className='d-flex align-items-center justify-content-between'>
      <div>
        <EmployeeMiniCard employee={user} />
      </div>

      <div className='type-column'>
        {I18NCommon(isFollower ? 'follower' : 'invited')}
      </div>
    </div>

    <hr className='my-1' />
  </>
)

const InvitedPeopleModal = ({
  users, followersById, onClose, helperText,
}) => {
  const {
    paginatedData: usersPaginated,
    paginationContainerProps,
    searchInputProps,
  } = useMemoryPaginationAndSearch(users, { searchField: 'preferredFullName' })

  return (
    <Modal
      size='md'
      visible
      className='InvitedPeopleModal'
      toggle={onClose}
    >

      <h4>{I18N('modal.header', { count: users.length })}</h4>
      <div className='text-secondary mb-4'>
        {helperText}
      </div>

      <input
        {...searchInputProps}
        type='text'
        placeholder={I18N('search_placeholder')}
        className='w-100 mb-3'
      />

      <div className='d-flex align-items-center justify-content-between'>
        <div>
          <b>{I18NCommon('name')}</b>
        </div>
        <div className='type-column'>
          <b>{I18NCommon('type')}</b>
        </div>
      </div>

      <hr className='mb-1' />

      {usersPaginated.length === 0 && (
        <p>{I18N('no_users_match_the_search_criteria')}</p>
      )}

      {usersPaginated.map(user => (
        <UserRow
          key={user.id}
          user={user}
          isFollower={!!followersById[user.id]}
        />
      ))}

      <PaginationContainer
        {...paginationContainerProps}
      />

      <div className='text-right'>
        <ButtonSmallNarrow onClick={onClose} className='mt-4'>
          {I18NCommon('close')}
        </ButtonSmallNarrow>
      </div>
    </Modal>
  )
}

export default InvitedPeopleModal
