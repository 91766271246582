import React, { useState } from 'react'
import PaperPlaneSendIcon from 'components/icons/paperPlaneSendIcon'

import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { useDispatch, useSelector } from 'react-redux'
import richTextInlineCommentSlice from 'redux/slices/richTextInlineComments'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const RichTextInlineCommentEditor = ({
  richTextId,
  referenceText,
  onCreateSuccess,
}) => {
  const dispatch = useDispatch()
  const [content, setContent] = useState('')
  const { isSaving } = useSelector(richTextInlineCommentSlice.selectors.getMetaData())

  const createInlineComment = () => {
    const onSuccess = (comment) => {
      setContent('')
      onCreateSuccess(comment?.data?.data || {})
    }

    const params = {
      content,
      referenceText,
    }

    dispatch(richTextInlineCommentSlice.asyncActions.create(richTextId, params, onSuccess))
  }

  const onKeyDown = (_eventView, e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      createInlineComment()
      return true
    }

    return false
  }

  return (
    <div
      className='RichTextInlineCommentEditor position-relative mt-1'
    >
      {isSaving ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          <TiptapEditor
            html={content}
            onChange={newContent => setContent(newContent)}
            toolbarItems={[]}
            className='p-2'
            handleKeyDown={onKeyDown}
          />
          <div className='position-absolute right-0 bottom-0 zindex-base'>
            <button
              onClick={createInlineComment}
              disabled={_.isEmpty(content)}
              className='h-100 btn btn-icon'
            >
              <PaperPlaneSendIcon className='' />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default RichTextInlineCommentEditor
