import generateCrudRoutes from 'services/generateCrudRoutes'

export const buildPromptCollectionPayload = promptCollection => _.pick(promptCollection, [
  'id',
  'name',
  'description',
  'prompts',
  'targetingRules',
])

const promptCollections = generateCrudRoutes(
  '/api/admin/ai/chat/prompt_collections',
  'prompt_collection',
  {},
  buildPromptCollectionPayload
)

export default promptCollections
