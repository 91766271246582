import { SimpleUserType } from 'types/user'

export enum TypeEnum {
  Attribute = 'attribute',
  GroupType = 'group_type',
  ExtraUserField = 'extra_user_field',
  Reports = 'reports',
  Tenure = 'tenure',
  Or = 'or',
  And = 'and',
}
export const TypeValues = Object.values(TypeEnum)

export enum OperatorEnum {
  Is = 'is',
  Not = 'not',
  ChangesTo = 'changes_to',
  ChangesFrom = 'changes_from',
  Into = 'into',
  DirectlyTo = 'directly_to',
  GreaterThan = 'greater_than',
  LessThan = 'less_than',
}
export const OperatorValues = Object.values(OperatorEnum)

export interface AudienceRuleType {
  type?: TypeEnum
  key?: (number|string)
  operator?: OperatorEnum
  values?: (number|string)[]
  reactKey?: string
}

export default interface TargetingRulesType {
  targetEntireCompany: boolean
  rules: AudienceRuleType[]
  users: SimpleUserType[]
  userIds?: string[]
}
