import Axios from 'axios'
import searchURI from 'services/searchURI'

const buildCorrespondentPayload = correspondent => _.pick(correspondent, [
  'group_id',
  'role',
  'includeGroupMembers',
])

const adminCorrespondents = {
  fetchAll(opts = {}) {
    return Axios.get(searchURI('/api/admin/journey/correspondents', opts))
  },

  create(correspondent) {
    return Axios.post('/api/admin/journey/correspondents', { correspondent: buildCorrespondentPayload(correspondent) })
  },

  destroy(correspondentId, correspondentAttrs = {}) {
    return Axios.delete(`/api/admin/journey/correspondents/${correspondentId}`, { data: correspondentAttrs })
  },
}

export default adminCorrespondents
