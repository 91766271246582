import React, { useEffect, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Link } from 'react-router-dom'
import SayThanksModal from 'components/celebrations/sayThanksModal'
import useIsCelebrationForCurrentUser from 'components/celebrations/hooks/useIsCelebrationForCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SocialShareModal from 'components/celebrations/socialShareModal'
import useSocialShare from 'components/celebrations/hooks/useSocialShare'
import { ButtonSecondary } from 'components/common/buttons'
import { useSlideShow } from 'pages/celebrations/slide_show_page/useSlideShow'
import { i18nPath } from 'utils/i18nHelpers'
import { SAY_THANKS_MODAL } from 'components/celebrations/sayThanksButton'
import { SOCIAL_SHARE_MODAL } from 'components/celebrations/greeting_card_actions/celebrationModalContext'
import CelebrationCoverSlide from './celebrationCoverSlide'
import CelebrationCommentSlide from './celebrationCommentSlide'
import CelebrationSliderControls from './celebrationSliderControls'

const I18N = i18nPath('views.celebrations.say_thanks_modal')

const Progress = ({ autoPlayTime, isViewed = false, slideDisplayTime }) => (
  <div className='SlideShowPage__Slider__Progress'>
    <div
      className='active'
      style={{ width: `${isViewed ? '100' : (autoPlayTime * 100) / slideDisplayTime}%` }}
    />
  </div>
)

const SliderProgress = ({
  currentSlide, numberOfSlides, autoPlayTime, slideDisplayTime,
}) => (
  <div className='SlideShowPage__Slider__SliderProgress'>
    {Array.from({ length: numberOfSlides }, (_, i) => i + 1).map(slideNumber => (
      <Progress
        key={slideNumber}
        autoPlayTime={slideNumber === currentSlide ? autoPlayTime : 0}
        isViewed={slideNumber < currentSlide}
        slideDisplayTime={slideDisplayTime}
      />
    ))}
  </div>
)

const CurrentSlide = () => {
  const {
    currentSlide,
    comments,
    autoPlayTime,
    celebration,
    numberOfSlides,
    slideClass,
    slideDisplayTime,
  } = useSlideShow()

  const slideComments = comments?.slice(
    (currentSlide - 2), (currentSlide - 1)
  )

  const progress = (
    <SliderProgress
      currentSlide={currentSlide}
      numberOfSlides={numberOfSlides}
      autoPlayTime={autoPlayTime}
      slideDisplayTime={slideDisplayTime}
    />
  )

  return (
    currentSlide === 1 ? (
      <div className='SlideShowPage__Slider__CoverSlide__wrapper'>
        {progress}

        <CelebrationCoverSlide celebration={celebration} />
      </div>
    ) : (
      <div className='SlideShowPage__Slider__CommentSlide__wrapper'>
        {progress}

        <div style={{ width: '1000px' }}>
          <TransitionGroup>
            <CSSTransition
              key={currentSlide}
              timeout={300}
              classNames={slideClass}
            >
              <CelebrationCommentSlide comments={slideComments} />
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    )
  )
}

const CelebrationSlider = ({
  ...props
}) => {
  const {
    celebration,
    start,
    currentSlide,
    finished,
  } = useSlideShow()

  const [currentOpenModal, setCurrentOpenModal] = useState<any>()
  const isCurrentUser = useIsCelebrationForCurrentUser(celebration)
  const showThankYouButton = !celebration.thanksMessage || !_.isEmpty(celebration.missingSayThanksRecipientIds)
  const currentCompany = useCurrentCompany()
  const socialShare = currentCompany?.settings?.celebrations?.socialShare

  const {
    availableNetworks,
    handleShareClick,
    onSocialSharePreview,
    resolveUrl,
    suggestedText: socialShareSuggestedText,
  } = useSocialShare(celebration)

  useEffect(() => {
    if (!finished) return

    if (isCurrentUser) {
      if (showThankYouButton) {
        setCurrentOpenModal(SAY_THANKS_MODAL)
      } else if (socialShare) {
        setCurrentOpenModal(SOCIAL_SHARE_MODAL)
      }
    }
  }, [finished])

  return (
    <div className='SlideShowPage__Slider__wrapper' {...props}>
      <div className='SlideShowPage__Slider'>
        <div>
          <CelebrationSliderControls direction='left' />

          <CurrentSlide />

          <CelebrationSliderControls direction='right' cover={currentSlide === 1} />
        </div>
      </div>

      {currentOpenModal === SAY_THANKS_MODAL && (
        <SayThanksModal
          celebration={celebration}
          visible
          onClose={() => setCurrentOpenModal(null)}
          missingSayThanksRecipientIds={celebration.missingSayThanksRecipientIds}
          onThanksSent={(isResend) => {
            if (isResend || !socialShare) return

            setCurrentOpenModal(SOCIAL_SHARE_MODAL)
          }}
          thanksSaidContent={(
            <div className='d-flex justify-content-center gap-4'>
              <ButtonSecondary
                onClick={() => {
                  setCurrentOpenModal(null)
                  start()
                }}
              >
                {I18N('replay')}
              </ButtonSecondary>

              <Link
                className='btn btn-primary'
                to={`/people/celebrations/${celebration.id}`}
              >
                {I18N('view_message_board')}
              </Link>
            </div>
          )}
        />
      )}

      {currentOpenModal === SOCIAL_SHARE_MODAL && (
        <SocialShareModal
          className='modal-enter'
          visible
          handleShareClick={handleShareClick('follow_up_modal')}
          resolveUrl={resolveUrl}
          availableNetworks={availableNetworks}
          onClose={() => setCurrentOpenModal(null)}
          onPreview={onSocialSharePreview}
          suggestedText={socialShareSuggestedText}
        />
      )}
    </div>
  )
}

export default CelebrationSlider
