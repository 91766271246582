const queryParamsFromHeaders = (response) => {
  const page = parseInt(response.headers['x-page'])
  const perPage = parseInt(response.headers['x-per-page'])
  const total = parseInt(response.headers['x-total'])
  const totalPages = Math.ceil(total / perPage)

  return {
    page,
    perPage,
    total,
    totalPages,
  }
}

export default queryParamsFromHeaders

export const defaultPaginationParams = {
  page: 1,
  perPage: 10,
  totalPages: null,
  total: null,
}
