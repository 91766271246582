import { createSlice } from '@reduxjs/toolkit'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { ReduxState } from 'redux/redux'

export interface VideoUploadData {
  [videoId: string]: {
    progress?: number | null
    errorMessage?: string
  }
}

export interface VideoUploadState {
  uploadData: VideoUploadData
  meta: DefaultMetaType
}

export const initialState: VideoUploadState = {
  uploadData: {},
  meta: {
    ...defaultMeta,
  },
}

const videoUploadSlice = createSlice({
  name: 'videoUpload',
  initialState,
  reducers: {
    ...defaultActions,

    setUploadProgress: (state, action) => {
      const { videoId, progress } = action.payload
      if (!state.uploadData[videoId]) {
        state.uploadData[videoId] = {}
      }

      state.uploadData[videoId].progress = progress
    },
  },
})

const selectors = {
  getVideoUploadData: (videoId: string) => (state: ReduxState) => state.videoUploads.uploadData[videoId] || {},
}

export default {
  ...videoUploadSlice,
  selectors,
}
