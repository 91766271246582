import React from 'react'
import classNames from 'classnames'
import useWindowResize from 'components/common/hooks/useWindowResize'
import CelebrationImage from 'components/celebrations/celebrationImage'

interface Props {
  celebration: any
  headlineDisplay: any
  children?: any
  showBanner?: boolean
  className?: string
  forNewCelebration?: boolean
}


const GreetingCardBannerImage = ({
  celebration,
  headlineDisplay,
  children = null,
  showBanner = false,
  className: classNameProp = '',
  forNewCelebration = false,
}: Props) => {
  const { isUpToLargeScreen } = useWindowResize()
  const withCustomImage = !!celebration.coverImageUrl
  const mobileWithCustomImage = isUpToLargeScreen && withCustomImage

  const style = {
    backgroundImage: withCustomImage ? `url(${celebration.coverImageUrl})` : undefined,
  }

  const className = classNames(
    'GreetingCardBannerImage',
    celebration.type,
    classNameProp,
    {
      'confetti-background': !withCustomImage,
      'px-5': !mobileWithCustomImage,
      mobileWithCustomImage,
      withCustomImage,
      showBanner,
    }
  )

  const headlineWrapperClassName = classNames(
    'CelebrationHeadlineWrapper w-100 d-flex justify-content-center h-100',
    {
      'flex-column': !forNewCelebration,
      'text-center align-items-center': forNewCelebration,

    }
  )

  return (
    <div className={className} style={style}>
      <div className={headlineWrapperClassName}>{headlineDisplay}</div>

      {!withCustomImage && (
        <CelebrationImage type={celebration.type} isCustomCelebration={celebration.customCelebration} />
      )}

      {children}
    </div>
  )
}

export default GreetingCardBannerImage
