import build from 'redux-object'
import { createSlice } from '@reduxjs/toolkit'
import appSignal from 'services/appSignal'

import API from 'services/api'

import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import entitySlice from 'redux/slices/entities'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { showToastMessage } from 'redux/slices/toasts'


export const defaultCommentsState = {
  commentIds: [],
  meta: { ...defaultMeta, queryParams: defaultPaginationParams, isDeleting: false },
}

// Helper to initialize the state[type][commentableId] if it doesn't exist
const initializeStateForKey = (state, type, commentableId) => {
  if (!state[type]) {
    state[type] = {}
  }
  if (!state[type][commentableId]) {
    state[type][commentableId] = _.cloneDeep(defaultCommentsState)
  }
}

// Helper to set meta properties
const setMetaForKey = (state, action, propName) => {
  const { type, commentableId, value } = action.payload
  initializeStateForKey(state, type, commentableId)
  _.set(state, [type, commentableId, 'meta', propName], value)
}

// The slice state will be such that the first key is the type (article, celebration, etc) and the second key is the commentableId
// This way we have the information for every commentable, it can not be shared because we have multiple comment widgets in the home page.
// EX: {'celebration': { 1: { commentIds: [], meta: {} }, 2: { commentIds: [], meta: {} } }, 'article': { 1: { commentIds: [], meta: {} }} }
export const initialState = {}

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    ...defaultActions,

    addCommentIds(state, action) {
      const { type, commentableId, commentIds } = action.payload
      initializeStateForKey(state, type, commentableId)

      state[type][commentableId].commentIds = _.uniq([...state[type][commentableId].commentIds, ...commentIds])
    },

    addCommentId(state, action) {
      const { type, commentableId, commentId } = action.payload
      initializeStateForKey(state, type, commentableId)

      state[type][commentableId].commentIds.push(commentId)
    },

    isLoading(state, action) {
      setMetaForKey(state, action, 'isLoading')
    },

    isSaving(state, action) {
      setMetaForKey(state, action, 'isSaving')
    },

    isDeleting(state, action) {
      setMetaForKey(state, action, 'isDeleting')
    },

    setQueryParams(state, action) {
      const { type, commentableId, queryParams } = action.payload
      initializeStateForKey(state, type, commentableId)

      state[type][commentableId].meta.queryParams = queryParams
    },

    reset(state, action) {
      const { type, commentableId } = action.payload

      // There is nothing to reset, so we just return
      if (!state[type] || !state[type][commentableId]) return

      state[type][commentableId] = { ...defaultCommentsState }
    },
  },
})

const asyncActions = {
  fetchAll: (commentable, type, queryParams) => async (dispatch) => {
    const commentableId = commentable.id
    dispatch(commentSlice.actions.isLoading({ type, commentableId, value: true }))

    try {
      const response = await API.comments.fetchAll(queryParams, commentable, type)
      const commentIds = response.data.data.map(comment => comment.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(commentSlice.actions.addCommentIds({ type, commentableId, commentIds }))
      dispatch(commentSlice.actions.setQueryParams({
        type,
        commentableId,
        queryParams: queryParamsFromHeaders(response),
      }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(commentSlice.actions.isLoading({ type, commentableId, value: false }))
    }
  },

  create: (commentable, type, body, onSuccess = () => {}, media = null) => async (dispatch) => {
    const commentableId = commentable.id
    dispatch(commentSlice.actions.isSaving({ type, commentableId, value: true }))

    try {
      const response = await API.comments.create(commentable, type, body, media)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(commentSlice.actions.addCommentId({ type, commentableId, commentId: response.data.data.id }))

      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)

      if (e?.response?.data?.error?.message) {
        const message = e?.response?.data?.error?.message
        dispatch(showToastMessage({ message, type: 'error' }))
      }
    } finally {
      dispatch(commentSlice.actions.isSaving({ type, commentableId, value: false }))
    }
  },

  update: (comment, type, body, onSuccess = () => {}, media = null) => async (dispatch) => {
    const commentableId = comment.commentable.id
    dispatch(commentSlice.actions.isSaving({ type, commentableId, value: true }))

    try {
      const response = await API.comments.update(comment.commentable, comment, type, body, media)
      dispatch(entitySlice.actions.update({ data: response.data }))

      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(commentSlice.actions.isSaving({ type, commentableId, value: false }))
    }
  },

  delete: (comment, type, onSuccess = () => {}) => async (dispatch) => {
    const commentableId = comment.commentable.id
    dispatch(commentSlice.actions.isDeleting({ type, commentableId, value: true }))

    try {
      await API.comments.destroy(comment.commentable, comment, type)
      dispatch(entitySlice.actions.remove({ id: comment.id, type: 'comment' }))

      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(commentSlice.actions.isDeleting({ type, commentableId, value: false }))
    }
  },
}

const selectors = {
  getComments: (type, id) => (state) => {
    const commentIds = _.get(state, ['comments', type, id, 'commentIds'], [])
    return commentIds.map(id => build(state.entities, 'comment', id)).filter(Boolean) || []
  },

  getMetaData: (type, id) => state => _.get(state, ['comments', type, id, 'meta'], defaultCommentsState.meta),
}

export default {
  ...commentSlice,
  asyncActions,
  selectors,
}
