import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { EDIT_VIEW, SOURCE_CODE_VIEW } from 'components/common/tiptap/tiptapEditorSourceCodeView'

const I18N = i18nPath('views.tiptap.toolbar')

const SourceCodeViewToggle = ({
  isSourceCodeViewEnabled,
  setCurrentView,
}) => (
  <ToolbarButton
    className='SourceCodeViewToggle'
    onClick={() => setCurrentView(isSourceCodeViewEnabled ? EDIT_VIEW : SOURCE_CODE_VIEW)}
    tooltipText={I18N('source_code_view_tooltip')}
    isActive={isSourceCodeViewEnabled}
  >
    <CdnSvg src='/images/tiptap/angle-brackets.svg' />
  </ToolbarButton>
)

export default SourceCodeViewToggle
