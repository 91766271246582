import React, { useEffect, useRef, useState } from 'react'
import { BubbleMenu } from '@tiptap/react'
import useClickOutside from 'components/common/hooks/useClickOutside'
import CutMenuItem from 'components/common/tiptap/menus/context_menu_items/cutMenuItem'
import CopyMenuItem from 'components/common/tiptap/menus/context_menu_items/copyMenuItem'
import TableMenuItems from 'components/common/tiptap/menus/context_menu_items/table_menu_items/tableMenuItems'
import tippy from 'tippy.js'

const ContextMenu = ({ editor, isOpen, onClose }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [internalOpen, setInternalOpen] = useState(false)
  const [height, setHeight] = useState(0)

  useClickOutside({ onClickOutside: onClose, ref, enableClickOutside: isOpen })

  useEffect(() => {
    setHeight(isTableActive ? TableMenuItems.reduce((acc, item) => acc + item.height, 0) : 0)

    setTimeout(() => {
      tippy(editor.options.element as HTMLElement).hide()
      tippy(editor.options.element as HTMLElement).show()

      setInternalOpen(isOpen)
    }, isOpen ? 100 : 0)
  }, [isOpen])

  // const isLinkActive = editor.isActive('link')
  // const isEmbedActive = editor.isActive('inlineEmbed') || editor.isActive('blockEmbed')
  // const isImageActive = editor.isActive('inlineImage') || editor.isActive('blockImage')
  const isTableActive = editor.isActive('tableCell') || editor.isActive('tableHeader')
  // const isVideoActive = editor.isActive('blockVideo') || editor.isActive('inlineVideo')

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 0,
        interactive: true,
        placement: 'right',
      }}
      shouldShow={() => true}
      className='ClearyBubbleMenuWrapper'
    >
      <div style={{ height: `${height}px` }}>
        {internalOpen && (
          <div
            ref={ref}
            className='ContextMenu'
            onContextMenu={(e) => {
              e.preventDefault()
            }}
          >
            {/* Cut and copy appear everywhere so we'll add them, for now, only to the table context menu */}
            {/* <CutMenuItem editor={editor} onClose={onClose} />
            <CopyMenuItem editor={editor} onClose={onClose} /> */}
            {/* paste is not allowed by document.execCommand, so pushing it out for now */}

            {isTableActive && (
              <>
                {TableMenuItems.map((Item, index) => (
                  <Item.Component key={index} editor={editor} onClose={onClose} />
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </BubbleMenu>
  )
}

export default ContextMenu
