import React from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.common.beta_tag')

const BetaTag = ({
  className = '',
}) => (
  <div className={classNames('BetaTag text-smallest d-flex align-items-center justify-content-center font-weight-500', className)}>
    {I18N('beta')}
  </div>
)

export default BetaTag
