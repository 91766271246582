import { showToastMessage } from 'redux/slices/toasts'
import appSignal from 'services/appSignal'

const extractToastMessage = payload => payload?.data?.error?.message

// Not testing this method, it is being migrated away from
const checkForError = (payload) => {
  let hasError = false
  let error = payload

  if (payload?.status) {
    hasError = payload.status === 404 || payload.status === 401
  }

  if (payload?.data) {
    const fallbackErrorMessage = payload?.status === 413 ? I18n.t('views.common.request_too_large') : payload?.statusText

    error = payload.data?.error || fallbackErrorMessage
  }

  return {
    error,
    hasError,
  }
}

const getResponseOrThrow = (error) => {
  if (error.response) {
    return error.response
  }

  throw error
}

const sendErrorToAppSignalAndShowToast = (e, dispatch, backupMesssage) => {
  appSignal.sendErrorUnlessClearyBackendError(e)
  const { error } = checkForError(getResponseOrThrow(e))
  dispatch(showToastMessage({ message: error.message || backupMesssage, type: 'error' }))
}

const getValidationErrors = (error) => {
  let errorMessage = error.message
  if (error.errors) {
    errorMessage = Object.values(error.errors).map(value => value.join(', ')).join(', ')
  }

  return errorMessage
}

export {
  checkForError,
  getResponseOrThrow,
  extractToastMessage,
  sendErrorToAppSignalAndShowToast,
  getValidationErrors
}
