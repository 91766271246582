import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import { useSelector, useDispatch } from 'react-redux'
import uploadVideoFromFile from 'components/common/tiptap/utils/uploadVideoFromFile'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.tiptap.toolbar')

const MAX_VIDEO_SIZE_IN_MB = 5000

const VideoUpload = ({ editor, closeDropdown }) => {
  const dispatch = useDispatch()
  const [isCreatingVideos, setIsCreatingVideos] = useState(false)
  const clearyRichTextId = editor?.options?.editorProps?.attributes?.richTextId

  const onDrop = async (files) => {
    setIsCreatingVideos(true)

    const insertVideo = (videoId) => {
      editor.chain().focus().setInlineVideo({ 'data-video-id': videoId }).run()
    }

    Promise.all(
      files.map(async file => uploadVideoFromFile(
        file,
        clearyRichTextId,
        dispatch,
        videoId => videoId
      ))
    ).then((videoIds) => {
      videoIds.filter(Boolean).forEach(videoId => insertVideo(videoId))

      setIsCreatingVideos(false)
      closeDropdown()
    })
  }

  if (isCreatingVideos) {
    return <CirclesLoadingIndicator />
  }

  return (
    <FileSelectDropzone
      onDrop={onDrop}
      maxSizeInMB={MAX_VIDEO_SIZE_IN_MB}
      accept={['video/*']}
    >
      <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
        <div className='font-weight-700'>{I18N('drop_file')}</div>
        <div className='text-secondary'>{I18N('or_click')}</div>
      </div>
    </FileSelectDropzone>
  )
}

export default VideoUpload
