import React, { useState } from 'react'
import VideoRecorder from 'components/common/videoRecorder'
import { REMOVE_MEDIA } from 'services/api/comments'

const SignWithVideoFromCamera = ({ comment, setMedia, media }) => {
  const setVideoFile = (file) => {
    setMedia({ type: 'video_record', file })
  }

  const callDeleteRecording = (willDelete) => {
    if (willDelete) setMedia({ type: REMOVE_MEDIA, file: REMOVE_MEDIA })
  }

  const recordingUrl = media.type === 'video_record'
    || (media.type !== REMOVE_MEDIA && comment?.media?.type === 'video')
    ? comment?.media?.url
    : null

  return (
    <VideoRecorder
      setVideoFile={setVideoFile}
      recordingUrl={recordingUrl}
      willDeleteRecording={media.type === REMOVE_MEDIA}
      setWillDeleteRecording={callDeleteRecording}
    />
  )
}

export default SignWithVideoFromCamera

