import React, { useEffect } from 'react'

import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import AiPromptRecordMentionExtension, { AiPromptRecordMentionNode } from 'components/common/tiptap/extensions/aiPromptRecordMentionNode'

const AiPromptTiptapEditor = ({
  html,
  onChange,
  editable = true,
  onFocus = () => {},
}) => {
  const editor = useEditor({
    editable,
    extensions: [
      StarterKit.configure({
        blockquote: false,
        bulletList: false,
        codeBlock: false,
        code: false,
        hardBreak: false,
        heading: false,
        horizontalRule: false,
        listItem: false,
        orderedList: false,
      }),
      AiPromptRecordMentionExtension,
      AiPromptRecordMentionNode,
    ],
    autofocus: true,
    content: html,
    onUpdate: ({ editor }) => onChange(editor.getHTML()),
    onFocus: () => onFocus(),
  })

  useEffect(() => {
    if (!editor) return
    if (html === editor.getHTML()) return

    editor?.commands.setContent(html)
  }, [html])

  useEffect(() => {
    editor?.setOptions({ editable })

    if (editable && editor) {
      editor.commands.focus()
    }
  }, [editable])

  return (
    <EditorContent
      className='AiPromptTiptapEditor w-100'
      editor={editor}
    />
  )
}

export default AiPromptTiptapEditor
