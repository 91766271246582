import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import useUserPortals from 'components/common/tiptap/hooks/useUserPortals'
import useMobileLinkPortals, { replaceEmbeddedFilesWithLinks } from 'components/common/tiptap/hooks/useMobileLinkPortals'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useVideoPortals, { replaceVideosWithCustomVideoPlayer } from 'components/common/tiptap/hooks/useVideoPortals'
import isClearyUrl, { isClearyApiUrl } from 'utils/isClearyUrl'
import appSignal from 'services/appSignal'
import useSocialSharePortals from 'components/common/tiptap/hooks/useSocialSharePortals'

const RichTextView = ({
  record,
  recordType,
  richTextKey,
  className = '',
}) => {
  const history = useHistory()
  const { inMobileApp } = useCurrentUser()

  let richTextHtml = record[richTextKey]
  if (inMobileApp) {
    richTextHtml = replaceEmbeddedFilesWithLinks(richTextHtml)
  }

  richTextHtml = replaceVideosWithCustomVideoPlayer(richTextHtml)

  const containerId = `${recordType}-${record.id}-${richTextKey}-rich-text-view`
  const mentionedUsers = record[`${richTextKey}MentionedUsers`] || []
  // portals are rendered after the component has mounted so that we can find the user mentions with data-user-ids in the DOM
  const userPortals = useUserPortals(containerId, mentionedUsers)
  const linkPortals = useMobileLinkPortals(containerId)
  const videoPortals = useVideoPortals(containerId, richTextHtml, recordType, record.id)
  const socialSharePortals = useSocialSharePortals(recordType, record.id, containerId, richTextHtml)

  const richTextContainer = useRef<any>()

  // we want to avoid rerendering the whole page if there's an internal click
  const handleRichTextClick = (event) => {
    // anchor elements may have children nested under them that will be the target
    // of click events, so we want to search up the DOM hierarchy until we
    // find an element with a 'href' attribute, or until we reach the container div
    let targetNode = event.target
    while (targetNode && !targetNode?.href) {
      targetNode = targetNode.parentNode
      if (targetNode === richTextContainer.current) {
        return
      }
    }

    if (!targetNode) return

    try {
      // if the target is a cleary url, and it's not a link to a new tab, and it's not an api url, then we want to handle the click
      // api urls are used for file attachments so we don't want to handle those with history.push
      if (isClearyUrl(targetNode.href) && targetNode.target !== '_blank' && !isClearyApiUrl(targetNode.href)) {
        const clearyRoute = targetNode.href.split(location.origin).pop()
        history.push(clearyRoute)
        event.preventDefault()
      }
    } catch (err) {
      appSignal.sendErrorUnlessClearyBackendError(err)
      event.preventDefault()
    }
  }

  return (
    <div id={containerId} className={className} onClick={handleRichTextClick} ref={richTextContainer}>
      <TipTapRichTextView
        html={richTextHtml}
      />
      {userPortals}
      {linkPortals}
      {videoPortals}
      {socialSharePortals}
    </div>
  )
}

export default RichTextView
