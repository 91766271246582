import GifSelector from 'components/common/tenor/gifSelector'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.sign_greeting_card_page')

interface Props {
  setGifUrl: (gifUrl: string) => void
  gifUrl: string
  initialTerm: string
}

const SignWithGif = ({
  setGifUrl, gifUrl, initialTerm,
}: Props) => (
  <GifSelector
    gifUrl={gifUrl}
    setGifUrl={setGifUrl}
    initialTerm={I18N(`gif_initial_term.${initialTerm}`)}
    width='100%'
  />
)

export default SignWithGif
