import React, { useState } from 'react'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react'
import AiPromptComponent from 'components/common/tiptap/extensions/nodeViews/ai/aiPromptComponent'
import useAiGenerateText from 'components/common/tiptap/hooks/ai/useAiGenerateText'
import { i18nPath } from 'utils/i18nHelpers'
import AiNodeSideMenu from 'components/common/tiptap/extensions/nodeViews/ai/aiNodeSideMenu'

const I18N = i18nPath('views.tiptap.ai')

const AiBlockComponent = ({
  editor,
  node,
  getPos,
  deleteNode,
  updateAttributes,
}) => {
  const editorAttributes = editor?.options?.editorProps?.attributes
  const isEditingTemplate = editorAttributes?.isEditingTemplate
  const clearyRichTextId = editorAttributes?.richTextId
  const prompt = node.attrs.prompt || ''
  const [showPromptEditor, setShowPromptEditor] = useState(isEditingTemplate)
  const [isPromptEditorDisabled, setIsPromptEditorDisabled] = useState(isEditingTemplate)

  const onRegenerateSuccess = (id) => {
    const nodePos = getPos()
    const nodeSize = node.nodeSize
    const textGenerationNode = editor.schema.nodes.aiTextGeneration
    const attrs = { prompt, 'data-text-generation-id': id ?? '' }
    const newNode = textGenerationNode.create(attrs)
    const transaction = editor.view.state.tr.delete(nodePos, nodePos + nodeSize).insert(nodePos, newNode)
    editor.view.dispatch(transaction)
  }

  const onEditPromptClick = () => {
    if (isEditingTemplate) {
      setIsPromptEditorDisabled(!isPromptEditorDisabled)
    } else {
      setShowPromptEditor(!showPromptEditor)
    }
  }

  const onClickOutside = () => {
    if (isEditingTemplate) {
      setIsPromptEditorDisabled(true)
    } else {
      setShowPromptEditor(false)
    }
  }

  const {
    generateText,
  } = useAiGenerateText({ prompt, onCreateSucess: onRegenerateSuccess, extraParams: { clearyRichTextId, creationSource: 'editor' } })

  return (
    <NodeViewWrapper className='AiBlockComponent border-radius'>
      {showPromptEditor && (
        <AiPromptComponent
          editor={editor}
          node={node}
          getPos={getPos}
          updateAttributes={updateAttributes}
          onClickOutside={onClickOutside}
          isDisabled={isPromptEditorDisabled}
          setIsDisabled={setIsPromptEditorDisabled}
          isInsideAiBlock
        />
      )}
      <NodeViewContent as='div' className='NodeViewContent' />
      <AiNodeSideMenu onEditPromptClick={onEditPromptClick} onRegenerateClick={generateText} deleteNode={deleteNode} />
    </NodeViewWrapper>
  )
}

export default AiBlockComponent
