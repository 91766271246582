import React from 'react'

import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import EmployeeMultiLinkWithPopover from 'components/common/employeeMultiLinkWithPopover'
import CdnSvg from 'components/common/cdnSvg'

const CorrespondentIconPath = '/images/correspondentIcon.svg'

const AnonymousUsersList = ({
  totalUserCount,
  shownAvatars,
  size,
}) => (
  <div className='d-flex'>
    {[...Array(Math.min(shownAvatars, totalUserCount))].map((_, index) => (
      <div key={`anonymous-user-${index}`} className='mr-1' style={{ height: size, width: size }}>
        <CdnSvg
          className='AnonymousUserIcon'
          src={CorrespondentIconPath}
        />
      </div>
    ))}
  </div>
)

const UsersList = ({
  users,
  size = '25px',
  linkTo,
  onUserClick,
}) => {
  const history = useHistory()

  const onAvatarClick = (user) => {
    if (onUserClick) {
      onUserClick(user)
    } else {
      history.push(linkTo === 'journeys' ? `/admin/users/${user.id}/journeys` : `/profile/${user.username}`)
    }
  }

  return (
    <div>
      {users && users.map((user, index) => (
        <EmployeeMultiLinkWithPopover
          users={[user]}
          key={user?.id || `user-${index}`}
          placement='bottom'
          linkTo={linkTo}
          onClick={() => onAvatarClick(user)}
        >
          <EmployeeThumbnailPhoto employee={user} size={size} className='thumbnailUserImage' />
        </EmployeeMultiLinkWithPopover>
      ))}
    </div>
  )
}

const RoundedAvatars = ({
  users = [],
  shownAvatars = 5,
  avatarSize = '25px',
  maxUsersListedInDropdown = 99,
  popOverPlacement = 'bottom-start',
  userCount = null,
  className = '',
  linkTo = 'profile',
  fetchMoreUsers = () => null,
  onMoreUsersClick = undefined,
  onUserClick = undefined,
  showAnonymousAvatars = false,
}) => {
  const totalUserCount = userCount || users.length
  const shownUsers = users.slice(0, shownAvatars)
  const dropdownUsers = users.slice(shownAvatars, Math.min(totalUserCount, shownAvatars + maxUsersListedInDropdown))
  const employeesNotDisplayed = Math.max(totalUserCount - maxUsersListedInDropdown - shownAvatars, 0)

  const dropdownUsersCountText = count => (count - shownAvatars > maxUsersListedInDropdown ? `${maxUsersListedInDropdown}+` : `+${count - shownAvatars}`)

  return (
    <div className={classNames('RoundedAvatars d-flex align-items-center', className)}>
      {showAnonymousAvatars ? (
        <AnonymousUsersList
          totalUserCount={totalUserCount}
          shownAvatars={shownAvatars}
          size={avatarSize}
        />
      ) : (
        <UsersList
          users={shownUsers}
          size={avatarSize}
          linkTo={linkTo}
          onUserClick={onUserClick}
        />
      )}
      {totalUserCount > shownAvatars && (
        <EmployeeMultiLinkWithPopover
          users={dropdownUsers}
          active={true}
          scrollable={true}
          employeesNotDisplayed={employeesNotDisplayed}
          placement={popOverPlacement}
          linkTo={linkTo}
        >
          <div
            className='plusUsersContainer rounded-circle pointer text-small'
            style={{ width: avatarSize, height: avatarSize }}
            onMouseEnter={fetchMoreUsers}
            onClick={onMoreUsersClick}
          >
            {dropdownUsersCountText(totalUserCount)}
          </div>
        </EmployeeMultiLinkWithPopover>
      )}
    </div>
  )
}

export default RoundedAvatars
