const styleAttribute = (name: string, defaultValue: string | null = null) => ({
  default: defaultValue,
  renderHTML: (attributes) => {
    if (!attributes[name]) {
      return {}
    }

    return {
      style: `${_.kebabCase(name)}: ${attributes[name]}`,
    }
  },
  parseHTML: element => element.style[name],
})

export default styleAttribute
