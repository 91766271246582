import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'

interface Props {
  gifUrl: string
  className?: string
}

const Gif = ({ gifUrl, className }: Props) => (
  <div className={classNames('TenorGif d-flex justify-content-center', className)}>
    <div>
      <img src={gifUrl} alt='gif' className='TenorGifImg' />

      <CdnSvg src='/images/logos/poweredByTenor.svg' className='d-block mt-2 mr-2 ml-auto PoweredByTenorSVG' />
    </div>
  </div>
)

export default Gif
