import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ToolbarColorPicker from 'components/common/tiptap/toolbar/items/toolbarColorPicker'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.tiptap.toolbar')

const TextBackgroundColorPicker = ({ editor, isDisabled = false }) => (
  <ToolbarColorPicker
    className='TextBackgroundColorPicker'
    onChange={newColor => editor.chain().focus().setBackgroundColor(newColor).run()}
    onRemove={() => editor.chain().focus().unsetBackgroundColor().run()}
    color={editor.getAttributes('textStyle')?.backgroundColor || '#ffffff'}
    tooltipText={I18N('text_background_color_tooltip')}
    isDisabled={isDisabled}
  >
    <CdnSvg src='/images/tiptap/text-background-color.svg' />
  </ToolbarColorPicker>
)

export default TextBackgroundColorPicker
