import useApi from 'components/common/hooks/useApi'
import { useEffect } from 'react'

const useGlobalStateBucket = (apiFunction, bucketName, shouldFetch = true) => {
  const [fetch, apiData] = useApi(apiFunction, {
    globalStateBucket: bucketName,
  })

  useEffect(() => {
    if (_.isEmpty(apiData.data) && shouldFetch) {
      fetch()
    }
  }, [shouldFetch])

  return apiData
}

export default useGlobalStateBucket
