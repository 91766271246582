import React, { useState, useRef } from 'react'
import ToolbarButton from 'components/common/tiptap/toolbar/items/toolbarButton'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import BubbleMenuDeleteButton from 'components/common/tiptap/menus/buttons/bubbleMenuDeleteButton'
import EmbedDropdownButton from 'components/common/tiptap/toolbar/items/embedDropdownButton'
import ChangeSizeButton from 'components/common/tiptap/menus/buttons/changeSizeButton'
import AutoplayButton from 'components/common/tiptap/menus/embeds/autoplayButton'
import EmbedDisplaySelect from 'components/common/tiptap/menus/embeds/embedDisplaySelect'
import TextAlignSelect from 'components/common/tiptap/toolbar/items/textAlignSelect'

const I18N = i18nPath('views.tiptap.toolbar')

const EmbedBubbleMenuOptions = ({ editor }) => (
  <div
    className='EmbedBubbleMenuOptions BubbleMenuOptions d-flex p-2 align-items-center justify-content-center'
  >
    <EmbedDropdownButton
      editor={editor}
      tooltipText={I18NCommon('replace')}
      iconPath='/images/tiptap/arrows-pointing-left-and-right.svg'
    />
    <BubbleMenuDeleteButton editor={editor} />
    <ChangeSizeButton
      editor={editor}
      nodeName={editor.isActive('inlineEmbed') ? 'inlineEmbed' : 'blockEmbed'}
    />
    <AutoplayButton editor={editor} />
    <EmbedDisplaySelect editor={editor} />
    <TextAlignSelect editor={editor} />
  </div>
)

export default EmbedBubbleMenuOptions
