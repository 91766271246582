import Axios from 'axios'
import qs from 'qs'
import snakeCaseKeys from 'utils/snakeCaseKeys'
import generateCrudRoutes from 'services/generateCrudRoutes'

export default {
  aiAssistantSuitableChannels: {
    fetchAll(params = {}) {
      const queryString = qs.stringify(snakeCaseKeys(params))

      return Axios.get(`/api/chat/ai_assistant_suitable_channels?${queryString}`)
    },
  },

  channels: {
    ...generateCrudRoutes('/api/chat/channels', 'channel'),
  },
  workspaces: {
    ...generateCrudRoutes('/api/chat/workspaces', 'workspace'),
  },
}
