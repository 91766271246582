import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  timeout: undefined,
  toastMessage: null,
  toastType: 'success',
}

const toastSlice = createSlice({
  name: 'toasts',
  initialState: defaultState,
  reducers: {
    clearToastMessage: (_state, _action) => defaultState,
    showToastMessage: (state, action) => {
      const { message, type, timeout } = action.payload

      state.toastMessage = message
      state.toastType = type
      state.timeout = timeout
      state.id = Date.now() // We need to have a unique identifier so the toasts shows again when the message does not change
    },
  },
})

const selectors = {
  getToasts: state => state.toasts,
}

export default {
  ...toastSlice,
  selectors,
}

const { showToastMessage, clearToastMessage } = toastSlice.actions
export { showToastMessage, clearToastMessage }
