import amplitude from 'amplitude-js'
import API from 'services/api'

const userPermissionsArray = (permissions) => {
  // currently the permissions is an object
  // we just need to return an array of permissions the user has
  // if clearyAdmin/superAdmin only return that
  if (permissions.clearyAdmin) {
    return ['clearyAdmin']
  } else if (permissions.superAdmin) {
    return ['superAdmin']
  } else {
    return Object.keys(permissions).filter(permission => permissions[permission])
  }
}

export const initTracking = (amplitudeApiKey, currentUser, currentCompany) => {
  const amp = amplitude.getInstance()

  const options = {
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
  }

  // The Ruby gem doesn't accept user ids with less than 5 characters anymore so we need pad zeros for the same logic
  // then we don't duplicate users in Amplitude
  const ampUserId = currentUser?.id?.toString()?.padStart(5, '0')

  amp.init(amplitudeApiKey, ampUserId, options)

  // set current user properties
  if (!_.isEmpty(currentUser)) {
    const identify = new amplitude.Identify()
    // use 'setOnce' to set the variable one time, or 'set' to override previous values for the same key
    identify
      .set('email', currentUser.email)
      .setOnce('company', currentCompany.name)
      .set('active', currentUser.active)
      .set('permissions', userPermissionsArray(currentUser.permissions))
      .set('is_manager', currentUser.manager)
      .set('assigned_city', currentUser.city)
      .set('worker_type', currentUser.workerType)
      .set('cleary_platform', currentUser.inMobileApp ? 'mobile_app' : 'web')

    amp.identify(identify)
  }
}

export const trackAmplitudeEvent = (eventName, properties = {}) => {
  amplitude.getInstance().logEvent(eventName, { ...properties, url: window.location.href })
}

export const trackEvent = (eventName, properties = {}) => API.analytics.trackEvent(eventName, properties)
