import {
  useState, useRef, useEffect, useCallback
} from 'react'
import useBeforeUnload from 'components/common/hooks/useBeforeUnload'
import API from 'services/api'
import { trackEvent } from 'services/tracker'
import isElementVisible from 'utils/isElementVisible'

/**
 * This hook is used to track analytics for a video.
 *
 * It tracks two analytics: the watching sessions and the video displayed.
 *
 * It uses the parent type and id to track analytics specifically to the video parent.
 *
 * It uses the video.played property (see: https://www.w3schools.com/tags/av_prop_played.asp ) to
 * track the time ranges that the user has watched the video and will send them to the server in two
 * cases:
 * 1. When the beforeUnload event is fired (when the user closes the tab or navigates away from the
 *   page).
 * 2. When the current component is unmounted (when the user navigates away from the page).
 *   There is one implementation detail here: the trackSession function is called in a useEffect
 *   hook with an empty dependency array, which means that it will be called only once, when the
 *   component is unmounted. To avoid a stale state of the sessions state, we use a ref to
 *   update the sessions variable when it changes.
 *
 * The videoRef returned by this hook should be used in the video tag.
 *
 * @param recordType the parent record type
 * @param recordId the parent record id
 * @param videoId the video id
 * @returns videoRef to be used in the video tag
 */

const useAnalyticsTracking = (recordType, recordId, videoId, isEditing = false) => {
  const sessionsRef = useRef<any[]>([])
  const trackedDisplayed = useRef<boolean>()

  const handleScroll = element => () => {
    if (!isElementVisible(element) || trackedDisplayed.current) return

    trackDisplayed()
  }

  const trackSession = () => {
    if (sessionsRef.current.length > 0 && recordType && recordId && !isEditing) {
      API.videos.trackWatchingSessions(
        recordType,
        recordId,
        videoId,
        crypto.randomUUID(),
        sessionsRef.current)
    }
  }

  const trackDisplayed = () => {
    if (recordType && recordId && !isEditing) {
      trackedDisplayed.current = true

      trackEvent('video:displayed', {
        trackableId: videoId,
        parentType: recordType,
        parentId: recordId,
      })
    }
  }

  useBeforeUnload(trackSession)

  const updateTimeRanges = videoPlayer => () => {
    const timeRangesAccum: any[] = []

    for (let i = 0; i < videoPlayer.played.length; i += 1) {
      const fromSecond = Math.floor(videoPlayer.played.start(i))
      const toSecond = Math.floor(videoPlayer.played.end(i))

      timeRangesAccum.push({ fromSecond, toSecond })
    }

    sessionsRef.current = timeRangesAccum
  }

  let trackOnScroll

  const videoRef = useCallback((videoPlayer) => {
    if (!videoPlayer) return

    videoPlayer.addEventListener('timeupdate', updateTimeRanges(videoPlayer))

    trackOnScroll = handleScroll(videoPlayer)

    trackOnScroll()
    window.addEventListener('scroll', trackOnScroll)
    window.addEventListener('resize', trackOnScroll)
  }, [])

  useEffect(() => () => {
    if (isEditing) return

    trackSession()

    window.removeEventListener('scroll', trackOnScroll)
    window.removeEventListener('resize', trackOnScroll)
  }, [])

  return { videoRef, sessionsRef }
}

export default useAnalyticsTracking
