import React from 'react'

import SlackIcon from 'components/icons/slackIcon'
import { I18NCommon } from 'utils/i18nHelpers'
import classNames from 'classnames'

const ViaSlackTag = ({ className }: { className?: string }) => (
  <span className={classNames(className, 'font-weight-300 text-muted-dark text-small d-inline-block ml-2')}>
    {I18NCommon('via')} <SlackIcon /> Slack
  </span>
)

export default ViaSlackTag
