import CdnSvg from 'components/common/cdnSvg'
import ClearyTooltip from 'components/common/clearyTooltip'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.greeting_card')

const SocialSharePreviewCTA = ({ onPreview }) => (
  <div className='SocialSharePreviewCTA d-flex align-items-center justify-content-center gap-4 mb-4'>
    <div className='text-secondary text-small '>
      {I18N('social_share_help')}

      <ClearyTooltip
        placement='bottom'
        className='ml-1'
        show={0}
        hide={0}
      >
        <span className='text-small'>{I18N('social_share_help_text')}</span>
      </ClearyTooltip>
    </div>

    <div className='share-this-card-separator border-left h-4' />

    <a onClick={onPreview} className='text-normal'>
      {I18N('preview')}

      <CdnSvg src='/images/tiptap/external-link.svg' />
    </a>
  </div>
)

export default SocialSharePreviewCTA
