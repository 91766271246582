import { Extension } from '@tiptap/core'

export const DEFAULT_LINE_HEIGHT = 'default'
export const LINE_HEIGHTS = [DEFAULT_LINE_HEIGHT, '1', '1.15', '1.5', '2']

interface LineHeightOptions {
  types: string[],
  heights: string[],
  defaultHeight: string,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lineHeight: {
      /**
       * Set the line height attribute
       */
      setLineHeight: (height: string) => ReturnType,
      /**
       * Unset the line height attribute
       */
      unsetLineHeight: () => ReturnType,
    }
  }
}

const LineHeight = Extension.create<LineHeightOptions>({
  name: 'lineHeight',

  addOptions() {
    return {
      types: ['heading', 'paragraph'],
      heights: LINE_HEIGHTS,
      defaultHeight: DEFAULT_LINE_HEIGHT,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: this.options.defaultHeight,
            parseHTML: element => element.style.lineHeight || this.options.defaultHeight,
            renderHTML: (attributes) => {
              if (attributes.lineHeight === this.options.defaultHeight) {
                return {}
              }

              return { style: `line-height: ${attributes.lineHeight}` }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setLineHeight: (height: string) => ({ commands }) => {
        if (!this.options.heights.includes(height)) {
          return false
        }

        return this.options.types.every(type => commands.updateAttributes(type, { lineHeight: height }))
      },

      unsetLineHeight: () => ({ commands }) => this.options.types.every(type => commands.resetAttributes(type, 'lineHeight')),
    }
  },
})

export default LineHeight
