import React, { useState } from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.tiptap.toolbar.zoom_video')

const InsertZoomVideoForm = ({ editor, closeDropdown }) => {
  const [zoomMeetingLink, setZoomMeetingLink] = useState('')
  const [generateRecap, setGenerateRecap] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const clearyRichTextId = editor?.options?.editorProps?.attributes?.richTextId
  const meetingId = zoomMeetingLink?.match(/(?<=zoom.us\/j\/)(\d+)/)?.[0]
  const containsZoom = zoomMeetingLink?.includes('zoom.us')
  const isZoomLinkWithoutMeetingId = containsZoom && !meetingId

  const onInsertSuccess = (data) => {
    const videoId = data?.videoId
    const aiTextGenerationId = data?.aiTextGenerationId
    const aiPrompt = data?.aiPrompt

    if (videoId) {
      editor.chain()
        .focus()
        .insertContent({
          type: 'inlineVideo',
          attrs: {
            'data-video-id': videoId,
          },
        })
        .run()
    }

    if (aiTextGenerationId) {
      editor.chain()
        .focus()
        .insertContent([
          {
            type: 'paragraph',
          },
          {
            type: 'aiTextGeneration',
            attrs: {
              'data-text-generation-id': aiTextGenerationId,
              'prompt': aiPrompt,
            },
          },
        ])
        .run()
    }

    closeDropdown()
  }

  const onInsertError = (data) => {
    setErrorMessage(data?.message)
  }

  const [
    createFromZoomRecording,
    { isLoading: isCreating },
  ] = useApi(API.videos.createFromZoomRecording, {
    onSuccess: onInsertSuccess,
    onError: onInsertError,
  })

  const handleInsert = (e) => {
    e.preventDefault()

    createFromZoomRecording({ meetingId, generateRecap, video: { clearyRichTextId } })
  }

  return (
    <form className='InsertZoomVideoForm d-flex flex-column' onSubmit={handleInsert}>
      <input
        className='w-100'
        value={zoomMeetingLink}
        onChange={e => setZoomMeetingLink(e.target.value)}
        placeholder={I18N('insert_url')}
        autoFocus
      />
      {meetingId && (
        <div className='d-flex align-items-center'>
          <input
            id='generate-recap'
            className='form-check'
            type='checkbox'
            checked={generateRecap}
            onChange={e => setGenerateRecap(e.target.checked)}
          />
          <label className='font-weight-400 mb-0 ml-1 text-small' htmlFor='generate-recap'>
            {I18N('generate_recap')}
          </label>
        </div>
      )}
      {isZoomLinkWithoutMeetingId && (
        <span className='text-danger text-small mb-1'>{I18N('invalid_url')}</span>
      )}
      {meetingId && errorMessage && (
        <span className='text-danger text-small mb-1'>{errorMessage}</span>
      )}
      <div className='d-flex justify-content-end mt-2'>
        <ButtonSmallNarrow
          type='submit'
          disabled={!meetingId || isCreating}
        >
          {I18NCommon('insert')}
        </ButtonSmallNarrow>
      </div>
    </form>
  )
}

export default InsertZoomVideoForm
