import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import BaseContextMenuItem from 'components/common/tiptap/menus/context_menu_items/baseContextMenuItem'
import { ALTERNATING_ROWS_TABLE_CLASS, BORDERLESS_TABLE_CLASS, DASHED_TABLE_CLASS } from 'components/common/tiptap/extensions/nodeViews/tableView'

const I18N = i18nPath('views.tiptap.context_menu.tables')

const tableStyles = [
  {
    label: 'borderless',
    class: BORDERLESS_TABLE_CLASS,
    disabledClass: DASHED_TABLE_CLASS,
  },
  {
    label: 'dashed',
    class: DASHED_TABLE_CLASS,
    disabledClass: BORDERLESS_TABLE_CLASS,
  },
  {
    label: 'alternating_rows',
    class: ALTERNATING_ROWS_TABLE_CLASS,
  },
]

const TableStyleItem = ({ editor, onClose }) => {
  const tableClasses = (editor.getAttributes('table').class?.split(' ') || [])

  return (
    <BaseContextMenuItem
      iconPath='/images/tiptap/table-with-star.svg'
      label={I18N('table_style.label')}
    >
      {tableStyles.map(({ label, class: tableClass, disabledClass }) => (
        <BaseContextMenuItem
          key={label}
          label={I18N(`table_style.submenu.${label}`)}
          isActive={tableClasses.includes(tableClass)}
          disabled={tableClasses.includes(disabledClass)}
          onClick={() => {
            onClose()

            editor.chain().focus().toggleClass(tableClass).run()
          }}
        />
      ))}
    </BaseContextMenuItem>
  )
}

export default TableStyleItem
