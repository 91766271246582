import classNames from 'classnames'
import React from 'react'

// adapted from https://codesandbox.io/p/sandbox/react-easy-crop-with-live-output-kkqj0?file=%2Fsrc%2FApp.jsx

const CroppedImage = ({
  croppedArea, imageUrl, aspect, className = '',
}) => {
  const scale = 100 / croppedArea.width

  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: 'calc(100% + 0.5px)',
    height: 'auto',
  }

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  }

  return (
    <div
      className={classNames('CroppedImage', className)}
      style={{ paddingBottom: `${100 / aspect}%` }}
    >
      <img src={imageUrl} alt='' style={imageStyle} />
    </div>
  )
}

export default CroppedImage
