import { useMemo } from 'react'

const useCelebrationInvitedData = (celebration) => {
  const invitedPeople = useMemo(() => _.uniqBy(
    [
      ...(celebration.invitees || []),
      ...(celebration.celebratedUserFollowers || []),
    ],
    'id'
  ), [celebration.invitees, celebration.celebratedUserFollowers])

  const followersById = useMemo(() => (
    _.keyBy(celebration.celebratedUserFollowers, 'id')
  ), [celebration.celebratedUserFollowers])

  return {
    invitedPeople,
    followersById,
  }
}

export default useCelebrationInvitedData
