import React from 'react'
import CroppedImage from 'components/common/croppedImage'
import SocialShareButton from 'components/common/social_share/socialShareButton'

const removeProtocol = (url?: string) => url?.replace(/(^\w+:|^)\/\//, '')

interface Props {
  articleId?: string
  imageUrl?: string
  title: string
  url: string
  suggestedText?: string
  editMode?: boolean
  croppedArea?: { x: number, y: number, width: number, height: number }
}

const SocialShare = ({
  articleId, imageUrl, title, url, suggestedText, editMode = false, croppedArea,
}: Props) => (
  <div className='SocialShare'>
    <div
      className='border-container overflow-hidden border-radius mb-4 pointer'
      onClick={() => !editMode && window.open(url, '_blank')}
    >
      {imageUrl && croppedArea && (
        <CroppedImage
          imageUrl={imageUrl}
          croppedArea={croppedArea}
          aspect={5}
        />
      )}

      <div className='text-container px-3 py-3'>
        <h4 className='mb-0'>
          {title}
        </h4>

        <p className='text-muted'>
          {removeProtocol(url)}
        </p>
      </div>
    </div>

    <div className='text-center'>
      <SocialShareButton
        articleId={articleId}
        url={url}
        suggestedText={suggestedText}
      />
    </div>
  </div>
)

export default SocialShare
