// adapted from: https://gist.github.com/saitonakamura/d51aa672c929e35cc81fa5a0e31f12a9
const replaceCircularReferences = (val, cache) => {
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakSet
  // for more info about WeakSet - basically allows for GC and objects only
  cache = cache || new WeakSet()

  if (val && typeof val === 'object') {
    if (cache.has(val)) return '[Circular]'

    cache.add(val)

    const obj = (Array.isArray(val) ? [] : {})
    Object.keys(val).forEach(key => obj[key] = replaceCircularReferences(val[key], cache))

    cache.delete(val)
    return obj
  }

  return val
}

export default replaceCircularReferences
