import Axios from 'axios'

const defaultUserSettings = {
  fetchAll() {
    return Axios.get('/api/admin/default_user_settings')
  },

  applySettingsToAllUser(keys) {
    return Axios.post('/api/admin/default_user_settings/apply_settings_to_all_user', { keys })
  },
}

export default defaultUserSettings
