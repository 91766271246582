import { useCallback, useMemo, useRef } from 'react'


// Store the latest version of a callback in a ref
// and return a new function that calls the latest callback from the ref
// usefull when the child component is re-rendered but the event handler is not updated
const useForwardToLatestCallback = (latestCallback: (...args: any) => never) => {
  const latestCallbackRef = useRef(latestCallback)
  latestCallbackRef.current = latestCallback

  return useCallback((...args) => latestCallbackRef.current(...args), [])
}

// Store the latest version of events object in a ref
// and return a new object with the same keys but the values are functions that call the event handler from the ref
// usefull when the child component is re-rendered but the event handlers are not updated
export const useForwardObjectToLatestCallback = (eventsObject: Record<string, any>) => {
  const updatedEventsRef = useRef<any>()
  updatedEventsRef.current = eventsObject

  // for each key in the events object, we will create a new object with the same key
  // and the value will be a function that will call the event handler from the updatedEventsRef

  return useMemo(() => _.mapValues(eventsObject, (_callback, key) => event => updatedEventsRef.current[key](event)), [])
}

export default useForwardToLatestCallback
