import React from 'react'
import classNames from 'classnames'

const HoverTopMenu = ({
  children,
  className = '',
}) => (
  <div className={classNames('HoverTopMenu', className)} contentEditable={false}>
    {children}
  </div>
)

export default HoverTopMenu
