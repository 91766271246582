import React, { useEffect, useRef, useState } from 'react'
import { ToolbarItem } from 'components/common/tiptap/toolbar/toolbar'

const MORE_BUTTON_WIDTH = 38
const MINIMUM_ITEMS_IN_TOOLBAR = 6

const calculateNumberOfItemsInToolbar = (
  itemWidths: number[],
  toolbarContainerWidth: number
) => {
  // given an array of toolbar item widths, and the width of the container,
  // calculate the number of items that can fit in the toolbar

  // sum the running widths of the toolbar items
  // start with the width of the more button because it's not in the itemWidths array
  let runningTotalOfWidths = MORE_BUTTON_WIDTH

  for (let i = 0; i < itemWidths.length; i += 1) {
    if (runningTotalOfWidths + itemWidths[i] > toolbarContainerWidth) {
      return i < MINIMUM_ITEMS_IN_TOOLBAR ? MINIMUM_ITEMS_IN_TOOLBAR : i
    } else {
      runningTotalOfWidths += itemWidths[i]
    }
  }

  return itemWidths.length
}

const useResponsiveToolbar = (
  toolbarRef: React.RefObject<HTMLElement>,
  toolbarItems: ToolbarItem[]
) => {
  const [priorityButtons, setPriorityButtons] = useState<ToolbarItem[]>(toolbarItems)
  const [moreButtons, setMoreButtons] = useState<ToolbarItem[]>([])
  const itemWidths = useRef<undefined | number[]>()

  const onResizeToolbar = () => {
    const toolbarElement = toolbarRef.current
    if (!toolbarElement) return

    const toolbarContainerWidth = toolbarElement.getBoundingClientRect().width
    if (!toolbarContainerWidth) return

    const buttonElements = Array.from(toolbarElement.children)

    // avoid recalculating the itemWidths in each resize change, they should be fixed
    if (!itemWidths.current) {
      itemWidths.current = buttonElements.map(item => item.getBoundingClientRect().width)
    }
    const priorityItemsCount = calculateNumberOfItemsInToolbar(itemWidths.current, toolbarContainerWidth)

    const priorityItems = toolbarItems.slice(0, priorityItemsCount)
    const moreItems = (priorityItems.length !== toolbarItems.length)
      ? toolbarItems.slice(priorityItemsCount, toolbarItems.length) : []

    setPriorityButtons(priorityItems)
    setMoreButtons(moreItems)
  }

  useEffect(() => {
    const toolbarElement = toolbarRef?.current

    if (!toolbarElement) return undefined

    onResizeToolbar()

    const observer = new ResizeObserver(() => {
      onResizeToolbar()
    })

    observer.observe(toolbarElement)

    return () => {
      // Cleanup the observer by unobserving all toolbarElements
      observer.disconnect()
    }
  }, [toolbarItems])

  return [priorityButtons, moreButtons]
}

export default useResponsiveToolbar
