import React from 'react'
import classNames from 'classnames'

interface EmojiDisplayProps {
  display: string
  custom: boolean
  customImgClassName?: string
  emojisClassName?: string
}

export const EmojiDisplay = ({
  display,
  custom,
  customImgClassName,
  emojisClassName,
}: EmojiDisplayProps) => {
  if (custom) {
    return <img src={display} alt='Custom Reaction' className={classNames(customImgClassName, 'CustomReactionImage')} />
  }

  return (
    <p className={classNames(emojisClassName, 'm-0')}>{display}</p>
  )
}

interface EmojiCounterDisplayProps {
  emojiId: string
  display: string
  totalCount: number
  custom: boolean
  onClick?: () => void
  className?: string
}

const EmojiCounterDisplay = ({
  emojiId,
  display,
  totalCount,
  custom,
  onClick = () => {},
  className,
}: EmojiCounterDisplayProps) => (
  <div
    className={classNames('EmojiCounterDisplay d-flex flex-row align-items-center', className)}
    onClick={onClick}
    data-testid={`reaction-count-${emojiId}`}
  >
    <EmojiDisplay display={display} custom={custom} customImgClassName='mr-1' emojisClassName='mr-1 text-small' />
    <p className='m-0 text-small' data-testid='reactions_total_count'>{totalCount}</p>
  </div>
)

export default EmojiCounterDisplay
