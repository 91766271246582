import React, { useEffect, useState } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import ToolbarDropdownButton from 'components/common/tiptap/toolbar/items/toolbarDropdownButton'
import { Button } from 'components/common/buttons'
import { useSelector } from 'react-redux'
import videoSlice from 'redux/slices/videos'
import classNames from 'classnames'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import ImageSelector from 'components/common/imageSelector'

const I18N = i18nPath('views.tiptap.toolbar.video_bubble_menu')

const UploadThumbnailText = () => (
  <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
    <CdnSvg src='/images/tiptap/image.svg' />
    <span className='mb-0 text-small'>{I18N('upload_thumbnail')}</span>
  </div>
)

const VideoThumbnailSelectDropdownContent = ({
  videoId,
  closeDropdown,
}) => {
  const video = useSelector(videoSlice.selectors.getVideo(videoId))
  const currentThumbnail = video.thumbnailUrl || ''
  const currentThumbnailFilename = currentThumbnail?.split('/').pop()
  const thumbnailOptions = video.thumbnails || []

  const [selectedThumbnail, setSelectedThumbnail] = useState(currentThumbnailFilename)
  const [addedImage, setUploadedImage] = useState<any>(null)

  const closeDropdownAndReloadVideo = (video) => {
    closeDropdown()

    const element = document.getElementById(`video-player-${video.id}`) as HTMLVideoElement
    element?.setAttribute('poster', video.thumbnailUrl)
    element?.load()
  }

  const [changeThumbnail, { isLoading: isChangingThumbnail }] = useApi(
    API.videos.changeThumbnail, {
      updateEntitySlice: true,
      toastSuccessMessage: I18N('updated_thumbnail'),
      toastErrorMessage: I18N('error_updating_thumbnail'),
      onSuccess: closeDropdownAndReloadVideo,
    }
  )

  const onImageData = (image) => {
    setUploadedImage(image)
    setSelectedThumbnail(image.preview)
  }

  const onSelectThumbnail = (thumbnail) => {
    setSelectedThumbnail(thumbnail)
  }

  const onSave = () => {
    if (selectedThumbnail === addedImage?.preview) {
      changeThumbnail(videoId, { addedImage })
    } else {
      const thumbnailId = thumbnailOptions.find(({ url }) => url === selectedThumbnail)?.id

      changeThumbnail(videoId, { selectedThumbnail: { id: thumbnailId } })
    }
  }

  return (
    <div className='VideoThumbnailSelectDropdownContent px-2 py-1'>
      <div className='thumbnail-options'>
        <div>
          {addedImage ? (
            <div className={classNames('thumbnail-option', addedImage.preview === selectedThumbnail ? 'selected' : '')}>
              <img src={addedImage.preview} alt='Uploaded image' onClick={() => onSelectThumbnail(addedImage.preview)} />
            </div>
          ) : (
            <ImageSelector
              showLoadedImage={false}
              onImageAdded={onImageData}
              maxSizeInMB={10}
              accept={['image/png', 'image/jpeg']}
              imageText={<UploadThumbnailText />}
            />
          )}
        </div>
        {thumbnailOptions.slice(0, 5).map(({ url }, index) => (
          <div
            key={index}
            className={classNames('thumbnail-option', url.includes(selectedThumbnail) ? 'selected' : '')}
          >
            <img
              key={index}
              src={url}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => onSelectThumbnail(url)}
            />
            {url.includes(currentThumbnailFilename) && <div className='current-thumbnail text-smallest text-white px-1'>{I18N('current_thumbnail')}</div>}
          </div>
        ))}
      </div>
      <div className='d-flex justify-content-between'>
        <div />
        <Button className='mt-2' onClick={onSave} showLoadingSpinner={isChangingThumbnail}>
          {I18NCommon('save')}
        </Button>
      </div>
    </div>
  )
}

const VideoThumbnailSelect = ({ editor, isDisabled = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const isBlockVideo = editor.isActive('blockVideo')
  const selectedNode = isBlockVideo ? 'blockVideo' : 'inlineVideo'
  const currentAttributes = editor.getAttributes(selectedNode)
  const videoId = currentAttributes['data-video-id']

  return (
    <ToolbarDropdownButton
      tooltipText={I18N('change_thumbnail')}
      className='VideoThumbnailSelect'
      dropdownContent={(
        <VideoThumbnailSelectDropdownContent
          videoId={videoId}
          closeDropdown={() => setIsDropdownOpen(false)}
        />
      )}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      isDisabled={isDisabled}
    >
      <CdnSvg src='/images/tiptap/image.svg' />
    </ToolbarDropdownButton>
  )
}

export default VideoThumbnailSelect
