import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { Button, ButtonSecondary } from 'components/common/buttons'
import { useSlideShow } from 'pages/celebrations/slide_show_page/useSlideShow'

const I18N = i18nPath('views.celebrations.greeting_card.slide_show')

const CelebrationSliderActions = ({ setShowEffect }) => {
  const {
    finished, start, autoPlay, toggle, celebration, onExitSlideShow,
  } = useSlideShow()

  return (
    <div className='SlideShowPage__Slider__Actions'>
      <div className='d-flex gap-2'>
        {finished ? (
          <ButtonSecondary
            onClick={() => start()}
            className='replay-btn'
          >
            <CdnSvg src='/images/replay.svg' className='mr-2' />
            {I18N('replay')}
          </ButtonSecondary>
        ) : (
          <ButtonSecondary onClick={toggle} className='autoplay-btn'>
            {autoPlay ? (
              <>
                <CdnSvg src='/images/pauseIcon.svg' className='mr-2' />
                {I18N('autoplay_on')}
              </>
            ) : (
              <>
                <CdnSvg src='/images/playIcon.svg' className='mr-2' />
                {I18N('autoplay_off')}
              </>
            )}
          </ButtonSecondary>
        )}

        {celebration.effect && (
          <div className='SlideShowPage__Slider__Actions__effect-btn' onClick={() => setShowEffect(true)}>
            <span role='img' aria-label='Celebration'>🎉</span>
          </div>
        )}
      </div>

      <div>
        {onExitSlideShow ? (
          <Button variant='secondary' className='mr-2' onClick={onExitSlideShow}>
            {I18N('board_view')}
          </Button>
        ) : (
          <Link to={`/people/celebrations/${celebration.id}`} className='mr-2 btn btn-secondary'>
            {I18N('board_view')}
          </Link>
        )}
      </div>
    </div>
  )
}

export default CelebrationSliderActions
