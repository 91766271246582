import Axios from 'axios'
import searchURI from 'services/searchURI'

const awardedBadges = {
  fetchAll(opts) {
    return Axios.get(searchURI(`/api/badges/${opts.badgeId}/awarded_badges`, opts))
  },

  fetch(badgeId, awardedBadgeId) {
    return Axios.get(`/api/badges/${badgeId}/awarded_badges/${awardedBadgeId}`)
  },

  create(badgeId, recipientIds) {
    return Axios.post(`/api/badges/${badgeId}/awarded_badges`, { recipientIds })
  },

  destroy(id, badgeId) {
    return Axios.delete(`/api/badges/${badgeId}/awarded_badges/${id}`)
  },
}

export default awardedBadges
