import { Extension } from '@tiptap/core'
import styleAttribute from 'components/common/tiptap/extensions/utils/styleAttribute'

// This extension is used to keep some styles passed from other sites (such as GoogleDocs)
const PasteHelper = Extension.create<any>({
  name: 'pasteHelper',

  addGlobalAttributes() {
    return [
      {
        types: ['heading', 'paragraph', 'tableCell', 'listItem', 'textStyle', 'horizontalRule'],
        attributes: {
          margin: styleAttribute('margin'),
          marginBottom: styleAttribute('marginBottom'),
          marginTop: styleAttribute('marginTop'),
          marginRight: styleAttribute('marginRight'),
          // marginLeft is handled by the `indent` extension
          padding: styleAttribute('padding'),
          paddingBottom: styleAttribute('paddingBottom'),
          paddingTop: styleAttribute('paddingTop'),
          paddingLeft: styleAttribute('paddingLeft'),
          paddingRight: styleAttribute('paddingRight'),
          border: styleAttribute('border'),
          borderBottom: styleAttribute('borderBottom'),
          borderTop: styleAttribute('borderTop'),
          borderLeft: styleAttribute('borderLeft'),
          borderRight: styleAttribute('borderRight'),
          borderBottomColor: styleAttribute('borderBottomColor'),
          borderTopColor: styleAttribute('borderTopColor'),
          borderLeftColor: styleAttribute('borderLeftColor'),
          borderRightColor: styleAttribute('borderRightColor'),
        },
      },
    ]
  },
})

export default PasteHelper
