import Axios from 'axios'

export default {
  summary(params) {
    return Axios.post('/api/admin/analytics/culture/summary', params)
  },
  engagement(params) {
    return Axios.post('/api/admin/analytics/culture/engagement', params)
  },
  activeSigners(params) {
    return Axios.post('/api/admin/analytics/culture/active_signers', params)
  },
  shoutoutActivity(params) {
    return Axios.post('/api/admin/analytics/culture/shoutout_activity', params)
  },
  exportEngagementXlsx(params) {
    return Axios.post('/api/admin/analytics/culture/export_engagement_xlsx', params)
  },
}

