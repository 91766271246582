import { createSlice } from '@reduxjs/toolkit'

import { defaultActions } from 'redux/slices/utils/commonReducers'

export type GlobalStorageState = any

export const initialState = {}

const globalStorageSlice = createSlice({
  name: 'globalStorage',
  initialState,
  reducers: {
    ...defaultActions,
    store(state, action) {
      state[action.payload.globalStateBucket] = action.payload.data
    },
  },
})

export default globalStorageSlice
