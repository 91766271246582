import Image from '@tiptap/extension-image'
import { mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import ImageResizer from 'components/common/tiptap/extensions/nodeViews/imageResizer'
import styleAttribute from 'components/common/tiptap/extensions/utils/styleAttribute'

export const RICH_TEXT_VALID_IMAGE_EXTENSIONS = ['.png', '.jpg', '.gif', '.webp', '.jpeg']
export const RICH_TEXT_VALID_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/gif', 'image/webp']

export const STYLE_ATTRIBUTES = ['textAlign', 'border', 'borderRadius', 'boxShadow', 'float', 'width', 'height']

const ClearyImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      // this attribute will be used to store the ActiveStorage::Attachment id
      'data-image-id': {
        default: null,
      },

      'display': {
        default: null,
      },

      'href': {
        default: null,
      },

      'target': {
        default: null,
      },

      'borderRadius': styleAttribute('borderRadius'),
      'boxShadow': styleAttribute('boxShadow'),
      'border': styleAttribute('border'),
      'float': styleAttribute('float'),
      'width': styleAttribute('width'),
      'height': styleAttribute('height'),
    }
  },

  renderHTML({ HTMLAttributes }) {
    if (HTMLAttributes.href) {
      return [
        'a',
        { href: HTMLAttributes.href, target: HTMLAttributes.target },
        ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
      ]
    }

    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageResizer)
  },
})

type OptionsType = {
  src: string
  alt?: string
  title?: string
  width?: number
  height?: number
  'data-image-id'?: number
  display?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blockImage: {
      setBlockImage: (options: OptionsType) => ReturnType,
    },

    inlineImage: {
      setInlineImage: (options: OptionsType) => ReturnType,
    },
  }
}

export const ClearyBlockImage = ClearyImage.extend({
  name: 'blockImage',
  group: 'block',
  inline: false,

  addAttributes() {
    return {
      ...this.parent?.(),

      display: {
        default: 'block',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img',
        getAttrs: (node: any) => node.attributes.display?.value === 'block' && null, // ProseMirror expects null when the check is successful
      },
    ]
  },


  renderHTML({ HTMLAttributes }) {
    // we need to pass the textAlign property to the div tag otherwise the image won't align as expected
    const match = HTMLAttributes.style?.match(/text-align:\s*(.*?)(?:;|$)/)
    const divStyle = match ? match[0] : ''

    if (HTMLAttributes.href) {
      return [
        'div',
        { style: divStyle },
        [
          'a',
          { href: HTMLAttributes.href, target: HTMLAttributes.target },
          ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
        ],
      ]
    }

    return [
      'div',
      { style: divStyle },
      ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
    ]
  },

  addCommands() {
    return {
      setBlockImage: options => ({ commands }) => commands.insertContent({
        type: this.name,
        attrs: options,
      }),
    }
  },
})

export const ClearyInlineImage = ClearyImage.extend({
  name: 'inlineImage',
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      ...this.parent?.(),

      display: {
        default: 'inline',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img',
        getAttrs: (node: any) => node.attributes.display?.value !== 'block' && null, // ProseMirror expects null when the check is successful
      },
    ]
  },

  addCommands() {
    return {
      setInlineImage: options => ({ commands }) => commands.insertContent({
        type: this.name,
        attrs: options,
      }),
    }
  },
})

export default ClearyImage
