import React from 'react'
import Modal from 'components/common/modal'
import InlineImageCrop from 'components/common/image_crop/inlineImageCrop'


const ModalImageCrop = ({ visible, onCancel, ...props }) => (
  <Modal
    visible={visible}
    toggle={onCancel}
  >
    <InlineImageCrop
      {...props}
      onCancel={onCancel}
      areaHeight='300px'
    />
  </Modal>
)

export default ModalImageCrop
